<div class="modal-content modal-container">
  <div class="">
    <h4>Request Submission</h4>
  </div>
  <div class="modal-description">
    <p>What would you like to request?*</p>
    <textarea name="request-text" id="request-text" cols="20" rows="5" class="request-text" placeholder="Type your request" [(ngModel)]="requestText"></textarea>
  </div>
  <div class="btn-container">
    <button type="button" class="cancel-btn" (click)="close()">
      Cancel
    </button>
    <button type="button" class="submit-btn" (click)="confirm()" [disabled]="requestText === '' || null || undefined" >
      Submit
    </button>
  </div>
</div>
