<div class="modal-dialog">
  <div class="modal-content modal-container">
    <div>
      <h4>Search sapcode by description</h4>
    </div>
    <div style="text-align: left;">
      <label class="modal-description">Search raw material or packaging</label>
      <input type="text" [(ngModel)]="description" (change)="filterRawMaterials()"/>
    </div>
    <div class="results">
      <div class="result-row d-flex justify-content-between" *ngFor="let item of results; let i = index" (click)="selectResult(i)" [ngClass]="{'selected': item.selected}">
        <p>{{item.material || item.componente || item.materialSap}}</p>
        <P *ngIf="this.typeConsult === 'Ingrediente'" style="max-width: 200px; text-align: right">{{item.txt_material || item.nombreComponente || item.texto}}</P>
        <P *ngIf="this.typeConsult === 'Empaque'">{{item.descripcion}}</P>
      </div>
      <p *ngIf="!results.length" class="text-warning text-center mt-2">No hay resultados</p>
    </div>
    <div class="text-center mt-3">
      <div>
        <button type="button" class="submit" (click)="confirm()">Submit</button>
      </div>
      <div>
        <button type="button" class="cancel mt-3" (click)="closeModal()">Cancel</button>
      </div>

    </div>

  </div>
</div>
