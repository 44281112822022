import { Component, Input, OnInit } from '@angular/core';
import { InclusionsFlavorsService } from '../../../../core/services/inclusions-flavors/inclusions-flavors.service';
import { TitleCasePipe } from '@angular/common';
import { InclusionModalComponent } from './inclusion-modal/inclusion-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { FlavorModalComponent } from './flavor-modal/flavor-modal.component';
import { RequestModalComponent } from '../../../shared/request-modal/request-modal.component';
import { WebSocketService } from '../../../../core/services/web-socket/web-socket.service';
import * as _ from 'lodash';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-inclusions-and-flavors',
  templateUrl: './inclusions-and-flavors.component.html',
  styleUrls: ['./inclusions-and-flavors.component.css'],
})
export class InclusionsAndFlavorsComponent implements OnInit {
  /** This array stores the ingredient specs selected in the basics. */
  @Input() ingredientSpecs: any[] = [];

  /** This variable is a boolean for disabled any step**/
  @Input() stepValidation: any;

  /** This attribute is used to handle the events of the extra inclusion checkbox. */
  addInclusion = false;

  /** This array stores the selectable inclusions, in case an extra inlusion is added. */
  extraInclusions: any[] = [];

  /** This array stores the selectable inclusions. */
  inclusions: any[] = [];

  /** This array stores the inclusions filtered by ingredient specs and category. */
  filteredInclusions: any[] = [];

  /** This array stores the selectable flavors. */
  flavors: any[] = [];

  /** This array stores the flavors filtered by ingredient specs and category. */
  filteredFlavors: any[] = [];

  /** The subcategories values for filtering inclusions and flavors. */
  filters: any = {
    inclusionsFilters: [],
    flavorsFilters: [],
  };

  /** The values selected by the user for filtering flavors and/or inclusions. */
  selectedSubcategories: any = {
    flavorSubcategory: '',
    baseSubcategory: '',
    extraSubcategory: '',
  };

  baseInclusionSelected = false;

  selectedOptions: any;

  validationDragees: any;

  constructor(
    private inclusionsFlavorsService: InclusionsFlavorsService,
    private titlecase: TitleCasePipe,
    private sms: SimpleModalService,
    private webSocketService: WebSocketService
  ) {}

  async ngOnInit() {
    await this.loadInclusions();
    await this.loadFlavors();
    this.filterByIngredientSpecs();
    await this.loadFilters();
    this.listenSocket();
    let shape = JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
    this.validationDragees = shape.texto === 'Dragees' || this.selectedOptions?.application.name === 'Dragees';
  }

  /**
   * This function filters both inclusions and flavors by the ingredient specs selected.
   */
  filterByIngredientSpecs() {
    if (this.ingredientSpecs) {
      this.filterInclusionsBySpecs();
      this.filterFlavorsBySpecs();
    }
  }

  /**
   * This function filters the inclusions by ingredient specs.
   */
  filterInclusionsBySpecs() {
    let filtered = [];
    filtered = this.filteredInclusions.filter((f: any) => {
      const decide = this.ingredientSpecs.every((s: any) => {
        const spec = f.specs?.split(',').includes(s.key);
        return spec;
      });
      return decide;
    });
    this.filteredInclusions = _.uniq(filtered);
    this.inclusions = this.filteredInclusions;
  }

  /**
   * This function filters the flavors by ingredient specs.
   */
  filterFlavorsBySpecs() {
    let filtered = [];
    filtered = this.filteredFlavors.filter((f: any) => {
      const decide = this.ingredientSpecs.every((s: any) => {
        const spec = f.specs?.split(',').includes(s.key);
        return spec;
      });
      return decide;
    });
    this.filteredFlavors = _.uniq(filtered);
    this.flavors = this.filteredFlavors;
  }

  /**
   * This function handles the changes when clicking the extra inclusion checkbox.
   */
  addExtraInclusion() {
    const inclusion = JSON.parse(localStorage.getItem('inclusion') || '{}');
    this.addInclusion = inclusion?.base ? true : false;
    if (this.addInclusion) {
      this.extraInclusions = this.filteredInclusions;
      for (let i = 0; i < this.extraInclusions.length; i++) {
        this.extraInclusions[i].selected = false;
      }
      if (this.webSocketService.getRole == 'admin') {
        this.webSocketService.emitToClient({ type: 'select', step: 'extra-inclusion', selection: this.addInclusion });
      }
    }
  }

  /**
   * This function listens to the socket, waiting for changes in the selection of the commercial, and selects the inclusions and flavors in the screen of the client.
   */
  listenSocket() {
    this.webSocketService.listen().subscribe((data: any) => {
      if (this.webSocketService.getRole != 'admin' && data.object.step == 'extra-inclusion') {
        this.addInclusion = data.object.selection;
        this.addExtraInclusion();
      } else if (
        this.webSocketService.getRole != 'admin' &&
        data.object.step == 'inclusions' &&
        data.object.inclusion
      ) {
        if (data.object.inclusion == 'base') {
          for (const element of this.inclusions) {
            element.selected = false;
          }
          this.inclusions[data.object.index].selected = !this.inclusions[data.object.index].selected;
          this.baseInclusionSelected = this.inclusions[data.object.index].selected ? true : false;
        } else {
          for (let i = 0; i < this.extraInclusions.length; i++) {
            this.extraInclusions[i].selected = false;
          }
          this.extraInclusions[data.object.index].selected = !this.extraInclusions[data.object.index].selected;
        }
        localStorage.setItem('inclusion', JSON.stringify(data.object.inclusions));
      } else if (this.webSocketService.getRole != 'admin' && data.object.step == 'flavor' && data.object.flavor) {
        for (let i = 0; i < this.flavors.length; i++) {
          this.flavors[i].selected = false;
        }
        this.flavors[data.object.index].selected = !this.flavors[data.object.index].selected;
        localStorage.setItem('flavor', JSON.stringify(data.object.flavor));
      }
    });
  }

  /**
   * This function loads the flavors to display as selectable.
   */
  async loadInclusions() {
    this.inclusions = await this.inclusionsFlavorsService.getByCategory('INCLUSIONS');
    this.filteredInclusions = this.inclusions;
    this.loadInclusionsImages('base');
    this.selectedOptions = JSON.parse(localStorage.getItem('selectedOptions') || '{}');
  }

  /**
   * This function loads the flavors to display as selectable.
   */
  async loadFlavors() {
    this.flavors = await this.inclusionsFlavorsService.getByCategory('FLAVORS');
    this.filteredFlavors = this.flavors;
    this.loadFlavorImages();
  }

  /**
   * This function loads all the images for the inclusions, even after filtering.
   * @param inclusionType The type of inclusion, the starting inclusion or an extra inclusion.
   */
  loadInclusionsImages(inclusionType: string) {
    if (inclusionType == 'base') {
      for (const element of this.inclusions) {
        element.selected = false;
        element.img = `https://luker-recursos.s3.amazonaws.com/create/inclusions-and-flavors/inclusions/${element.codigoSap}.jpg`;
      }
    } else {
      for (const element of this.extraInclusions) {
        element.selected = false;
        element.img = `https://luker-recursos.s3.amazonaws.com/create/inclusions-and-flavors/inclusions/${element.codigoSap}.jpg`;
      }
    }
  }

  /**
   * This function loads all the images for flavors, even after filtering.
   */
  loadFlavorImages() {
    for (let i = 0; i < this.flavors.length; i++) {
      this.flavors[i].selected = false;
      this.flavors[
        i
      ].img = `https://luker-recursos.s3.amazonaws.com/create/inclusions-and-flavors/flavors/${this.flavors[i].codigoSap}.jpg`;
    }
  }

  /**
   * This function loads all the values of the subcategories for inclusions and flavors.
   */
  async loadFilters() {
    this.filters.inclusionsFilters = await this.inclusionsFlavorsService.getSubcategories('INCLUSIONS');
    this.filters.flavorsFilters = await this.inclusionsFlavorsService.getSubcategories('FLAVORS');
  }

  /**
   * This function handles the changes of the flavors and inclusions options selected by the user, depending on which category the user made the change from.
   * @param category The category where the item was selected or changed.
   * @param pos The position of the selected item.
   */
  handleClickSelection(category: string, pos: number) {
    if (category == 'inclusions' || category == 'extraInclusions') {
      this.handleClickInclusions(category, pos);
    } else if (category == 'flavors') {
      this.handleClickFlavors(pos);
    }
  }

  /**
   * This function handles the selections and changes made on the inclusions.
   * @param inclusionType The type of inclusion, the starting inclusion or an extra inclusion.
   * @param pos The position of the selected inclusion.
   */
  handleClickInclusions(inclusionType: string, pos: number) {
    if ((inclusionType == 'inclusions' && this.stepValidation === false) || this.stepValidation === undefined) {
      for (const element of this.inclusions) {
        element.selected = false;
      }
      this.inclusions[pos].selected = !this.inclusions[pos].selected;
      this.openInclusionModal(this.inclusions[pos], 'base', pos);
    } else if (inclusionType == 'extraInclusions' && this.stepValidation === false) {
      for (const element of this.extraInclusions) {
        element.selected = false;
      }
      this.extraInclusions[pos].selected = !this.extraInclusions[pos].selected;
      this.openInclusionModal(this.extraInclusions[pos], 'extra', pos);
    }
  }

  /**
   * This function handles the selections and changes made on the flavors.
   * @param pos The position of the selected flavor.
   */
  handleClickFlavors(pos: number) {
    if (this.validationDragees === false) {
      if (this.stepValidation === false) {
        for (const element of this.flavors) {
          element.selected = false;
        }
        this.flavors[pos].selected = !this.flavors[pos].selected;
        this.openFlavorModal(this.flavors[pos], pos);
      }
    }
  }

  /**
   * This function handles the changes of the subcategory filter of inclusions and flavors.
   * @param category The category of ingredient selected. It can be a base or extra inclusion, or a flavor.
   */
  async filterBySubcategory(category: string) {
    switch (category) {
      case 'base':
        if (this.selectedSubcategories.baseSubcategory) {
          this.inclusions = this.filteredInclusions.filter(
            (i: any) => i.categoria == 'INCLUSIONS' && i.subcategoria == this.selectedSubcategories.baseSubcategory
          );
        } else {
          this.inclusions = this.filteredInclusions;
        }
        this.loadInclusionsImages('base');
        break;

      case 'extra':
        if (this.selectedSubcategories.extraSubcategory) {
          this.extraInclusions = this.filteredInclusions.filter(
            (i: any) => i.categoria == 'INCLUSIONS' && i.subcategoria == this.selectedSubcategories.extraSubcategory
          );
        } else {
          this.extraInclusions = this.filteredInclusions;
        }
        this.loadInclusionsImages('extra');
        break;

      case 'flavor':
        if (this.selectedSubcategories.flavorSubcategory) {
          this.flavors = this.filteredFlavors.filter(
            (f: any) => f.categoria == 'FLAVORS' && f.subcategoria == this.selectedSubcategories.flavorSubcategory
          );
        } else {
          this.flavors = this.filteredFlavors;
        }
        this.loadFlavorImages();
        break;

      default:
        break;
    }
  }

  /**
   * This function removes an ingredient from local storage.
   * @param type The category of ingredient to remove. It can be a base or extra inclusion, or a flavor.
   */
  removeFromLocalStorage(type: string) {
    const inclusion = JSON.parse(localStorage.getItem('inclusion') || '{}');
    inclusion[type] = {};
    localStorage.setItem('inclusion', JSON.stringify(inclusion));
  }

  /**
   * This function opens a modal to change the inclusion details.
   * @param inclusion The inclusion object selected by the user.
   * @param inclusionType The type of inclusion selected. It can be base or extra.
   */
  openInclusionModal(inclusion: any, inclusionType: string, pos: number) {
    const modal = this.sms
      .addModal(
        InclusionModalComponent,
        { pickedInclusion: inclusion, data: inclusionType },
        { autoFocus: true, closeOnClickOutside: true }
      )
      .subscribe((result) => {
        if (!result) {
          if (inclusionType == 'base') {
            this.inclusions[pos].selected = false;
          } else {
            this.extraInclusions[pos].selected = false;
          }
        } else {
          if (inclusionType == 'base') {
            this.baseInclusionSelected = true;
          }
          if (this.webSocketService.getRole == 'admin') {
            this.webSocketService.emitToClient({
              type: 'select',
              step: 'inclusions',
              inclusion: inclusionType,
              index: pos,
              inclusions: result,
            });
          }
        }
      });
  }

  /**
   * This function opens a modal to change the flavor details.
   * @param flavor The flavor object selected by the user.
   */
  openFlavorModal(flavor: any, pos: number) {
    const modal = this.sms
      .addModal(FlavorModalComponent, { data: flavor }, { autoFocus: true, closeOnClickOutside: true })
      .subscribe((result) => {
        if (!result) {
          this.flavors[pos].selected = false;
        } else {
          if (this.webSocketService.getRole == 'admin') {
            this.webSocketService.emitToClient({ type: 'select', step: 'flavor', index: pos, flavor: result });
          }
        }
      });
  }

  /**
   * This function opens a modal to make comments in the current step.
   */
  openRequestModal() {
    const modal = this.sms.addModal(RequestModalComponent, {}, { autoFocus: true, closeOnClickOutside: true });
  }

  /**
   * This function removes the ingredient selected previously.
   * @param category The category of ingredient selected. It can be a base or extra inclusion, or a flavor.
   * @param pos The index of the selected element.
   */
  removeIngredient(category: string, pos: number) {
    switch (category) {
      case 'base':
        this.inclusions[pos].selected = false;
        break;

      case 'extra':
        this.extraInclusions[pos].selected = false;
        break;

      case 'flavor':
        this.flavors[pos].selected = false;
        break;

      default:
        break;
    }
    this.removeFromLocalStorage(category);
  }
}
