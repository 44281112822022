// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://develop-luker.imagineapps.co',
  // baseURL: 'http://localhost:8098',
  npdURL: 'https://develop-npd.lukercreator.imagineapps.co',
  urlSalesForceAuth: 'https://lukerchocolate--lukercreat.sandbox.my.salesforce.com/services/oauth2/token',
  clientIdSalesForce: '3MVG9Fy_1ZngbXqO2ABvk..EH_k1PzDou66__.XL9mxTjpQULp0RqBHHsbqO9t9_B7cLPJnKC66VmYuXdkSl5',
  clientSecretSalesForce: 'CFC365649B4342F7629BCB496E46BC9E09E0E5EB2D4D95F942E27DD196D3EF5A',
  usernameSalesForce: 'dmvalbuena@lukerchocolate.com.lukercreat',
  SalesForcePwd: 'luker.2023!',
  urlSalesForceServices: 'https://lukerchocolate--lukercreat.sandbox.my.salesforce.com/services/data/v55.0/sobjects',
  utlQuerySalesforce: 'https://lukerchocolate--lukercreat.sandbox.my.salesforce.com/services/data/v55.0/query?q=',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
