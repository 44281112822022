<div class="main-container">
  <div>
    <p class="title-basics">Let's get the basics</p>
  </div>
    <div class="selector-container">
        <ng-container>
            <div *ngIf="screenType == 'ingredient'" class="text-center w-100">
                <p class="category">Application</p>
              <div class="container-options">
                <p class="category-options" *ngFor="let item of options.applicationOptions; let i = index" [class.selected]="item.selected" (click)="handleClickSelection('applicationOptions', i)">{{item.name}}</p>
              </div>
            </div>

            <div *ngIf="screenType == 'product'" class="text-center w-100">
                <p class="category">Product Category</p>
              <div class="container-options">
                <p class="category-options" *ngFor="let item of options.categoryOptions; let i = index" [class.selected]="item.selected" (click)="handleClickSelection('categoryOptions', i)">{{item.name}}</p>
              </div>
            </div>
        </ng-container>
        <div class="separator"></div>
        <div class="text-center w-100">
            <p class="category">Recipes</p>
            <div class="container-options">
              <p class="category-options" *ngFor="let item of options.recipesOptions; let i = index" [class.selected]="item.selected" (click)="handleClickSelection('recipesOptions', i)">{{item.name}}</p>
            </div>
        </div>
        <div class="separator"></div>
        <div class="type-container w-100">
            <p class="category">Chocolate Type</p>
          <div class="container-options-chocolate">
            <div class="chocolate-type" *ngFor="let item of options.filteredChocolateType; let i = index" (click)="handleClickSelection('filteredChocolateType', i)">
              <img src="{{item.img}}" alt="" [class.selected]="item.selected">
              <p class="category-options">{{item.name | titlecase}}</p>
            </div>
          </div>
        </div>
        <div class="separator"></div>
        <div class="w-100">
            <p class="category">Lead time</p>
            <div class="container-options">
              <p class="category-options" *ngFor="let item of options.leadtimeOptions; let i = index" [class.selected]="item.selected" (click)="handleClickSelection('leadtimeOptions', i)">{{item.name}}</p>
            </div>
        </div>
        <div class="separator"></div>
        <div class="w-100">
            <p class="category">Ingredient Specs</p>
            <div class="container-options">
              <p class="category-options" *ngFor="let item of options.ingredientSpecsOptions; let i = index" [class.selected]="item.selected" (click)="handleClickSelection('ingredientSpecsOptions', i)">{{item.name}}</p>
            </div>
        </div>
    </div>
</div>

<div class="footer-container">
    <img class="footer-logo" src="./../../../../assets/footer/Luker Chocolate Logo (white).png" alt="">
    <button (click)="changeToStepper()" class="footer-button">Start creating</button>
</div>
