<div class="main-container">
    <!-- <div class="info-container">
        <p class="consult-title">Cost Consulting</p>
    </div> -->
    <div style="display: flex; width: 100%; align-items: center; padding: 0px 40px; margin-top: 10px;">
        <div class="search-options" >
            <button class="option-btn" style="" (click)="handleSearchType('ingredient')" [ngClass]="{'active': searchType == 'ingredient'}">Ingredient</button>
            <button class="option-btn" style="" (click)="handleSearchType('packaging')" [ngClass]="{'active': searchType == 'packaging'}">Packaging</button>
          <button class="option-btn" style="" (click)="handleSearchType('rawMaterialOne')" [ngClass]="{'active': searchType == 'rawMaterialOne'}">Raw material one</button>
    <!--            <button class="option-btn" (click)="handleSearchType('priceChanges')" [ngClass]="{'active': searchType == 'priceChanges'}">Price changes</button>-->
            <button class="option-btn" (click)="handleSearchType('transport')" [ngClass]="{'active': searchType == 'transport'}">Consult transport</button>
    <!--          <button class="option-btn" (click)="handleSearchType('priceTrends')" [ngClass]="{'active': searchType == 'priceTrends'}">Price Trends</button>-->
        </div>
    </div>
    <div class="search-results" *ngIf="searchType === 'ingredient' || searchType === 'packaging'">
        <div class="filters" *ngIf="searchType == 'ingredient'">
            <div class="filter-container">
                <div class="container__dropdown">
                    <label htmlFor="category">Category</label>
                    <p-dropdown  [showClear]="true" [(ngModel)]="ingredientFilterSelections.selectedCategory" 
                    (ngModelChange)="filterByCategory($event); filterAllRegisters()" 
                    styleClass="primeng-dropdown" placeholder="Select one option" [options]="ingredientFilters.categories">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">
                                <div>{{ ingredientFilterSelections.selectedCategory }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-category pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ category }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="container__dropdown">
                    <label htmlFor="category">Supplier</label>
                    <p-dropdown  [showClear]="true" [(ngModel)]="ingredientFilterSelections.selectedProvider" 
                    (ngModelChange)="filterAllRegisters()" 
                    styleClass="primeng-dropdown" placeholder="Select one option" [options]="ingredientFilters.providers">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">
                                <div>{{ ingredientFilterSelections.selectedProvider}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-supplier pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ supplier }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="btn-container">
                    <button class="clear-btn" [ngClass]="{'enable-clear': (ingredientFilterSelections.selectedProvider || ingredientFilterSelections.selectedCategory)}" (click)="clearFilters('ingredient')" [disabled]="(!ingredientFilterSelections.selectedProvider && !ingredientFilterSelections.selectedCategory)">Clear</button>
                </div>
            </div>
            <p-divider styleClass="divider__filters" layout="vertical"></p-divider>
            <div id="filter-conatiner-margin-top" class="filter-container">
                <div class="container__input container__input-filters">
                    <label htmlFor="clientname">Description</label>
                    <input placeholder="Write here" styleClass="primeng-input" (ngModelChange)="handleSearchBars('description')" pInputText [(ngModel)]="description" id="description"/>
                </div>
                <div class="container__input container__input-filters">
                    <label htmlFor="clientname">SAP Code</label>
                    <input placeholder="Write here" styleClass="primeng-input" (ngModelChange)="handleSearchBars('sap')" pInputText [(ngModel)]="sapCode" id="description"/>
                </div>
                <div class="btn-container">
                    <button class="clear-btn" [ngClass]="{'enable-filter': (sapCode || description)}" (click)="clearFilters('ingredient')" [disabled]="(!sapCode && !description)">Search</button>
                </div>
            </div>
        </div>
        <div class="filters" *ngIf="searchType == 'packaging'">
            <div class="filter-container-packaging-1">
                <div class="container__dropdown">
                    <label htmlFor="type">Package type</label>
                    <p-dropdown [showClear]="true" [(ngModel)]="packagingFilterSelections.selectedType" 
                    (ngModelChange)="filterSearch()" 
                    styleClass="primeng-dropdown" placeholder="Select one option" [options]="packagingFilters.types">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">
                                <div>{{ packagingFilterSelections.selectedType }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-type pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ type }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="container__dropdown">
                    <label htmlFor="classification">Classification</label>
                    <p-dropdown [showClear]="true" [(ngModel)]="packagingFilterSelections.selectedClassification" 
                    (ngModelChange)="filterSearch()" 
                    styleClass="primeng-dropdown" placeholder="Select one option" [options]="packagingFilters.classifications">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">
                                <div>{{ packagingFilterSelections.selectedClassification}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-classification pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ classification }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="container__dropdown">
                    <label htmlFor="material">Material</label>
                    <p-dropdown [showClear]="true" [(ngModel)]="packagingFilterSelections.selectedMaterial" 
                    (ngModelChange)="filterSearch()" 
                    styleClass="primeng-dropdown" placeholder="Select one option" [options]="packagingFilters.materials">
                        <ng-template pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">
                                <div>{{ packagingFilterSelections.selectedMaterial}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-material pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <div>{{ material }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="btn-container">
                    <button class="clear-btn" [ngClass]="{'enable-clear': (packagingFilterSelections.selectedMaterial || packagingFilterSelections.selectedClassification || packagingFilterSelections.selectedType)}" (click)="clearFilters('packaging')" [disabled]="(!packagingFilterSelections.selectedMaterial && !packagingFilterSelections.selectedClassification && !packagingFilterSelections.selectedType)">Clear</button>
                </div>
            </div>
            <p-divider styleClass="divider__filters" layout="vertical"></p-divider>
            <div id="filter-conatiner-margin-top" class="filter-container-packaging-2">
                <div class="container__input">
                    <label htmlFor="clientname">Description</label>
                    <input placeholder="Write here" styleClass="primeng-input" (ngModelChange)="handleSearchBars('description')" pInputText [(ngModel)]="description" id="description"/>
                </div>
                <div class="container__input">
                    <label htmlFor="clientname">SAP Code</label>
                    <input placeholder="Write here" styleClass="primeng-input" (ngModelChange)="handleSearchBars('sap')" pInputText [(ngModel)]="sapCode" id="description"/>
                </div>
                <div class="btn-container">
                    <button class="clear-btn" [ngClass]="{'enable-filter': (sapCode || description)}" (click)="clearFilters('ingredient')" [disabled]="(!sapCode && !description)">Search</button>
                </div>
            </div>

        </div>
        <div class="results-table">
            <div class="side-search col-4 col-sm-12">
                <!-- <div class="result-list"> -->
                    <div class="container__product-results-text">Product results</div>
                    <p-divider styleClass="divider__side-search"></p-divider>

                    <p-table [scrollable]="true" *ngIf="searchResults.length && searchType == 'ingredient'" [columns]="columnsIngredient" [value]="searchResults" [tableStyle]="{ 'width': '100%' }">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columnsIngredient">
                                    {{ col.header }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-columns="columns">
                            <tr [ngClass]="{'selected-result': rowData.selected}" (click)="loadResult(i)">
                                <td *ngFor="let col of columnsIngredient">
                                    {{ rowData[col.field] }}
                                </td>
                                <!-- <td>
                                    {{ rowData[columns[0].field] }}
                                </td>
                                <td>
                                    {{ rowData[columns[1].field] }}
                                </td> -->
                            </tr>
                        </ng-template>
                    </p-table>

                    <p-table [scrollable]="true" *ngIf="searchResults.length && searchType == 'packaging'" [columns]="columnsPackaging" [value]="searchResults" [tableStyle]="{ 'width': '100%' }">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columnsPackaging">
                                    {{ col.header }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-columns="columns">
                            <tr [ngClass]="{'selected-result': rowData.selected}" (click)="loadResult(i)">
                                <td *ngFor="let col of columnsPackaging">
                                    {{ rowData[col.field] }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                    <div *ngIf="searchResults.length==0" class="no-results">
                        <div class="center-div">
                            <div>
                                Please, search for a product 
                            </div>
                            <div>
                                in order to see results.
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>

            <div style="overflow-y: auto;" class="search-result-table col-md-8 col-sm-12">
                <div style="margin-bottom: 16px;" class="container__accordion" [ngClass]="{'nopadding': !openAccordionPrice}">
                    <div class="accordion-header" [ngClass]="{'withmarging': !openAccordionPrice}" (click)="openCloseAccordionPrice()">
                        <div>
                            Price
                        </div>
                        <div>
                            <i *ngIf="openAccordionPrice" class="pi pi-chevron-up"></i>
                            <i *ngIf="!openAccordionPrice" class="pi pi-chevron-down"></i>
                        </div>
                    </div>
                    <div *ngIf="openAccordionPrice" class="accordion-body">
                        <p-divider styleClass="divider__accordion"></p-divider>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Price</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.minPrice | number : '1.2-2'}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Currency</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.currency}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Factor inconterm</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.factor}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Full price</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.fullPrice | number : '1.2-2' }}" readonly="readonly"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-bottom: 16px;" [ngClass]="{'nopadding': !openAccordion}" class="container__accordion">
                    <div class="accordion-header" [ngClass]="{'withmarging': !openAccordion}" (click)="openCloseAccordion()">
                        <div>
                         Product Details
                        </div>
                        <div>
                            <i *ngIf="openAccordion" class="pi pi-chevron-up"></i>
                            <i *ngIf="!openAccordion" class="pi pi-chevron-down"></i>
                        </div>
                    </div>
                    <div *ngIf="openAccordion && searchType === 'ingredient'" class="accordion-body">
                        <p-divider styleClass="divider__accordion"></p-divider>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Category</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.category}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Subcategory</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.subcategory}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Fact sheet</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.technichalSpecs}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Supplier</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.provider}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Status</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText [ngClass]="{'text-danger': itemFound.status === 'Vencido'}"
                                value="{{ itemFound.status.toUpperCase() }}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Last update</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.lastUpdate}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">MOQ</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.purchaseMin}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Units</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.units}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Incoterm</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.incoTerm}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Leadtime (days)</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.leadtime}}"/>
                            </div>
                        </div>
                        <!-- <div *ngIf="viewMore" class="view-more-content">
                            <span>Price</span>
                            <div class="product-detail">
                                <div class="container__input">
                                    <label htmlFor="clientname">Price</label>
                                    <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.minPrice | number : '1.2-2'}}"/>
                                </div>
                                <div class="container__input">
                                    <label htmlFor="clientname">Currency</label>
                                    <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.currency}}"/>
                                </div>
                            </div>
                            <div class="product-detail">
                                <div class="container__input">
                                    <label htmlFor="clientname">Factor inconterm</label>
                                    <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.factor}}"/>
                                </div>
                                <div class="container__input">
                                    <label htmlFor="clientname">Full price</label>
                                    <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.fullPrice | number : '1.2-2' }}" readonly="readonly"/>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="view-more" (click)="viewMoreInfo()">
                            <div>
                                <div>
                                 {{viewMore?"View less":"View more"}}
                                </div>
                                <div>
                                    <i *ngIf="viewMore" class="pi pi-chevron-up"></i>
                                    <i *ngIf="!viewMore" class="pi pi-chevron-down"></i>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div *ngIf="openAccordion && searchType === 'packaging'" class="accordion-body">
                        <p-divider styleClass="divider__accordion"></p-divider>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Packaging type</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.packageType}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Material</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.material}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Fact sheet</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.technichalSpecs}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Mechanical layout</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.mechanical}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Supplier</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.provider}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Status</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText [ngClass]="{'text-danger': itemFound.status === 'Vencido'}"
                                value="{{ itemFound.status.toUpperCase() }}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Last update</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.lastUpdate}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Incoterm</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.incoTerm}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Category</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.category}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Sustainability</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.sustainability}}"/>
                            </div>
                        </div>
                        <!-- <div *ngIf="viewMore" class="view-more-content">
                            <span>Price</span>
                        </div> -->
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Leadtime (days)</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.leadtime}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">MOQ</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.purchaseMin}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Units</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.units}}"/>
                            </div>
                            <!-- <div class="container__input">
                                <label htmlFor="clientname">Price</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.minPrice | number : '1.2-2'}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Currency</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.currency}}"/>
                            </div>
                            <div class="container__input">
                                <label htmlFor="clientname">Factor inconterm</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.factor}}"/>
                            </div>
                        </div>
                        <div class="product-detail">
                            <div class="container__input">
                                <label htmlFor="clientname">Full price</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" pInputText value="{{itemFound.fullPrice | number : '1.2-2' }}" readonly="readonly"/>
                            </div> -->
                            <div class="container__input">
                                <label htmlFor="clientname">MOQ und</label>
                                <input placeholder="-" styleClass="primeng-input" [readOnly]="true" value="{{itemFound.purchaseMin / (itemFound.ctdmin) * itemFound.porcentaje | number:'1.0-0'}}"  pInputText/>
                            </div>
                        </div>
                        
                        <!-- <div class="view-more" (click)="viewMoreInfo()">
                            <div>
                                <div>
                                 {{viewMore?"View less":"View more"}}
                                </div>
                                <div>
                                    <i *ngIf="viewMore" class="pi pi-chevron-up"></i>
                                    <i *ngIf="!viewMore" class="pi pi-chevron-down"></i>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div style="margin-bottom: 16px;" class="container__accordion" [ngClass]="{'nopadding': !openAccordion2}">
                    <div class="accordion-header" [ngClass]="{'withmarging': !openAccordion2}" (click)="openCloseAccordion2()">
                        <div>
                         Scales
                        </div>
                        <div>
                            <i *ngIf="openAccordion2" class="pi pi-chevron-up"></i>
                            <i *ngIf="!openAccordion2" class="pi pi-chevron-down"></i>
                        </div>
                    </div>
                    <div *ngIf="openAccordion2" class="accordion-body">
                        <p-divider styleClass="divider__accordion"></p-divider>
                        <div class="container__table-price">
                            <table id="sales-price">
                                <thead>
                                    <tr>
                                        <th>
                                            Scale quantity
                                        </th>
                                        <th>
                                            Units
                                        </th>
                                        <th>
                                            Scale price
                                        </th>
                                        <th>
                                            Curency
                                        </th>
                                        <th *ngIf="searchType == 'packaging'">
                                            Scale quantity (UND)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngFor="let item of scalesFilter">
                                        <td>{{item.quantity}}</td>
                                        <td>{{item.units}}</td>
                                        <td>{{item.price | number : '1.2-2'}}</td>
                                        <td>{{item.currency}}</td>
                                        <td *ngIf="searchType == 'packaging'">{{item.quantity / (itemFound.ctdmin) * itemFound.porcentaje | number:'1.0-0' || 0}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <ng-container>
                    <div class="results row">
                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'ingredient'">
                                <p class="result-name">Category</p>
                                <input type="text" name="category" id="category" class="result-value" value="{{itemFound.category}}" readonly="readonly">
                            </div>
                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'ingredient'">
                              <p class="result-name">Toolbox</p>
                              <input type="text" name="category" id="category" [class.toolbox]="itemFound.toolbox" class="result-value" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'packaging'">
                                <p class="result-name">Packaging type</p>
                                <input type="text" name="package-type" id="package-type" class="result-value" value="{{itemFound.packageType}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'packaging'">
                                <p class="result-name">Material</p>
                                <input type="text" name="material" id="material" class="result-value" value="{{itemFound.material}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Fact sheet</p>
                                <input type="text" name="technical-specs" id="technical-specs" class="result-value" value="{{itemFound.technichalSpecs}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'packaging'">
                                <p class="result-name">Mechanical layout</p>
                                <input type="text" name="mechanical" id="mechanical" class="result-value" value="{{itemFound.mechanical}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Supplier</p>
                                <input type="text" name="provider" id="provider" class="result-value" value="{{itemFound.provider}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                              <p class="result-name">Status</p>
                              <input type="text" name="status" id="status" class="result-value"
                                  [ngClass]="{'text-danger': itemFound.status === 'Vencido'}"
                                  value="{{ itemFound.status.toUpperCase() }}" readonly>
                          </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Last update</p>
                                <input type="text" name="last-update" id="last-update" class="result-value" value="{{itemFound.lastUpdate}}" readonly="readonly">
                            </div>
                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">IncoTerm</p>
                                <input type="text" name="inco" id="inco" class="result-value" value="{{itemFound.incoTerm}}" readonly="readonly">
                            </div>
                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'ingredient'">
                                <p class="result-name">Subcategory</p>
                                <input type="text" name="subcategory" id="subcategory" class="result-value" value="{{itemFound.subcategory}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'packaging'">
                                <p class="result-name">Category</p>
                                <input type="text" name="category" id="category" class="result-value" value="{{itemFound.category}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'packaging'">
                                <p class="result-name">sustainability</p>
                                <input type="text" name="sustainability" id="sustainability" class="result-value" value="{{itemFound.sustainability}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Leadtime (days)</p>
                                <input type="text" name="leadtime" id="leadtime" class="result-value" value="{{itemFound.leadtime}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">MOQ</p>
                                <input type="text" name="purchase-min" id="purchase-min" class="result-value" value="{{itemFound.purchaseMin}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Units</p>
                                <input type="text" name="purchase-min" id="purchase-min" class="result-value" value="{{itemFound.units}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Price</p>
                                <input type="text" name="min-price" id="min-price" class="result-value" value="{{itemFound.minPrice | number : '1.2-2'}}" readonly="readonly">
                            </div>

                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Currency</p>
                                <input type="text" name="min-price" id="min-price" class="result-value" value="{{itemFound.currency}}" readonly="readonly">
                            </div>
                      <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                        <p class="result-name">Factor incoterm</p>
                        <input type="text" name="inco" id="inco" class="result-value" value="{{itemFound.factor}}" readonly="readonly">
                      </div>
                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6">
                                <p class="result-name">Full price</p>
                                <input type="text" name="inco" id="inco" class="result-value" value="{{itemFound.fullPrice | number : '1.2-2' }}" readonly="readonly">
                            </div>
                            <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6" *ngIf="searchType == 'packaging'">
                                <p class="result-name">MOQ UND</p>
                                <input type="text" name="category" id="category" class="result-value" value="{{itemFound.purchaseMin / (itemFound.ctdmin) * itemFound.porcentaje | number:'1.0-0'}}" readonly="readonly">
                            </div>
                      <div class="col-md-4 col-lg-4 col-xl-4 col-sm-6 col-6"></div>
                    </div>
                  <div class="scales">
                    <table>
                      <caption style="display: none">Scales</caption>
                      <thead>
                      <tr>
                        <th scope="col">Scale Quantity</th>
                        <th scope="col">Units</th>
                        <th scope="col">Scale price</th>
                        <th scope="col">Currency</th>
                        <th id="min-unidades" *ngIf="searchType == 'packaging'">Scale Quantity (UND)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let item of scalesFilter">
                        <td>{{item.quantity}}</td>
                        <td>{{item.units}}</td>
                        <td>{{item.price | number : '1.2-2'}}</td>
                        <td>{{item.currency}}</td>
                        <td *ngIf="searchType == 'packaging'">{{item.quantity / (itemFound.ctdmin) * itemFound.porcentaje | number:'1.0-0' || 0}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container> -->
            </div>
        </div>
    </div>
<!--    <div *ngIf="searchType == 'priceChanges'" class="search-results">-->
<!--        <div class="card-prices">-->
<!--            <div class="btn-icon d-flex justify-content-end" >-->
<!--                <div class="mt-2">-->
<!--                    <select [(ngModel)]="typeRegister" (change)="loadChangePrices()">-->
<!--                        <option *ngFor="let item of typesOfRegister" [value]="item">{{item}}</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--                <div class="icon-container p-1" (click)="exportExcel()">-->
<!--                  <img src="./../../../assets/summary/PDF.png" alt="logo-export" class="export-img" />-->
<!--                </div>-->
<!--              </div>-->
<!--            <ng2-smart-table [settings]="settings" [source]="registersInfo">-->
<!--            </ng2-smart-table>-->
<!--        </div>-->
<!--    </div>-->
  <div *ngIf="searchType == 'transport'" class="search-result-raw">
    <app-transport-consult></app-transport-consult>
  </div>
<!--  <div *ngIf="searchType == 'priceTrends'" class="search-results">-->
<!--    <app-price-trends></app-price-trends>-->
<!--  </div>-->
  <div *ngIf="searchType === 'rawMaterialOne'" class="search-result-raw">
    <app-raw-material-one></app-raw-material-one>
  </div>
</div>

<!-- <div class="footer-container">
  <img class="footer-logo" src="./../../../../assets/footer/Luker Chocolate Logo (white).png" alt="logo">
</div> -->


