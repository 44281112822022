import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class ChocolateProfileService {
  chocolateProfile: any = {};

  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of a Chocolate Profile, by filtering with the chocolate Id.
   * @param chocId The id of the chocolate selected by the user.
   * @returns A promise of the Chocolate Profile filtered by chocolateId.
   */
  getChocolateProfileByChocId(chocId: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('chocolateId', chocId);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`perfil_chocolate/chocolate`, queryParams).subscribe(
        (response: any) => {
          this.chocolateProfile = response;
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
