<div class="modal-content">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class="img-check">
        <img src="/assets/project-brief/check.png" alt="image check succesfully">
      </div>
    </div>
    <div class="col-12 mt-4">
      <p class="title-modal text-center">Project created successfully</p>
    </div>
    <div class="col-12">
      <button class="btn-modal" (click)="goToHome()">Close</button>
    </div>
  </div>
</div>
