<div class="main-container">
  <div class="row">
    <div class="col-xl-5 col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div class="title">
        <p>Beyond Sustainability</p>
      </div>
      <div class="d-flex">
        <div class="img-container">
          <img src="./../../../assets/impact/chocolate-dream.png" alt="">
        </div>
        <div class="description-container">
          <p class="description">Luker Chocolate's collaborative plan to transform the chocolate value chain from its origin, building sustainable well-being in Colombia’s cocoa-producing regions with three main objectives:</p>
        </div>
      </div>
    </div>
    <div class="col-xl-7 col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12">
          <div class="card-container">
            <img src="./../../../../assets/sustainability/leaf.png" alt="" class="leaf-img">
            <div class="objective-card">
              <p class="number">1</p>
              <p class="text">Enhance farmers income through increased profitability, fair prices and stronger farmer associations.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12">
          <div class="card-container">
            <img src="./../../../../assets/sustainability/leaf.png" alt="" class="leaf-img">
            <div class="objective-card">
              <p class="number">2</p>
              <p class="text">Strengthen the social wellbeing of rural communities through education, entrepreneurship and human rights enforcement.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12">
          <div class="card-container">
            <img src="./../../../../assets/sustainability/leaf.png" alt="" class="leaf-img">
            <div class="objective-card">
              <p class="number">3</p>
              <p class="text">Maintain environmental balance through the protection of water and forests.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 col-sm-12">
          <div class="footer-sustainability">
            <div class="btn-container" (click)="redirectToProjects()">
              <span class="btn-text">See more of our projects</span>
              <div class="logo-container">
                <img src="./../../../../assets/sustainability/our-projects.png" alt="" class="logo">
              </div>
              <div class="icon-container">
                <img src="./../../../../assets/sustainability/next-arrow.png" alt="" class="icon">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
