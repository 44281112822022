<div class="main-container">
  <div class="step-info row">
    <p class="step-title text-center">{{ stepName }}</p>
  </div>
  <div class="d-flex justify-content-center" style="padding-bottom: 5rem;">
    <div class="card-transporting mt-4 pb-3 container">
      <div class="container transport-container">
        <div class="row">
          <strong class="title-transport pt-4">Container</strong>
          <div class="col-6 pt-2">
            <label class="subtitle-transport">Type of container</label>
            <div class="mt-2">
              <select [(ngModel)]="typeContainerSelected" (change)="selectedOptions()">
                <option *ngFor="let i of typeContainers" value='{{i}}'>{{i}}</option>
              </select>
            </div>
          </div>
          <div class="col-6 pt-2">
            <label class="subtitle-transport">Number of containers</label>
            <div style="margin-top: 0.5rem;">
              <input type="number" placeholder="Number of containers" min="0" max="100" [(ngModel)]="numberOfContainers" (change)="selectedOptions()">
              <p *ngIf="numberOfContainers < 0" class="text-danger">El número de contenedores no puede ser negativo</p>
            </div>
          </div>
          <strong class="title-transport pt-4">Destination</strong>
          <div class="col-6 pt-2">
            <label class="subtitle-transport">Incoterm</label>
            <div class="mt-2">
              <select [(ngModel)]="typeIncoterm" (change)="selectedOptions()">
                <option value="FOB">FOB</option>
                <option value="CIF">CIF</option>
              </select>
            </div>
          </div>
          <div class="col-6 pt-2">
            <label class="subtitle-transport">Source port</label>
            <div class="mt-2">
              <select [(ngModel)]="portSourceSelected" (change)="selectedOptions()">
                <option *ngFor="let i of portSources" value='{{i}}'>{{i}}</option>
              </select>
            </div>
          </div>
          <div class="col-6 pt-2" *ngIf="typeIncoterm == 'CIF'">
            <label class="subtitle-transport">Destination country</label>
            <div class="mt-2">
              <select [(ngModel)]="countrieSelected" (change)="selectedOptions()">
                <option *ngFor="let i of countriesRegistersFilter" value='{{i}}'>{{i}}</option>
              </select>
            </div>
          </div>
          <div class="col-6 pt-2" *ngIf="typeIncoterm == 'CIF'">
            <label class="subtitle-transport">Destination port</label>
            <div class="mt-2">
              <select [(ngModel)]="portDestinationSelected" [value]="portDestinationSelected" (change)="selectedOptions()">
                <option>{{portDestinationFilter}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
