<div class="nutritional-table" id="nutritional-table-pdf">
  <h4>Nutrition facts</h4>
  <div class="d-flex justify-content-between">
    <strong>Based on</strong>
    <strong>100gr / 3,5 oz</strong>
  </div>
  <div class="border-nutritional"></div>
  <div class="d-flex justify-content-between mt-2">
    <strong>Calories</strong>
    <strong>{{totalNutritionalCost?.calorias | number: '1.2-2'}} kcal</strong>
  </div>
  <div class="border-nutritional"></div>
  <div class="d-flex justify-content-between mt-1">
    <strong>Total Protein</strong>
    <strong>{{totalNutritionalCost?.proteinas | number: '1.2-2'}} g</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Total fat</strong>
    <strong>{{totalNutritionalCost?.totalGrasas| number: '1.2-2'}} g</strong>
  </div>
  <div class="d-flex justify-content-between" style="margin-left: 20px">
    <strong>Saturated fat</strong>
    <strong>{{totalNutritionalCost?.grasasSaturadas| number: '1.2-2'}}</strong>
  </div>
  <div class="d-flex justify-content-between" style="margin-left: 20px">
    <strong>Trans Fat</strong>
    <strong>{{totalNutritionalCost?.transFat| number: '1.2-2'}}</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Cholesterol</strong>
    <strong>{{totalNutritionalCost?.colesterol | number: '1.2-2'}} mg</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Sodium</strong>
    <strong>{{totalNutritionalCost?.sodio | number: '1.2-2'}} mg</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Potassium</strong>
    <strong>{{totalNutritionalCost?.potasio | number: '1.2-2'}} mg</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Total Carbohydrates</strong>
    <strong>{{totalNutritionalCost?.totalCarbohidratos | number: '1.2-2'}} mg</strong>
  </div>
  <div class="d-flex justify-content-between" style="margin-left: 20px">
    <strong>Sugars</strong>
    <strong>{{totalNutritionalCost?.azucar| number: '1.2-2'}}</strong>
  </div>
  <div class="d-flex justify-content-between" style="margin-left: 20px">
    <strong>Added sugars</strong>
    <strong>{{totalNutritionalCost?.azucarAnadida| number: '1.2-2'}}</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Fiber</strong>
    <strong>{{totalNutritionalCost?.fibra| number: '1.2-2'}}</strong>
  </div>
  <div class="border-nutritional"></div>
  <div class="d-flex justify-content-between">
    <strong>Vitamin A</strong>
    <strong>{{totalNutritionalCost?.vitaminaA| number: '1.2-2'}}</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Vitamin C</strong>
    <strong>{{totalNutritionalCost?.vitaminaC| number: '1.2-2'}}</strong>
  </div>
  <div class="d-flex justify-content-between">
    <strong>Vitamin D</strong>
    <strong>{{totalNutritionalCost?.vitaminaD| number: '1.2-2'}}</strong>
  </div>
  <div class="border-nutritional"></div>
  <p>* Approximated values by chocolate recipe</p>
</div>
