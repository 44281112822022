import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.css'],
})
export class RequestModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  mappingSection = new Map();

  requestText = '';

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.mappingSection.set(1, 'shape');
    this.mappingSection.set(2, 'inclusions');
    this.mappingSection.set(3, 'chocolate');
    this.mappingSection.set(4, 'packaging');
  }

  confirm() {
    let url = this.router.url.split('/');
    let section: string = '';
    if (url.includes('create-product') && url[url.length - 1] == '4') {
      section = this.mappingSection.get(2);
    } else if (url.includes('create-product') && url[url.length - 1] == '5') {
      section = this.mappingSection.get(4);
    } else {
      section = this.mappingSection.get(parseInt(url[url.length - 1]));
    }
    let comments = JSON.parse(localStorage.getItem('comments') || '{}');
    comments[section] = comments[section] ? [...comments[section], this.requestText] : [this.requestText];
    localStorage.setItem('comments', JSON.stringify(comments));
    this.close();
  }
}
