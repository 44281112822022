import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BespokeFormulaService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of an array containing the values of chocolate types.
   * @returns A promise of all the bespoke chocolate types.
   */
  getBespokeTypes() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('formula_bespoke/tipos').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of the cacao percentages per chocolate type.
   * @param type The type of chocolate to filter by.
   * @returns A promise of all cacao percentages per type.
   */
  getPercentagesByType(type: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tipo', type);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`formula_bespoke/porcentajes`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the materials of a bespoke chocolate, with its new ingredients.
   * @param queryObject The object whose values will be filtered.
   * @param newItems The new ingredients selected for the bespoke.
   * @returns A promise of all the materials that match the object's value and the new ingredients.
   */
  getNewMaterialsList(queryObject: any, newItems: any[]) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.postWithParams(`formula_bespoke/cambiar_materiales`, newItems, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function changes the bespoke recipe and returns a promise of all the costs of its new materials list.
   * @param newItems The new items to change or add to the materials list.
   * @param scale The quantity (kilograms) of chocolate needed.
   * @param inclusionsPercentage The concentration or percentage of flavors and inclusions.
   * @returns A promise of the new materials list of a bespoke chocolate.
   */
  getBespokeCost(newItems: any[], scale: number, inclusionsPercentage: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('scale', scale);
    queryParams = queryParams.append('inclusionPercentage', inclusionsPercentage);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.postWithParams(`formula_bespoke/calcular_costo`, newItems, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
