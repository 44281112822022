import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DividerModule } from 'primeng/divider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './public/home/home.component';
import { MenuCardComponent } from './public/home/menu-card/menu-card.component';
import { HeaderComponent } from './public/shared/header/header.component';
import { BasicsSelectionComponent } from './public/create/basics-selection/basics-selection.component';
import { FormsModule } from '@angular/forms';
import { ProjectBriefComponent } from './public/project-brief/project-brief.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectionStepComponent } from './public/create/selection-step/selection-step.component';
import { ChocolatePickStepComponent } from './public/create/selection-step/chocolate-pick-step/chocolate-pick-step.component';
import { PackagingStepComponent } from './public/create/selection-step/packaging-step/packaging-step.component';
import { ShapeAndSizeStepComponent } from './public/create/selection-step/shape-and-size-step/shape-and-size-step.component';
import { ImpactSummaryComponent } from './public/impact-summary/impact-summary.component';
import { LoginComponent } from './public/login/login.component';
import { ChocolateSweetenersComponent } from './public/create/selection-step/chocolate-sweeteners/chocolate-sweeteners.component';
import { ProductSummaryComponent } from './public/product-summary/product-summary.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { InclusionsAndFlavorsComponent } from './public/create/create-product/inclusions-and-flavors/inclusions-and-flavors.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { RequestModalComponent } from './public/shared/request-modal/request-modal.component';
import { ConsultPriceComponent } from './public/consult-price/consult-price.component';
import { InclusionModalComponent } from './public/create/create-product/inclusions-and-flavors/inclusion-modal/inclusion-modal.component';
import { DesignInspirationComponent } from './public/create/create-product/design-inspiration/design-inspiration.component';
import { BespokeMouldModalComponent } from './public/create/create-product/bespoke-mould-modal/bespoke-mould-modal.component';
import { AssistantComponent } from './public/create/assistant/assistant.component';
import { FlavorModalComponent } from './public/create/create-product/inclusions-and-flavors/flavor-modal/flavor-modal.component';
import { BespokeComponent } from './public/create/bespoke/bespoke.component';
import { WebSocketService } from './core/services/web-socket/web-socket.service';
import { ReadyToLaunchComponent } from './public/create/ready-to-launch/ready-to-launch/ready-to-launch.component';
import { ProductDescriptionComponent } from './public/create/ready-to-launch/product-description/product-description.component';
import { RealLifeSizeModalComponent } from './public/shared/real-life-size-modal/real-life-size-modal.component';
import { GoBeyondComponent } from './public/sustainability/go-beyond/go-beyond.component';
import { OurProjectsComponent } from './public/sustainability/our-projects/our-projects.component';
import { ArtistProfileComponent } from './public/create/create-product/design-inspiration/artist-profile/artist-profile.component';
import { UserProfileComponent } from './public/user-profile/user-profile.component';
import { ClientProjectsComponent } from './public/client-projects/client-projects.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SustainabilityComponent } from './public/sustainability/sustainability.component';
import { OurDreamersComponent } from './public/sustainability/our-dreamers/our-dreamers.component';
import { OurFarmsComponent } from './public/sustainability/our-farms/our-farms.component';
import { FarmModalComponent } from './public/sustainability/our-farms/farm-modal/farm-modal.component';
import { ContextComponent } from './public/sustainability/context/context.component';
import { TrendsInspirationComponent } from './public/trends-inspiration/trends-inspiration.component';
import { TrendInfoComponent } from './public/trends-inspiration/trend-info/trend-info.component';
import { ProjectNameModalComponent } from './public/product-summary/project-name-modal/project-name-modal.component';
import { SendEmailModalComponent } from './public/sustainability/send-email-modal/send-email-modal.component';
import { TransportComponent } from './public/create/transport/transport.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PasswordModalComponent } from './public/product-summary/password-modal/password-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimuladorPvpComponent } from './public/simulador-pvp/simulador-pvp.component';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';
import { MenuModule } from 'primeng/menu';
import { RdProjectsComponent } from './public/rd-projects/rd-projects.component';
import { RdConsultingComponent } from './public/rd-projects/rd-consulting/rd-consulting.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalConsultComponent } from './public/rd-projects/modal-consult/modal-consult.component';
import { CompareProductsComponent } from './public/compare-products/compare-products.component';
import { CompareInternoComponent } from './public/compare-products/compare-interno/compare-interno.component';
import { FinishedProductComponent } from './public/create/finished-product/finished-product.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { TransportConsultComponent } from './public/consult-price/transport-consult/transport-consult.component';
import { PriceTrendsComponent } from './public/consult-price/price-trends/price-trends.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ModalConsultPricesComponent } from './public/consult-price/price-trends/modal-consult-prices/modal-consult-prices.component';
import { ModalFilterMaterialComponent } from './public/create/finished-product/modal-filter-material/modal-filter-material.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ModalUpdatePasswordComponent } from './public/shared/modal-update-password/modal-update-password.component';
import { HomeFeriaComponent } from './public/home-feria/home-feria.component';
import { InformationFeriaComponent } from './public/create/information-feria/information-feria.component';
import { CategoriesFilterComponent } from './public/create/categories-filter/categories-filter.component';
import { BriefFeriaComponent } from './public/product-summary/brief-feria/brief-feria.component';
import { NutritionalTableComponent } from './public/product-summary/nutritional-table/nutritional-table.component';
import { ClaimsItemComponent } from './public/create/ready-to-launch/product-description/claims-item/claims-item.component';
import { ModalConfirmationComponent } from './public/product-summary/modal-confirmation/modal-confirmation.component';
import { SwiperModule } from 'swiper/angular';
import { ToastModule } from 'primeng/toast';
import { ChipsModule } from 'primeng/chips';
import { RawMaterialOneComponent } from './public/consult-price/raw-material-one/raw-material-one.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ChangeDataModalComponent } from './public/product-summary/change-data-modal/change-data-modal.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ModalEditPricesComponent } from './public/product-summary/modal-edit-prices/modal-edit-prices.component';
import { SalesforceModuleComponent } from './public/create/salesforce-module/salesforce-module.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { TipsComponent } from './public/create/tips/tips.component';
import { ModalProductDetailsComponent } from './public/create/finished-product/modal-product-details/modal-product-details.component';
import { DownloadQuoteComponent } from './public/create/finished-product/download-quote/download-quote.component';
import { TwoDigitDecimaNumberDirective } from './directives/onlyTwoDecimalNumbers.directive';
import { ThreeDigitDecimaNumberDirective } from './directives/onlyThreeDecimalNumbers.directive';
import { OnlyNumberDirective } from './directives/onlyNumbers.directive';
import { HomeOptionsComponent } from './public/home/home-options/home-options.component';
import { MenuOptionComponent } from './public/home/menu-option/menu-option.component';
import { PrivateLabelPortfolioComponent } from './public/create/ready-to-launch/private-label-portfolio.component';
import { DownloadQuotePrivateLabelComponent } from './public/create/ready-to-launch/ready-to-launch/download-quote/download-quote-private-label.component';
import { SavedPrivateLabelQuotesComponent } from './public/create/ready-to-launch/ready-to-launch/saved-private-label-quotes/saved-private-label-quotes.component';

export const customCurrencyMaskConfig:any = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: ",",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeOptionsComponent,
    MenuOptionComponent,
    MenuCardComponent,
    HeaderComponent,
    BasicsSelectionComponent,
    ProjectBriefComponent,
    SelectionStepComponent,
    ChocolatePickStepComponent,
    PackagingStepComponent,
    ShapeAndSizeStepComponent,
    ImpactSummaryComponent,
    LoginComponent,
    ChocolateSweetenersComponent,
    ProductSummaryComponent,
    InclusionsAndFlavorsComponent,
    RequestModalComponent,
    ConsultPriceComponent,
    InclusionModalComponent,
    DesignInspirationComponent,
    BespokeMouldModalComponent,
    AssistantComponent,
    TipsComponent,
    FlavorModalComponent,
    BespokeComponent,
    ReadyToLaunchComponent,
    ProductDescriptionComponent,
    RealLifeSizeModalComponent,
    GoBeyondComponent,
    OurProjectsComponent,
    ArtistProfileComponent,
    UserProfileComponent,
    ClientProjectsComponent,
    SustainabilityComponent,
    OurDreamersComponent,
    OurFarmsComponent,
    FarmModalComponent,
    ContextComponent,
    TrendsInspirationComponent,
    TrendInfoComponent,
    ProjectNameModalComponent,
    SendEmailModalComponent,
    TransportComponent,
    PasswordModalComponent,
    SimuladorPvpComponent,
    RdProjectsComponent,
    RdConsultingComponent,
    ModalConsultComponent,
    CompareProductsComponent,
    CompareInternoComponent,
    FinishedProductComponent,
    PrivateLabelPortfolioComponent,
    SavedPrivateLabelQuotesComponent,
    DownloadQuoteComponent,
    DownloadQuotePrivateLabelComponent,
    TransportConsultComponent,
    PriceTrendsComponent,
    ModalConsultPricesComponent,
    ModalFilterMaterialComponent,
    ModalProductDetailsComponent,
    ForgotPasswordComponent,
    ModalUpdatePasswordComponent,
    HomeFeriaComponent,
    InformationFeriaComponent,
    CategoriesFilterComponent,
    BriefFeriaComponent,
    NutritionalTableComponent,
    ClaimsItemComponent,
    ModalConfirmationComponent,
    RawMaterialOneComponent,
    ChangeDataModalComponent,
    ModalEditPricesComponent,
    SalesforceModuleComponent,
    TwoDigitDecimaNumberDirective,
    ThreeDigitDecimaNumberDirective,
    OnlyNumberDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IvyCarouselModule,
    SimpleModalModule.forRoot({ container: document.body }),
    NgxPaginationModule,
    ChartsModule,
    Ng2SmartTableModule,
    FontAwesomeModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSpinnerModule,
    AutoCompleteModule,
    PasswordModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    SwiperModule,
    ToastModule,
    ChipsModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    CheckboxModule,
    DividerModule,
    TableModule,
    AccordionModule,
    MenuModule,
  ],
  providers: [TitleCasePipe, WebSocketService, DecimalPipe],
  entryComponents: [RequestModalComponent, ProjectNameModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
