import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErithritolFactorService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the erithritol factors that match the values of a specific object.
   * @param queryObject The object whose values will be filtered.
   * @returns A promise of all the erithritol factors that match the object's value.
   */
  filterCombinations(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`factor_eritritol/filtrar_combinaciones`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of a materials list, that match with an erithritol factor combination key.
   * @param bespokeObject The bespoke chocolate, with the erithritol key and its new materials.
   * @param queryObject The key of the bespoke formula.
   * @returns A promise of the new materials list with the erithritol sugars added.
   */
  filter(bespokeObject: any, queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.postWithParams(`factor_eritritol/filtrar_material`, bespokeObject, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
