<div class="modal-content modal-container">
    <div class="">
      <h4>Inclusion Details</h4>
    </div>
    <div class="modal-description">
      <p>Select the concentration of your flavor.</p>
    </div>
    <div class="details-container">
        <div class="option-container">
            <p class="option-name">Concentration</p>
            <select name="concentration" id="" [(ngModel)]="concentration" (change)="validate()" [class.red-border]="emptySelection">
                <option [value]="data.porcentajeAlto">{{data.porcentajeAlto * 100}} % - High concentration</option>
                <option [value]="data.porcentajeBajo">{{data.porcentajeBajo * 100}} % - Low concentration</option>
            </select>
            <p class="warning" *ngIf="emptySelection">Choose a concentration first</p>
        </div>
    </div>
    <div class="btn-container">
      <button type="button" class="cancel-btn" (click)="close()">
        Cancel
      </button>
      <button type="button" class="submit-btn" (click)="confirm()">
        Submit
      </button>
    </div>
  </div>
  