<div class="main-container">

        <p class="title">Flavors & Inclusions</p>
    <div class="flavors-inclusions-container" >
        <div class="flavors-container" >
            <div class="description-filter-container">
                <p class="select-description">First, choose a flavor for your chocolate.</p>
                <div class="filters">
                    <p class="filter-label">Subcategory</p>
                    <select name="flavor-filter" id="flavor-filter" [(ngModel)]="selectedSubcategories.flavorSubcategory" (change)="filterBySubcategory('flavor')">
                        <option value=""></option>
                        <option value="{{item}}" *ngFor="let item of filters.flavorsFilters">{{item}}</option>
                    </select>
                </div>
            </div>
            <div class="ingredient-row">
                <div class="ingredient" *ngFor="let item of flavors; let i = index" [class.isDisabled]="stepValidation || validationDragees">
                    <img src="{{item.img}}" alt="ingredient" class="ingredient-img" [class.selected]="item.selected" (click)="handleClickSelection('flavors', i)">
                    <p class="ingredient-name">{{item.nombre | titlecase}}</p>
                    <button *ngIf="item.selected" class="edit-btn" (click)="openFlavorModal(item, i)">Edit</button>
                    <!-- <button *ngIf="item.selected" class="remove-btn" (click)="removeIngredient('flavor', i)">Remove</button> -->
                </div>
            </div>
            <div class="actions">
                <button class="other-btn" (click)="openRequestModal()" [disabled]="stepValidation || selectedOptions?.application.name === 'Dragees'">+ Request Other Flavor</button>
            </div>
        </div>
        <div class="inclusions-container">
            <div class="description-filter-container">
                <p class="select-description">Next, choose inclusions for your chocolate.</p>
                <div class="filters">
                    <p class="filter-label">Subcategory</p>
                    <select name="base-filter" id="base-filter" [(ngModel)]="selectedSubcategories.baseSubcategory" (change)="filterBySubcategory('base')">
                        <option value=""></option>
                        <option value="{{item}}" *ngFor="let item of filters.inclusionsFilters">{{item}}</option>
                    </select>
                </div>
            </div>
            <div class="ingredient-row">
                <div class="ingredient" *ngFor="let item of inclusions; let i = index" [class.isDisabled]="stepValidation">
                    <img src="{{item.img}}" alt="" class="ingredient-img" [class.selected]="item.selected" (click)="handleClickSelection('inclusions', i)">
                    <p class="ingredient-name">{{item.nombre | titlecase}}</p>
                    <button *ngIf="item.selected" class="edit-btn" (click)="openInclusionModal(item, 'base', i)">Edit</button>
                    <!-- <button *ngIf="item.selected" class="remove-btn" (click)="removeIngredient('base', i)">Remove</button> -->
                </div>
            </div>
            <div class="actions">
                <button class="other-btn" (click)="openRequestModal()" [disabled]="stepValidation">+ Request Other Inclusion</button>
            </div>
            <div style="margin-top: 20px; padding-bottom: 30px;" class="description-filter-container">
                <div class="extra-inclusion">
                    <input type="checkbox" name="extra-inclusion" id="extra-inclusion" (change)="addExtraInclusion()" [ngModel]="addInclusion" [disabled]="!baseInclusionSelected">
                    <label for="extra-inclusion">Add an extra inclusion</label>
                </div>
                <div class="filters">
                    <p class="filter-label">Subcategory</p>
                    <select name="extra-filter" id="extra-filter" [(ngModel)]="selectedSubcategories.extraSubcategory" (change)="filterBySubcategory('extra')">
                        <option value=""></option>
                        <option value="{{item}}" *ngFor="let item of filters.inclusionsFilters">{{item}}</option>
                    </select>
                </div>
            </div>
            <div class="ingredient-container" *ngIf="addInclusion">
                <div class="ingredient-row">
                    <div class="ingredient" *ngFor="let item of extraInclusions; let i = index">
                        <img src="{{item.img}}" alt="" class="ingredient-img" [class.selected]="item.selected" (click)="handleClickSelection('extraInclusions', i)">
                        <p class="ingredient-name">{{item.nombre | titlecase}}</p>
                        <button *ngIf="item.selected" class="edit-btn" (click)="openInclusionModal(item, 'extra', i)">Edit</button>
                        <!-- <button *ngIf="item.selected" class="remove-btn" (click)="removeIngredient('extra', i)">Remove</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

