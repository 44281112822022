<div class="main-container">
  <div class="page-info">
    <span class="title">Sustainability</span>
    <span class="description">Get to know our commitment towards sustainability</span>
  </div>
  <div class="row mt-5">
    <div class="col-xs-12 col-sm-12 col-xl-6 col-lg-6 text-center d-flex justify-content-center">
      <div class="menu-card" [routerLink]="'context'">
        <span class="subpage-title">The cocoa context in Colombia is different</span>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-xl-6 col-lg-6 text-center text-center d-flex justify-content-center">
      <div class="menu-card" [routerLink]="'go-beyond'">
        <span class="subpage-title">We go beyond sustainability</span>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-xl-6 col-lg-6 text-center text-center d-flex justify-content-center">
      <div class="menu-card" *ngIf="!isDreamersExpanded" (click)="onDreamersClick()">
        <span class="subpage-title">Luker Clients that share our dream</span>
      </div>
      <div class="row" *ngIf="isDreamersExpanded">
        <div class="col-xs-12 col-sm-12 col-xl-6 col-lg-6" [routerLink]="'our-dreamers/maison'">
          <div class="short-card">
            <div class="img-container-sustain">
              <img src="./../../../assets/sustainability/dreamers/maison-logo.png" alt="logo" class="brand-logo" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-xl-6 col-lg-6" [routerLink]="'our-dreamers/pots'">
          <div class="short-card">
            <div class="img-container-sustain">
              <img src="./../../../assets/sustainability/dreamers/pots-logo.png" alt="logo" class="brand-logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-xl-6 col-lg-6 text-center text-center d-flex justify-content-center">
      <div class="menu-card col-6" [routerLink]="'our-farms'">
        <span class="subpage-title">Our Farms</span>
      </div>
    </div>
  </div>
</div>
