<div class="container-brief" id="brief">
  <p-toast></p-toast>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center gap-3">
          <div class="logo-luker mr-3">
            <img src="./assets/header/logo-negro.svg" alt="logo luker" />
          </div>
          <div class="d-flex align-items-center desktop-icons-social mr-2 ms-3">
            <img class="icon-social" src="./assets/header/web.svg" alt="logo web" />
            <span class="title-icon">/lukerchocolare.com</span>
          </div>
          <div class="d-flex align-items-center desktop-icons-social mr-2 ms-3">
            <img class="icon-social" src="./assets/header/linkedin.svg" alt="logo linkedin" />
            <span class="title-icon">/lukerluker</span>
          </div>
          <div class="d-flex align-items-center desktop-icons-social mr-2 ms-3">
            <img class="icon-social" src="./assets/header/instagram.svg" alt="logo instagram" />
            <span class="title-icon">@lukerchocolates</span>
          </div>
        </div>
        <div *ngIf="buttonsShow" class="d-flex" style="cursor: pointer" (click)="goToHome()">
          <a>
            <img src="./assets/header/home-icon.svg" alt="logo home" />
          </a>
          <p class="home-btn ms-2">Home</p>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3">
      <p class="title-brief">
        Hello {{ briefInformation.cliente }}, it was awesome to talk to you at PLMA. This is what we created at our
        stand.
      </p>
    </div>
    <div class="col-md-12 col-lg-12 col-xl-6">
      <div class="row">
        <div class="col-4">
          <div class="d-flex justify-content-center">
            <div class="container-img-product">
              <img src="{{ imgProduct }}?{{ dateEarly }}" alt="image product" crossorigin="anonymous" />
            </div>
          </div>
        </div>
        <div class="col-8">
          <p class="title-category" *ngIf="origin === 'Ready to Launch Portfolio'">
            {{ finishedIngredient?.rtl['categoria'] }}
          </p>
          <p class="description-category mb-3" *ngIf="origin === 'Ready to Launch Portfolio'">
            {{ finishedIngredient?.rtl['descCategoria'] }}
          </p>
          <p class="title-category" *ngIf="origin === 'Ready to Launch Portfolio'">Ingredients</p>
          <p class="description-category mb-3" *ngIf="origin === 'Ready to Launch Portfolio'">
            {{ finishedIngredient?.rtl['ingredients'] }}
          </p>
          <div class="d-flex mt-3">
            <p class="title-category" *ngIf="origin === 'Ready to Launch Portfolio'">MOQ</p>
            <p class="description-category ms-3" *ngIf="origin === 'Ready to Launch Portfolio'">
              {{ finishedIngredient?.rtl['moq'] }}
            </p>
          </div>
          <div class="responsive-claims">
            <p class="title-category mt-3">Claims</p>
            <div class="items-ingredients-packaging pt-2">
              <app-claims-item [claims]="claimsProduct"></app-claims-item>
            </div>
          </div>
        </div>
        <div class="col-12 responsive-desktop-claims">
          <p class="title-category mt-3">Claims</p>
          <div class="items-ingredients-packaging pt-2">
            <app-claims-item [claims]="claimsProduct"></app-claims-item>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-12 col-xl-6">
      <div class="row">
        <div class="col-12 mt-3">
          <p class="title-category">Suggested configuration</p>
          <div class="card-ingredients">
            <div class="ingedients-container">
              <div class="items-ingredients" *ngFor="let item of ingredients">
                <div class="d-flex justify-content-center">
                  <img
                    class="image-ingredient"
                    src="{{ item.img }}"
                    alt="image ingredients of product"
                    crossorigin="anonymous" />
                </div>
                <p class="title-item mt-2" style="width: 100px">{{ item.title }}</p>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="items-ingredients" *ngFor="let item of packaging">
                <div class="d-flex justify-content-center">
                  <div class="container-img-packaging">
                    <img src="{{ item.img }}" alt="image ingredients of product" crossorigin="anonymous" />
                  </div>
                </div>
                <p class="title-item mt-2" style="width: 100px">{{ item.title }}</p>
                <p class="title-item">Units: {{ item.units }}</p>
                <p class="title-item" *ngIf="item.acabados">Acabados: {{ item.acabados }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center mt-3">
          <p class="description-category">
            We go beyond sustainability. Discover why
            <a href="https://www.lukerchocolate.com/en/beyond-sustainability/	" target="_blank" rel="noopener">here</a>
            . Let’s keep in touch,
            <a
              href="https://info.lukerchocolate.com/e/892141/rchocolate-15min-month-2021-07/g7dg2/966446029?h=id0eE-8PQpL3CNe5bf8vlRxKzZAVG4pvOOWab2yfKBI" target="_blank" rel="noopener">
              schedule a meeting with our sales team
            </a>
          </p>
          <p class="title-category">We will contact you soon.</p>
          <div class="row bottom-buttons">
            <div class="col-6">
              <button class="btn-save" *ngIf="buttonsShow" (click)="converPdfBrief(false)">Save Project</button>
            </div>
            <div class="col-6">
              <button class="btn-pdf" *ngIf="buttonsShow" (click)="converPdfBrief(true)">Download PDF</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="icons-social-mobile">
    <div class="d-flex align-items-center mr-2">
      <a href="https://www.lukerchocolate.com/en/" target="_blank" rel="noopener">
        <img class="icon-social" src="./assets/header/web.svg" alt="logo web" />
        <span class="title-icon">/lukerchocolate.com/en/</span>
      </a>
    </div>
    <div class="d-flex align-items-center ms-3">
      <a href="https://www.linkedin.com/company/lukerchocolate/mycompany/" target="_blank" rel="noopener">
        <img class="icon-social" src="./assets/header/linkedin.svg" alt="logo linkedin" />
        <span class="title-icon">/lukerchocolate</span>
      </a>
    </div>
    <div class="d-flex align-items-center ms-3">
      <a href="https://www.instagram.com/lukerchocolate/?hl=en" target="_blank" rel="noopener">
        <img class="icon-social" src="./assets/header/instagram.svg" alt="logo instagram" />
        <span class="title-icon">@lukerchocolate</span>
      </a>
    </div>
  </div>
</div>
