<div class="main-container">
    <div class="page-info">
        <span class="title">Trends Inspiration</span>
    </div>
    <div class="content">
        <div class="selection-container">
            <span class="description">Choose your country</span>
            <div class="selection-row">
                <div class="selection-card" *ngFor="let item of countries; let i = index" [class.selected]="item.selected" (click)="handleSelection('countries', i)">
                    <div class="flag-img-container">
                        <img src="{{item.img}}" alt="" class="flag-img">
                    </div>
                    <span class="country-name">{{item.name}}</span>
                </div>
            </div>
        </div>

        <div class="selection-container">
            <span class="description">Choose a trend</span>
            <div class="selection-row">
                <div class="selection-card" *ngFor="let item of trends; let i = index" [class.selected]="item.selected" (click)="handleSelection('trends', i)">
                    <div class="name-container healthy" [ngClass]="{'healthy': item.key == 'healthy', 'ethical' : item.key == 'ethical', 'experience' : item.key == 'experience'}">
                        <span class="trend-name">{{item.name}}</span>
                    </div>
                    <span class="trend-description">{{item.description}}</span>
                </div>
            </div>
        </div>
    </div>
</div>