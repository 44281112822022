<div class="modal-dialog" style="max-width: 700px" >
  <div class="modal-content modal-container">
    <div>
      <h4>Search sapcode by name</h4>
    </div>
    <div style="text-align: left">
      <label class="modal-description">Search Finished product</label>
      <input type="text" (change)="getProductFinished()" [(ngModel)]="nameProduct">
    </div>
    <div class="results">
      <div class="result-row d-flex justify-content-between"
      *ngFor="let item of results; let i = index"
      (click)="selectItem(i)"
      [ngClass]="{'selected': item.selected}">
        <p>{{item.productoTerminado || item.idRtl}}</p>
        <p>{{item.nombrePt || item.nombre}}</p>
      </div>
      <p *ngIf="results.length==0" class="text-warning text-center mt-2">No hay resultados</p>
    </div>
    <div class="text-center mt-3">
      <div>
        <button type="button" class="submit" (click)="confirmItem()">Submit</button>
      </div>
      <div>
        <button class="cancel mt-3" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div>
