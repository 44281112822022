<div class="modal-dialog">
  <div class="modal-content modal-container">
    <div>
      <h4>Change password</h4>
    </div>
    <div class="mt-3">
      <form (ngSubmit)="updatePassword()" [formGroup]="changePasswordForm">
        <div class="form-group">
          <label>Password</label>
          <p-password
            [feedback]="true"
            [toggleMask]="true"
            styleClass='p-password p-component p-inputwrapper p-input-icon-right'
            formControlName="password"
            >
            <ng-template pTemplate="footer">
              <p class="mt-2">Suggestions</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase</li>
                  <li>At least one numeric</li>
                  <li>Minimum 8 characters</li>
              </ul>
          </ng-template>
          </p-password>
          <small *ngIf="fieldIsInvalidPassword('password')"
                 class='p-error'>
            Minimum password of 8 characters*
          </small>
        </div>
          <div class="form-group">
            <label>Repeat password</label>
            <p-password
              [feedback]="false"
              [toggleMask]="true"
              styleClass='p-password p-component p-inputwrapper p-input-icon-right'
              formControlName="confirmPassword"
            >
            </p-password>
            <small class='p-error' *ngIf='confirmPasswordInvalid'>
              Passwords do not match. Retry.*
            </small>
          </div>
        <div *ngIf="success">
          <p-messages [(value)]='successMessage' [enableService]='false'></p-messages>
        </div>
        <div>
          <button type="submit" [disabled]="changePasswordForm.invalid || confirmPasswordInvalid">Update password</button>
        </div>
        <div>
          <button class="cancel-btn" (click)="closeModal()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
