<body id="main_container">
  <div class="content">
    <p class="text">
      What's your mission today?
    </p>
    <div class="container__cards">
      <app-menu-option
       *ngFor="let data of cards_data"
        [title]="data.title"
        [detail]="data.detail"
        [imgPathWhite]="data.imgPathWhite"
        [imgPathBrown]="data.imgPathBrown"
        [redirectTo]="data.redirectTo"
        [origin]="data.origin"
        [params]="data.params??{}"
      ></app-menu-option>
    </div>
  </div>
</body>