import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-beyond',
  templateUrl: './go-beyond.component.html',
  styleUrls: ['./go-beyond.component.css'],
})
export class GoBeyondComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirectToProjects() {
    this.router.navigateByUrl('sustainability/our-projects');
  }
}
