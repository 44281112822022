import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trends-inspiration',
  templateUrl: './trends-inspiration.component.html',
  styleUrls: ['./trends-inspiration.component.css'],
})
export class TrendsInspirationComponent implements OnInit {
  /** This array stores the countries information for trends selection. */
  countries: any[] = [
    {
      name: 'United States',
      img: './../../../assets/trends/flags/us.png',
      key: 'usa',
      selected: false,
    },
    {
      name: 'United Kingdom',
      img: './../../../assets/trends/flags/uk.png',
      key: 'uk',
      selected: false,
    },
  ];

  /** This array stores the trends information. */
  trends: any[] = [
    {
      name: 'Healthy Living',
      description: 'Healthy Mind & Healthy Body',
      key: 'healthy',
      selected: false,
    },
    {
      name: 'Ethical Living',
      description: 'Protect our planet and take care of our community',
      key: 'ethical',
      selected: false,
    },
    {
      name: 'Experience More',
      description: 'New flavors, new preparations',
      key: 'experience',
      selected: false,
    },
  ];

  /** Trigger to validate if the country is selected to create the path. */
  isCountrySelected = false;

  /** Trigger to validate if the trend is selected to create the path. */
  isTrendSelected = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  /**
   * This function handles the selection of countries and trends.
   * @param category Wheter the selection was a country or a trend.
   * @param pos The index of the selected item.
   */
  handleSelection(category: string, pos: number) {
    if (category == 'countries') {
      for (let i = 0; i < this.countries.length; i++) {
        this.countries[i].selected = false;
      }
      this.countries[pos].selected = true;
      this.isCountrySelected = true;
    } else {
      for (let i = 0; i < this.trends.length; i++) {
        this.trends[i].selected = false;
      }
      this.trends[pos].selected = true;
      this.isTrendSelected = true;
    }
    if (this.isCountrySelected && this.isTrendSelected) {
      let route = this.getTrendPath();
      this.router.navigate(['trends', route]);
    }
  }

  /**
   * This function returns the path of the country and the trend information.
   * @returns The path to navigate and show the trend information.
   */
  getTrendPath() {
    let countryKey = this.countries.find((c) => c.selected).key;
    let trendKey = this.trends.find((t) => t.selected).key;
    return `${countryKey}_${trendKey}`;
  }
}
