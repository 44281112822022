import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalConsultPricesComponent } from './modal-consult-prices/modal-consult-prices.component';
import { RegisterInfoService } from '../../../core/services/register-info/register-info.service';
import * as moment from 'moment';

@Component({
  selector: 'app-price-trends',
  templateUrl: './price-trends.component.html',
  styleUrls: ['./price-trends.component.css'],
})
export class PriceTrendsComponent implements OnInit {
  faMagnifyingGlass = faMagnifyingGlass;
  sapCodeOne = 'Sap code';
  descriptionOne = 'Description';
  sapCodeTwo: string = 'Sap code';
  descriptionTwo = 'Description';
  sapCodeThree = 'Sap code';
  descriptionThree = 'Description';
  rangeDates: any;
  dataChart: any;
  showChart = false;

  constructor(private sms: SimpleModalService, private changePricesService: RegisterInfoService) {}

  ngOnInit(): void {}

  activateModal(index: any) {
    let sap = this.sms.addModal(ModalConsultPricesComponent).subscribe((resp: any) => {
      if (resp) {
        if (index === 1) {
          this.sapCodeOne = resp.sapCode;
          this.descriptionOne = resp.description;
        } else if (index === 2) {
          this.sapCodeTwo = resp.sapCode;
          this.descriptionTwo = resp.description;
        } else if (index === 3) {
          this.sapCodeThree = resp.sapCode;
          this.descriptionThree = resp.description;
        }
      }
    });
  }
  consultPrices() {
    let codeSap = [this.sapCodeOne, this.sapCodeTwo, this.sapCodeThree];
    this.changePricesService.getProductsByCodeSap(codeSap, this.rangeDates).then((res: any) => {
      this.dataChart = res;
      this.showChart = true;
      this.chartPriceTrends();
    });
  }

  chartPriceTrends() {
    const labels = this.dataChart.labels;
    let labelsFormated = [];
    for (let item of labels) {
      labelsFormated.push(moment(item).format('DD/MM/YYYY'));
    }
    const data = {
      labels: labelsFormated,
      datasets: [
        {
          label: 'Primer producto',
          data: this.dataChart.producto1,
          fill: false,
          borderColor: '#333333',
          tension: 0.1,
        },
        {
          label: 'Segundo producto',
          data: this.dataChart.producto2,
          fill: false,
          borderColor: '#f6d24b',
          tension: 0.1,
        },
        {
          label: 'Tercer producto',
          data: this.dataChart.producto3,
          fill: false,
          borderColor: '#65b32e',
          tension: 0.1,
        },
      ],
    };
    const config = {
      type: 'line',
      data: data,
    };
    let chartPriceTrends = new Chart('chartPriceTrends', config);
  }
}
