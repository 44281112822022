import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { MainService } from '../main/main.service';
import { IUserResponse } from '../../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of the response after trying to post a Project object.
   * @param project The Project object needed to post.
   * @returns A promise with the response of the Project post attempt.
   */
  postProject(project: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('proyecto', project).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al crear el proyecto ${error}`);
        }
      );
    });
  }

  /**
   * This function returns a promise with all users.
   * @returns A promise with all users.
   */
  async getAllUsers(): Promise<IUserResponse[]> {
    return new Promise((resolve, reject) => {
      this.mainService.get('users').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al obtener los usuarios ${error}`);
        }
      );
    });
  }

  /**
   * This function returns a promise of a paginated list of projects created by the user.
   * @param userId The object id of the active user.
   * @returns A promise of a paginated list of projects created by the user.
   */
  getProjectsByUser(userId: any, pageNumber?: number, tipoProyecto?: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', userId);
    queryParams = queryParams.append('tipoProyecto', tipoProyecto);
    if (pageNumber) {
      queryParams = queryParams.append('page', pageNumber);
    }
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`proyecto/usuario`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getProjectsCompareByUser(userId: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('idUser', userId);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams('proyecto-user', queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postDataByProject(data: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('proyecto_datos', data).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al mandar datos ${error}`);
        }
      );
    });
  }

  getDataByProject(projectId: any) {
    return new Promise((resolve, reject) => {
      this.mainService.get(`/proyecto_datos/proyecto/${projectId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of the response after trying to put a Project object.
   * @param projectId The Project id to modify.
   * @param projectData The Project object needed to put.
   * @returns A promise with the response of the Project put attempt.
   */
  putDataByProject(projectId: any, projectData: any) {
    return new Promise((resolve, reject) => {
      this.mainService.put(`proyecto/${projectId}`, projectData).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of project by id.
   * @param projectId The object id of the project.
   * @returns A promise of the project.
   */
  getProjectById(projectId: any) {
    return new Promise((resolve, reject) => {
      this.mainService.get(`proyecto/${projectId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al obtener proyecto ${error}`);
        }
      );
    });
  }

  /**
   * This function returns a promise of the product consolidated by id.
   * @param productId The object id of the product.
   * @returns A promise of the product.
   * **/
  getProductById(productId: any) {
    return new Promise((resolve, reject) => {
      this.mainService.get(`producto_consolidado/${productId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al obtener producto ${error}`);
        }
      );
    });
  }

  /**
   * This function returns a promise of the combination packaging consolidated by id.
   * @param combinationPackaging The object id of the combination packaging.
   * @returns A promise of the combination packaging.
   * **/
  getCombinationPackaging(combinationPackaging: any) {
    return new Promise((resolve, reject) => {
      this.mainService.get(`combinacion_empaque/${combinationPackaging}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al obtener combinacion de empaque ${error}`);
        }
      );
    });
  }

  /**
   * This function returns a promise of the response after trying to post a email info.
   * @param data object contain the pdf in base64 and email client.
   * @returns A promise with the response of the Project post attempt.
   */
  sendEmailProject(data: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('proyecto-email', data).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
