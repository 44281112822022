<div class="main-container">
  <div class="chocolate-pick">
    <p class="chocolate-name">{{ pickedChocolate.texto }}</p>
  </div>
  <div class="chocolate-options row">
    <div class="profile col-lg-12 col-md-12 col-xl-6 col-sm-12">
<!--      <div class="img-container d-flex">-->
<!--        <img src="{{ pickedShape.img }}" class="chocolate-img" alt="" />-->
<!--        <div class="ingredient-text mobile">-->
<!--          <p class="text-title">Ingredients</p>-->
<!--          <p class="text">The recipe’s original ingredients are highlighted, but you can adjust them!</p>-->
<!--          <p class="ingredient-title">Milk</p>-->
<!--          <p class="ingredient-title">Sweeteners</p>-->
<!--        </div>-->
<!--      </div>-->
      <p class="sensorial-profiles">Sensorial Profiles</p>
      <div class="chart-container" *ngIf="isMilkType">
        <canvas
          baseChart
          [datasets]="radarChartData"
          [colors]="chartColors"
          [options]="radarChartOptions"
          [labels]="radarChartLabelsMilk"
          [chartType]="radarChartType"
          [legend]="false"></canvas>
      </div>
      <div class="chart-container" *ngIf="!isMilkType">
        <canvas
          baseChart
          [datasets]="radarChartData"
          [colors]="chartColors"
          [options]="radarChartOptions"
          [labels]="radarChartLabelsDark"
          [chartType]="radarChartType"
          [legend]="false"></canvas>
      </div>
      <div class="buttons-container" style="padding-bottom: 20px">
        <button class="request-btn" (click)="openRequestModal()">+ Request Other Ingredient</button>
      </div>
    </div>
    <div class="ingredient-info col-lg-12 col-md-12 col-xl-6 col-sm-12">
      <div class="ingredient-text">
        <p class="text-title">Ingredients</p>
        <p class="text">The recipe’s original ingredients are highlighted, but you can adjust them!</p>
      </div>
      <div class="container-sweeteners">
        <div class="milk-info" *ngIf="isMilkType">
          <p class="ingredient-title">Milk</p>
          <div class="ingredient-list">
            <div class="ingredient-details" *ngFor="let item of filteredMilk">
              <ng-container *ngIf="item.selected">
                <div class="ingredient-img-container" [ngClass]="item.selected ? 'selectable' : 'disabled-item'">
                  <img src="{{ item.img }}" alt="" class="ingredient-img" />
                </div>
                <p class="ingredient-name">{{ item.nombre | titlecase }}</p>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="sweeteners-info">
          <p class="ingredient-title">Sweeteners</p>
          <div class="ingredient-list">
            <div class="ingredient-details" *ngFor="let item of filteredSweeteners">
              <ng-container *ngIf="item.selected">
                <div class="ingredient-img-container" [ngClass]="item.selected ? 'selectable' : 'disabled-item'">
                  <img src="{{ item.img }}" alt="" class="ingredient-img" />
                </div>
                <p class="ingredient-name">{{ item.nombre | titlecase }}</p>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="sweeteners-info" *ngIf="validationLecitine">
          <p class="ingredient-title">Lecithin</p>
          <div class="ingredient-list">
            <div class="ingredient-details" *ngFor="let item of filteredLecitine">
              <ng-container *ngIf="item.selected">
                <div class="ingredient-img-container" [ngClass]="item.selected ? 'selectable' : 'disabled-item'">
                  <img src="{{ item.img }}" alt="" class="ingredient-img" />
                </div>
                <p class="ingredient-name">{{ item.nombre | titlecase }}</p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
