import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class FilterStepService {
  constructor(private mainService: MainService) {}

  getFilterSteps() {
    return new Promise<any>((resolve, reject) => {
      this.mainService.get(`filtro_pasos`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
