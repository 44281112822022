import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth/auth.service';
import { Location } from '@angular/common';
import { NewsService } from '../../../core/services/news/news.service';
import { WebSocketService } from 'src/app/core/services/web-socket/web-socket.service';
import { faBell, faPen } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import { CompareProductsComponent } from '../../compare-products/compare-products.component';
import { ModalUpdatePasswordComponent } from '../modal-update-password/modal-update-password.component';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  /** This attribute stores the type of header at the current page. */
  headerType = 'normal';

  /** This attribute stores the flow the user is in. */
  currentFlow = '';

  /** Trigger to know if the header must be hidden. */
  hide = false;

  /** This attribute stores the current step of the current flow. */
  currentStep = 0;

  /** Trigger to know if the user clicked on the user profile dropdown menu. */
  activeDropdown = false;

  activeNotifications = false;

  notifications: any[] = [];

  /** This object stores the user of the active session. */
  user: any = {};

  /** The role of each participant of the active live session. */
  sessionRole = '';

  /**Icons */
  faBell = faBell;
  faPen = faPen;

  items: any;

  /**Variable identify type user active role Feria  **/
  activeTypeUser = false;

  @ViewChild('stepsContainer', { read: ElementRef }) public stepsContainer!: ElementRef<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private webSocketService: WebSocketService,
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private newsService: NewsService,
    private sms: SimpleModalService
  ) {}

  ngOnInit(): void {
    this.watchURLChanges();
    this.getUserData();
    this.setLiveSessionData();
    this.loadNotifications();
  }

  /**
   * This function gets the data of the active user after authentication.
   */
  getUserData() {
    this.user = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    let typeUserLogged = this.user.tipo;
    // this.activeTypeUser = typeUserLogged === 'Feria';
    this.activeTypeUser = typeUserLogged === 'FERIA';
  }

  openModalUpdatePassword() {
    this.activeDropdown = false;
    this.sms.addModal(ModalUpdatePasswordComponent, {}, {closeOnClickOutside:true, closeOnEscape:true});
  }

  /**
   * This function watches the url and sets the header type depending on the url.
   */
  watchURLChanges() {
    this.router.events.subscribe((path) => {
      if (path instanceof NavigationEnd) {
        let split = path.url.split('/');
        if (
          split.includes('login') ||
          split.includes('forgot-password') ||
          split.includes('categories-filter') ||
          split.includes('home-feria') ||
          (this.user.tipo === 'FERIA' && split.includes('product-summary'))
          // (this.user.tipo === 'Feria' && split.includes('product-summary'))
        ) {
          this.hide = true;
        } else {
          this.hide = false;
        }
        if (split.includes('create-ingredient')) {
          this.currentFlow = 'ingredient';
          this.handleIngredientHeader(split);
        } else if (split.includes('create-product')) {
          this.currentFlow = 'product';
          this.handleProductHeader(split);
        } else if (split.includes('ready-to-launch')) {
          this.headerType = 'steps';
          this.currentFlow = 'launch';
          this.handleRTLHeader(split);
        } else if (split.includes('portfolio-products')) {
          this.headerType = 'steps';
          this.currentFlow = 'quote';
          this.handleRTLHeader(split);
        } else if (split.includes('consult-price')) {
          this.headerType = 'steps';
          this.currentFlow = 'consult-price';
          this.currentStep = 1;
        } else if (split.includes('my-projects?filter=seguimiento')) {
          this.headerType = 'steps';
          this.currentFlow = 'consult-price';
          this.currentStep = 2;
        } else if (split.includes('my-projects?filter=experimental')) {
          this.headerType = 'steps';
          this.currentFlow = 'consult-price';
          this.currentStep = 3;
        } else if (split.includes('rd-projects')) {
          this.headerType = 'steps';
          this.currentFlow = 'consult-price';
          this.currentStep = 4;
        } else if (split.includes('compare-products')) {
          this.headerType = 'steps';
          this.currentFlow = 'consult-price';
          this.currentStep = 5;
        } else if (split.includes('project-brief') || split.includes('project-brief?origin=private_label')) {
          this.currentFlow = 'project-brief';
          this.headerType = 'normal';
        } else if (split.includes('simulator-pvp')) {
          this.headerType = 'simulator-pvp';
        } else if (split.includes('rd-consulting')) {
          this.headerType = 'simulator-pvp';
        } else if (split.includes('compare-interno')) {
          this.headerType = 'simulator-pvp';
        } else if (split.includes('home-feria') || split.includes('home')) {
          this.currentFlow = 'home';
          this.headerType = 'normal';
        } else if(split.includes('home-options')){
          this.currentFlow = 'home-options';
        } else if (split.includes('private-label-projects')) {
          this.currentFlow = 'saved-private-label-quotes';
        } else {
          this.headerType = 'normal';
        }
      }
    });
  }

  /**
   * This function loads all the data of the live session and listens to the socket for navigation.
   */
  setLiveSessionData() {
    this.router.events.subscribe((path) => {
      if (path instanceof NavigationEnd) {
        let split = path.url.split('/');
        if (
          split.includes('create-ingredient') ||
          split.includes('create-product') ||
          split.includes('ready-to-launch')
        ) {
          this.sessionRole = this.webSocketService.getRole;
        }
      }
    });
  }

  /**
   * This function handles the header of the create ingredient flow.
   * @param split An array containing all the strings of the url.
   */
  handleIngredientHeader(split: string[]) {
    if (split.includes('steps')) {
      this.headerType = 'steps';
      this.currentStep = parseInt(split[split.length - 1]);
    } else if (split.includes('basics') || split.includes('product-summary')) {
      this.headerType = 'ingredient-basics';
    } else if (split.includes('create-bespoke')) {
      this.currentStep = 3;
    } else {
      this.headerType = 'create-steps';
    }
  }

  /**
   * This function handles the header of the create product flow.
   * @param split An array containing all the strings of the url.
   */
  handleProductHeader(split: string[]) {
    if (split.includes('steps')) {
      this.headerType = 'steps';
      this.currentStep = parseInt(split[split.length - 1]);
    } else if (split.includes('basics') || split.includes('product-summary')) {
      this.headerType = 'product-basics';
    }
  }

  /**
   * This function handles the header of the ready to launch flow.
   * @param split An array containing all the strings of the url.
   */
  handleRTLHeader(split: string[]) {
    if (split.includes('steps')) {
      this.headerType = 'steps';
      this.currentStep = parseInt(split[split.length - 1]);
      this.moveProductPortfolioStepsScroll()
    } else if (split.includes('product-summary')) {
      this.headerType = 'rtl-basics';
    }
  }

  moveProductPortfolioStepsScroll(){
    const pixelsToScroll = this.currentFlow=="quote"?190:155
    setTimeout( () => {
      if (this.currentStep==1) {
        this.stepsContainer.nativeElement.scrollTo({ left: 0, behavior: 'smooth' })
      }
      if (this.currentStep==2) {
        this.stepsContainer.nativeElement.scrollTo({ left: (this.stepsContainer.nativeElement.scrollLeft + pixelsToScroll), behavior: 'smooth' })
      }
    }, 300);
  }

  handleQuoteHeader(split: string[]) {
    if (split.includes('steps')) {
      this.headerType = 'steps';
      this.currentStep = parseInt(split[split.length - 1]);
    } else if (split.includes('product-summary')) {
      this.headerType = 'quote-online';
    }
  }

  /**
   * This function sets the trigger of the user profile dropdown menu.
   */
  toggleDropdown() {
    this.activeDropdown = !this.activeDropdown;
  }

  toggleNotifications() {
    this.activeNotifications = !this.activeNotifications;
  }

  /**
   * This function ends the active session and logs out the user.
   */
  logout() {
    this.activeDropdown = false;
    this.authService.endActiveSession();
    this.router.navigateByUrl('login');
  }

  /**
   * This function navigates to the previous route.
   */
  navigateToPreviousLocation() {
    this.location.back();
  }

  async loadNotifications() {
    await this.newsService.getAllNotifications().then((res) => {
      let filter = res.filter((notification) => notification.estado == 'Activo');
      this.notifications = filter;
      this.notifications.sort(function (a: any, b: any) {
        return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
      });
    });
  }

  openCompareProducts() {
    this.sms.addModal(CompareProductsComponent, {}, { autoFocus: true, closeOnClickOutside: true });
  }
}
