<div class="modal-dialog">
  <div class="modal-content modal-container">
    <h4>Change prices</h4>
    <h5>Raw Material One</h5>
    <div class="container-data">
      <div *ngFor="let item of rawMaterialOne">
        <label>{{item.name}}</label>
        <div class="input-prices">
          <input type="number" [ngModel]="item.precioNeto" (ngModelChange)="item.precioNeto=$event" min="0">
          <div>{{item.moneda}}</div>
        </div>
      </div>
      <h5 class="mt-3">Raw Material two</h5>
      <div *ngFor="let item of rawMaterialTwo">
        <label>{{item.nombre}}</label>
        <div class="input-prices">
          <input type="number" [ngModel]="item.precioNeto" (ngModelChange)="item.precioNeto=$event" min="0">
          <div>{{item.moneda}}</div>
        </div>
      </div>
    </div>
    <div>
      <button class="submit-btn" (click)="confirm()">Submit</button>
    </div>
    <div>
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>
