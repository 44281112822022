import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectComexService {
  constructor(private mainService: MainService) {}

  getRegisterTransporting() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`registro_transporte`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getCustomsTransporting() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`info_aduana`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getTransportData() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`info_transporte`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postDataComexByProject(data: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('datos_comex_proyecto', data).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al mandar datos ${error}`);
        }
      );
    });
  }

  getDataComexByProject(projectId: any) {
    return new Promise((resolve, reject) => {
      this.mainService.get(`datos_comex_proyecto/${projectId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
