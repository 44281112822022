import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class PackagingService {
  /** This array stores the packaging combination objects to use less backend calls.*/
  packagingInfo: any[] = [];

  /** This array stores the costs of each package composing a combination package. */
  packagingCosts: any[] = [];

  constructor(private mainService: MainService) {}

  filter(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`empaque/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  filterConsult(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`empaque/filtrar/consult`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  filterCombination(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`combinacion_empaque/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the packaging combination objects obtained in the main service.
   * @returns A promise of all the packaging combination objects.
   */
  getPackagingCombinations() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('combinacion_empaque').subscribe(
        (response: any) => {
          this.packagingInfo = response as any[];
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the packages that compose a packaging combination with its costs.
   * @param combId The id of the packaging combination selected by the user.
   * @returns A promise of all the packages and their costs.
   */
  getPackagingCombinationCosts(combId: string, scale: number) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`combinacion_empaque/costo_combinacion?id=${combId}&scale=${scale}`).subscribe(
        (response: any) => {
          this.packagingCosts = response as any[];
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an array of packages, filtering by SAP Code.
   * @param sapCode The SAP Code of the package to search.
   * @returns A promise of all the packages that match the SAP Code of the search.
   */
  getBySapCode(sapCode: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('codigoSap', sapCode);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`empaque/sap`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an array of packages, filtering by description.
   * @param description The description of the package to search.
   * @returns A promise of all the packages that match the description of the search.
   */
  getByDescription(description: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('descripcion', description);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`empaque/descripcion`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an array containing the values of a filter type.
   * @param filter The type of filter selected.
   * @returns A promise of all the values that a filter can take.
   */
  getPackagingFilters(filter: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`empaque/${filter}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
