import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  cards_data:any =[
    {
      title:"Product Viability & Feasibility",
      detail:"Check product feasibility, Quote Existing products, Consult Price of raw materials.",
      imgPath:"../../../assets/home/feasibility-icon.png",
      redirectTo:"home-options"
    },
    {
      title:"Product Development",
      detail:"Get cost of new products in an easy way.",
      imgPath:"../../../assets/home/development-icon.png",
      redirectTo:"NPD Cost"
    }
  ]

  constructor() {}

  ngOnInit() {}
}
