import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import ls from 'localstorage-slim';

import { IUserResponse } from 'src/app/core/interfaces/user.interface';

import { get } from 'lodash';

import { ProjectService } from '../../core/services/project/project.service';

@Component({
  selector: 'app-client-projects',
  templateUrl: './client-projects.component.html',
  styleUrls: ['./client-projects.component.css'],
})
export class ClientProjectsComponent implements OnInit {
  allUsers: IUserResponse[] = [];

  /** This array stores the projects created by the active user. */
  userProjects: any[] = [];

  queryParams: any;

  userProjectsFilter: any[] = [];

  /** The amount of project type docs. */
  queryLength = 0;

  /** Variable that holds the current page of the pagination. */
  page = 1;

  currentRoute: string;

  /**Icons */
  faCircleInfo = faCircleInfo;

  /** This map stores the paths for navigating to the product summaries. */
  flowMap: Map<string, string> = new Map();
  filterParam: any;

  // The selected user.
  selectedUser: string = this.activeUser.id;

  constructor(private projectService: ProjectService, private router: Router, private route: ActivatedRoute) {
    this.currentRoute = '';
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        if (this.currentRoute === '/my-projects?filter=seguimiento') {
          this.filterParam = 'seguimiento';
          await this.getUserProjects(1);
        } else if (this.currentRoute === '/my-projects?filter=experimental') {
          this.filterParam = 'experimental';
          await this.getUserProjects(1);
        }
      }
    });
  }

  /**
   * This function gets all the users and the projects created by the active user and get all users
   */
  async ngOnInit() {
    this.allUsers = await this.projectService.getAllUsers();
    await this.getUserProjects(1);
    this.loadFlowMap();
  }

  /** The data of the active user. */
  get activeUser() {
    const user = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    return user;
  }

  /**
   * This function gets all the projects created by the selected user.
   * @param event The event that contains the selected user.
   */
  async loadProjectsFromUser(event: any) {
    this.selectedUser = event.target.value;
    const pageNumber = parseFloat(event);
    const paginationResponse: any = await this.projectService.getProjectsByUser(
      this.selectedUser,
      pageNumber,
      this.filterParam
    );
    this.queryLength = paginationResponse.totalDocs;
    this.userProjects = paginationResponse.docs;
    let params = this.route.snapshot.queryParams;
    this.filterProjects(params.filter);
  }

  /**
   * This function gets all the projects created by the active user.
   */
  async getUserProjects(event: any) {
    const pageNumber = parseFloat(event);
    const paginationResponse: any = await this.projectService.getProjectsByUser(
      this.activeUser.id,
      pageNumber,
      this.filterParam
    );
    this.queryLength = paginationResponse.totalDocs;
    this.userProjects = paginationResponse.docs;
    let params = this.route.snapshot.queryParams;
    this.filterProjects(params.filter);
  }

  /**
   * This function filters the projects by the selected type.
   * @param filterParam The selected type.
   * @returns The filtered projects.
   */
  filterProjects(filterParam: any) {
    this.userProjectsFilter = this.userProjects.filter((x) => x.tipoProyecto === filterParam);
  }

  /**
   * This function navigates to the product summary and shows the brief of the project clicked.
   * @param pos The position of the clicked project.
   */
  viewProjectBrief(pos: number) {
    this.removePreviousProject();
    const path: any = this.flowMap.get(this.userProjects[pos].producto.tipo);
    if (path === 'portfolio-products') {
      localStorage.setItem('origin', 'Portfolio products');
    }
    this.saveCostParameters(pos);
    this.saveProjectInfo(pos, path);
    this.getDataProject(pos);
    this.router.navigateByUrl(`${path}/product-summary`);
  }

  /**
   * This function gets the data of the project clicked.
   * @param pos The position of the clicked project.
   */
  getDataProject(pos: any) {
    this.projectService.getDataByProject(this.userProjects[pos].id).then((res: any) => {
      if (this.userProjects[pos].projectModified) {
        ls.set(
          'pricesModifed',
          JSON.stringify({
            rawMaterialOne: res.listRawMaterialOne,
            rawMaterialTwo: res.listRawMaterialTwo,
          })
        );
      }
    });
  }

  /**
   * This function loads the flow map necessary to navigate.
   */
  loadFlowMap() {
    this.flowMap.set('Producto', 'create-product');
    this.flowMap.set('Ready to Launch', 'ready-to-launch');
    this.flowMap.set('Ingrediente', 'create-ingredient');
    this.flowMap.set('Portfolio products', 'portfolio-products');
  }

  /**
   * This function saves the cost parameters of the selected project.
   * @param pos The index of the selected user project.
   */
  saveCostParameters(pos: number) {
    const costParameters: any = {
      dollarPrice: this.userProjects[pos].precioDolar,
      cacaoPrice: this.userProjects[pos].precioCacao,
      weight: this.userProjects[pos].peso,
      cacaoBonus: this.userProjects[pos].primaCacao,
    };
    ls.set('parameters', JSON.stringify(costParameters));
  }

  /**
   * This function saves the project brief and saves the consolidated product depending on its type.
   * @param pos The index of the selected user project.
   * @param path The path of the consolidated product type.
   */
  saveProjectInfo(pos: number, path: string) {
    const project: any = this.userProjects[pos];
    ls.set('brief', JSON.stringify(this.userProjects[pos]));
    localStorage.setItem('productFinished', project.producto.codigoSapProductoTerminado);
    localStorage.setItem('nameProductFinished', project.producto.nombreProductoTerminado);
    if (path == 'create-ingredient') this.saveIngredient(project.producto);
    else if (path == 'create-product') this.saveProduct(project.producto);
    else if (path == 'portfolio-products') this.saveProduct(project.producto);
    else if (path == 'ready-to-launch') this.saveRTL(project.producto);
  }

  /**
   * This function saves a consolidated product of ingredient type.
   * @param product The consolidated product that contains the ingredients.
   */
  saveIngredient(product: any) {
    if (product.chocolate && product.forma) {
      product.chocolate.img = `https://luker-recursos.s3.amazonaws.com/basics/${product.chocolate.tipo}.PNG`;
      product.forma.img = `https://luker-recursos.s3.amazonaws.com/create/shape-and-size/${product.chocolate.tipo}/${product.formaId}.jpg`;
    }
    if (product.tipo === 'Portfolio products') {
      localStorage.setItem('chocolate', JSON.stringify(product.chocolate));
    } else {
      localStorage.setItem('chocolate', JSON.stringify(product.chocolate));
      ls.set('shape', JSON.stringify(product.forma));
      localStorage.setItem('packaging', JSON.stringify(product.combinacionEmpaque));
    }
  }

  /**
   * This function saves a consolidated product of product type.
   * @param product The consolidated product that contains the ingredients.
   */
  saveProduct(product: any) {
    this.saveIngredient(product);
    this.saveInclusions(product);
  }

  /**
   * This function saves a consolidated product of ready to launch type.
   * @param product The consolidated product that contains the ingredients.
   */
  saveRTL(product: any) {
    localStorage.setItem('rtl', JSON.stringify(product.rtl));
    this.saveIngredient(product);
    this.saveInclusions(product);
    this.saveDesignSelections(product);
  }

  /**
   * This function saves the inclusions of a product.
   * @param product The consolidated product that contains the ingredients.
   */
  saveInclusions(product: any) {
    const inclusion: any = {};
    let flavor: any = {};
    if (product.inclusion1Id) {
      inclusion.base = {};
      inclusion.base.inclusion = product.inclusion1;
      inclusion.base.inclusion.img = `https://luker-recursos.s3.amazonaws.com/create/inclusions-and-flavors/inclusions/${inclusion.base.inclusion.codigoSap}.jpg`;
      inclusion.base.percentage = product.inclusion1Porcentaje * 100;
    }
    if (product.inclusion2Id) {
      inclusion.extra = {};
      inclusion.extra.inclusion = product.inclusion2;
      inclusion.extra.inclusion.img = `https://luker-recursos.s3.amazonaws.com/create/inclusions-and-flavors/inclusions/${inclusion.extra.inclusion.codigoSap}.jpg`;
      inclusion.extra.percentage = product.inclusion2Porcentaje * 100;
    }
    if (product.saborizante1Id) {
      flavor = product.saborizante1;
      flavor.pickedConcentration = product.saborizante1Porcentaje;
      localStorage.setItem('flavor', JSON.stringify(flavor));
    }
    if (inclusion?.base || inclusion?.extra) {
      localStorage.setItem('inclusion', JSON.stringify(inclusion));
    }
  }

  /**
   * This function saves the design inspiration selections of a consolidated prouct.
   * @param product The consolidated product that contains the design selections.
   */
  saveDesignSelections(product: any) {
    let artist: any = {};
    let design: any = {};
    if (product.artista) {
      artist = product.artista;
      localStorage.setItem('artist', JSON.stringify(artist));
    }
    if (product.diseno) {
      design = product.diseno;
      localStorage.setItem('design', JSON.stringify(design));
    }
  }

  /**
   * This function removes from local storage the user selections of previous projects.
   */
  removePreviousProject() {
    localStorage.removeItem('chocolate');
    localStorage.removeItem('shape');
    localStorage.removeItem('packaging');
    localStorage.removeItem('parameters');
    localStorage.removeItem('inclusion');
    localStorage.removeItem('flavor');
    localStorage.removeItem('packagings');
    localStorage.removeItem('nameProductFinished');
    localStorage.removeItem('productFinished');
    localStorage.removeItem('origin');
    ls.remove('pricesModifed');
  }
}
