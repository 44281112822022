import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RTLProductService } from '../../../core/services/rtl-product/rtlproduct.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MoneyApisService } from '../../../core/services/money-apis/money-apis.service';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-information-feria',
  templateUrl: './information-feria.component.html',
  styleUrls: ['./information-feria.component.css'],
})
export class InformationFeriaComponent implements OnInit {
  /** Object list of type clients */
  clientGroup: any[] = [];

  clientGroupSelected: any;

  /** List of users to create products */
  userCreate: any[] = [];

  /**  Output activate completed step */
  @Output() completed = new EventEmitter<any>();

  /** The information of the active user. */
  activeUser: any;

  /**Formgroup reactive */
  informationForm: FormGroup;

  constructor(
    public rtlService: RTLProductService,
    private formBuilder: FormBuilder,
    private moneyService: MoneyApisService
  ) {
    this.informationForm = this.formBuilder.group({
      date: [Date.now],
      client: [''],
      company: [''],
      country: ['Colombia'],
      weight: [1000],
      cacaoPrice: [''],
      dollarPrice: [''],
      projectName: [''],
      email: [''],
      clientGroup: [''],
      salesExecutive: [''],
    });
  }

  ngOnInit(): void {
    this.getActiveUser();
    this.getUserFeria();
    this.getDollarPrice();
    this.getCocoaPrice();
  }

  /** Get the active user logged */
  getActiveUser() {
    this.activeUser = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
  }

  /**
   * Function that returns the list of users to create products
   */
  getUserFeria() {
    this.rtlService.getUsersTypeFeria().then((response: any) => {
      this.userCreate = response;
      this.clientGroup = [
        { name: 'Retail' },
        { name: 'Distributor Etnicos' },
        { name: 'Distributor Foodservice' },
        { name: 'Industrial' },
        { name: 'Small Business Manufacturer' },
        { name: 'Small Business Non-Manufacturer' },
        { name: 'Other' },
      ];
      this.userCreate.reverse();
    });
  }

  /** Get the current TRM and assign the value
   * of the trm to the value of dollarprice
   */
  getDollarPrice() {
    this.moneyService.getTrm().subscribe((response: any) => {
      if (response) {
        this.informationForm.patchValue({ dollarPrice: response.value });
      }
    });
  }

  /**Gets a list of cocoa prices assigns a list of cocoa values and
   * assigns the first value of the list of cocoa prices values
   */
  getCocoaPrice() {
    this.moneyService.getCocoaPrices().subscribe((response: any) => {
      if (response) {
        let pricesCocoa = [];
        for (const property in response) {
          let price = response[property];
          pricesCocoa.push({
            value: price['Mínimo'],
          });
        }
        this.informationForm.patchValue({ cacaoPrice: pricesCocoa[0].value });
      }
    });
  }

  /**Onchange function that assigns results of the reactive form to an object
   * to store them in the localstorage and handle the state of the
   * product in the brief
   */
  changeForm() {
    let project: any = {
      nombre: this.informationForm.value.projectName,
      usuario: this.activeUser.id,
      fecha: this.informationForm.value.date,
      cliente: this.informationForm.value.client,
      pais: this.informationForm.value.country,
      peso: this.informationForm.value.weight,
      precioCacao: this.informationForm.value.cacaoPrice,
      precioDolar: this.informationForm.value.dollarPrice,
      emailFeria: this.informationForm.value.email,
      clientGroup: this.informationForm.value.clientGroup,
      salesExecutive: this.informationForm.value.salesExecutive,
    };
    if (this.informationForm.value.client === '' || this.informationForm.value.email === '') {
      this.completed.emit(false);
    } else {
      ls.set('brief', JSON.stringify(project), { encrypt: true });
      this.saveParametersCost(project);
      this.completed.emit({ flow: 'launch', step: 4, completed: true });
    }
  }

  /**Function that saves the costs of cocoa, dollar and weight of the product
   * in the localstorage to manage the status of the product and manage
   * it in the brief
   * @project: object with the information of the project
   */
  saveParametersCost(project: any) {
    let costParameter: any = {
      cacaoPrice: project['precioCacao'],
      dollarPrice: project['precioDolar'],
      weight: project['peso'],
    };
    ls.set('parameters', JSON.stringify(costParameter), { encrypt: true });
  }
}
