import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-modal-product-details',
  templateUrl: './modal-product-details.component.html',
  styleUrls: ['../../../rd-projects/modal-consult/modal-consult.component.css', './modal-product-details.component.css'],
})
export class ModalProductDetailsComponent extends SimpleModalComponent<any, any> implements OnInit {
  product:any;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
