import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ManufactureCombinationService {
  /** This object stores all the Z items costs of a manufacture combination. */
  manufacturingCost: any;

  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the Z items costs of a manufacture combination.
   * @param shapeId The id of the shape selected by the user.
   * @param combId The id of the package combination selected by the user.
   * @param chocolateType The type of the chocolate selected by the user.
   * @param inclusion Whether the user selected inclusions or not.
   * @returns A promise of all Z items of a manufacture combination.
   */
  getCombinationsBySelections(
    shapeId: string,
    combId: string,
    chocolateType: string,
    inclusion: string,
    productFinished?: any
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.appendAll({
      shapeId: shapeId,
      combId: combId,
      chocolateType: chocolateType,
      inclusion,
      productFinished,
    });

    return new Promise<any>((resolve, reject) => {
      this.mainService.getWithParams('combinacion_manufactura/seleccion', queryParams).subscribe(
        (response: any) => {
          this.manufacturingCost = response as any;
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
