import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { MainService } from '../main/main.service';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class WebSocketService {
  // public baseURL = "https://test-sockets-2.herokuapp.com/";
  public baseURL = 'https://luker-cotizador.herokuapp.com';
  // public baseURL: string = "http://localhost:8081/";
  // public baseURL: string = "http://localhost:4200";

  /** The socket used for the live session- */
  private socket: any;

  /** The role of users on both sides of the session. */
  private role = 'admin';

  /** The id of the current live session. */
  private sessionId = '';

  /** Whether the current stream is a live session or not. */
  private isActive = false;

  constructor(private mainService: MainService) {
    this.socket = io(environment.baseURL);
  }

  /**
   * This function listens to the socket changes or updates.
   * @returns An observable of the socket.
   */
  public listen() {
    return new Observable((subscriber) => {
      this.socket.on(this.sessionId, (data: any) => {
        subscriber.next(data);
      });
    });
  }

  /**
   * This function emits data to the listener side of the socket.
   * @param data The data the socket will emit.
   */
  public emit(data: any) {
    this.socket.emit(this.sessionId, data);
  }

  /** This function returns the role of the user active in the live session. */
  get getRole() {
    return this.role;
  }

  /** This function returns the session id of the current live session. */
  get getSessionId() {
    return this.sessionId;
  }

  /** This function returns if the current stream is a live session or not. */
  get active() {
    return this.isActive;
  }

  /**
   * This function sets the active session as true if the current flow is part of a client live session.
   * @param isActive
   */
  setAsActive(isActive: any) {
    this.isActive = isActive;
  }

  /**
   * This function generates the client live session code.
   * @returns A promise of the session generated code.
   */
  getLiveSessionCode() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('sesion_cliente/generar_sesion').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function sets the live session data.
   * @param role The role of the active user in the current live session.
   * @param sessionId The id of the current live session.
   */
  setSessionData(role: string, sessionId: string) {
    this.role = role;
    this.sessionId = sessionId;
  }

  /**
   * This function sends data to the socket to update the live session.
   * @param data The data the socket emits.
   * @returns A promise of the processed data.
   */
  emitToClient(data: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService
        .post('sesion_cliente/recibir', { sessionId: this.sessionId, data })
        .pipe(take(1))
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
