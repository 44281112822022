import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MaterialListService {
  /** This array stores the material list objects of a chocolate to use less backend calls.*/
  materialsInfo: any[] = [];

  rawMaterialsTypeOne: any[] = [];

  rawMaterialsTypeTwo: any[] = [];

  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the material list objects obtained in the main service.
   * @param materialSap The SAP code of the chocolate from which material list is needed.
   * @returns A promise of all the material list objects of a specific chocolate.
   */
  getMaterialsByChocolate(materialSap: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`lista_material/material/${materialSap}`).subscribe(
        (response: any) => {
          this.materialsInfo = response as any[];
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getMaterialsByProductFinished(productFinished: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`lista_material/producto-terminado/${productFinished}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getMaterialsByName(nombrePt: any) {
    let queryParams = new HttpParams({ fromObject: nombrePt });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams('filtro_nombre_producto', queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the raw material needed to manufacture a product.
   * @param finishedProduct The SAP code of a finished product.
   * @param scale The quantity (kilograms) of chocolate needed.
   * @param inclusionsPercentage The concentration or percentage of flavors and inclusions.
   * @returns A promise of all the type two raw material items and their costs.
   */
  getCostByRawMaterialTypeTwo(finishedProduct: string, scale: number, inclusionsPercentage: number) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService
        .get(
          `lista_material/raw_material_two?material=${finishedProduct}&scale=${scale}&inclusionPercentage=${inclusionsPercentage}`
        )
        .subscribe(
          (response: any) => {
            this.rawMaterialsTypeTwo = response as any[];
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   * This function returns a promise of all the raw material needed to manufacture a product.
   * @param finishedProduct The SAP code of a finished product.
   * @param inclusionsPercentage A promise of all the raw material items and their costs.
   * @returns A promise of all the type one raw material items and their costs.
   */
  getCostByRawMaterialTypeOne(finishedProduct: string, inclusionsPercentage: number) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService
        .get(`lista_material/raw_material_one?material=${finishedProduct}&inclusionPercentage=${inclusionsPercentage}`)
        .subscribe(
          (response: any) => {
            this.rawMaterialsTypeOne = response as any[];
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getChocolateBySap(sap: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`chocolate_filtrar/${sap}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
