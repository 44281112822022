<div class="container-feria">
  <div class="row">
    <div class="col-12 overflow-container">
      <div class="d-flex justify-content-center">
        <div class="card-information">
          <h3 class="text-center">Client information</h3>
          <form action="" class="mt-3" (change)="changeForm()" [formGroup]="informationForm">
            <div class="inputs-form">
              <label>Client Name*</label>
              <div>
                <input type="text" formControlName="client">
              </div>
            </div>
            <div class="inputs-form">
              <label>Company</label>
              <div>
                <input type="text" formControlName="company" autocomplete="off">
              </div>
            </div>
            <div class="inputs-form">
              <label>Email*</label>
              <div>
                <p-password
                  [feedback]="false"
                  [toggleMask]="true"
                  styleClass='p-password p-component p-inputwrapper p-input-icon-right'
                  type="password" formControlName="email" autocomplete="off"></p-password>
              </div>
            </div>
            <div class="inputs-form">
              <label>Project Name</label>
              <div>
                <input type="text" formControlName="projectName">
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="inputs-form">
                  <label>Client group</label>
                  <div>
                    <p-dropdown [options]="clientGroup"  appendTo="body" optionLabel="name" optionValue="name" formControlName="clientGroup"></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="inputs-form">
                  <label>Sales executive</label>
                  <div>
                    <p-dropdown [options]="userCreate" appendTo="body" optionLabel="nombreUsuario" optionValue="nombreUsuario" formControlName="salesExecutive"></p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
