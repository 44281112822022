import { Component, OnInit, Input } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-real-life-size-modal',
  templateUrl: './real-life-size-modal.component.html',
  styleUrls: ['./real-life-size-modal.component.css'],
})
export class RealLifeSizeModalComponent extends SimpleModalComponent<any, boolean> implements OnInit {
  /** This object stores the shape selected by the user. */
  @Input() data: any;

  /** Path of the shape real life size image. */
  shapeSizeImage: string = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadRealLifeSizeImage();
  }

  /**
   * This function closes the modal.
   */
  confirm() {
    this.close();
  }

  /**
   * This function sets the path of the shape real life size image.
   */
  loadRealLifeSizeImage() {
    this.shapeSizeImage = `./../../../../assets/create/shape-and-size/real-size/${this.data}.png`;
  }
}
