import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'luker-web';
  items = [
    'Picking the chocolate',
    'Selecting the packaging',
    'Choosing the recipe',
    'Checking the Price formulas',
    'Simulating the manufacturing',
    'Boom ready',
  ];
  currentItem: any;
  private interval: any;
  private pointer: number = 0;

  constructor(public router: Router) {
    const navEndEvent$ = this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd));
    navEndEvent$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-231219042-1', {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.currentItem = this.items[this.pointer];
      this.pointer++;
      if (this.pointer > 6) {
        this.pointer = 0;
      }
    }, 2000);
  }
}
