<div class="modal-dialog">
  <div class="modal-content modal-container">
    <div class="title">
      <h4>Enter your password</h4>
    </div>
    <div class="modal-description">
      <p>Password</p>
    </div>
    <div class="details-container">
      <p-password
        [feedback]="false"
        [toggleMask]="true"
        styleClass='p-password p-component p-inputwrapper p-input-icon-right'
        [class.red-border]="emptyPassword"
        [(ngModel)]="passwordUser">
      </p-password>
      <p class="warning" *ngIf="emptyPassword">Write a password first</p>
    </div>
    <div class="btn-container">
      <button type="button" class="submit-btn" (click)="confirm()">Submit</button>
    </div>
  </div>
</div>
