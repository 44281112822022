import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { MaterialListService } from '../material-list/material-list.service';

@Injectable({
  providedIn: 'root',
})
export class RTLProductService {
  constructor(private mainService: MainService, private materialService: MaterialListService) {}

  categoriesFilter = false;
  categorySelected: any;

  /**
   * This function filters all the RTL products by a query object.
   * @param queryObject The object whose values will be used for filtering.
   * @returns A promise of all the RTL products that match the filter values.
   */
  filter(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`producto_rtl/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the values of a field in the RTL collection.
   * @param filter The field whose values will be filtered.
   * @returns A promise of all the values of a field.
   */
  getFilterValues(filter: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('filtro', filter);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams('producto_rtl/traer_filtro', queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the RTL Products currently available.
   * @returns A promise of the all RTL Products.
   */
  getAllRTLProducts() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('producto_rtl').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getRtlProductByRtlId(rtlId: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`producto_rtil_id/${rtlId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getRtlProductByName(nombrePt: any) {
    let queryParams = new HttpParams({ fromObject: nombrePt });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams('filtro_nombre_rtl', queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Return boolean of category
   */
  getValidationCategory() {
    return this.categoriesFilter;
  }

  /**
   * Return name of category selected
   */
  getCategorySelected() {
    return this.categorySelected;
  }

  /**
   * Get list of users to create products in the form information in the flow "Feria"
   */
  getUsersTypeFeria() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('usuario_feria').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function get material list
   * @params sapCode The sap code of the material list to filter
   * @return list of materials
   */
  async getMaterialsRTL(sapCode: any) {
    await this.materialService.getMaterialsByChocolate(sapCode);
    return this.materialService.materialsInfo;
  }
}
