import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import ls from 'localstorage-slim';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /** This attribute stores the token of the active session. */
  public authToken: any;

  /** An emitter to keep track of the logged in user. */
  userLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpClient) {}

  /**
   * This function returns a promise of the user object that is currently trying to authenticate.
   * @param user The user trying to log in the application.
   * @returns A promise of the user object.
   */
  public authenticateUser(user: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.baseURL}/users/authenticate`, user).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public forgotPassword(email: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.baseURL}/users/forgot`, email).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public updatePassword(user: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.baseURL}/users/reset`, user).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function is used to store the current user information.
   * @param token The token of the active session.
   * @param user The data of the active user.
   */
  public storeUserData(token: any, user: any) {
    ls.set('id_token', token, { encrypt: true });
    ls.set('user', JSON.stringify(user), { encrypt: true });
    this.userLoggedIn.emit(true);
  }

  /**
   * This function is used to load the token of the active user.
   */
  public loadToken() {
    const token = ls.get('id_token', { decrypt: true });
    this.authToken = token;
  }

  /**
   * This function is used to get the token of the current user.
   * @returns The token of the active session.
   */
  public getToken() {
    this.loadToken();
    return this.authToken;
  }

  /**
   * This function ends the active session.
   */
  endActiveSession() {
    this.authToken = undefined;
    ls.clear();
  }

  /**
   * This function get type of user logged.
   */
  public getTypeUser() {
    let user = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    return user.tipo;
  }

  /**
   * This function get user logged.
   */
  public getUser() {
    return JSON.parse(ls.get('user', { decrypt: true }) || '{}');
  }
}
