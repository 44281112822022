<div class="modal-content modal-container">
    <div class="">
      <h4>Tailor Made Moulds</h4>
    </div>
    <div class="modal-description">
      <p>These are some options we could create specially for you, or use as a starting point to create something unique!</p>
    </div>
    <div id="carouselExampleFade" class="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="2000" data-bs-pause="false">
        <div class="carousel-inner">

            <div class="carousel-item active">
                <div class="bespoke-container">
                    <div class="img-container">
                        <img src="{{bespokeMoulds[0].img}}" alt="" class="mould-img">
                    </div>
                </div>
            </div>
            
            <div class="carousel-item" *ngFor="let item of bespokeMoulds.slice(1,4)">
                <div class="bespoke-container">
                    <div class="img-container">
                        <img src="{{item.img}}" alt="" class="mould-img">
                    </div>
                </div>
            </div>
        </div>

        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="btn-container">
      <button type="button" class="cancel-btn" (click)="close()">
        Return
      </button>
      <button type="button" class="submit-btn" (click)="confirm()">
        See Complete Catalog
      </button>
    </div>
  </div>
  