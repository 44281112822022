import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class InclusionsFlavorsService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the inclusions and flavors.
   * @returns A promise of all the inclusions and flavors.
   */
  getAll() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`inclusion_saborizante`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the inclusions and flavors that match the values of a specific object.
   * @param queryObject The object whose values will be filtered.
   * @returns A promise of all the inclusions and flavors that match the object's value.
   */
  filter(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`registro_info_consult/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  filterRawMaterialOne(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any>((resolve, reject) => {
      this.mainService.getWithParams(`lista_material_filtrar/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  filterChocolate(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`filtro_chocolate`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of only one inclusion or flavor that match the values of a specific object.
   * @param queryObject The object whose values will be filtered.
   * @returns A promise of all the inclusion or flavor that match the object's value.
   */
  filterOne(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any>((resolve, reject) => {
      this.mainService.getWithParams(`inclusion_saborizante/filtrar_unico`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of a Flavor and Inclusion, by filtering per category.
   * @param category The name of the category to filter by.
   * @returns A promise of the Flavor and Inclusion filtered per chocolateId.
   */
  getByCategory(category: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('categoria', category);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`inclusion_saborizante/categoria`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an object containing the values of a inclusions.
   * @param ingredientId id of inclusion to filter
   */
  getByIngredientId(ingredientId: string) {
    return new Promise<any>((resolve, reject) => {
      this.mainService.get(`inclusion_saborizante/filtrar_ingrediente/${ingredientId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an array of ingredients, filtering by SAP Code.
   * @param sapCode The SAP Code of the ingredient to search.
   * @returns A promise of all the ingredients that match the SAP Code of the search.
   */
  getBySapCode(sapCode: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('codigoSap', sapCode);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`inclusion_saborizante/sap`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an array of ingredients, filtering by SAP Code.
   * @param name The name of the ingredient to search.
   * @returns A promise of all the ingredients that match the name of the search.
   */
  getByName(name: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('nombre', name);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`inclusion_saborizante/nombre`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an array containing the values of a filter type.
   * @param filter The type of filter selected.
   * @returns A promise of all the values that a filter can take.
   */
  getIngredientFilters(filter: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`inclusion_saborizante/${filter}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of an array containing the values of the subcategory filter.
   * @param category The category that contains the subcategory values.
   * @returns A promise of all the subcategory values that a category contains.
   */
  getSubcategories(category: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('categoria', category);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`inclusion_saborizante/subcategorias`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
