import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private mainService: MainService) {}

  /**
   * This function calls the backend to send an email of the susteinability projects selected by a user.
   * @param projects The list of projects a client is interested in.
   * @param queryObject The name and email of the receiver.
   * @returns A promise of the email sent response.
   */
  sendSusteinabilityEmail(projects: any, queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.postToEmailWithParams(`send-susteinability-email`, projects, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
