<div class="main-container" style="overflow-x: scroll;">
  <div style="min-width: 975px;">
    <div class="text-center">
      <span class="title-compare">Compare Products</span>
    </div>
  
    <div class="row mt-5">
      <div class="col-4">
        <div class="header-title">Variable</div>
      </div>
      <div class="col-4">
        <div class="header-title">{{projectsA?.nombre}}</div>
      </div>
      <div class="col-4">
        <div class="header-title">{{projectsB?.nombre}}</div>
      </div>
    </div>
  <!-- Parte 1 -->
    <div class="row mt-2">
      <div class="col-4">
        <div class="header-variable">Ingredientes</div>
      </div>
      <div class="col-4">
        <div class="header-title">Raw Material One</div>
        <div *ngFor="let item of dataProjectsA.listRawMaterialOne">
          <div class="body-products">
            <div>
              {{item.name}}
            </div>
            <div>
              {{item.proportion * 100 | number: '1.2-2'}}%
            </div>
          </div>
        </div>
        <div class="header-title">Raw Material Two</div>
        <div *ngFor="let item of dataProjectsA.listRawMaterialTwo">
          <div class="body-products" *ngIf="!item.inclusion">
            <div>
              <p class="text-center">
                {{item.name}}
              </p>
            </div>
            <div>
              <span>{{item.proportion * 100 | number: '1.2-2'}}%</span>
            </div>
          </div>
        </div>
        <div class="header-title">Packaging</div>
        <div *ngFor="let item of dataProjectsA.listPackaging">
          <div class="body-products">
            <div class="d-flex align-items-center">
              <p class="text-center">
                {{item.name}}
              </p>
            </div>
            <div>
              <span>
                {{item.units}}
              </span>
            </div>
          </div>
        </div>
        <div class="header-title">Inclusions</div>
        <div *ngIf="dataProjectsA?.inclusionsDetails.length">
          <div *ngFor="let item of dataProjectsA.inclusionsDetails">
            <div class="body-products" *ngIf="item.base && item.base.inclusion.nombre">
              <div>
                {{item.base.inclusion.nombre}}
              </div>
              <div>
                {{item.base.percentage}}%
              </div>
            </div>
            <div class="body-products" *ngIf="item.nombre">
              <div>
                <span>
                  {{item.nombre}}
                </span>
  
              </div>
              <div>
                <span>
                  {{item.pickedConcentration * 100}}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="header-title">Raw Material One</div>
        <div *ngFor="let item of dataProjectsB.listRawMaterialOne">
          <div class="body-products">
            <div>
              {{item.name}}
            </div>
            <div>
              {{item.proportion * 100 | number: '1.2-2'}}%
            </div>
          </div>
        </div>
        <div class="header-title">Raw Material Two</div>
        <div *ngFor="let item of dataProjectsB.listRawMaterialTwo">
          <div class="body-products" *ngIf="!item.inclusion">
            <div>
              <p class="text-center">
                {{item.name}}
              </p>
            </div>
            <div>
              <span>{{item.proportion * 100 | number: '1.2-2'}}%</span>
            </div>
          </div>
        </div>
        <div class="header-title">Packaging</div>
        <div *ngFor="let item of dataProjectsB.listPackaging">
          <div class="body-products">
            <div>
              <p class="text-center">
                {{item.name}}
              </p>
            </div>
            <div>
              <span>
                {{item.units}}
              </span>
            </div>
          </div>
        </div>
        <div class="header-title">Inclusions</div>
        <div *ngIf="dataProjectsB?.inclusionsDetails.length">
          <div *ngFor="let item of dataProjectsB.inclusionsDetails">
            <div class="body-products" *ngIf="item.base && item.base.inclusion.nombre">
              <div>
                {{item.base.inclusion.nombre}}
              </div>
              <div>
                {{item.base.percentage}}%
              </div>
            </div>
            <div class="body-products" *ngIf="item.nombre">
              <div>
                <span>
                  {{item.nombre}}
                </span>
  
              </div>
              <div>
                <span>
                  {{item.pickedConcentration * 100}}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Parte 2 -->
    <div class="border-separate"></div>
    <div class="row mt-2">
      <div class="col-4">
        <div class="header-variable">Price</div>
      </div>
      <div class="col-4">
        <div class="body-products">
          <div>
            <span>Price per unit</span>
          </div>
          <div>
            <span>{{projectsA?.precioUnidad / projectsA?.precioDolar | number: '1.2-2'}}</span>
          </div>
          </div>
        <div class="body-products">
          <div>
            <span>Price per kg</span>
          </div>
          <div>
            <span>{{projectsA?.costoKg / projectsA?.precioDolar | number: '1.2-2'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>EBITDA</span>
          </div>
          <div>
            <span>{{dataProjectsA.ebitdaPercentage | number: '1.2-2'}} %</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>Gross Margin</span>
          </div>
          <div>
            <span>{{dataProjectsA.marginPercentage | number: '1.2-2'}} %</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="body-products">
          <div>
            <span>Price per unit</span>
          </div>
          <div>
            <span>{{projectsB?.precioUnidad / projectsB?.precioDolar | number: '1.2-2'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>Price per kg</span>
          </div>
          <div>
            <span>{{projectsB?.costoKg / projectsB?.precioDolar | number: '1.2-2'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>EBITDA</span>
          </div>
          <div>
            <span>{{dataProjectsB.ebitdaPercentage | number: '1.2-2'}} %</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>Gross Margin</span>
          </div>
          <div>
            <span>{{dataProjectsB.marginPercentage | number: '1.2-2'}} %</span>
          </div>
        </div>
      </div>
    </div>
    <div class="border-separate"></div>
    <div class="row mt-2">
      <div class="col-4">
        <div class="header-variable">Cost structure</div>
      </div>
      <div class="col-4">
        <div class="body-products">
          <div>
            <span>Manufacturing</span>
          </div>
          <div>
            <span>{{dataProjectsA?.manufacturingCost | number: '1.0-0'}}</span>
            <button
              class="show-details"
              (click)="showManufacturing()"
              [class.hide-details]="displayManufacturing === false"
              style="margin-left: 30px"></button>
          </div>
        </div>
        <div *ngIf="displayManufacturing">
          <div  class="body-products" *ngFor="let item of manufacturingCostsA">
            <div>
              <span>{{item.name}}</span>
            </div>
            <div>
              <span>{{item.value | number: '1.0-0'}}</span>
            </div>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>MP1</span>
          </div>
          <div>
            <span>{{dataProjectsA?.rawMaterialCostOne | number: '1.0-0'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>MP2</span>
          </div>
          <div>
            <span>{{dataProjectsA?.rawMaterialCostTwo | number: '1.0-0'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>Packaging</span>
          </div>
          <div>
            <span>{{dataProjectsA?.packagingCost | number: '1.0-0'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>Total</span>
          </div>
          <div>
            <span>{{
              dataProjectsA?.manufacturingCost +
              dataProjectsA?.rawMaterialCostOne +
              dataProjectsA?.rawMaterialCostTwo +
              dataProjectsA?.packagingCost | number: '1.0-0'
              }}</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="body-products">
          <div>
            <span>Manufacturing</span>
          </div>
          <div>
            <span>{{dataProjectsB?.manufacturingCost | number: '1.0-0'}}</span>
            <button
              class="show-details"
              (click)="showManufacturingB()"
              [class.hide-details]="displayManufacturingB === false"
              style="margin-left: 30px"></button>
          </div>
        </div>
        <div *ngIf="displayManufacturingB">
          <div  class="body-products" *ngFor="let item of manufacturingCostsB">
            <div>
              <span>{{item.name}}</span>
            </div>
            <div>
              <span>{{item.value | number: '1.0-0'}}</span>
            </div>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>MP1</span>
          </div>
          <div>
            <span>{{dataProjectsB?.rawMaterialCostOne | number: '1.0-0'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>MP2</span>
          </div>
          <div>
            <span>{{dataProjectsB?.rawMaterialCostTwo | number: '1.0-0'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>Packaging</span>
          </div>
          <div>
            <span>{{dataProjectsB?.packagingCost | number: '1.0-0'}}</span>
          </div>
        </div>
        <div class="body-products">
          <div>
            <span>Total</span>
          </div>
          <div>
            <span>{{
              dataProjectsB?.manufacturingCost +
              dataProjectsB?.rawMaterialCostOne +
              dataProjectsB?.rawMaterialCostTwo +
              dataProjectsB?.packagingCost | number: '1.0-0'
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
