import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css'],
})
export class ConfirmModalComponent extends SimpleModalComponent<any, any> implements OnInit {

  api = '';
  text = '';
  title = '';

  result = false

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  confirm() {
    this.result = true
    this.close();
  }
}
