<div class="main_container" (click)="cardClicked()">
  <div class="logo" id="white">
    <img src="{{imgPathWhite}}" alt="logo">
  </div>
  <div class="logo" id="brown">
    <img src="{{imgPathBrown}}" alt="logo">
  </div>
  <div class="information">
    <p class="title">
      {{title}}
    </p>
    <p class="details">
      {{detail}}
    </p>
  </div>
</div>