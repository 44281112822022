import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../core/services/project/project.service';

@Component({
  selector: 'app-compare-interno',
  templateUrl: './compare-interno.component.html',
  styleUrls: ['./compare-interno.component.css'],
})
export class CompareInternoComponent implements OnInit {
  projectUrlA: any;
  projectUrlB: any;

  dataProjectsA: any;
  dataProjectsB: any;

  projectsA: any;
  projectsB: any;

  displayManufacturing = false;
  displayManufacturingB = false;

  manufacturingCostsA: any[] = [];
  manufacturingCostsB: any[] = [];

  constructor(private route: ActivatedRoute, private projectService: ProjectService) {}

  async ngOnInit() {
    this.getParamsIdUrl();
    await this.getProjectsById();
  }

  getParamsIdUrl() {
    this.projectUrlA = this.route.snapshot.paramMap.get('id');
    this.projectUrlB = this.route.snapshot.paramMap.get('id2');
  }

  async getProjectsById() {
    this.projectsA = await this.projectService.getProjectById(this.projectUrlA);
    this.projectsB = await this.projectService.getProjectById(this.projectUrlB);
    this.dataProjectsA = await this.projectService.getDataByProject(this.projectUrlA);
    this.manufacturingCostsA.push(
      {
        name: 'Depreciation',
        value: this.dataProjectsA.manufacturingList[0].zdep,
      },
      {
        name: 'Energy',
        value: this.dataProjectsA.manufacturingList[0].zenr,
      },
      {
        name: 'Gas',
        value: this.dataProjectsA.manufacturingList[0].zgas,
      },
      {
        name: 'Maintance',
        value: this.dataProjectsA.manufacturingList[0].zman,
      },
      {
        name: 'Direct Labour',
        value: this.dataProjectsA.manufacturingList[0].zmod,
      },
      {
        name: 'MOI and Other CIF',
        value: this.dataProjectsA.manufacturingList[0].zoin,
      },
      {
        name: 'External manufacturing',
        value: this.dataProjectsA.manufacturingList[0].maquila,
      }
    );
    this.dataProjectsB = await this.projectService.getDataByProject(this.projectUrlB);
    this.manufacturingCostsB.push(
      {
        name: 'Depreciation',
        value: this.dataProjectsB.manufacturingList[0].zdep,
      },
      {
        name: 'Energy',
        value: this.dataProjectsB.manufacturingList[0].zenr,
      },
      {
        name: 'Gas',
        value: this.dataProjectsB.manufacturingList[0].zgas,
      },
      {
        name: 'Maintance',
        value: this.dataProjectsB.manufacturingList[0].zman,
      },
      {
        name: 'Direct Labour',
        value: this.dataProjectsB.manufacturingList[0].zmod,
      },
      {
        name: 'MOI and Other CIF',
        value: this.dataProjectsB.manufacturingList[0].zoin,
      },
      {
        name: 'External manufacturing',
        value: this.dataProjectsB.manufacturingList[0].maquila,
      }
    );
  }

  showManufacturing() {
    this.displayManufacturing = !this.displayManufacturing;
  }

  showManufacturingB() {
    this.displayManufacturingB = !this.displayManufacturingB;
  }
}
