import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-project-name-modal',
  templateUrl: './project-name-modal.component.html',
  styleUrls: ['./project-name-modal.component.css'],
})
export class ProjectNameModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  /** The name of the project. */
  name = '';

  typeProject: any;
  project: any;
  /** Flag to validate if the name field is empty. */
  emptyName = true;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  /**
   * This function saves the project name and closes the modal.
   */
  confirm() {
    this.validateName();
    if (!this.emptyName) {
      this.result = this.name;
      this.close();
    } else {
      this.result = false;
    }
  }

  /**
   * This function validates if the name field is empty.
   */
  validateName() {
    if (this.name == '') {
      this.emptyName = true;
    } else {
      this.emptyName = false;
    }
  }

  typeOfProject() {
    localStorage.setItem('typeProject', this.typeProject);
    this.project = this.typeProject;
  }
}
