import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.css'],
})
export class PasswordModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  /**Password entered by user */
  passwordUser: any;

  /**Flag to validation of the empty password */
  emptyPassword = false;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  /**Function to confirm of the password and validate empty password */
  confirm() {
    this.validatePassword();
    if (!this.emptyPassword) {
      this.result = this.passwordUser;
      this.close();
    } else {
      this.result = '';
    }
  }

  /**Function to validatePassword */
  validatePassword() {
    if (this.passwordUser == '' || this.passwordUser == undefined) {
      this.emptyPassword = true;
    } else {
      this.emptyPassword = false;
    }
  }
}
