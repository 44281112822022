import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class DesperdiciosService {
  constructor(private mainService: MainService) {}

  getWasteMerm() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`desperdicios_merma`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getWasteExtraInclusion() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`datos_extra_inclusion`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
