import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { RegisterInfoService } from '../../../../core/services/register-info/register-info.service';

@Component({
  selector: 'app-modal-consult-prices',
  templateUrl: './modal-consult-prices.component.html',
  styleUrls: ['../../../rd-projects/modal-consult/modal-consult.component.css'],
})
export class ModalConsultPricesComponent extends SimpleModalComponent<any, any> implements OnInit {
  constructor(private serviceRegisterInfo: RegisterInfoService) {
    super();
  }

  nameMaterial: any;
  results: any;
  sapCodeSelected: any;
  descriptionSelected: any;

  ngOnInit(): void {
    this.getRegisterInfo();
  }

  getRegisterInfo() {
    this.serviceRegisterInfo.getProductsByName({ nombre: this.nameMaterial.toUpperCase() }).then((res: any) => {
      this.results = res;
      this.results = this.results.filter((value: any, index: any, self: any) => {
        return (
          self.findIndex((t: any) => {
            return t.codigoSap === value.codigoSap;
          }) === index
        );
      });
    });
  }

  setSelectedFalse() {
    for (const element of this.results) {
      element.selected = false;
    }
  }

  selectResult(index: any) {
    this.setSelectedFalse();
    this.results[index].selected = true;
    this.sapCodeSelected = this.results[index].codigoSap;
    this.descriptionSelected = this.results[index].nombre;
  }

  confirm() {
    this.result = {
      sapCode: this.sapCodeSelected,
      description: this.descriptionSelected,
    };
    this.close();
  }

  closeModal() {
    this.close();
  }
}
