import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { SimpleModalService } from 'ngx-simple-modal';

import ls from 'localstorage-slim';

import { RequestModalComponent } from '../../../shared/request-modal/request-modal.component';
import { PackagingService } from '../../../../core/services/packaging/packaging.service';
import { WebSocketService } from '../../../../core/services/web-socket/web-socket.service';

@Component({
  selector: 'app-packaging-step',
  templateUrl: './packaging-step.component.html',
  styleUrls: ['./packaging-step.component.css'],
})
export class PackagingStepComponent implements OnInit {
  /** This attribute is used to store the name of the current step. */
  @Input() stepName = '';

  /** This attribute is used to store the description of the current step. */
  @Input() stepDescription = '';

  /** This variable is a boolean for disabled any step**/
  @Input() stepValidation: any;

  /** This array contains all the packaging options. */
  packagingOptions: any[] = [];

  /** This array contains all the packaging combinations properties. */
  packagingCombinations: any;

  /** This array contains all the packages whose classification is primary. */
  primaryPackages: any[] = [];

  /** This array contains all the packages whose classification is secondary. */
  secondaryPackages: any[] = [];

  /** This array contains all the packages whose classification is third. */
  thirdPackages: any[] = [];

  /** This array contains all the packages whose classification is fourth */
  fourthPackages: any[] = [];

  /** Flag to enable the secondary packaging selection. */
  primarySelected: boolean = false;

  /** Flag to enable the third packaging selection. */
  secondarySelected: boolean = false;

  /** Flag to enable the fourth packaging selection. */
  thirdSelected: boolean = false;

  /** Flag to enable the fifth packaging selection. */
  fourthSelected: boolean = false;

  /** This object sends an event to validate its completion. */
  @Output() completed = new EventEmitter<any>();

  /** The combination package selected by the user. */
  pickedPackaging = {};

  /** This attribute is used to store the current flow the user is in. */
  currentFlow = '';

  requireThird: any = undefined;
  requireFourth: any = undefined;

  constructor(
    private packagingService: PackagingService,
    private titlecase: TitleCasePipe,
    private sms: SimpleModalService,
    private router: Router,
    private webSocketService: WebSocketService
  ) {}

  async ngOnInit() {
    this.setCurrentFlow();
    if (this.currentFlow == 'ingredient') {
      await this.getPackagingCombinations();
    } else if (this.currentFlow == 'product') {
      this.getPrimaryPackages();
    }
    this.listenSocket();
  }

  /**
   * This function sets the current flow the user is in.
   */
  setCurrentFlow() {
    let url = this.router.url;
    let split = url.split('/');
    if (split.includes('create-ingredient')) {
      this.currentFlow = 'ingredient';
    } else if (split.includes('create-product')) {
      this.currentFlow = 'product';
    }
  }

  /**
   * This function listens to the socket, waiting for changes in the selection of the commercial, and selects the package(s) in the screen of the client.
   */
  listenSocket() {
    this.webSocketService.listen().subscribe((data: any) => {
      if (this.currentFlow == 'ingredient') {
        if (
          this.webSocketService.getRole != 'admin' &&
          data.object.step == 'packaging' &&
          data.object.index != undefined
        ) {
          this.setSelectedAsFalse('combination');
          this.packagingOptions[data.object.index].selected = !this.packagingOptions[data.object.index].selected;
          this.pickedPackaging = this.packagingOptions[data.object.index];
          this.savePackagingSelection();
        }
      } else {
        if (data.object.package) {
          this.handleProductData(data);
        }
      }
    });
  }

  /**
   * This function handles the selection of a product packages in a live session.
   * @param data The data sent by the commercial by using the socket.
   */
  handleProductData(data: any) {
    if (this.webSocketService.getRole != 'admin' && data.object.index != undefined) {
      if (data.object.package == 'primary') {
        for (const element of this.primaryPackages) {
          element.selected = false;
        }
        this.primaryPackages[data.object.index].selected = !this.primaryPackages[data.object.index].selected;
        this.primarySelected = true;
        this.getSecondaryPackages(this.primaryPackages[data.object.index].empaqueId);
      } else {
        for (const element of this.secondaryPackages) {
          element.selected = false;
        }
        this.secondaryPackages[data.object.index].selected = !this.secondaryPackages[data.object.index].selected;
        this.savePackagingSelection();
      }
    }
  }

  /**
   * This function gets the packaging combinations for ingredients, filtering by the field 'use'.
   */
  async getPackagingCombinations() {
    await this.packagingService.getPackagingCombinations();
    this.packagingCombinations = this.packagingService.packagingInfo;
    let shape = this.getShapeSelection();
    let filtered = this.packagingCombinations.filter((c: any) => {
      if (c.use == 'Industry' && c.formaId == shape.formaId) return c;
    });
    this.packagingOptions = filtered;
    this.setSelectedAsFalse('combination');
  }

  removeDuplicates(list: any) {
    let uniqueObjects = [];
    let stringifyObjects: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let stringified = JSON.stringify(list[i]);
      if (!stringifyObjects.includes(stringified)) {
        uniqueObjects.push(list[i]);
        stringifyObjects.push(stringified);
      }
    }
    return uniqueObjects;
  }

  /**
   * This function gets all the primary packages by filtering all the combinations that match the shapeId.
   */
  getPrimaryPackages() {
    this.primarySelected = false;
    this.secondarySelected = false;
    this.thirdSelected = false;
    this.fourthSelected = false;
    let addedPackages: any[] = [];
    let combinations: any[] = [];
    let shape = this.getShapeSelection();
    let query = { formaId: shape.formaId };
    this.packagingService.filterCombination(query).then((res1) => {
      combinations = res1 as any[];
      combinations.forEach((comb) => {
        if (!addedPackages.find((c) => c.empaque1Id == comb.empaque1Id)) addedPackages.push(comb);
      });
      for (const element of addedPackages) {
        this.packagingService.filter({ empaqueId: element.empaque1Id }).then((res: any) => {
          (res as any[])[0].units = element.unitsPerPackage1;
          this.primaryPackages.push((res as any[])[0]);
          this.primaryPackages = this.removeDuplicates(this.primaryPackages);
        });
      }
    });
    this.setSelectedAsFalse('primary');
  }

  /**
   * This function gets all the secondary packages by filtering all the combinations that match the shapeId and de primaryPackageId.
   * @param primaryPackageId The id of the selected primary package.
   */
  async getSecondaryPackages(primaryPackageId: string) {
    this.primarySelected = true;
    this.secondarySelected = false;
    this.thirdSelected = false;
    this.fourthSelected = false;
    let combinations: any[] = [];
    let shape = this.getShapeSelection();
    let query = { formaId: shape.formaId, empaque1Id: primaryPackageId };
    this.secondaryPackages = [];
    const res = await this.packagingService.filterCombination(query);
    combinations = res as any[];
    for (const element of combinations) {
      this.packagingService.filter({ empaqueId: element.empaque2Id }).then((res2) => {
        if (Array.isArray(res2) && res2.length > 0) {
          // Verifica si res2 contiene datos antes de acceder a [0]
          (res2 as any[])[0].units = element.unitsPerPackage2;
          this.secondaryPackages.push((res2 as any[])[0]);
          this.secondaryPackages = this.removeDuplicates(this.secondaryPackages);
        } else {
          // Maneja el caso en el que res2 no contiene datos
          // Puedes mostrar un mensaje de error o tomar alguna otra acción adecuada
        }
      });
    }
    this.setSelectedAsFalse('secondary');
    return true;
  }

  /**
   * This function gets all the secondary packages by filtering all the combinations that match the shapeId and of primaryPackageId.
   * @param primaryPackageId The id of the selected primary package.
   * @param secondaryPackageId The id of the selected secondary package.
   */
  async getThirthPackages(primaryPackageId: string, secondaryPackageId: string) {
    this.primarySelected = true;
    this.secondarySelected = true;
    this.thirdSelected = false;
    this.fourthSelected = false;
    let combinations: any[] = [];
    let shape = this.getShapeSelection();
    let query = { formaId: shape.formaId, empaque1Id: primaryPackageId, empaque2Id: secondaryPackageId };
    this.thirdPackages = [];
    const res = await this.packagingService.filterCombination(query);
    combinations = res as any[];

    for (const element of combinations) {
      const res2 = await this.packagingService.filter({ empaqueId: element.empaque3Id });

      if (Array.isArray(res2) && res2.length > 0) {
        // Verifica si res2 contiene datos antes de acceder a [0]
        res2[0].units = element.unitsPerPackage3;
        this.thirdPackages.push(res2[0]);
        this.thirdPackages = this.removeDuplicates(this.thirdPackages);
      }
    }
    if (this.thirdPackages.length > 0) {
      this.requireThird = true;
    } else {
      this.requireThird = false;
    }
    this.setSelectedAsFalse('third');
    return true;
  }

  /**
   * This function gets all the secondary packages by filtering all the combinations that match the shapeId and of primaryPackageId.
   * @param primaryPackageId The id of the selected primary package.
   * @param secondaryPackageId The id of the selected secondary package.
   * @param thirthPackageId The id of the selected third package.
   */
  async getFourthPackages(primaryPackageId: string, secondaryPackageId: string, thirdPackageId: string) {
    this.primarySelected = true;
    this.secondarySelected = true;
    this.thirdSelected = true;
    this.fourthSelected = false;
    let combinations: any[] = [];
    let shape = this.getShapeSelection();
    let query = {
      formaId: shape.formaId,
      empaque1Id: primaryPackageId,
      empaque2Id: secondaryPackageId,
      empaque3Id: thirdPackageId,
    };
    this.fourthPackages = [];
    const res = await this.packagingService.filterCombination(query);
    // if (res.length > 0) {
    //   this.requireFourth = true;
    // } else {
    //   this.requireThird = this.requireFourth = false;
    // }
    combinations = res as any[];
    for (const element of combinations) {
      const res2 = await this.packagingService.filter({ empaqueId: element.empaque4Id });
      if (res2 && Array.isArray(res2) && res2.length > 0) {
        res2[0].units = element.unitsPerPackage4;
        this.fourthPackages.push(res2[0]);
        this.fourthPackages = this.removeDuplicates(this.fourthPackages);
      } 
      // } else {
      //   this.fourthPackages = [];
      // }
    }
    if (this.fourthPackages.length > 0) {
      this.requireFourth = true;
    } else {
      this.requireFourth = false;
    }
    this.setSelectedAsFalse('fourth');
    return true;
  }

  /**
   * This function sets all packaging combination options as false, to avoid multiple selections.
   * @param type The type of array which items selection will change.
   */
  setSelectedAsFalse(type: string) {
    if (type == 'combination') {
      for (const element of this.packagingOptions) {
        element.selected = false;
      }
    } else if (type == 'primary') {
      for (let i = 0; i < this.packagingOptions.length; i++) {
        this.primaryPackages[i].selected = false;
      }
    }
  }

  /**
   * This function handles the changes of the packaging combination option selected by the user.
   * @param pos The position of the selected item.
   */
  handleClickSelection(pos: number) {
    this.setSelectedAsFalse('combination');
    this.packagingOptions[pos].selected = !this.packagingOptions[pos].selected;
    this.pickedPackaging = this.packagingOptions[pos];
    this.savePackagingSelection();
    if (this.webSocketService.active) {
      if (this.webSocketService.getRole == 'admin' && this.currentFlow == 'ingredient') {
        this.webSocketService.emitToClient({ type: 'select', step: 'packaging', index: pos });
      }
    }
    this.completed.emit({ flow: this.currentFlow, step: 4, completed: true });
  }

  /**
   * This function handles the click of both primary and secondary package selectors.
   * @param packageType The classification of package. It can be primary or secondary.
   * @param pos The position of the selected package.
   */
  // Maneja la selección de un paquete en función de su tipo (primary, secondary, third, fourth) y posición (pos)
  handlePackageClick(packageType: string, pos: number) {
    let targetPackages, targetSelectedProp, targetGetter, targetRequireProp: any;
    
    // Configura las variables de destino en función del tipo de paquete
    switch (packageType) {
      case 'primary':
        targetPackages = this.primaryPackages;
        targetSelectedProp = 'primarySelected';
        targetGetter = (index: any) => this.getSecondaryPackages(this.primaryPackages[index].empaqueId);
        targetRequireProp = 'requireSecondary';
        break;
        case 'secondary':
          targetPackages = this.secondaryPackages;
          targetSelectedProp = 'secondarySelected';
          targetGetter = (index: any) =>
          this.getThirthPackages(this.primaryPackages[0].empaqueId, this.secondaryPackages[index].empaqueId);
          targetRequireProp = 'requireThird';
          break;
          case 'thirty':
            targetPackages = this.thirdPackages;
            targetSelectedProp = 'thirdSelected';
            targetGetter = (index: any) =>
            this.getFourthPackages(
              this.primaryPackages[0].empaqueId,
              this.secondaryPackages[0].empaqueId,
              this.thirdPackages[index].empaqueId
              );
              targetRequireProp = 'requireFourth';
        break;
      case 'fourth':
        targetPackages = this.fourthPackages;
        targetSelectedProp = 'fourthSelected';
        break;
      default:
        return; // Si el tipo de paquete no es reconocido, no hace nada
    }

    // Deselecciona todos los elementos en el conjunto de paquetes de destino
    for (const element of targetPackages) {
      element.selected = false;
    }

    // Cambia el estado seleccionado del paquete en la posición 'pos'
    targetPackages[pos].selected = !targetPackages[pos].selected;

    // Establece el estado seleccionado del paquete actual en true
    (this as any)[targetSelectedProp] = true;
    // this.emitSelected();

    // Si hay una función 'targetGetter', obtiene paquetes del siguiente nivel y actualiza el estado de requerimiento
    if (targetGetter) {
      targetGetter(pos).then((res) => {
        if (res) {
          (this as any)[targetRequireProp] = targetPackages.length > 0;
          this.emitSelected();
        }
      });
    } else {
      this.emitSelected();
    }

    // Si se cumple la condición en 'shouldEmitWebSocket', emite eventos WebSocket
    if (this.shouldEmitWebSocket(packageType)) {
      this.webSocketService.emitToClient({ type: 'select', step: 'packaging', package: packageType, index: pos });
    }
  }

  // Emite eventos en función de las selecciones realizadas en los paquetes de niveles
  emitSelected() {
    const isPackageSelected = (packageArray: any[]) => packageArray.some((p) => p.selected);

    let selectedFirst, selectedTwo, selectedThree, selectedFour: any;
    if (isPackageSelected(this.primaryPackages)) selectedFirst = true;
    if (isPackageSelected(this.secondaryPackages)) selectedTwo = true;
    if (isPackageSelected(this.thirdPackages)) selectedThree = true;
    if (isPackageSelected(this.fourthPackages)) selectedFour = true;

    // Verificamos que la primera y segunda opción estén seleccionadas
    if (selectedFirst && selectedTwo) {
      // Validamos si se requiere el tercer nivel de paquetes y si no lo requiere, emitimos el formulario
      if (!this.requireThird && this.requireThird !== undefined) {
        this.savePackagingSelection();
        this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
      } else {
        // Si es requerido el nivel 3 pero no se ha seleccionado el paquete, no emitimos el formulario
        if (!selectedThree && this.requireThird) {
          if (this.thirdPackages.length > 0) {
            this.completed.emit({ flow: this.currentFlow, step: 5, completed: false });
          } else {
            this.savePackagingSelection();
            this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
          }
        } else {
          // Si el nivel 3 es requerido y está seleccionado, validamos si es requerido el nivel 4 y si no es requerido, emitimos
          this.savePackagingSelection();
          this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
          // if (!this.requireFourth && !this.requireFourth !== undefined) {
          //   this.savePackagingSelection();
          //   this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
          // } else {
          //   if (!selectedFour) {
          //     //No se por que el requireForth siempre llega en true a pesar que lo cambio a false,
          //     this.savePackagingSelection();
          //     this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
          //   } else {
          //     this.savePackagingSelection();
          //     this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
          //   }
          // }
        }
      }
    } else {
      this.completed.emit({ flow: this.currentFlow, step: 5, completed: false });
    }

    // if (this.requireThird !== true) {
    //   this.savePackagingSelection();
    //   this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
    // } else {
    //   if (isPackageSelected(this.thirdPackages)) {
    //     if (this.requireFourth !== true) {
    //       this.savePackagingSelection();
    //       this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
    //     } else {
    //       if (isPackageSelected(this.fourthPackages)) {
    //         this.savePackagingSelection();
    //         this.completed.emit({ flow: this.currentFlow, step: 5, completed: true });
    //       } else {
    //         this.completed.emit({ flow: this.currentFlow, step: 5, completed: false });
    //       }
    //     }
    //   } else {
    //     this.completed.emit({ flow: this.currentFlow, step: 5, completed: false });
    //   }
    // }
  }

  // Determina si se deben emitir eventos WebSocket para las selecciones de paquetes de niveles
  shouldEmitWebSocket(packageType: string): boolean {
    return (
      this.webSocketService.active &&
      this.webSocketService.getRole == 'admin' &&
      this.currentFlow == 'product' &&
      (packageType == 'primary' || packageType == 'secondary')
    );
  }

  /**
   * This function saves the packaging combination object in the local storage.
   */
  savePackagingSelection() {
    if (this.currentFlow === 'ingredient') {
      localStorage.setItem('packaging', JSON.stringify(this.pickedPackaging));
    } else {
      let primary = this.primaryPackages.find((p) => p.selected);
      let secondary = this.secondaryPackages.find((p) => p.selected);
      let third = this.thirdPackages.find((p) => p.selected);
      let fourth = this.fourthPackages.find((p) => p.selected);
      let shape = this.getShapeSelection();
      if (third || fourth) {
        // Ensure that primary and secondary are defined before accessing their properties
        if (primary && secondary) {
          const empaque1Id = primary.empaqueId;
          const empaque2Id = secondary.empaqueId;
          const empaque3Id = third ? third.empaqueId : undefined;
          const empaque4Id = fourth ? fourth.empaqueId : undefined;
          let combinationObject = {
            formaId: shape.formaId,
            empaque1Id: empaque1Id,
            empaque2Id: empaque2Id,
            empaque3Id: empaque3Id,
            empaque4Id: empaque4Id,
          }
          if (empaque3Id==undefined) {
            delete combinationObject.empaque3Id
          }
          if (empaque4Id==undefined) {
            delete combinationObject.empaque4Id
          }
          this.packagingService
            .filterCombination(combinationObject)
            .then((res) => {
              localStorage.setItem('packaging', JSON.stringify((res as any[])[0]));
            });
        }
      } else {
        // Ensure that primary and secondary are defined before accessing their properties
        if (primary && secondary) {
          this.packagingService
            .filterCombination({
              formaId: shape.formaId,
              empaque1Id: primary.empaqueId,
              empaque2Id: secondary.empaqueId,
            })
            .then((res) => {
              localStorage.setItem('packaging', JSON.stringify((res as any[])[0]));
            });
        }
      }

      // Ensure that primary is defined before accessing its properties
      if (primary) {
        localStorage.setItem('type', JSON.stringify(primary.imagen));
      }
      // Ensure that primary is defined before accessing its properties
      if (primary) {
        localStorage.setItem('type', JSON.stringify(primary.imagen));
      }
    }
  }

  /**
   * This function returns the shape previously selected by the user and stored in the local storage.
   * @returns The shape previously selected by the user.
   */
  getShapeSelection() {
    return JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
  }

  /**
   * This function opens a modal to request another package.
   */
  openRequestModal() {
    let modal = this.sms.addModal(RequestModalComponent, {}, { autoFocus: true, closeOnClickOutside: true });
  }
}
