import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../core/services/project/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { SrpService } from '../../core/services/srp/srp.service';
import { PgService } from '../../core/services/tablepg/pg.service';
import { ManufactureCombinationService } from '../../core/services/manufacture-combination/manufacture-combination.service';
import { faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-simulador-pvp',
  templateUrl: './simulador-pvp.component.html',
  styleUrls: ['./simulador-pvp.component.css'],
  providers: [CurrencyPipe],
})
export class SimuladorPvpComponent implements OnInit {
  /**Id project to consult**/
  idProject: any;

  /**Project to consult **/
  project: any;

  /**Product consolidated of project **/
  productConsolidated: any;

  /**Combination of packaging's **/
  combinationPackaging: any;

  /**Default values of logistic expenses **/
  logistic: any = {
    shipping: 0,
    customs: 0,
    contingencies: 0,
    other: 0,
    total: 0,
  };

  /**Object of registers default**/
  registerDefault: any = {};

  /**Array of registers**/
  registers: any = [];

  /**This variable is the price / unit of the project**/
  priceUnit: any;

  /**This variable is the price final of the product**/
  finalPrice: any;

  /**This variable is the price / kg fixed**/
  priceKgFixed: any;

  /**This object save variables of margin and ebitda**/
  marginAndEbitda: any = {};

  informationOperations: any;

  manufactureCost: any;

  costPl: any = {};

  netSales: any;

  costsTransport: any;

  faTrash = faTrash;
  faFileExcel = faFileExcel;
  faFilePdf = faFilePdf;

  buttonsBol = false;
  fileName = 'Srp.xlsx';

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private srpService: SrpService,
    private pgService: PgService,
    private manufactureCombService: ManufactureCombinationService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.spinner.show();
    await this.getProjectById();
    this.loadDefaultRegister();
    this.loadMarginAndEbitda();
    this.getDataProject();
    await this.plTransportInformation();
    await this.loadPlOperations();
    this.spinner.hide();
  }

  /**
   * Get information of projectt by id and product consolidated.
   */
  async getProjectById() {
    this.idProject = this.route.snapshot.paramMap.get('id');
    this.project = await this.projectService.getProjectById(this.idProject);
    this.productConsolidated = await this.projectService.getProductById(this.project.producto.id);
    this.combinationPackaging = await this.projectService.getCombinationPackaging(
      this.productConsolidated.combinacionEmpaque._id
    );
    this.priceUnit =
      ((this.project.costoKg / 1000) * this.combinationPackaging.weightPerUnit) / this.project.precioDolar;
    this.priceKgFixed = this.project.costoKg / this.project.precioDolar;
  }

  /**
   * Change prices of logistic.
   */
  async onChangeLogistic() {
    this.logistic.total =
      this.logistic.shipping + this.logistic.customs + this.logistic.contingencies + this.logistic.other;
    this.registers[0].logisticExpenses = this.logistic.total;
    this.registers[0].logisticExpensesUnit = (this.logistic.total * 1) / this.project?.cantidadUnidades;
  }

  /**
   * Load default registers and validation of existing srp in the project.
   */
  loadDefaultRegister() {
    this.registerDefault = {
      default: true,
      action: false,
      actorName: '',
      cost: this.priceUnit,
      grossMargin: 0,
      logisticExpenses: 0,
      logisticExpensesUnit: 0,
      taxes: 0,
      others: 0,
      finalCost: 0,
      finalPrice: 0,
      lastPrice: 0,
    };
    this.registers.push(this.registerDefault);
    if (this.project.srp) {
      this.srpService.getSrpByIdProject(this.idProject).then((res: any) => {
        this.registers = res.listPrices;
        this.logistic.shipping = res.shipping;
        this.logistic.customs = res.customs;
        this.logistic.contingencies = res.contingencies;
        this.logistic.other = res.other;
        this.logistic.total = res.totalExpenses;
        this.finalPrice = res.finalPrice;
        this.registerDefault.logisticExpenses = res.totalExpenses;
      });
    }
  }

  /**
   * Add new register of prices.
   */
  addRegisterToArray() {
    this.registers.push({
      actorName: '',
      action: true,
      cost: this.registers[this.registers.length - 1].finalPrice,
      grossMargin: 0,
      logisticExpenses: 0,
      logisticExpensesUnit: 0,
      taxes: 0,
      finalCost: 0,
      finalPrice: 0,
      others: 0,
    });
  }

  /**
   * This function calculate of operations for array.
   * @param index of array to modify
   */
  updateRegister(index?: any) {
    let totalLogisticExpenses = (this.registers[index].logisticExpenses * this.logistic.total) / 100;
    let operationTotalLogicExpenses;
    let operationTotalLogicExpensesUnit;
    /**Calculate percentage of variables taxes and grossmarging **/
    let taxes = this.registers[index].taxes / 100;
    let gross = this.registers[index].grossMargin / 100;
    let othersPercentage = this.registers[index].others / 100;
    const { actorName, grossMargin, logisticExpenses, others } = this.registers[index];
    /**Validate of default register to no modify the register default**/
    if (this.registers[index].default) {
      operationTotalLogicExpensesUnit =
        (this.logistic.total * this.project.producto.contPalets) / this.project?.cantidadUnidades;
    } else {
      operationTotalLogicExpenses = totalLogisticExpenses;
      operationTotalLogicExpensesUnit = (operationTotalLogicExpenses * 1) / this.project?.cantidadUnidades;
    }
    this.registers[index].actorName = actorName;
    this.registers[index].grossMargin = grossMargin;
    this.registers[index].logisticExpenses = logisticExpenses;
    this.registers[index].logisticExpensesUnit = operationTotalLogicExpensesUnit;
    this.registers[index].others = others;
    if (index < 1) {
      this.registers[index].finalCost =
        this.priceUnit + operationTotalLogicExpensesUnit + this.priceUnit * othersPercentage + this.priceUnit * taxes;
    } else {
      this.registers[index].finalCost =
        this.registers[index].cost +
        (this.registers[index].logisticExpenses / 100) * this.registers[index].cost +
        this.registers[index].cost * othersPercentage +
        this.registers[index].cost * taxes;
    }
    this.registers[index].finalPrice = this.registers[index].finalCost / (1 - gross);
    this.finalPrice = this.registers[index].finalPrice;
    /**Save in to variable the final price**/
    if (this.registers[index + 1]) {
      this.registers[index + 1].cost = this.registers[index].finalPrice;
      this.finalPrice = this.registers[index].finalPrice;
    }
  }

  removeItem(index: any) {
    this.registers.splice(index, 1);
  }

  /**
   * This function send to service of srp the data of project.
   */
  postSrp() {
    let srp = {
      projectId: this.idProject,
      listPrices: this.registers,
      shipping: this.logistic.shipping,
      customs: this.logistic.customs,
      contingencies: this.logistic.contingencies,
      other: this.logistic.other,
      totalExpenses: this.logistic.total,
      finalPrice: this.finalPrice,
    };

    if (this.project.srp) {
      this.srpService.updateSrpById(this.project.srp, srp).then((res: any) => {
        this.router.navigateByUrl('/my-projects?filter=seguimiento');
      });
    } else {
      this.srpService.postSrp(srp).then((res: any) => {
        if (res) {
          this.projectService.putDataByProject(this.idProject, { srp: res.data._id }).then((res: any) => {
            this.router.navigateByUrl('/my-projects?filter=seguimiento');
          });
        }
      });
    }
  }

  getDataProject() {
    this.projectService.getDataByProject(this.idProject).then((res) => {
      this.informationOperations = res;
    });
  }

  async getManufacturing() {
    this.manufactureCost = await this.manufactureCombService.getCombinationsBySelections(
      this.combinationPackaging.formaId,
      this.combinationPackaging.combinacionEmpaqueId,
      this.productConsolidated.chocolate.tipo,
      this.project.producto.tieneInclusion
    );
  }

  async plTransportInformation() {
    this.costsTransport = await this.pgService.loadPgInformation();
  }

  async getCostVariablesPl() {
    this.netSales = (this.project.costoKg / this.project.precioDolar) * this.project.precioDolar;
    await this.getManufacturing();
    this.costPl.costosVariables = this.pgService.operationsCostVariablesPg(
      this.netSales,
      this.manufactureCost.zenr,
      this.manufactureCost.zgas,
      this.informationOperations.rawMaterialCostOne,
      this.informationOperations.packagingCost,
      this.informationOperations.rawMaterialCostTwo
    );
  }

  async getVariablesExpends() {
    this.costPl.variableExpends = this.pgService.operationsVariableExpends(
      this.project.pais,
      this.netSales,
      this.costsTransport.industryCommerceNational,
      this.costsTransport.taxThousandNational,
      this.costsTransport.briefcaseNational,
      this.costsTransport.inventoriesMpNational,
      this.costsTransport.inventoriesMfNational,
      this.costPl.costosVariables[0].energy,
      this.manufactureCost.zgas,
      this.manufactureCost.zmod,
      this.manufactureCost.zman,
      this.manufactureCost.zoin,
      this.costsTransport.providersNational,
      this.informationOperations.packagingCost,
      this.informationOperations.rawMaterialCostOne,
      this.informationOperations.rawMaterialCostTwo,
      this.costsTransport.monthlyRateNational,
      this.costsTransport.monthlyRateInternational,
      this.costsTransport.industryCommerceInternational,
      this.costsTransport.taxThousandExport,
      this.costsTransport.briefcaseInternational,
      this.costsTransport.inventoriesMpInternational,
      this.costsTransport.inventoriesMfInternational,
      this.costsTransport.providersInternational,
      this.costPl.costosVariables[0].contribVariable,
      this.informationOperations.comex / this.project.peso
    );
  }

  async getFixedDirect() {
    this.costPl.fixedDirects = this.pgService.operationsFixedDirect(
      this.project.pais,
      this.netSales,
      this.costsTransport.LogisticsWarehouseSaleNational,
      this.costsTransport.marketingNational,
      this.manufactureCost.zdep,
      this.manufactureCost.zmod,
      this.manufactureCost.zman,
      this.manufactureCost.zoin,
      this.costPl.variableExpends[0].contribFijDirec,
      this.costsTransport.LogisticsWarehouseSaleInternational,
      this.costsTransport.marketingInternational
    );
  }

  async getFixedPl() {
    this.costPl.fixedPl = this.pgService.operationFixedPg(
      this.project.pais,
      this.netSales,
      this.costsTransport.sellingExpensesNational,
      this.costsTransport.groupVariableNational,
      this.costsTransport.generalCompanyNational,
      this.costPl.fixedDirects[0].contribuCostGast,
      this.costsTransport.sellingExpensesInternational,
      this.costsTransport.groupVariableInternational,
      this.costsTransport.generalCompanyInternational
    );
  }

  async getOperationsFinalPl() {
    this.costPl.operationsFinal = this.pgService.operationFinalPg(
      this.informationOperations.rawMaterialCostOne,
      this.informationOperations.rawMaterialCostTwo,
      this.informationOperations.packagingCost,
      this.manufactureCost.zenr,
      this.manufactureCost.zdep,
      this.manufactureCost.zmod,
      this.manufactureCost.zman,
      this.manufactureCost.zoin,
      this.costPl.fixedPl[0].contribution,
      this.costPl.variableExpends[0].costCapital,
      this.netSales,
      this.costPl.costosVariables[0].costosVariables
    );
  }

  async loadPlOperations() {
    await this.getCostVariablesPl();
    await this.getVariablesExpends();
    await this.getFixedDirect();
    await this.getFixedPl();
    await this.getOperationsFinalPl();
  }

  /**
   * This function calculate margin and ebitda.
   * @param event value of input
   */
  async changeMarginEbitda(event: any) {
    this.project.costoKg = event.target.value * this.project?.precioDolar;
    this.marginAndEbitda.margin = this.costPl.operationsFinal[0].porcentajeMargenBruto;
    this.marginAndEbitda.ebitda = this.costPl.operationsFinal[0].porcentajeEbitda;
    this.priceUnit =
      ((this.project.costoKg / 1000) * this.combinationPackaging.weightPerUnit) / this.project.precioDolar;
    this.registerDefault.cost = this.priceUnit;
    for (let i of this.registers) {
      this.updateRegister(this.registers.indexOf(i));
    }
    this.spinner.show();
    await this.getCostVariablesPl();
    await this.getVariablesExpends();
    await this.plTransportInformation();
    await this.getFixedDirect();
    await this.getFixedPl();
    await this.getOperationsFinalPl();
    this.spinner.hide();
  }

  /**
   * Load margin and ebitda of project.
   */
  loadMarginAndEbitda() {
    this.marginAndEbitda.marginFixed = (this.productConsolidated.margenBruto / this.project.costoKg) * 100;
    this.marginAndEbitda.ebitdaFixed = (this.productConsolidated.ebitda / this.project.costoKg) * 100;
    this.marginAndEbitda.margin = this.marginAndEbitda.marginFixed;
    this.marginAndEbitda.ebitda = this.marginAndEbitda.ebitdaFixed;
  }

  convertPdf() {
    this.buttonsBol = true;
    setTimeout(() => {
      const srp = document.getElementById('contentToConvert')!;
      const srpHeight = srp.clientHeight;
      const srpWidth = srp.clientWidth;
      const options = { background: 'white', width: srpWidth, height: srpHeight };
      domtoimage.toPng(srp, options).then((dataUrl) => {
        const doc = new jsPDF(srpWidth > srpHeight ? 'l' : 'p', 'mm', [srpWidth, srpHeight]);
        const imgProps = doc.getImageProperties(dataUrl);
        const pdfWidth = imgProps.width;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
        doc.save('test.pdf');
        this.buttonsBol = false;
        });
        }, 500);
  }

  dataInformation() {
    return [
      {
        'Product name': `${this.productConsolidated?.chocolate.text} / ${
          (this.combinationPackaging?.empaque1.material, this.combinationPackaging?.empaque1.tipoEmpaque)
        }`,
        'Client name': this.project.cliente,
        'Quantity kg': this.project.peso,
        'Quantity units': this.project.cantidadUnidades,
        'Weight (gr)': this.combinationPackaging?.weightPerUnit,
        'Price / kg': Math.round((this.project?.costoKg / this.project?.precioDolar + Number.EPSILON) * 100) / 100,
        'Price / unit': Math.round((this.priceUnit + Number.EPSILON) * 100) / 100,
        palets: this.productConsolidated?.palets || 0,
        'Cont x 20': this.productConsolidated?.contPalets || 0,
        Shipping: this.logistic.shipping,
        Customs: this.logistic.customs,
        Contingencies: this.logistic.contingencies,
        Margin: Math.round(this.costPl.operationsFinal[0]?.porcentajeMargenBruto),
        Ebitda: Math.round(this.costPl.operationsFinal[0]?.porcentajeEbitda),
        'Final price': Math.round((this.finalPrice + Number.EPSILON) * 100) / 100,
        Other: this.logistic.other,
      },
    ];
  }

  exportExcel() {
    let dataInfo = this.dataInformation();
    let data = [];
    for (let item of this.registers) {
      data.push({
        'Actor name': item.actorName,
        Cost: Math.round((item.cost + Number.EPSILON) * 100) / 100,
        'Gross margin': item.grossMargin,
        'logistic expenses': item.logisticExpenses,
        'Logistic Expenses/Un': item.logisticExpensesUnit,
        taxes: item.taxes,
        others: item.others,
        'final cost': Math.round((item.finalCost + Number.EPSILON) * 100) / 100,
        'final price': Math.round((item.finalPrice + Number.EPSILON) * 100) / 100,
      });
    }
    const worksheetDataSrp: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const worksheetDataInfo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataInfo);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheetDataInfo, 'Information');
    XLSX.utils.book_append_sheet(workbook, worksheetDataSrp, 'Registros');
    XLSX.writeFile(workbook, this.fileName);
  }
}
