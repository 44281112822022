<div class="container-trends">
  <div class="row">
    <div class="col-xl-5 col-md-12 col-lg-12 col-sm-12 col-xs-12 mobile-column">
      <div class="card-options">
        <div class="d-flex justify-content-between">
          <div>
            <label class="title-compare">Select items to compare</label>
          </div>
          <div>
            <label class="min-max">Min 1, max 3</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="label-input">Select date to compare</label>
            <p-calendar selectionMode="range" [(ngModel)]="rangeDates"></p-calendar>
          </div>
          <div class="col-6 mt-3">
            <label class="label-input">Description</label>
            <div class="consult-description">
              <label>{{this.descriptionOne}}</label>
              <fa-icon [icon]="faMagnifyingGlass" (click)="activateModal(1)"></fa-icon>
            </div>
          </div>
          <div class="col-6 mt-3">
            <label class="label-input">SAP code</label>
            <div class="consult-description">
              {{this.sapCodeOne}}
            </div>
          </div>
          <div class="col-6 mt-3">
            <label class="label-input">Description</label>
            <div class="consult-description">
              <label>{{this.descriptionTwo}}</label>
              <fa-icon [icon]="faMagnifyingGlass" (click)="activateModal(2)"></fa-icon>
            </div>
          </div>
          <div class="col-6 mt-3">
            <label class="label-input">SAP code</label>
            <div class="consult-description">
              {{this.sapCodeTwo}}
            </div>
          </div>
          <div class="col-6 mt-3">
            <label class="label-input">Description</label>
            <div class="consult-description">
              <label>{{this.descriptionThree}}</label>
              <fa-icon [icon]="faMagnifyingGlass" (click)="activateModal(3)"></fa-icon>
            </div>
          </div>
          <div class="col-6 mt-3">
            <label class="label-input">SAP code</label>
            <div class="consult-description">
              {{this.sapCodeThree}}
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12" (click)="consultPrices()">
            <button class="btn-compare" [disabled]="rangeDates === undefined">Compare</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-7 col-md-12 col-lg-12 col-sm-12 col-xs-12">
      <div class="card-trends" *ngIf="showChart">
        <canvas id="chartPriceTrends"></canvas>
      </div>
      <div class="card-fill" *ngIf="showChart === false">
        Fill the form to compare
      </div>
    </div>
  </div>
</div>
