<div class="main-container">
    <div class="page-info">
        <span class="title"><span class="green-title">The Luker</span> anchor model</span>
    </div>
    <div class="content">
        <div class="farm-col cont1">
            <div class="farm-container">
                <div class="farm-img-container" (click)="showFarmDescription('necocli')">
                    <img src="./../../../../assets/sustainability/farms/necocli-preview.png" alt="" class="farm-img">
                </div>
                <span class="farm-name">El Rosario, Necoclí - Urabá</span>
            </div>
            <div class="farm-container">
                <div class="farm-img-container" (click)="showFarmDescription('huila')">
                    <img src="./../../../../assets/sustainability/farms/huila-preview.png" alt="" class="farm-img">
                </div>
                <span class="farm-name">La Escalereta, Huila</span>
            </div>

        </div>

        <div class="map-container">
            <div class="map-img-container">
                <img src="./../../../../assets/sustainability/farms/anchor-map.jpeg" alt="" class="map-img">
            </div>
        </div>

        <div class="farm-col">
            <div class="farm-container">
                <div class="farm-img-container" (click)="showFarmDescription('casanare')">
                    <img src="./../../../../assets/sustainability/farms/casanare-preview.png" alt="" class="farm-img">
                </div>
                <span class="farm-name">El Paraíso - Villanueva, Casanare</span>
            </div>

            <div class="farm-container">
                <div class="farm-img-container" (click)="showFarmDescription('luker')">
                    <img src="./../../../../assets/sustainability/farms/luker-preview.png" alt="" class="farm-img">
                </div>
                <span class="farm-name">Granja Luker</span>
            </div>
        </div>
    </div>
</div>