import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { InclusionsFlavorsService } from '../../../core/services/inclusions-flavors/inclusions-flavors.service';
import { PackagingService } from '../../../core/services/packaging/packaging.service';

@Component({
  selector: 'app-modal-consult',
  templateUrl: './modal-consult.component.html',
  styleUrls: ['./modal-consult.component.css'],
})
export class ModalConsultComponent extends SimpleModalComponent<any, any> implements OnInit {
  description: any;

  results: any;

  sapCodeSelected: any;

  typeConsult: any;

  typeRawMaterial: any;

  constructor(private inclusionFlavorService: InclusionsFlavorsService, private packagingService: PackagingService) {
    super();
  }

  ngOnInit(): void {
    this.getTypeConsult();
  }

  getTypeConsult() {
    this.typeConsult = JSON.parse(localStorage.getItem('tipoConsulta') || '{}');
    this.typeRawMaterial = JSON.parse(localStorage.getItem('tipoMateriaPrima') || '{}');
  }

  filterRawMaterials() {
    if (this.typeConsult === 'Ingrediente') {
      if (this.typeRawMaterial === 'Materia prima 1') {
        this.inclusionFlavorService
          .filterRawMaterialOne({ nombreComponente: this.description.toUpperCase() })
          .then((res: any) => {
            this.results = res;
            this.results = this.results.reduce((unique: any, o: any) => {
              if (!unique.some((obj: any) => obj.componente === o.componente)) {
                unique.push(o);
              }
              return unique;
            }, []);
          });
      } else if (this.typeRawMaterial === 'Chocolate') {
        this.inclusionFlavorService.filterChocolate({ texto: this.description }).then((res: any) => {
          this.results = res;
        });
      } else
        this.inclusionFlavorService.filter({ txt_material: this.description.toUpperCase() }).then((res: any) => {
          this.results = res;
        });
    } else {
      this.packagingService.filterConsult({ descripcion: this.description.toUpperCase() }).then((res: any) => {
        this.results = res;
      });
    }
  }

  setSelectedFalse() {
    for (const element of this.results) {
      element.selected = false;
    }
  }

  selectResult(index: any) {
    this.setSelectedFalse();
    this.results[index].selected = true;
    if (this.typeConsult === 'Ingrediente') {
      if (this.typeRawMaterial === 'Materia prima 1') {
        this.sapCodeSelected = this.results[index].componente;
      } else if (this.typeRawMaterial === 'Chocolate') {
        this.sapCodeSelected = this.results[index].materialSap;
      } else {
        this.sapCodeSelected = this.results[index].material;
      }
    } else {
      this.sapCodeSelected = this.results[index].codigoSap;
    }
  }

  confirm() {
    this.result = this.sapCodeSelected;
    this.close();
  }

  closeModal() {
    this.close();
  }
}
