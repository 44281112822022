<div class="modal-dialog" style="max-width: 700px">
  <div class="modal-content modal-container"  style="max-width: 700px">
    <div>
      <h4>Search sapcode by description</h4>
    </div>
    <div style="text-align: left">
      <label class="modal-description">Search product to compare</label>
      <input type="text" [(ngModel)]="nameMaterial" (keyup)="getRegisterInfo()">
    </div>
    <div class="results">
      <div class="result-row d-flex justify-content-between"
           *ngFor="let item of results; let i = index"
           (click)="selectResult(i)"
           [ngClass]="{'selected': item.selected}">
        <p>{{item.codigoSap}}</p>
        <p>{{item.nombre}}</p>
      </div>
      <p class="text-warning text-center mt-2">No hay resultados</p>
    </div>
    <div class="text-center mt-3">
      <div>
        <button type="button" class="submit" (click)="confirm()">Submit</button>
      </div>
      <div>
        <button type="button" class="cancel mt-3" (click)="closeModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>
