import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SimpleModalService } from 'ngx-simple-modal';
import ls from 'localstorage-slim';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipsService } from 'src/app/core/services/tips/tips.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ModalProductDetailsComponent } from '../modal-product-details/modal-product-details.component';
import * as fs from 'file-saver';
import { PgService } from 'src/app/core/services/tablepg/pg.service';

@Component({
  selector: 'app-download-quote',
  templateUrl: './download-quote.component.html',
  styleUrls: ['./download-quote.component.css'],
})
export class DownloadQuoteComponent implements OnInit {
  /** Output to know if the step is completed, i.e., a product is selected. */
  @Output() completed = new EventEmitter<any>();
  faMagnifyingGlass = faMagnifyingGlass;

  constructor(
    private sms: SimpleModalService,
    private spinner: NgxSpinnerService,
    private tipsService: TipsService,
    private formBuilder: FormBuilder,
    private pgService: PgService,


  ) {}

  /**Table of costs p&g */
  costsPg: any;

  totalCost = 0
  dollarPrice= 0
  productsList:any=[]

  columns: any[] = [
    { name: 'Actions' },
    { name: 'Sapcode' },
    { name: 'Txt Material' },
    { name: 'Total cost' },
    { name: 'Sale price (COP)' },
    { name: 'Margin (%)' },
    { name: 'EBITDA (%)' },
    { name: 'Sale price (USD)' }
  ]

  tableForm:FormGroup= this.formBuilder.group({
    form_array: this.formBuilder.array([
    ])
  });

  tips:any=[]
  brief:any = {}
  user:any = {}


  async ngOnInit() {
    await this.loadPgInformation()
    this.setParameters();
    this.setProducts();
    this.loadAssistantTips();
  }

   /**
   * Gets the form array.
   */
   public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row with the given product index in the productlists array
   *
   * @param index
   */
  async addNewRow(index: number) {
    const price_usd =this.productsList[index].price_usd||0
    const price_cop = this.dollarPrice*price_usd
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const ebitda = this.calculateEbitda(price_usd, this.productsList[index])
    const ebitda_percentage = price_usd!=0?((ebitda / price_cop) * 100):0

    const formGroup = this.formBuilder.group(
      {
        sapCode: this.productsList[index].sapCode,
        txt_material: this.productsList[index].txt_material,
        total_cost: Math.round(this.productsList[index].total_cost),
        price_cop: Math.round(price_cop),
        gross_margin: gross_margin_percentage==0?0:gross_margin_percentage.toFixed(2),
        ebitda: ebitda_percentage==0?0:ebitda_percentage.toFixed(2),
        price_usd: price_usd==0?0:price_usd.toFixed(2),
      }
    );
    this.getFormArray().push(formGroup);
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    formControls.get('price_usd')?.valueChanges.subscribe(v => this.updatePrice(v, index));
    formControls.get('gross_margin')?.valueChanges.subscribe(v => this.updateMargin(v, index));
    formControls.get('ebitda')?.valueChanges.subscribe(v => this.updateEbitda(v, index));

  }

  /**
   * Removes the given index row from the table.
   *
   * @param index
   */
  async removeRow(index: number)  {

      const product = this.productsList[index]
      this.totalCost-=this.productsList[index].total_cost;
      this.getFormArray().controls.splice(index, 1);
      await this.productsList.splice(index, 1)
      if (this.productsList.length==0) {
        this.completed.emit({ flow: 'quote', step: 2, completed: false });
        this.completed.emit({ flow: 'quote', step: 1, completed: false });
      }
      localStorage.setItem('productsList', JSON.stringify(this.productsList));
      return;
  }

  /**
   * Shows a dialog with the product details.
   *
   * @param index
   */
  async showProductDetails(index: number)  {

    

  }

  setProducts(){
    this.productsList = JSON.parse(localStorage.getItem('productsList')||"[]");
    for (let index = 0; index < this.productsList.length; index++) {
      this.addNewRow(index)
      this.totalCost+=this.productsList[index].total_cost;
    }
  }

  downloadAsPDF(){
    console.log("descargar como pdf")
  }
  async downloadAsExcel(){
    const cliente = this.brief.cliente;
    const preparadoPor = this.user.nombre + " " + this.user.apellido;
    const fecha = this.brief.fecha;
    const pais = this.brief.pais;
    const productsList = this.productsList
    const res = await fetch("../../../../assets/excel/quote.xlsx")
    const arrayBuffer = await res.arrayBuffer()
    var workbook = await new Workbook();
    await workbook.xlsx.load(arrayBuffer)
    let worksheet = workbook.getWorksheet(1);
    let worksheetEnglish = workbook.getWorksheet(2);

    //For quote in spanish
    worksheet.getCell('D9').value = cliente
    worksheet.getCell('H8').value = fecha
    worksheet.getCell('H8').value = fecha
    worksheet.getCell('H11').value = preparadoPor
    worksheet.getCell('D11').value = pais
    //For quote in english
    worksheetEnglish.getCell('D9').value = cliente
    worksheetEnglish.getCell('H8').value = fecha
    worksheetEnglish.getCell('H8').value = fecha
    worksheetEnglish.getCell('H11').value = preparadoPor
    worksheetEnglish.getCell('D11').value = pais
    for (let index = 0; index < productsList.length; index++) {
      const product = productsList[index]
      //For quote in spanish
      worksheet.getRow(14+index).getCell(2).value = product.sapCode
      worksheet.getRow(14+index).getCell(3).value = product.txt_material
      worksheet.getRow(14+index).getCell(8).value = product.price_usd
      //For quote in english
      worksheetEnglish.getRow(14+index).getCell(2).value = product.sapCode
      worksheetEnglish.getRow(14+index).getCell(3).value = product.txt_material
      worksheetEnglish.getRow(14+index).getCell(8).value = product.price_usd
      this.createSheet(product, workbook)
    }

    workbook.xlsx.writeBuffer().then((res: any) => {
      const blob = new Blob([res]);
      fs.saveAs(blob, 'portfolio-products.xlsx');
    });

  }

  async createSheet(product:any, workbook:any){
    let worksheet = workbook.addWorksheet(product.sapCode,  {properties:{defaultColWidth:17}});
    worksheet.addRow(["Packaging"])
    worksheet.addRow([
      "componente",
      "nombreComponente",
      "precio_neto",
      "factorKg",
      "costo",
      "status",
      "registro",
      "validez",
      "inco1",
      "inco2"
    ])
    const packagingList = product.packaging_list.map(({descElemento, grupoArticuloComponente, importeEscala, nombrePt, factorDivision, ump, moneda,  ...keepAttrs}:any) => keepAttrs)
    const orderPackaging = {componente: null,nombreComponente: null,precio_neto: null,factorKg: null, costo:null} 
    
    for (let packaging of packagingList) {
      const sortedPackaging = Object.assign(orderPackaging, packaging);
      const values = Object.values(sortedPackaging)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow(["Raw materials one"])
    worksheet.addRow([
      "componente",
      "nombreComponente",
      "precio_neto",
      "factorKg",
      "costo"
    ])
    const rawMaterialOneList = product.raw_material_one_list.map(({descElemento, grupoArticuloComponente, nombrePt, categoria,  ...keepAttrs}:any) => keepAttrs)
    const orderRawMaterialOne = {componente: null,nombreComponente: null,precio_neto: null,factorKg: null, costo:null} 
    
    for (let material of rawMaterialOneList) {
      const sortedRawMaterialOne = Object.assign(orderRawMaterialOne, material);
      const values = Object.values(sortedRawMaterialOne)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow(["Raw materials two"])
      worksheet.addRow([
        "componente",
        "nombreComponente",
        "precio_neto",
        "factorKg",
        "costo",
        "status",
        "registro",
        "validez",
        "inco1",
        "inco2"
    ])
    const rawMaterialTwoList = product.raw_material_two_list.map(({descElemento, grupoArticuloComponente, importeEscala, nombrePt, factorDivision, ump, moneda,  ...keepAttrs}:any) => keepAttrs)
    const orderRawMaterialTwo = {componente: null,nombreComponente: null,precio_neto: null,factorKg: null, costo:null} 
    
    for (let material of rawMaterialTwoList) {
      const sortedRawMaterialTwo = Object.assign(orderRawMaterialTwo, material);
      const values = Object.values(sortedRawMaterialTwo)
      worksheet.addRow(values)
      
    }
    worksheet.addRow()
    worksheet.addRow(["Processes"])
      worksheet.addRow([
        "ltxa_1", "arbid", "oee",
          "zmod",
          "zman",
          "zenr",
          "zgas",
          "zdep",
          "zoin",
          "subtotal"
    ])
    const processesList = product.processes_list
    const orderProcesses = {
      ltxa_1:null,
      arbid:null,
      oee:null,
      zmod:null,
      zman:null,
      zenr:null,
      zgas:null,
      zdep:null,
      zoin:null,
      subtotal:null
    } 
    
    for (let process of processesList) {
      const sortedProcesses = Object.assign(orderProcesses, process);
      const values = Object.values(sortedProcesses)
      worksheet.addRow(values)
      
    }

    worksheet.addRow()
    worksheet.addRow()
    worksheet.addRow(["Sale Price (USD)",	"Sale Price (COP)",	"Total cost", "Gross margin (COP)",	"Gross margin (%)", "EBITDA (COP)",	"EBITDA (%)"])
    worksheet.addRow([product.price_usd??0, product.price_cop??0, product.total_cost, product.gross_margin??0, product.gross_margin_percentage??0, product.ebitda??0, product.ebitda_percentage??0])

    worksheet.mergeCells('A1', 'J1');
    worksheet.mergeCells(`A${(packagingList.length + 4)}`, `E${(packagingList.length + 4)}`);
    worksheet.mergeCells(`A${(rawMaterialOneList.length + packagingList.length + 7)}`, `J${(rawMaterialOneList.length + packagingList.length + 7)}`);
    worksheet.mergeCells(`A${(rawMaterialOneList.length + packagingList.length + rawMaterialTwoList.length + 10)}`, `J${(rawMaterialOneList.length + packagingList.length + rawMaterialTwoList.length + 10)}`);
  }

  updatePrice(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_usd = +(value||0)
    const price_cop = this.dollarPrice*price_usd
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0

    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage

    localStorage.setItem('productsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
      price_cop: Math.round(price_cop),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0
    }, {emitEvent: false, onlySelf: true})
  }

  updateMargin(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const gross_margin_percentage = value||0
    const price_cop = gross_margin_percentage==100 || gross_margin_percentage==0?0:(this.productsList[index].total_cost)/(1 - gross_margin_percentage/100)
    const price_usd = price_cop/this.dollarPrice
    const gross_margin = price_cop!=0?price_cop-this.productsList[index].total_cost:0
    const ebitda = price_usd!=0?this.calculateEbitda(price_usd, this.productsList[index]):0
    const ebitda_percentage= price_usd!=0?((ebitda / price_cop) * 100):0
    
    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage
    localStorage.setItem('productsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      price_usd: price_usd!=0?price_usd.toFixed(2):0,
      price_cop: Math.round(price_cop),
      ebitda: ebitda_percentage!=0?ebitda_percentage.toFixed(2):0
     }, {emitEvent: false, onlySelf: true})
  }

  updateEbitda(value:any, index:any){
    const formArray = this.getFormArray().controls;
    const formControls = formArray[index]
    const price_cop =  this.calculateNetsalesFromEbitda(value, this.productsList[index])
    const price_usd = price_cop/this.dollarPrice
    const gross_margin = price_cop-this.productsList[index].total_cost
    const gross_margin_percentage = price_cop!=0?(gross_margin*100/price_cop):0
    const ebitda_percentage = value||0
    const ebitda = ebitda_percentage*price_cop/100

    this.productsList[index].price_usd = price_usd
    this.productsList[index].price_cop = price_cop
    this.productsList[index].gross_margin = gross_margin
    this.productsList[index].gross_margin_percentage = gross_margin_percentage
    this.productsList[index].ebitda = ebitda
    this.productsList[index].ebitda_percentage = ebitda_percentage
    
    localStorage.setItem('productsList', JSON.stringify(this.productsList));
    formControls.patchValue({
      price_usd: price_usd!=0?price_usd.toFixed(2):0,
      price_cop: Math.round(price_cop),
      gross_margin: gross_margin_percentage!=0?gross_margin_percentage.toFixed(2):0,
     }, {emitEvent: false, onlySelf: true})
  }

  

  loadAssistantTips() {
    let queryTips: any = {};
      queryTips.paso = "SHAPE AND SIZE"
      this.tips = [];
      this.tipsService.filter(queryTips).then((res:any) => {
        this.tips = res as any[];
      });
  }

  setParameters(){
    const parameters =JSON.parse(ls.get('parameters', { decrypt: true }) || '{}');
    this.brief =JSON.parse(ls.get('brief', { decrypt: true }) || '{}');
    this.user =JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    if (parameters.dollarPrice!=null) {
      this.dollarPrice=parameters.dollarPrice;
    }
  }
  
  openModalProductInfo(index:number) {
    this.sms.addModal(ModalProductDetailsComponent,
      {product: this.productsList[index]},
      {
        closeOnEscape: true,
        closeOnClickOutside: true
      }
    ).subscribe((res: any) => {
    });
  }

    /**This function load the costs of p&g */
    async loadPgInformation() {
      this.costsPg = await this.pgService.loadPgInformation();
    }
  
    calculateEbitda(priceUSD:any, product:any){
      if(!(priceUSD>0)) return 0
      const pais = this.brief.pais;
      const netSales = priceUSD * this.dollarPrice;
      const DataPgOperationsCostVariables:any = this.pgService.operationsCostVariablesPgObject(
        netSales,
        product.processes.zenr,
        product.processes.zgas,
        product.cost_raw_material_one,
        product.cost_raw_material_two,
        product.cost_packaging
      );
      const DataPgOperationsVariableExpends = this.pgService.operationsVariableExpendsObject(
        pais,
        netSales,
        this.costsPg.industryCommerceNational,
        this.costsPg.taxThousandNational,
        this.costsPg.briefcaseNational,
        this.costsPg.inventoriesMpNational,
        this.costsPg.inventoriesMfNational,
        DataPgOperationsCostVariables.energy,
        product.processes.zgas,
        product.processes.zmod,
        product.processes.zman,
        product.processes.zoin,
        this.costsPg.providersNational,
        product.cost_packaging,
        product.cost_raw_material_one,
        product.cost_raw_material_two,
        this.costsPg.monthlyRateNational,
        this.costsPg.monthlyRateInternational,
        this.costsPg.industryCommerceInternational,
        this.costsPg.taxThousandExport,
        this.costsPg.briefcaseInternational,
        this.costsPg.inventoriesMpInternational,
        this.costsPg.inventoriesMfInternational,
        this.costsPg.providersInternational,
        DataPgOperationsCostVariables.contribVariable,
        0
      );
  
      const DataPgOperationsFixedDirect:any = this.pgService.operationsFixedDirectObject(
        pais,
        netSales,
        this.costsPg.LogisticsWarehouseSaleNational,
        this.costsPg.marketingNational,
        product.processes.zdep,
        product.processes.zmod,
        product.processes.zman,
        product.processes.zoin,
        DataPgOperationsVariableExpends.contribFijDirec,
        this.costsPg.LogisticsWarehouseSaleInternational,
        this.costsPg.marketingInternational
      );
  
      const DataPgOperationsFixed = this.pgService.operationFixedPgObject(
        pais,
        netSales,
        this.costsPg.sellingExpensesNational,
        this.costsPg.groupVariableNational,
        this.costsPg.generalCompanyNational,
        DataPgOperationsFixedDirect.contribuCostGast,
        this.costsPg.sellingExpensesInternational,
        this.costsPg.groupVariableInternational,
        this.costsPg.generalCompanyInternational
      );
      const ebitda = DataPgOperationsFixed.contribution + product.processes.zdep + DataPgOperationsVariableExpends.costCapital
      return ebitda
    }
  
    calculateNetsalesFromEbitda(ebitda_percentage:any, product:any){
      const pais = this.brief.pais;
      const netSales = this.pgService.netSalesFromEbitdaPercentage(
        ebitda_percentage,
        pais,
        product.processes.zenr,
        product.processes.zgas,
        product.processes.zmod,
        product.processes.zman,
        product.processes.zoin,
        product.cost_packaging,
        product.cost_raw_material_one,
        product.cost_raw_material_two,
        this.costsPg.sellingExpensesNational,
        this.costsPg.sellingExpensesInternational,
        this.costsPg.groupVariableNational,
        this.costsPg.groupVariableInternational,
        this.costsPg.generalCompanyNational,
        this.costsPg.generalCompanyInternational,
        this.costsPg.industryCommerceNational,
        this.costsPg.industryCommerceInternational,
        this.costsPg.taxThousandNational,
        this.costsPg.taxThousandExport,
        this.costsPg.LogisticsWarehouseSaleNational,
        this.costsPg.LogisticsWarehouseSaleInternational,
        this.costsPg.marketingNational,
        this.costsPg.marketingInternational,
        0,
  
      )
      return netSales
    }
}