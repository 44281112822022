<div class="bee-page-container" style="margin-top: 200px; position: fixed">
  <div class="d-flex justify-content-end p-3">
    <p class="logout-item" (click)="logout()">Logout</p>
  </div>
  <div class="bee-row bee-row-1">
    <div class="bee-row-content">
      <div class="bee-col bee-col-1 bee-col-w12">
        <div class="bee-block bee-block-1 bee-image"><img alt="" class="bee-center bee-autowidth" src="./assets/images/17b0f29c-9e50-47b3-bd3d-26d651510cc1.jpg" style="max-width:1025px;"/></div>
      </div>
    </div>
  </div>
  <div class="bee-row bee-row-2">
    <div class="bee-row-content">
      <div class="bee-col bee-col-1 bee-col-w6">
        <div class="bee-block bee-block-1 bee-image"><img alt="" class="bee-center bee-fixedwidth"(click)="btnClick('rtl')"  src="./assets/images/Discover_our_products_1740_x_2715.png" style="max-width:370px;"/></div>
      </div>
      <div class="bee-col bee-col-2 bee-col-w6">
        <div class="bee-block bee-block-1 bee-image"><img alt="" class="bee-center bee-fixedwidth" (click)="btnClick('product')" src="./assets/images/lets_create_1740_x_2715.png" style="max-width:358px;"/></div>
      </div>
    </div>
  </div>
</div>
