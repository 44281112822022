import { Component, OnInit } from '@angular/core';

import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-change-data-modal',
  templateUrl: './change-data-modal.component.html',
  styleUrls: ['./change-data-modal.component.css'],
})
export class ChangeDataModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  /**This variable get data of component product summary **/
  data: any;

  resultModified: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.resultModified = this.data;
  }

  /**This function close to modal and send to data **/
  submitBtn() {
    this.data.modifiedParameters = true;
    this.result = this.data;
    this.close();
  }

  closeModal() {
    this.resultModified.modifiedParameters = false;
    // this.result = this.resultModified;
    this.close();
  }
}
