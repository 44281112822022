<div class="main-container">
  <div class="left-container">
    <div class="step-info">
      <div>
        <p class="step-title">{{ stepName }}</p>
        <p class="step-description">{{ stepDescription }}</p>
      </div>
    </div>
    <div class="container-img">
      <img src="{{ currentDesign.img }}" alt="" class="current-design" />
    </div>
  </div>

  <div class="right-container">
    <div class="btn-container">
      <button class="send-layout-btn" (click)="saveDesginComment()">
        My design team will hand in the artwork. Send me the mechanical layout.
      </button>
    </div>
    <div class="carousels">
      <div class="horizontal-carousel">
        <div class="name">
          <p class="carousel-name">Luker Design Hub</p>
        </div>
        <div class="w-100">
          <swiper
            [spaceBetween]="30"
            [slidesPerView]="3"
            [navigation]="true"
            [pagination]="{
              clickable: true
            }"
            class="swiper-container"
            >
            <ng-template swiperSlide *ngFor="let item of designImages; let i = index">
              <div [class.selected]="item.selected" (click)="handleClickSelection('design', i)">
                <div class="w-100 d-flex justify-content-center">
                  <img src="https://luker-recursos.s3.amazonaws.com/create/design/{{item.texto}}.png" alt="image design luker" class="item-img">
                </div>

              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-container">
    <div class="step-info">
      <div>
        <p class="step-title">{{ stepName }}</p>
        <p class="step-description">{{ stepDescription }}</p>
      </div>
    </div>
    <div class="container-img">
      <img src="{{ currentDesign.img }}" alt="" class="current-design" />
    </div>
  </div>
</div>
