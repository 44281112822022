import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import ls from 'localstorage-slim';

import { IUserResponse } from 'src/app/core/interfaces/user.interface';

import { AuthService } from '../../core/services/auth/auth.service';
import { RdServicesService } from '../../core/services/rd-project/rd-services.service';

@Component({
  selector: 'app-rd-projects',
  templateUrl: './rd-projects.component.html',
  styleUrls: ['./rd-projects.component.css'],
})
export class RdProjectsComponent implements OnInit {
  allUsers: IUserResponse[] = [];
  selectedUser: string = this.activeUser.id;
  projectNameFilter: string = '';

  /** The amount of project type docs. */
  queryLength = 0;

  /**
   * User logged in.
   * **/
  user: any = this.activeUser;

  /**
   * List of projects rd by user
   * **/
  userProjectsRd: any[] = [];

  /** Variable that holds the current page of the pagination. */
  page = 1;

  constructor(private router: Router, private rdProjectService: RdServicesService, private authService: AuthService) {}

  async ngOnInit() {
    this.allUsers = await this.rdProjectService.getAllUsers();
    this.allUsers.push(this.activeUser);
    await this.getProjectsById(1);
  }

  /** The data of the active user. */
  get activeUser() {
    const user = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    return user;
  }

  /**
   * async function obtain data of projects rd by user logged in
   * @param event number of page
   * **/
  async getProjectsById(event: any) {
    const pageNumber = parseFloat(event);
    const paginationProjectsRd: any = await this.rdProjectService.getProjectRdByUser(this.selectedUser, pageNumber);
    this.queryLength = paginationProjectsRd.totalDocs;
    this.userProjectsRd = paginationProjectsRd.docs;
  }

  deleteProject(rdProject: any) {
    this.rdProjectService.deleteRdProject(rdProject).then((res: any) => {
      this.getProjectsById(1);
    });
  }

  /**
   * This function redirecto to the project and create new project rd
   * **/
  redirectToNewRd() {
    let projectRd = {
      elaboradoPor: this.user._id,
    };
    this.rdProjectService.postNewRdProject(projectRd).then((res: any) => {
      this.router.navigateByUrl(`rd-consulting/${res.data._id}`);
    });
  }

  /**
   * This function filter projects rd by project name
   * @param event event of input
   */
  async onProjectNameFilterChange(event: any) {
    this.projectNameFilter = event.target.value;
    if (this.projectNameFilter.length > 3) {
      this.userProjectsRd = [];
      const projects = await this.rdProjectService.filterProjectsRdByProjectName(this.projectNameFilter);
      this.userProjectsRd = projects;
    } else {
      this.userProjectsRd = [];
      this.getProjectsById(1);
    }
  }
}
