import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterTypeService } from '../../../core/services/register-type/register-type.service';
import ls from 'localstorage-slim';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.css'],
})
export class MenuCardComponent implements OnInit {
  /** Input for the title of the card. */
  @Input() title = '';

  /** Input for the description of the card. */
  @Input() detail = '';

  /** Input for the image or logo of the card. */
  @Input() imgPath = '';

  /** Input to indicate which path should the router redirect to. */
  @Input() redirectTo = '';

  /** Input to indicate which path should the router redirect to. */
  @Input() externalSite = false;

  constructor(private router: Router, private registerTypeService: RegisterTypeService) {}


  ngOnInit(): void {
  }

  /**
   * This function handles the click events, redirecting the user and saving the click origin.
   */
  cardClicked() {
    if (this.redirectTo == 'NPD Cost') {
      console.log('Redirigiendo a NPD Cost');
      const token: string = ls.get('id_token', { decrypt: true })!;
      const formatToken = token!.split(' ')[1];
      const splittedToken= formatToken.split('.');
      let urlToken = ""
      for (let index = 0; index < splittedToken.length; index++) {
        if ((splittedToken.length-1)==index) {
          
          urlToken += splittedToken[index];
        } else {
          urlToken += splittedToken[index]+"PUNTO";
        }
      }
      const user = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
      window.location.href = `${environment.npdURL}/redirect-v6/${urlToken}/${user._id??"none"}`;
    } else {
      this.router.navigate([this.redirectTo]);
    }
  }
}
