import { Component, OnInit } from '@angular/core';
import { ChocolateService } from '../../../core/services/chocolate/chocolate.service';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { TitleCasePipe } from '@angular/common';
import { WebSocketService } from '../../../core/services/web-socket/web-socket.service';
import { RegisterTypeService } from '../../../core/services/register-type/register-type.service';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-basics-selection',
  templateUrl: './basics-selection.component.html',
  styleUrls: ['./basics-selection.component.css'],
})
export class BasicsSelectionComponent implements OnInit {
  /** This object is used to store the selected application.*/
  selectedApplication = {};

  /** This object is used to store the selected product category.*/
  selectedCategory = {};

  /** This attribute is used to store the selected recipe. It is also used for filtering the chocolate types.*/
  selectedRecipe = '';

  /** This attribute is used to store the selected chocolate type.*/
  selectedType = '';

  /** This attribute is used to store the selected project size.*/
  selectedProjectSize = '';

  /** This attribute is used to store the selected leadtime.*/
  selectedLeadtime = '';

  /** This array is used to store the selected ingredient specs.*/
  selectedIngredientSpecs: any[] = [];

  /** This array stores the chocolate objects to use less backend calls.*/
  chocolateInfo: any;

  /** This attribute is used to store the type of screen that will display. */
  screenType = '';

  /**Icon **/
  faCircleArrowDown = faCircleArrowDown;
  /** This object stores all the options available for each category of the basics selection. */
  options: any = {
    applicationOptions: [
      { name: 'Enrobing', selected: false, key: 'enrobing' },
      { name: 'Desserts', selected: false, key: 'desserts' },
      { name: 'Moulding', selected: false, key: 'moulding' },
      { name: 'Decorating', selected: false, key: 'decorating' },
      { name: 'Fillings & Ganaches', selected: false, key: 'filling' },
      { name: 'Decorative Figures', selected: false, key: 'decorativeFigures' },
      { name: 'Baking', selected: false, key: 'baking' },
    ],
    categoryOptions: [
      { name: 'Confectionary', selected: false, key: 'confectionary' },
      { name: 'Beverages', selected: false, key: 'beverages' },
      { name: 'Snacks', selected: false, key: 'snacks' },
      { name: 'Baking', selected: false, key: 'baking' },
      { name: 'Dragees', selected: false, key: 'dragees' },
      { name: 'Seasonal', selected: false, key: 'seasonal' },
    ],
    recipesOptions: [],
    filteredChocolateType: [],
    leadtimeOptions: [
      { name: 'In a hurry (3 months)', selected: false },
      { name: 'I can wait (5 months)', selected: false },
      { name: 'I have time (7 months)', selected: false },
    ],
    ingredientSpecsOptions: [
      { name: 'Kosher', selected: false, key: 'kosher' },
      { name: 'Non-GMOs', selected: false, key: 'nongmo' },
      { name: 'Organic', selected: false, key: 'organic' },
      { name: 'Vegan', selected: false, key: 'vegan' },
    ],
  };

  constructor(
    private chocolateService: ChocolateService,
    private router: Router,
    private titlecase: TitleCasePipe,
    private webSocketService: WebSocketService,
    private registerTypeService: RegisterTypeService
  ) {}

  ngOnInit(): void {
    this.setBasicsType();
    this.getChocolateProperties();
    this.getChocolateOptions();
    this.setLiveSessionData();
  }

  /**
   * This function gets the properties of the chocolate objects.
   */
  async getChocolateProperties() {
    this.chocolateService.getChocolateInfo().then((value) => {
      this.chocolateInfo = value;
    });
  }

  /**
   * This function sets the data needed to navigate in a live session.
   */
  setLiveSessionData() {
    if (this.webSocketService.getSessionId && this.webSocketService.getRole == 'viewer') {
      this.webSocketService.listen().subscribe((data: any) => {
        if (data.object.type == 'redirect') {
          const route = data.object.url;
          this.router.navigateByUrl(route);
        }
      });
    }
  }

  /**
   * This functions sets all the chocolate type options and recipes before filtering.
   */
  async getChocolateOptions() {
    await this.chocolateService.getChocolateOptions();
    let chocolates = [];
    let recipes = [];
    chocolates = this.chocolateService.chocolateTypeOptions;
    for (const element of chocolates) {
      this.options.filteredChocolateType.push({
        name: element,
        img: `https://luker-recursos.s3.amazonaws.com/create/basics/${element}.PNG`,
        selected: false,
      });
    }
    recipes = this.chocolateService.recipesOptions;
    for (const element of recipes) {
      this.options.recipesOptions.push({ name: element, selected: false });
    }
  }

  /**
   * This function is used to filter the chocolate types by a previously selected recipe.
   * @param type The recipe you need the function to filter by.
   */
  getTypesByRecipe(type: any) {
    let filtered = this.chocolateInfo.filter(function (c: { portafolio: any; tipo: any }) {
      if (c.portafolio == type) return c.tipo;
    });
    filtered = _.uniqBy(filtered, 'tipo');
    this.options.filteredChocolateType = filtered.map((c: { tipo: any }) => {
      return {
        name: c.tipo,
        img: `./../../../../assets/basics/${c.tipo}.PNG`,
        selected: false,
      };
    });
    if (type=="") {
      this.getChocolateOptions()
    }
  }

  /**
   * This function is used to update the chocolate type options by calling the getTypesByRecipe() function.
   */
  filterBySelectedRecipe() {
    this.getTypesByRecipe(this.selectedRecipe);
  }

  /**
   * This function sets the basics type, depending on which screen the user made the change from.
   */
  setBasicsType() {
    let url = this.router.url;
    let split = url.split('/');
    if (split.includes('create-ingredient')) {
      this.screenType = 'ingredient';
    } else if (split.includes('create-product')) {
      this.screenType = 'product';
    }
  }

  /**
   * This function is used to navigate through the stepper.
   */
  changeToStepper() {
    localStorage.setItem(
      'selectedOptions',
      JSON.stringify({
        application: this.screenType == 'ingredient' ? this.selectedApplication : this.selectedCategory,
        recipe: this.selectedRecipe,
        chocolateType: this.selectedType,
        leadtime: this.selectedLeadtime,
        ingredientSpecs: this.selectedIngredientSpecs,
      })
    );
    this.router.navigateByUrl(`/create-${this.screenType}/steps/1`);
    const typeBrief = localStorage.getItem('origin');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let typeRegister: any = {
      tipo: typeBrief,
      usuario: user,
    };
    this.registerTypeService.postRegisterType(typeRegister);
  }

  /**
   * This function handles the changes of the category options selected by the user, depending on which screen the user made the change from.
   * @param category The category where the item was selected or changed.
   * @param pos The position of the selected item.
   */
  handleClickSelection(category: string, pos: number) {
    if (category != 'ingredientSpecsOptions') {
      for (let i = 0; i < this.options[category].length; i++) {
        if (i != pos) this.options[category][i].selected = false;
      }
    }
    this.handleClick(category, pos);
  }

  /**
   * This function handles the selections and changes made from the Ingredient Basics screen.
   * @param category The category where the item was selected or changed.
   * @param pos The position of the selected item.
   */
  handleClick(category: string, pos: number) {
    switch (category) {
      case 'applicationOptions':
        this.options.applicationOptions[pos].selected = !this.options.applicationOptions[pos].selected;
        this.options.applicationOptions[pos].selected
          ? (this.selectedApplication = this.options.applicationOptions[pos])
          : (this.selectedApplication = {});
        break;

      case 'categoryOptions':
        this.options.categoryOptions[pos].selected = !this.options.categoryOptions[pos].selected;
        this.options.categoryOptions[pos].selected
          ? (this.selectedCategory = this.options.categoryOptions[pos])
          : (this.selectedCategory = {});
        break;

      case 'recipesOptions':
        this.options.recipesOptions[pos].selected = !this.options.recipesOptions[pos].selected;
        this.options.recipesOptions[pos].selected
          ? (this.selectedRecipe = this.options.recipesOptions[pos].name)
          : (this.selectedRecipe = '');
        this.filterBySelectedRecipe();
        break;

      case 'filteredChocolateType':
        this.options.filteredChocolateType[pos].selected = !this.options.filteredChocolateType[pos].selected;
        this.options.filteredChocolateType[pos].selected
          ? (this.selectedType = this.options.filteredChocolateType[pos].name)
          : (this.selectedType = '');
        break;

      case 'leadtimeOptions':
        this.options.leadtimeOptions[pos].selected = !this.options.leadtimeOptions[pos].selected;
        this.options.leadtimeOptions[pos].selected
          ? (this.selectedLeadtime = this.options.leadtimeOptions[pos].name)
          : (this.selectedLeadtime = '');
        break;

      case 'ingredientSpecsOptions':
        this.options.ingredientSpecsOptions[pos].selected = !this.options.ingredientSpecsOptions[pos].selected;
        if (
          this.options.ingredientSpecsOptions[pos].selected &&
          !this.selectedIngredientSpecs.includes(this.options.ingredientSpecsOptions[pos])
        ) {
          this.selectedIngredientSpecs.push(this.options.ingredientSpecsOptions[pos]);
        } else if (
          !this.options.ingredientSpecsOptions[pos].selected &&
          this.selectedIngredientSpecs.includes(this.options.ingredientSpecsOptions[pos])
        ) {
          const index = this.selectedIngredientSpecs.findIndex((s) => s == this.options.ingredientSpecsOptions[pos]);
          this.selectedIngredientSpecs.splice(index, 1);
        }
        break;

      default:
        break;
    }
  }
}
