<div class="main-container">
  <a routerLink="/trends">< Go back</a>
    <h3 style="text-align: left;">{{title}}</h3>
    <div class="content">
        <div class="left-container">
            <div class="info-container cont-1">
                <ng-container *ngIf="key == 'usa_healthy'">
                    <p class="subtitle">Sugar Free/Alternative sugar: Due to health concerns consumers are trying to reduce or avoid traditional sugar when buying chocolate:</p>
                    <div class="section-info">
                        <ul>
                            <li>10,5% of Americans have diabetes and 34,5% of adults have pre-diabites while 42% of Americans have diabetes. 55% of US consumers who have cut back on chocolate in the past year cite 'reducing sugar intake' as a reason.-We expect to see stronger regulations.</li>
                            <li>Market share for sugar free chocolate has grown 13 times from 2019 to 2021. They now account for 3,7% of total launches.</li>
                            <li>Average sugar content in north america has declined from 47g per 100gr in 2016-17 to 44g per 100gr in 2020-21.</li>
                            <li>Darker chocolates, sugar free chocolates and alternative sugars are among the most dynamic new product launches.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Snacks: Growing trend for chocolate consumption focused on small formats and healthy options for day to day consumption occassions for busy lifestyles.</p>
                    <div class="section-info">
                        <ul>
                            <li>58% of Us consumers are snacking more since covid. 76% say it is a way of treating themselves, 63% Snack as a way of breaking there day.</li>
                            <li>Acording to IFICF 85% of american have made at least one change in the food they eat, snacks was the most common category.</li>
                            <li>Small chocolate format leading market share with 28,5%-pouches, bags 21,9%-tablets-13,38%.</li>
                            <li>Portion control main driver for small snacking formats great opportunity for sharing bags and pouches.</li>
                            <li>Main stream diets  reason for snacking formats and great opportunity for dark chocolate that is naturally keto friendly.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Functional foods: Food that serve a specific purpose, add nutritional value and make consumers feel great.</p>
                    <div class="section-info">
                        <p>Protein:</p>
                        <ul>
                            <li>Foods with protein will increases the sensation of satiety of consumers for longer periods of time, helping reduce overall caloric intake.</li>
                            <li>Protein is closely coneccted to healthy and active lifestyles helping repair muscle tissue.</li>
                            <li>Functional Claims have grown 115% in the last year in new product launches 2020.</li>
                            <li>Both in bevergaes and snacking products protein is gaining terrain. xxx number of launches in the US in 2020 claimed high protein.</li>
                        </ul>

                        <p>Antiox:</p>
                        <ul>
                            <li>Antioxidant play different key roles in a healthy body including: heart heath and skin care among many other. Antioxidants protects us from radicals and illness.</li>
                            <li>Chocolate is naturally a great source of antioxidants. Cocoa Polyphenols are welll-known in the scientific community for their powerful antioxidant capacity.</li>
                            <li>Antioxidants in 100 gr of a dark chocolate bar = 6 glasses of wine = 3 green tea cups = potassium in 1 babana = calcium in 2 glass of milk = copper in 1 steak of meat.</li>
                        </ul>
                    </div>
                </ng-container>


                <ng-container *ngIf="key == 'usa_ethical'">
                    <p class="subtitle">Plant Based, veganism and flexitarianism as a new to help the planet and introduce healthier eating habits</p>
                    <div class="section-info">
                        <p>Consumers want products that take advantage of fruits vegetables, seed and nuts to replace traditional animal products.</p>
                        <ul>
                            <li>Given that most of carbon emission in the food industry comes from animals, conscious consumers are moving towards options based on plants. A third of the world population actively chooses to eat less animal products.</li>
                            <li>6% of Americans dont consume any meat 4% are vegetarians 2% are vegan.</li>
                            <li>Flexitarians are the biggest group in the vegan world representing 50% of US young consumers. This consumers actively try to reduce intake of animal based products but are not radicals.</li>
                            <li>Almost 40% of North American consumers are trying to include more vegan ingredients   to their diets.</li>
                            <li>Dairy   free claim is the most common claim present in 5% of total new product launches in 2020.</li>
                            <li>Oat Milk products sales rose 1.946% over the last two years.</li>
                            <li>Oat milk chocolates was the most demanded couverture in 2020 to desing plant-based snacks. Given that milk chocolate still accounts for 80% of US chocolate confectionery, oat is a perfect replacement for milk.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Value chain/ Traceability: Consumers are interested in Knowing where and how their food is grown, harvested and transformed to avoid social, environmental and ethical compromises.</p>
                    <div class="section-info">
                        <ul>
                            <li>Deforestation, child labor and rising inequality are 3 of the most pressing issues in supply-chains like cocoa. Consciousness consumers are demanding brands to report on these issues and actively solve them.</li>
                            <li>Chocolate launches with “single-origin” claims grew at a 25% CAGR from 2015 to 2019.</li>
                            <li>60% of global consumer are making more environmentally friendly and sustainable purchases.</li>
                            <li>More than a third of consumers has shown increasing interest in knowing where the product they consume comes from, this associated with its responsible sourcing and impact had on the growing region.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Purpose driven brands: Brand that are actively part of the solution not the problem. With strong social and enviromental objectives and impact metrics.</p>
                    <div class="section-info">
                        <ul>
                            <li>A large group brands are avoiding traditional certifications and pushing towards much more abitiouse and transparent sustanability models.</li>
                            <li>Brands are solving individual problems in there value chains by connecting and supporting directly the diferent links in their value chain.</li>
                            <li>Direct relationship and support for comunities involved in farming their ingredients.</li>
                            <li>Companies are finding ways to generate new incomes for cacao farmers aditional to paying them fair prices for their crops.</li>
                        </ul>
                    </div>
                </ng-container>


                <ng-container *ngIf="key == 'usa_experience'">
                    <p class="subtitle">Origins: Consumers are interested in knowing where the product they consume is from</p>
                    <div class="section-info">
                        <ul>
                            <li>Consumers are increasingly sophisticating their taste to understand the sensorial profiles that each region naturally offer. In cocoa, every region offers a unique taste profile thanks to the environmental conditions of each territory. Tasting single origin chocolates is a similar experience like tasting wines, premium coffee, cheese and exotic liquors.</li>
                            <li>The single origin experience becomes more relevant in dark chocolates.</li>
                            <li>39% of US consumers of fined flavored chocolate think the country or region of the product is important. For 43% of them South America is there preferred region.</li>
                            <li>Fine flavor chocolate accounts for only 8% of the wold production. This specialty varieties only grows in South American countries, being Colombia the main one.</li>
                        </ul>
                    </div>
                    <p class="subtitle">Seasonal: Consumers are buying chocolates more than ever for seasonal ocasions for gifting or for personal consumption. Perfect ocasion to try new flavors and formats.</p>
                    <div class="section-info">
                        <ul>
                            <li>Most popular seasons Christmas with 42% of US consumers buying them for the festive season, followed closely by Valentine's Day at 40% and Halloween at 37%.</li>
                            <li>60% of American consumers buy chocolate themed candy.</li>
                            <li>Seasonal chocolate has seen a historic growth in launch activity, rising to 42% of total launches in the 12 months to January 2021, up from 36% in the same period of 2016-17.</li>
                            <li>Seasonal launches are a great way to try new concepts, falvors and formats that might become regular product lines.</li>
                        </ul>
                    </div>
                </ng-container>


                <ng-container *ngIf="key == 'uk_healthy'">
                    <p class="section-title">Opportunities/Drivers:</p>
                    <p class="subtitle">Sugar Free/Alternative sugar: Due to health concerns consumers are trying to reduce or avoid traditional sugar when buying chocolate:</p>
                    <div class="section-info">
                        <p> The government created the anti-obesity strategy, which includes a ban on promotions of products that are High in Fat, Sugar and Salt (HFSS) with effect from April 2022</p>
                        <ul>
                            <li>4,9 Millionpeople have diabities in the UK-13,6 Million people have risk of type 2 diabities. and 850,000 people have udiagnosed type 2 diabities.</li>
                            <li>A healthy lifestyle could decrease the risk of type 2 diabities by 50%.</li>
                            <li>72% of consumers ranked personal health as its top priority, and 70% consider health of their family & friends as a top priority as well.</li>
                            <li>Sugar-free sugar confectionery posted a growth of 19% in 2020.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Snacks: Growing trend for chocolate consumption focused on small formats and healthy options for day to day consumption occassions for busy lifestyles.</p>
                    <div class="section-info">
                        <p>The most common motivations to snack are seek comfort, to reward themselves, save time, and improve mental, emotional, and physical health</p>
                        <ul>
                            <li>Chocolate pouches and bags is the most dynamic category in 2021, with retail current value growth of 3% to reach GBP1.1 billion.</li>
                            <li>The   Health and Wellness packaged food category grew up to a 14.26% Y-o-Y in 2020.</li>
                            <li>40% increase in snack consumption during the pandemic.</li>
                            <li>Chocolate pouches and bags is the most dynamic category in 2021, with retail current value growth of 3% to reach GBP1.1 billion.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Functional foods: Food that serve a specific purpose, add nutritional value and make consumers feel great.</p>
                    <div class="section-info">
                        <p>Protein:</p>
                        <ul>
                            <li>Foods with protein will increases the sensation of satiety of consumers for longer periods of time, helping reduce overall caloric intake.</li>
                            <li>Protein is closely coneccted to healthy and active lifestyles helping repair muscle tissue.</li>
                            <li>Functional Claims have grown 115% in the last year in new product launches 2020.</li>
                            <li>Both in bevergaes and snacking products protein is gaining terrain. xxx number of launches in the US in 2020 claimed high protein.</li>
                        </ul>

                        <p>High Antiox:</p>
                        <ul>
                            <li>73% of Uk consumers seek to add nutrients that support their immune system.</li>
                            <li>Antioxidant play different key roles in a healthy body including: heart heath and skin care among many other. Antioxidants protects us from radicals and illness.</li>
                            <li>Chocolate is naturally a great source of antioxidants. Cocoa Polyphenols are welll-known in the scientific community for their powerful antioxidant capacity.</li>
                            <li>Antioxidants in 100 gr of a dark chocolate bar = 6 glasses of wine = 3 green tea cups = potassium in 1 babana = calcium in 2 glass of milk = copper in 1 steak of meat.</li>
                        </ul>
                    </div>
                </ng-container>


                <ng-container *ngIf="key == 'uk_ethical'">
                    <p class="section-title">Opportunities/Drivers:</p>
                    <p class="subtitle">Plant Based, veganism and flexitarianism as a new to help the planet and introduce healthier eating habits. Consumers want products that take advantage of fruits vegetables, seed and nuts to replace traditional animal products.</p>
                    <div class="section-info">
                        <ul>
                            <li>In Europe, especially in the UK, ethics (46%) led alongside health (36%) as   motivators to consume plant-based foods, opposite to health leading the rest   of the world. - Euromonitor, 2020.</li>
                            <li>GBP 1.2bn Potential flexitarian + vegan chocolate market in the UK 2021.</li>
                            <li>GBP 229 MM Potential vegan chocolate market in the UK 2021.</li>
                            <li>Londoners are embracing plant-based food more than any other region (46%), followed by those in the west midlands (34%) and the south west (32%).</li>
                            <li>The share of plant-based chocolate launches has grown 24.7% in the last 3 years. Mintel, 2021.</li>
                            <li>Oat milk in track to become the number one ingredient in plant based products by 2022 sales have surged 70% in 2020.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Value chain/ Traceability: Consumers are interested in Knowing where and how their food is grown, harvested and transformed to avoid social, environmental and ethical compromises.</p>
                    <div class="section-info">
                        <ul>
                            <li>35% of UK consumers say they mostly buy chocolate with ethical certifications. Mintel, 2021.</li>
                            <li>As for chocolate confectionery in the UK, GMO Free is among the fastest growing claims, registering a growth in share of 630.2% in the last year.</li>
                            <li>62% of consumers claim to increase their focus on climate change and how their actions impact the planet. – August 2020. How is COVID-19 changing the retail consumer? Accenture.</li>
                            <li>75% of Uk shoppers want supermarkets to only stock food from sustainable and ethical sources.</li>
                        </ul>
                    </div>

                    <p class="subtitle">Purpose driven brands: Brand that are actively part of the solution not the problem. With strong social and enviromental objectives and impact metrics.</p>
                    <div class="section-info">
                        <ul>
                            <li>A large group brands are avoiding traditional certifications and pushing towards much more abitiouse and transparent sustanability models.</li>
                            <li>Brands are solving individual problems in there value chains by connecting and supporting directly the diferent links in their value chain.</li>
                            <li>Direct relationship and support for comunities involved in farming their ingredients.</li>
                            <li>Companies are finding ways to generate new incomes for cacao farmers aditional to paying them fair prices for their crops.</li>
                        </ul>
                    </div>
                </ng-container>


                <ng-container *ngIf="key == 'uk_experience'">
                    <p class="section-title">Opportunities/Drivers:</p>
                    <p class="subtitle">Origins: Explore the world through chocolate</p>
                    <div class="section-info">
                        <p>GBP 6,806 MM Potential chocolate confectionery market</p>
                        <ul>
                            <li>26% of global consumers now look for the country of origin on food and drink labels. – Euromonitor, 2020.</li>
                            <li>6% of total chocolate launches in Europe have a claim of origin/ Region. - Mintel, 2021.</li>
                        </ul>
                    </div>
                    <p class="subtitle">Flavors and textures</p>
                    <div class="section-info">
                        <p>Indulgence   is the top reason to eat chocolate for consumers in the major European   markets, and 78% of UK consumers prioritize taste when choosing between   chocolate products. – Mintel, 2021</p>
                        <p>Most-used flavors 2020 (% share)</p>
                        <ul>
                            <li>Orange/Sweet Orange – 14.3%</li>
                            <li>Caramel/Caramelised – 6.7%</li>
                            <li>Salted Caramel – 5.7%</li>
                            <li>Hazelnut – 5.7%</li>
                            <li>Raspberry – 4.8%</li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="right-container cont-1">
            <div class="ingredients-container">
                <p class="ingredients-title">Ingredients</p>
                <div class="ingredients-images">
                    <div class="ingredient-container row" *ngFor="let ingredient of trendIngredients; let i = index">
                        <div class="img-container-ingredient col-4">
                            <img [src]="ingredient.img" alt="img-ingrediente" class="ingredient-img">
                        </div>
                        <div class="ingredient-name col-8">{{ingredient.nombre || ingredient.texto}}</div>

                    </div>
                </div>
            </div>
          <div class="benchmarks-container">
            <p class="ingredients-title">Benchmarks</p>
            <div class="benchmarks-images row">
              <div class="img-container-benchmark col-6" *ngFor="let item of benchmarks">
                <img [src]="item" alt="img-benchmark" class="benchmark-img col-sm-6">
              </div>
            </div>
          </div>
        </div>
      <div class="benchmark-mobile cont-3">
        <p class="ingredients-title">Benchmarks</p>
        <div class="benchmarks-images-mobile row">
          <div class="col-6" *ngFor="let item of benchmarks">

            <img [src]="item" alt="img-benchmark-mobile" class="benchmark-img-mobile col-sm-6">
          </div>
        </div>
      </div>
    </div>
</div>
