import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of the response after trying to post a Product object.
   * @param product The product object needed to post.
   * @returns A promise with the response of the Product post attempt.
   */
  postProduct(product: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('producto_consolidado', product).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al crear el producto ${error}`);
        }
      );
    });
  }
}
