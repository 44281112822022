import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SalesforceServicesService } from '../../../core/services/salesforce/salesforce-services.service';
import { FormBuilder } from '@angular/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import ls from 'localstorage-slim';
@Component({
  selector: 'app-salesforce-module',
  templateUrl: './salesforce-module.component.html',
  styleUrls: ['./salesforce-module.component.css'],
})
export class SalesforceModuleComponent implements OnInit {
  typeConsult: any;
  clientSelected: any;

  listOpportunity: any;
  salesForceClients: any;
  opportunitySelected: any;

  listLeads: any;
  filteredLeads: any[] = [];
  leads: any[] = [];

  newLead: boolean = false;

  formLead: FormGroup;
  formContact: FormGroup;
  newClient: boolean = false;
  formAccount: FormGroup;
  newOpportunity = false;

  constructor(private salesforceService: SalesforceServicesService, private formBuilder: FormBuilder) {
    this.formLead = this.formBuilder.group({
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      Company: new FormControl('', Validators.required),
    });
    this.formContact = this.formBuilder.group({
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      Email: new FormControl('', Validators.required),
    });
    this.formAccount = this.formBuilder.group({
      Name: new FormControl('', Validators.required),
      Phone: new FormControl('', Validators.required),
      Industry: new FormControl('Other', Validators.required),
      CLK_ClientSegment__c: new FormControl('Other', Validators.required),
      website: new FormControl('lukercreator.com', Validators.required),
    });
  }

  async ngOnInit() {
    await this.getAllClients();
    this.getAllLeads();
  }

  /**
   * Get all clients from salesforce by query SOQL
   */
  async getAllClients() {
    let responseClients: any = await this.salesforceService.getAllAccountsQuery();
    this.salesForceClients = responseClients.records;
  }

  /**
   * Get all opportunities from salesforce by query SOQL
   * the query is filtered by the client selected
   */
  getOpportunity() {
    this.salesforceService.getAllOpportunitiesQuery(this.clientSelected).then((response: any) => {
      this.listOpportunity = response.records;
    });
  }

  getAllLeads() {
    this.salesforceService.getAllLeadsQuery().then((response: any) => {
      this.leads = response.records;
    });
  }

  filteredItem(event: any) {
    let filtered: any[] = [];
    let query = event.query;

    for (const element of this.leads) {
      let lead = element;
      if (lead.Company.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(lead);
      }
    }
    this.filteredLeads = filtered;
  }

  setFormLead() {
    this.newLead = !this.newLead;
  }

  setFormAccount() {
    this.newClient = !this.newClient;
  }

  setNewOpportunity() {
    this.newOpportunity = !this.newOpportunity;
    this.opportunitySelected = '';
    this.setDataOpportunity();
  }

  validateFormLead() {
    if (this.typeConsult == 'lead') {
      this.setDataLead();
    } else {
      this.setDataOpportunity();
    }
  }

  setDataLead() {
    if (this.formLead.valid || this.formContact.valid || this.formAccount.valid) {
      let lead = {
        idLeadSelected: this.listLeads?.Id || '',
        contactData: {
          FirstName: this.formContact.value.FirstName,
          LastName: this.formContact.value.LastName,
          Email: this.formContact.value.Email,
        },
        newAccount: this.newClient,
        accountData: {
          Name: this.formAccount.value.Name,
          Phone: this.formAccount.value.Phone,
          Industry: this.formAccount.value.Industry,
          CLK_ClientSegment__c: this.formAccount.value.CLK_ClientSegment__c,
          Website: this.formAccount.value.website,
        },
        typeConsult: this.typeConsult,
        clientSelected: this.clientSelected,
        newLead: this.newLead,
        leadData: {
          FirstName: this.formLead.value.FirstName,
          LastName: this.formLead.value.LastName,
          Company: this.formLead.value.Company,
        },
      };
      ls.set('lead', JSON.stringify(lead), { encrypt: true });
    }
  }

  setDataOpportunity() {
    let opportunity = {
      idOpportunitySelected: this.opportunitySelected,
      typeConsult: this.typeConsult,
      clientSelected: this.clientSelected,
    };
    ls.set('lead', JSON.stringify(opportunity), { encrypt: true });
  }
}
