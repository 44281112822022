import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectComexService } from '../../../core/services/project-comex/project-comex.service';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.css'],
})
export class TransportComponent implements OnInit {
  /** This attribute is used to store the name of the current step. */
  @Input() stepName = '';

  /**  Output activate completed step */
  @Output() completed = new EventEmitter<any>();

  typeIncoterm: any;
  incoterm: any;

  countrie: any;
  countrieSelected: any;

  portSource: any;
  portSourceSelected: any;

  typeContainer: any;
  typeContainerSelected: any;

  portDestination: any;
  portDestinationSelected: any;
  portDestinationFilter: any;

  numberOfContainers: any;

  filterCountryDestination: any;

  portSources: string[] = ['Cartagena', 'Buenaventura', 'Santa Marta'];

  typeContainers: string[] = ['20" Dry', '40" Dry', 'Carga suelta', '40" Reefer', '40" Dry HC', '20" Reefer'];

  registers: any;
  countriesRegisters: any;
  countriesRegistersFilter: any;

  prueba: any;

  constructor(private projectComexService: ProjectComexService) {}

  ngOnInit(): void {
    this.getRegisterPorts();
    this.completed.emit({ flow: 'launch', step: 4, completed: true });
  }

  selectedOptions() {
    this.incoterm = this.typeIncoterm;
    this.portSource = this.portSourceSelected;
    this.typeContainer = this.typeContainerSelected;
    this.countrie = this.countrieSelected;
    this.portDestination = this.portDestinationSelected;
    this.filterPorts();
    let filteredPrice = this.filterCountryDestination[0]?.fleteUsd;
    let portDestination = this.filterCountryDestination[0]?.puertoDestino;
    if (this.incoterm === 'FOB') {
      filteredPrice = 0;
      portDestination = '';
      this.countrie = '';
    }
    let objectTransporting = {
      incoterm: this.incoterm,
      country: this.countrie,
      portSource: this.portSource,
      typeContainer: this.typeContainer,
      portDestination: portDestination,
      numberOfContainers: this.numberOfContainers,
      priceUsd: filteredPrice,
    };
    localStorage.setItem('objectTransporting', JSON.stringify(objectTransporting));
  }

  getRegisterPorts() {
    this.projectComexService.getRegisterTransporting().then((response) => {
      this.registers = response;
      this.countriesRegisters = this.registers.map((item: any) => item.paisDestino);
      this.countriesRegistersFilter = this.countriesRegisters.filter((item: any, index: any) => {
        return this.countriesRegisters.indexOf(item) === index;
      });
    });
  }

  filterPorts() {
    this.filterCountryDestination = this.registers.filter(
      (item: any) => item.paisDestino == this.countrie && item.tipoContenedor == this.typeContainer
    );
    if (this.filterCountryDestination.length > 0) {
      this.portDestinationFilter = this.filterCountryDestination[0].puertoDestino;
    } else {
      this.portDestinationFilter = 'N/A';
    }
  }
}
