import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ProjectService } from '../../core/services/project/project.service';
import { Router } from '@angular/router';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-compare-products',
  templateUrl: './compare-products.component.html',
  styleUrls: ['./compare-products.component.css'],
})
export class CompareProductsComponent extends SimpleModalComponent<any, any> implements OnInit {
  projectsA: any;

  projectsB: any;

  projectA: any;
  projectB: any;

  filteredProjectsA: any[] = [];
  filteredProjectsB: any[] = [];

  errorB!: boolean;
  errorA!: boolean;

  constructor(private projectService: ProjectService, private router: Router) {
    super();
  }

  async ngOnInit() {
    await this.getUserProjects();
  }

  async getUserProjects() {
    const user = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    this.projectsA = await this.projectService.getProjectsCompareByUser(user.id);
  }

  selectProjectA() {
    this.projectsB = this.projectsA.filter((project: any) => project._id !== this.projectA._id);
  }

  confirm() {
    this.validations();
    if (this.projectA._id && this.projectB._id) {
      this.router.navigateByUrl(`/compare-interno/${this.projectA._id}/${this.projectB._id}`);
      this.close();
    }
  }

  validations() {
    if (!this.projectA || !this.projectA._id) {
      this.errorA = true;
    }
    if (!this.projectB || !this.projectB._id) {
      this.errorB = true;
    }
  }

  filterProject(event: any) {
    let filtered: any[] = [];
    let query = event.query;
    for (const element of this.projectsA) {
      let project = element;
      if (project.nombre.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(project);
      }
    }
    this.filteredProjectsA = filtered;
    this.errorA = false;
  }

  filterProjectB(event: any) {
    let filtered: any[] = [];
    let query = event.query;
    for (const element of this.projectsB) {
      let project = element;
      if (project.nombre.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(project);
      }
    }
    this.filteredProjectsB = filtered;
    this.errorB = false;
  }
}
