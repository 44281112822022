import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.css'],
})
export class SendEmailModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  /** This attribute stores the email of the Luker's sustainability representative. */
  copy: string = 'vortiz@lukerchocolate.com';

  /** This attribute stores the email of the current user. */
  email = '';

  /** A form control to validate the name field is correct. */
  name = new FormControl('', [Validators.required]);

  /** Flag to validate if the name field is empty. */
  emptyName = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setEmail();
  }

  /**
   * This function saves the email adress and closes the modal.
   */
  confirm() {
    this.validateName();
    if (!this.emptyName) {
      this.result = { copy: this.copy, email: this.email, name: this.name.value };
      this.close();
    } else {
      this.result = false;
    }
  }

  /**
   * This function validates if the name field is empty.
   */
  validateName() {
    if (this.name.invalid) {
      this.emptyName = true;
    } else {
      this.emptyName = false;
    }
  }

  /**
   * This function sets the email of the current user.
   */
  setEmail() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.email = user.email;
  }
}
