<div class="modal-content modal-container">
    <div class="">
      <h4>Your Project</h4>
    </div>
    <div class="modal-description">
      <p>Enter the email address where you would like us to send the information</p>
    </div>

    <div class="details-container">
      <div class="option-container">
          <p class="option-name">Name</p>
          <input type="text" name="name" [formControl]="name" [class.red-border]="emptyName">
          <p class="warning" *ngIf="emptyName">Write a valid name</p>
      </div>
    </div>

    <div class="btn-container">
      <button type="button" class="cancel-btn" (click)="close()">
        Cancel
      </button>
      <button type="button" class="submit-btn" (click)="confirm()">
        Submit
      </button>
    </div>
</div>
