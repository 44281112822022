import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../core/services/auth/auth.service';
import { Router } from '@angular/router';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-modal-update-password',
  templateUrl: './modal-update-password.component.html',
  styleUrls: ['./modal-update-password.component.css'],
})
export class ModalUpdatePasswordComponent extends SimpleModalComponent<any, any> implements OnInit {
  changePasswordForm: any;
  userLogged: any;
  successMessage: any;
  success = true;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.userLogged = JSON.parse(ls.get('user', { decrypt: true }) || '{}');
    this.passwordForm();
  }

  private passwordForm() {
    this.changePasswordForm = this.formBuilder.group({
      email: [this.userLogged.email, Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  fieldIsInvalidPassword(field: string) {
    return this.changePasswordForm.get(field)?.touched && this.changePasswordForm.get(field)?.invalid;
  }

  get confirmPasswordInvalid(): boolean {
    if (this.changePasswordForm.get('password')?.touched && this.changePasswordForm.get('confirmPassword')?.touched) {
      const password1 = this.changePasswordForm.get('password')?.value;
      const password2 = this.changePasswordForm.get('confirmPassword')?.value;
      return password1 !== password2;
    }
    return false;
  }

  closeModal(){
    this.success = false
  }

  updatePassword() {
    if (this.changePasswordForm.valid && this.success) {
      this.authService.updatePassword(this.changePasswordForm.value).then((res: any) => {
        this.success = true;
        this.successMessage = [
          {
            severity: 'success',
            detail: `Your password has been updated.`,
          },
        ];
        setTimeout(() => {
          this.authService.endActiveSession();
          this.router.navigateByUrl('login');
          this.close();
        }, 1000);
      });
    } else {
      this.close();
    }
  }
}
