import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the news.
   * @returns A promise of all the news .
   */
  getAllNews() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`noticia`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getAllNotifications() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`notificaciones_alertas`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
