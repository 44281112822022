/* eslint-disable prefer-spread */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ChocolateService } from '../../../../core/services/chocolate/chocolate.service';

import * as _ from 'lodash';
import { TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { WebSocketService } from '../../../../core/services/web-socket/web-socket.service';
import { BespokeFormulaService } from '../../../../core/services/bespoke-formula/bespoke-formula.service';
import ls from 'localstorage-slim';
import { FilterStepService } from '../../../../core/services/filter-step/filter-step.service';

@Component({
  selector: 'app-chocolate-pick-step',
  templateUrl: './chocolate-pick-step.component.html',
  styleUrls: ['./chocolate-pick-step.component.css'],
})
export class ChocolatePickStepComponent implements OnInit {
  /** This attribute is used to store the name of the current step. */
  @Input() stepName = '';

  /** This attribute is used to store the description of the current step. */
  @Input() stepDescription = '';

  /** This attribute is used to store the application selected in the basics selection. */
  @Input() application = '';

  /** This attribute is used to store the recipe selected in the basics selection. */
  @Input() portfolio = '';

  @Input() chocolateType = '';

  /** This attribute is used to store the chocolate type selected in the basics selection. */
  @Input() type = '';

  @Input() ingredientSpecs = [];

  /** This array contains all the chocolate options. */
  chocolateOptions: any[] = [];

  /** This array contains the chocolate options after filtering by recipe and/or type. */
  filteredChocolateOptions: any[] = [];

  /** This array contains the chocolates which cacao percentage matches the cacao percentage slider range. */
  displayedChocolateOptions: any[] = [];

  /** The chocolate picked by the user. */
  pickedChocolate: any = {};

  /** This attribute is used to store the current flow the user is in. */
  currentFlow = '';

  /** Attribute binded to the cacao percentage slider value. */
  cacaoPercentage = 0;

  /** An emitter to let the selection step component know when the step is completed. */
  @Output() completed = new EventEmitter<any>();

  /**List of dragees selected **/
  drageesList: any[] = [];

  /**Is option selected in step basic**/
  selectedOption: any;

  /**Type chocolate filtered of Dragees**/
  chocolateTypes: any[] = [];

  /**Type of Dragees selected**/
  typeChocolatePicked: any;

  /**LIst of percentege by dragees selected**/
  percentageChocolatePicked: any[] = [];

  /**Percentage of dragees selected**/
  percentageSelected: any;

  /**Materials 1 and 2 by dragees selected in bespoke**/
  materialsBespoke: any[] = [];

  dragees: any;

  shape: any;

  validationShape: any;

  constructor(
    private chocolateService: ChocolateService,
    private titlecase: TitleCasePipe,
    private router: Router,
    private webSocketService: WebSocketService,
    private bespokeFormulaService: BespokeFormulaService,
    private filterStepService: FilterStepService
  ) {}

  async ngOnInit() {
    this.setCurrentFlow();
    await this.validationTypeChocolate();
    this.listenSocket();
    this.shape = JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
    this.validationShape = this.shape.texto === 'Dragees' || this.selectedOption.application.name === 'Dragees';
    if (this.selectedOption.application.name === 'Dragees' || this.validationShape) {
      await this.getChocolateOptions();
      this.loadSelectedDragees();
    }
  }

  /**
   * This function sets the current flow the user is in.
   */
  setCurrentFlow() {
    let url = this.router.url;
    let split = url.split('/');
    if (split.includes('create-ingredient')) {
      this.currentFlow = 'ingredient';
    } else if (split.includes('create-product')) {
      this.currentFlow = 'product';
    }
  }

  /**
   * In case a recipe and type of chocolate have not been selected in the basic selection screen, this function gets all the chocolates available without filtering.
   */
  async getChocolates() {
    await this.chocolateService.getChocolateInfo();
    this.chocolateOptions = this.chocolateService.chocolateInfo;
    this.getChocolatesByForma();
    if (this.portfolio || this.type || this.application || this.ingredientSpecs) {
      this.filterChocolates();
    } else {
      this.filteredChocolateOptions = this.chocolateOptions;
    }
    this.listenCacaoPercentage();
    this.setSelectedAsFalse();
  }

  /**
   * This functions filter the chocolates depending on the portfolio and/or chocolate type.
   */
  filterChocolates() {
    if (this.portfolio != '' && this.type != '') {
      this.filterByPortfolioAndType(this.portfolio, this.type);
    } else if (this.portfolio != '' && this.type == '') {
      this.filterByPortfolio(this.portfolio);
    } else if (this.portfolio == '' && this.type != '') {
      this.filterByType(this.type);
    }
    if (this.application) {
      this.filterByApplication();
    }
    if (this.ingredientSpecs) {
      this.filterBySpecs();
    }
  }

  getChocolatesByForma() {
    this.filterStepService.getFilterSteps().then((res) => {
      let shape = JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
      for (let item of res) {
        if (item.formaChocolate === shape.formaId && item.comentario === 'No mostrar chocolates') {
          let chocolates = item.restriccion.split(',');
          this.filteredChocolateOptions = this.filteredChocolateOptions.filter((chocolate) => {
            return !chocolates.includes(chocolate.chocolateId);
          });
          this.minimumPercentage();
          this.listenCacaoPercentage();
        }
      }
    });
  }

  /**
   * This function sets all chocolate options as false, to avoid multiple selections when re-filtering the options by percentage, recipe or type.
   */
  setSelectedAsFalse() {
    for (const element of this.displayedChocolateOptions) {
      element.selected = false;
    }
    for (const element of this.drageesList) {
      element.selected = false;
    }
  }

  /**
   * This function handles the changes of the chocolate option selected by the user.
   * @param pos The position of the selected item.
   */
  handleClickSelection(pos: number) {
    const bespokeDragees: any = {};
    this.setSelectedAsFalse();
    this.displayedChocolateOptions[pos].selected = !this.displayedChocolateOptions[pos].selected;
    this.pickedChocolate = this.displayedChocolateOptions[pos];
    (
      this.pickedChocolate as any
    ).img = `./../../../../../assets/basics/${this.displayedChocolateOptions[pos].tipo}.PNG`;
    if (this.webSocketService.active) {
      if (this.webSocketService.getRole == 'admin') {
        this.webSocketService.emitToClient({
          type: 'select',
          step: 'chocolate',
          percentage: this.cacaoPercentage,
          chocId: this.pickedChocolate.chocolateId,
        });
      }
    }

    if (this.selectedOption.application.name === 'Dragees' || this.validationShape) {
      bespokeDragees.type = this.drageesList[pos].typeDragees;
      bespokeDragees.percentage = this.drageesList[pos].percentage;
      bespokeDragees.materials = this.drageesList[pos].materialsBespoke;
      localStorage.setItem('bespoke', JSON.stringify(bespokeDragees));
      localStorage.removeItem('chocolate');
      this.drageesList[pos].selected = !this.drageesList[pos].selected;
    }
    this.saveChocolateSelection();
  }

  /**
   * This function listens to the socket, waiting for changes in the selection of the commercial, and selects the chocolate in the screen of the client.
   */
  listenSocket() {
    this.webSocketService.listen().subscribe((data: any) => {
      if (this.webSocketService.getRole != 'admin' && data.object.percentage && data.object.step == 'chocolate') {
        this.cacaoPercentage = data.object.percentage;
        this.listenCacaoPercentage();
        this.displayedChocolateOptions.find((c) => c.chocolateId == data.object.chocId).selected = true;
        this.pickedChocolate = this.displayedChocolateOptions.find((c) => c.chocolateId == data.object.chocId);
        this.saveChocolateSelection();
      }
    });
  }

  /** Listener of the cacao percentage value changes when using the slider. */
  listenCacaoPercentage() {
    this.setSelectedAsFalse();
    this.displayedChocolateOptions = this.filteredChocolateOptions.filter(
      (chocolate) =>
        this.cacaoPercentage / 100 - 0.03 <= chocolate.porcentaje &&
        this.cacaoPercentage / 100 + 0.03 >= chocolate.porcentaje
    );
    this.displayedChocolateOptions = _.orderBy(this.displayedChocolateOptions, ['porcentaje'], ['desc']);
  }

  async validationTypeChocolate() {
    await this.getChocolates();
    this.minimumPercentage();
    this.selectedOption = JSON.parse(localStorage.getItem('selectedOptions') || '{}');
  }

  minimumPercentage() {
    let minimunPercentage = Math.min.apply(
      Math,
      this.filteredChocolateOptions.map((item) => item.porcentaje)
    );
    this.cacaoPercentage = minimunPercentage * 100;
    this.listenCacaoPercentage();
  }

  /**
   * This function filters the chocolates by the recipe and the type selected in basics selections.
   * @param portfolio The recipe selected in basics selections.
   * @param type The chocolate type selected in basics selections.
   */
  filterByPortfolioAndType(portfolio: any, type: any) {
    let filtered = this.chocolateOptions.filter((c: any) => {
      if (c.portafolio == portfolio && c.tipo == type) return c;
    });
    this.filteredChocolateOptions = _.uniq(filtered);
    this.listenCacaoPercentage();
  }

  /**
   * This function filters only by the recipe selected in basics selections.
   * @param portfolio The recipe selected in basics selections.
   */
  filterByPortfolio(portfolio: any) {
    let filtered = this.chocolateOptions.filter((c: any) => {
      if (c.portafolio == portfolio) return c;
    });
    this.filteredChocolateOptions = _.uniq(filtered);
    this.listenCacaoPercentage();
  }

  /**
   * This function filters only by the chocolate type selected in basics selections.
   * @param type The chocolate type selected in basics selections.
   */
  filterByType(type: any) {
    let filtered = this.chocolateOptions.filter((c: any) => {
      if (c.tipo == type) return c;
    });
    this.filteredChocolateOptions = _.uniq(filtered);
    this.listenCacaoPercentage();
  }

  /**
   * This function filters the chocolate by application.
   */
  filterByApplication() {
    let filtered = [];
    if (this.portfolio != '' || this.type != '') {
      filtered = this.filteredChocolateOptions.filter((c: any) => {
        if (c[this.application]) return c;
      });
    } else {
      filtered = this.chocolateOptions.filter((c: any) => {
        if (c[this.application]) return c;
      });
    }
    this.filteredChocolateOptions = _.uniq(filtered);
    this.listenCacaoPercentage();
  }

  /**
   * This function filters the chocolate by ingredient specs.
   */
  filterBySpecs() {
    let filtered = [];
    if (!this.portfolio && !this.type && !this.application) {
      filtered = this.chocolateOptions.filter((f: any) => {
        let decide = this.ingredientSpecs.every((s: any) => {
          let spec = f.specs?.split(',').includes(s.key);
          return spec;
        });
        return decide;
      });
    } else {
      filtered = this.filteredChocolateOptions.filter((f: any) => {
        let decide = this.ingredientSpecs.every((s: any) => {
          let spec = f.specs?.split(',').includes(s.key);
          return spec;
        });
        return decide;
      });
    }
    this.filteredChocolateOptions = _.uniq(filtered);
    this.listenCacaoPercentage();
  }

  /**
   * This function saves the chocolate object in the local storage.
   */
  saveChocolateSelection() {
    if (this.validationShape === false) {
      localStorage.setItem('chocolate', JSON.stringify(this.pickedChocolate));
      localStorage.removeItem('bespoke');
      this.completed.emit({ flow: this.currentFlow, step: 2, completed: true });
    } else {
      this.completed.emit({ flow: this.currentFlow, step: 2, completed: true });
    }
  }

  /**
   * Get the chocolate types from service bespoke
   * filter the chocolate type 'GRAGEA'
   * **/
  async getChocolateOptions() {
    let typeChocolates = await this.bespokeFormulaService.getBespokeTypes();
    this.chocolateTypes = typeChocolates.filter((item: any) => item.includes('GRAGEA'));
  }

  /**
   * onchange function to charge percentage by typeChocolatePicked
   * **/
  loadPercentage() {
    this.bespokeFormulaService.getPercentagesByType(this.typeChocolatePicked).then((percentages: any) => {
      this.percentageChocolatePicked = percentages;
    });
  }

  /**
   * Load service getNewMaterialsList by query param
   * the response is a list of materials and information about bespoke
   **/
  loadDrageesOptions() {
    const materials: any[] = [];
    let percentageFormat = this.percentageSelected + '';
    percentageFormat = percentageFormat.replace('.', ',');
    this.bespokeFormulaService
      .getNewMaterialsList({ llave: `${percentageFormat}${this.typeChocolatePicked}` }, materials)
      .then((res: any) => {
        this.materialsBespoke = res;
        this.drageesList.push({
          typeDragees: this.typeChocolatePicked,
          percentage: this.percentageSelected,
          selected: false,
          materialsBespoke: res,
        });
      });
  }

  loadSelectedDragees() {
    this.dragees = JSON.parse(localStorage.getItem('bespoke') || '{}');
    if (this.dragees.type) {
      this.typeChocolatePicked = this.dragees.type;
      this.percentageSelected = this.dragees.percentage;
      this.drageesList.push({
        typeDragees: this.typeChocolatePicked,
        percentage: this.percentageSelected,
        selected: true,
        materialsBespoke: this.dragees.materials,
      });
      this.loadPercentage();
    }
  }

  /**
   * This function redirects the user to the bespoke chocolate screen.
   */
  navigateToBespoke() {
    this.router.navigateByUrl(`create-${this.currentFlow}/create-bespoke`);
  }
}
