<div class="main-container">
<div class="form-container">
  <div class="form-card">
    <div class="d-flex" style="cursor: pointer;" routerLink="/login">
      <div>
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </div>
      <div>
        <p>Back to login</p>
      </div>
    </div>
    <div>
      <h3 class="forgot-title">Forgot password</h3>
    </div>
    <div>
      <p class="info-forgot">Enter your registered email to send you a temporary password so you can change it.</p>
    </div>
    <div>
      <form [formGroup]="forgotForm" (ngSubmit)="submitForm()">
        <div class="form-group">
          <label>Email Adress</label>
          <input type="email"
                 pInputText
                 formControlName="email"
                 [ngClass]="{'ng-invalid ng-dirty':fieldsInvalid('email')}">
          <p *ngIf="forgotForm.get('email')!.hasError('required') && forgotForm.get('email')!.touched" class="invalid-warning">Email is required.</p>
          <p *ngIf="forgotForm.get('email')!.hasError('email')" class="invalid-warning">Enter a valid email address.</p>
        </div>
        <div *ngIf="success">
          <p-messages [(value)]='successMessage' [closable]="true" [enableService]='false'></p-messages>
        </div>
        <div>
          <button type="submit" [disabled]="forgotForm.invalid">Send</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
