import { Component, OnInit } from '@angular/core';
import { InclusionsFlavorsService } from '../../../core/services/inclusions-flavors/inclusions-flavors.service';
import { BespokeFormulaService } from '../../../core/services/bespoke-formula/bespoke-formula.service';
import { Router } from '@angular/router';
import { ErithritolFactorService } from '../../../core/services/erithritol-factor/erithritol-factor.service';
import { TipsService } from '../../../core/services/tips/tips.service';
import ls from 'localstorage-slim';

@Component({
  selector: 'app-bespoke',
  templateUrl: './bespoke.component.html',
  styleUrls: ['./bespoke.component.css'],
})
export class BespokeComponent implements OnInit {
  /** This array stores the percentages by picked type. */
  bespokePercentages: number[] = [];

  /** This array stores the chocolate types available for bespoke formulas. */
  chocolateTypes: any[] = [];

  /** This attribute stores de selected chocolate type. */
  pickedType = '';

  /** This attribute stores the cacao percentage selected. */
  pickedPercentage = 0;

  /** The shape picked by the user in the first step. */
  pickedShape: any;

  /** The erithritol combination selected, if the sweetener is erithritol. */
  pickedErithritolCombination = '';

  /** This array contains the filtered milk available for a chocolate, if the chocolate type is milk. */
  filteredMilk: any[] = [];

  /** This array contains the filtered sweeteners available for a chocolate. */
  filteredSweeteners: any[] = [];

  /** This array contains the erithritol combinations available by chocolate type. */
  erithritolCombinations: any[] = [];

  /** This array stores the filtered tips for bespoke chocolates. */
  assistantTips: any[] = [];

  /** Auxiliary attribute to render the milk options, if the chocolate type is milk. */
  isMilkType = false;

  /** Auxiliary attribute to render the erithritol combinations, if the sweetener is Erithritol. */
  erithritolSelected = false;

  /** This attribute is used to store the current flow the user is in. */
  currentFlow = '';

  /** The sweetener selected by the user. */
  selectedSweetener: any = {};

  /** The milk selected by the user. */
  selectedMilk: any = {};

  constructor(
    private inclusionsFlavorsService: InclusionsFlavorsService,
    private bespokeFormulaService: BespokeFormulaService,
    private erithritolFactorService: ErithritolFactorService,
    private tipsService: TipsService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.setCurrentFlow();
    this.pickedShape = this.getPickedShape();
    await this.loadChocolateTypes();
    this.loadAssistantTips();
    this.validateMilkType();
    this.getAllByCategory('MILK');
    this.getAllByCategory('SUGAR');
  }

  /** Validates if the step is completed to allow navigation to next steps. */
  get enableNextStep() {
    if (this.pickedType!="" && this.pickedPercentage!=0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This function gets the shape object picked in the first step and stored in the local storage.
   * @returns The shape object that is currently in local storage.
   */
  getPickedShape() {
    return JSON.parse(ls.get('shape', { decrypt: true }) || '{}');
  }

  /**
   * This function loads all the chocolate types available for bespoke formulas.
   */
  async loadChocolateTypes() {
    this.chocolateTypes = await this.bespokeFormulaService.getBespokeTypes();
  }

  /**
   * This function loads the filtered tips for the bespoke chocolate alternative step.
   */
  async loadAssistantTips() {
    this.assistantTips = await this.tipsService.filter({ paso: 'BESPOKE CHOCOLATE' });
  }

  /**
   * This function loads all the cacao percentages per chocolate type.
   */
  loadPercentagesByType() {
    this.bespokeFormulaService.getPercentagesByType(this.pickedType).then((res) => {
      this.bespokePercentages = res as number[];
    });
    this.erithritolFactorService.filterCombinations({ tipo: this.pickedType }).then((res) => {
      this.erithritolCombinations = res as any[];
    });
    this.validateMilkType();
  }

  /**
   * This function sets the current flow the user is in.
   */
  setCurrentFlow() {
    const url = this.router.url;
    const split = url.split('/');
    if (split.includes('create-ingredient')) {
      this.currentFlow = 'ingredient';
    } else if (split.includes('create-product')) {
      this.currentFlow = 'product';
    }
  }

  /**
   * This function gets an ingredient by an specific category.
   * @param category The category of the ingredient.
   */
  async getAllByCategory(category: string) {
    if (category == 'SUGAR') {
      this.filteredSweeteners = await this.inclusionsFlavorsService.getByCategory('SUGAR');
      for (let i = 0; i < this.filteredSweeteners.length; i++) {
        this.filteredSweeteners[
          i
        ].img = `https://luker-recursos.s3.amazonaws.com/create/inclusions-and-flavors/sweeteners/${this.filteredSweeteners[i].codigoSap}.jpg`;
        this.filteredSweeteners[i].selected = false;
      }
    } else if (category == 'MILK') {
      this.filteredMilk = await this.inclusionsFlavorsService.getByCategory('MILK');
      for (let i = 0; i < this.filteredMilk.length; i++) {
        this.filteredMilk[
          i
        ].img = `https://luker-recursos.s3.amazonaws.com/create/inclusions-and-flavors/milk/${this.filteredMilk[i].codigoSap}.jpg`;
        this.filteredMilk[i].selected = false;
      }
    }
  }

  /**
   * This function handles the changes of the sweeteners and milk options selected by the user, depending on which category the user made the change from.
   * @param category The category where the item was selected or changed.
   * @param pos The position of the selected item.
   */
  handleClickSelection(category: string, pos: number) {
    if (category == 'sugar') {
      for (let i = 0; i < this.filteredSweeteners.length; i++) {
        this.filteredSweeteners[i].selected = false;
      }
      this.filteredSweeteners[pos].selected = !this.filteredSweeteners[pos].selected;
      if (this.filteredSweeteners[pos].codigoSap == '4100860') {
        this.erithritolSelected = true;
        this.erithritolFactorService.filterCombinations({ tipo: this.pickedType }).then((res) => {
          this.erithritolCombinations = res as any[];
        });
      } else {
        this.erithritolSelected = false;
        this.selectedSweetener = this.filteredSweeteners[pos];
      }
    } else if (category == 'milk') {
      for (let i = 0; i < this.filteredMilk.length; i++) {
        this.filteredMilk[i].selected = false;
      }
      this.filteredMilk[pos].selected = !this.filteredMilk[pos].selected;
      this.selectedMilk = this.filteredMilk[pos];
    }
  }

  /**
   * This function validates if the chocolate type selected is milk.
   */
  validateMilkType() {
    if (this.pickedType == 'MILK') {
      this.isMilkType = true;
    } else {
      this.isMilkType = false;
    }
  }

  /**
   * This function changes to the next step and saves the bespoke chocolate.
   */
  async changeToNextStep() {
    let list: any[] = [];
    const newMaterials = [];
    let percentageFormat = this.pickedPercentage + '';
    percentageFormat = percentageFormat.replace('.', ',');
    if (this.isMilkType && this.selectedMilk) {
      newMaterials.push(this.selectedMilk);
    }
    if (this.erithritolSelected) {
      list = await this.handleEritritholSelection(newMaterials);
      console.log('handleEritritholSelection', list);
    } else {
      newMaterials.push(this.selectedSweetener);
      list = await this.bespokeFormulaService.getNewMaterialsList(
        { llave: `${percentageFormat}${this.pickedType}` },
        newMaterials
      );
    }
    this.saveBespokeRecipe(list);
    this.router.navigateByUrl(`/create-${this.currentFlow}/steps/4`);
  }

  /**
   * This function returns the list of the a bespoke with erithritol sugar.
   * @param newMaterials The new ingredients selected for the bespoke.
   * @returns The materials list of the bespoke with its erithritol sugars.
   */
  async handleEritritholSelection(newMaterials: any[]) {
    let erithritolRecipe: any[] = [];
    const erithritolBespoke: any = {};
    let percentageFormat = this.pickedPercentage + '';
    percentageFormat = percentageFormat.replace('.', ',');
    erithritolBespoke.llave = `${this.pickedType}${this.pickedErithritolCombination}`;
    erithritolBespoke.newMaterials = newMaterials;
    erithritolRecipe = await this.erithritolFactorService.filter(erithritolBespoke, {
      llave: `${percentageFormat}${this.pickedType}`,
    });
    return erithritolRecipe;
  }

  /**
   * This function saves in the local storage the bespoke selected by the user.
   * @param bespokeRecipe The materials list of a bespoke chocolate.
   */
  saveBespokeRecipe(bespokeRecipe: any[]) {
    const bespokeChocolate: any = {};
    bespokeChocolate.type = this.pickedType;
    bespokeChocolate.percentage = this.pickedPercentage;
    bespokeChocolate.materials = bespokeRecipe;
    localStorage.setItem('bespoke', JSON.stringify(bespokeChocolate));
    localStorage.removeItem('chocolate');
  }
}
