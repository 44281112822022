import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { TipsService } from 'src/app/core/services/tips/tips.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PrivateLabelPortfolioService } from 'src/app/core/services/private-label-portfolio/private-label-portfolio.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmModalComponent } from 'src/app/public/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-saved-private-label-quotes',
  templateUrl: './saved-private-label-quotes.component.html',
  styleUrls: ['./saved-private-label-quotes.component.css'],
})
export class SavedPrivateLabelQuotesComponent implements OnInit {
  /** Output to know if the step is completed, i.e., a product is selected. */
  @Output() completed = new EventEmitter<any>();
  faMagnifyingGlass = faMagnifyingGlass;

  datepipe: DatePipe = new DatePipe('en-US')

  constructor(
    private tipsService: TipsService,
    private formBuilder: FormBuilder,
    private privateLabelPortfolioService: PrivateLabelPortfolioService,
    private router: Router,
    private sms: SimpleModalService,
  ) {}

  totalCost = 0
  dollarPrice= 0
  projects:any=[]
  parameters:any = {}

  columns: any[] = [
    { name: 'Actions' },
    { name: 'Date' },
    { name: 'Prepared by' },
    { name: 'Client' },
    { name: 'Comments' }
  ]

  tableForm:FormGroup= this.formBuilder.group({
    form_array: this.formBuilder.array([
    ])
  });

  tips:any=[]
  brief:any = {}
  user:any = {}


  ngOnInit(): void {
    this.setProjects();
    this.loadAssistantTips();
  }

   /**
   * Gets the form array.
   */
   public getFormArray(): FormArray {
    return this.tableForm.get('form_array') as FormArray;
  }

  /**
   * Gets the list of form arrays.
   *
   * @param row
   */
  public getFormArrayControls(row: any): string[] {
    return Object.keys(row.controls);
  }

  /**
   * Adds a new row with the given product index in the productlists array
   *
   * @param index
   */

  async addNewRow(index: number) {
    const formGroup = this.formBuilder.group(
      {
        date: this.datepipe.transform(this.projects[index].date, 'dd MMM, yyyy'),
        user: this.projects[index].user.nombre + " " + this.projects[index].user.apellido,
        client: this.projects[index].client,
        comments: this.projects[index].comments,
      }
    );
    this.getFormArray().push(formGroup);
  }

  /**
   * Removes the given index row from the table.
   * @param index
   */
  // async removeRow(index: number)  {

  //     const product = this.productsList[index]
  //     this.totalCost-=this.productsList[index].total_cost;
  //     this.getFormArray().controls.splice(index, 1);
  //     await this.productsList.splice(index, 1)
  //     if (this.productsList.length==0) {
  //       this.completed.emit({ flow: 'launch', step: 2, completed: false });
  //       this.completed.emit({ flow: 'launch', step: 1, completed: false });
  //     }
  //     localStorage.setItem('rtlProductsList', JSON.stringify(this.productsList));
  //     return;
  // }

  /**
   * Redirects to the project details.
   *
   * @param index
   */
  async goToProject(index: number)  {
    this.router.navigate(["ready-to-launch", "steps", "1"], { queryParams: {projectId:this.projects[index]._id}});
  }

  async setProjects(){
    this.projects = await this.privateLabelPortfolioService.getAllProjects()
    for (let index = 0; index < this.projects.length; index++) {
      this.addNewRow(index)
    }
  }

  loadAssistantTips() {
    let queryTips: any = {};
      queryTips.paso = "SHAPE AND SIZE"
      this.tips = [];
      this.tipsService.filter(queryTips).then((res:any) => {
        this.tips = res as any[];
      });
  }

  openModalDeleteProject(index:number) {
    this.sms.addModal(ConfirmModalComponent,
      {
        api: "",
        title: "Delete Quote",
        text: "Are you sure you want to delete this quote?."
      },
      {
        closeOnEscape: true,
        closeOnClickOutside: true
      }
    ).subscribe(async (res: any) => {
      if (res) {
        const result = await this.privateLabelPortfolioService.deleteProject(this.projects[index]._id)
        if (result!=null) {
          this.getFormArray().controls.splice(index, 1);
          await this.projects.splice(index, 1)
        }
      }
    });
  }
  
}