import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css'],
})
export class AssistantComponent implements OnInit {
  /** This array stores all the filtered tips of the current step. */
  @Input() assistantTips: any[] = [];

  /** This attribute stores the current step of the flow.*/
  @Input() currentStep = 0;

  constructor() {}

  ngOnInit(): void {}
}
