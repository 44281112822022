import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-impact-summary',
  templateUrl: './impact-summary.component.html',
  styleUrls: ['./impact-summary.component.css'],
})
export class ImpactSummaryComponent implements OnInit {
  /** Trigger to know when the go beyond logo is clicked. */
  goBeyondClicked = false;

  /** This attribute is used to store the name of the current step. */
  @Input() stepName = '';

  constructor() {}

  ngOnInit(): void {}

  /**
   * This function changes the value of the go beyond trigger.
   */
  handleClick() {
    this.goBeyondClicked = true;
  }
}
