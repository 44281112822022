
<div class="main-container pt-3" [ngClass]="{'pdf-mobile': buttonsBol}" id="contentToConvert">
  <div class="row">
    <div class="col-12 text-center">
      <p class="title">SRP Simulator</p>
    </div>
  </div>
  <div class="row" style="padding: 0px 15px;">
    <div class="col-md-4 mb-3 container__description-project">
      <div class="description-project-one ">
        Project ID
      </div>
    </div>
    <div class="col-md-8 mb-3 container__description-project">
      <div class="description-project text-right justify-content-end pr-4">
        {{this.idProject}}
      </div>
    </div>
    <div class="col-md-4 mb-3 container__description-project">
      <div class="description-project-one ">
        Product name
      </div>
    </div>
    <div class="col-md-8 mb-3 container__description-project" style="overflow-x: scroll;">
      <div class="description-project">
        {{productConsolidated?.chocolate.texto}} / {{combinationPackaging?.empaque1.material}} {{combinationPackaging?.empaque1.tipoEmpaque}}
      </div>
    </div>
    <div class="col-md-4 mb-3 container__description-project">
      <div class="description-project-one">
        Client Name
      </div>
    </div>
    <div class="col-md-8 mb-3 container__description-project">
      <div class="description-project">
        {{project?.cliente}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-end  ">
        <div (click)="exportExcel()" *ngIf="buttonsBol === false">
          <fa-icon [icon]="faFileExcel" class="icon-excel"></fa-icon>
        </div>
        <div style="margin-left: 20px" (click)="convertPdf()" *ngIf="buttonsBol === false">
          <fa-icon [icon]="faFilePdf" class="icon-pdf"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="specifications row mt-5">
    <div class="col-4">
      <div class="specification-srp d-flex justify-content-between">
        <p>Quantity Kg</p>
        <p>{{project?.peso}}</p>
      </div>
      <div class="specification-srp d-flex justify-content-between">
        <p>Quantity units</p>
        <p>{{project?.cantidadUnidades}}</p>
      </div>
      <div class="specification-srp d-flex justify-content-between">
        <p>Weight (gr)</p>
        <p>{{combinationPackaging?.weightPerUnit}}</p>
      </div>
      <div class="specification-srp d-flex justify-content-between">
        <p>Price / kg</p>
        <p>USD {{project?.costoKg / project?.precioDolar | number: '1.2-2'}}</p>
      </div>
      <div class="specification-srp d-flex justify-content-between">
        <p>Price / unit</p>
        <p>USD {{ priceUnit | number: '1.2-2'}}</p>
      </div>
    </div>
    <div class="col-3">
      <div class="specification-srp d-flex justify-content-between">
        <p>Palets</p>
        <p>{{productConsolidated?.palets || 0}}</p>
      </div>
      <div class="specification-srp d-flex justify-content-between">
        <p>Cont x 20</p>
        <p>{{ productConsolidated?.contPalets | number: '1.2-2' || 0 }}</p>
      </div>
    </div>
    <div class="col-5">
      <div class="specification-srp-editable d-flex justify-content-between">
        <p>Shipping</p>
        <div class="d-flex">
          <p>USD</p>
          <input type="number" class="input-edit"
                 [(ngModel)]="logistic.shipping"
                 (change)="onChangeLogistic()"
                >
        </div>

      </div>
      <div class="specification-srp-editable d-flex justify-content-between">
        <p>Customs</p>
        <div class="d-flex">
          <p>USD</p>
          <input type="number" class="input-edit" [(ngModel)]="logistic.customs"
                 (change)="onChangeLogistic()">
        </div>
      </div>
      <div class="specification-srp-editable d-flex justify-content-between">
        <p>Contingencies</p>
        <div class="d-flex">
          <p>USD</p>
          <input type="number" class="input-edit" [(ngModel)]="logistic.contingencies"
                 (change)="onChangeLogistic()">
        </div>

      </div>
      <div class="specification-srp-editable d-flex justify-content-between">
        <p>Other</p>
        <div class="d-flex">
          <p>USD</p>
          <input type="number" class="input-edit" [(ngModel)]="logistic.other"
                 (change)="onChangeLogistic()">
        </div>
      </div>
      <div class="specification-srp-final d-flex justify-content-between">
        <p>Total expenses</p>
        <p>USD $ {{logistic.total | number: '1.2-2' }}</p>
      </div>
    </div>
  </div>

  <div class="row mt-5" style="--bs-gutter-x:0">
    <div class="col container__table-srp">
      <table class="w-100 table-srp">
        <caption>Table SRP</caption>
        <thead>
          <tr>
            <th scope="col"></th>
            <th class="header-table" scope="col">Actor’s Name</th>
            <th class="header-table" scope="col">Cost USD</th>
            <th class="header-table" scope="col">Gross Margin %</th>
            <th class="header-table" scope="col">Logistic Expenses %</th>
            <th class="header-table" scope="col">Logistic Expenses/Un USD</th>
            <th class="header-table" scope="col">Taxes %</th>
            <th class="header-table" scope="col">Others %</th>
            <th class="header-table" scope="col">Final Cost USD</th>
            <th class="header-table" scope="col">Final Price USD</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of registers;let i = index">
          <td *ngIf="item.action === false || i === 0"></td>
          <td *ngIf="item.action">
            <fa-icon [icon]="faTrash" (click)="removeItem(i)"></fa-icon>
          </td>
          <td class="editable-field">
            <input type="text" [(ngModel)]="item.actorName" class="input-edit-table" (change)="updateRegister(i)">
          </td>
          <td class="field-table">{{item?.cost | number: '1.2-2'}}</td>
          <td class="editable-field">
            <input type="number"  [(ngModel)]="item.grossMargin" class="input-edit-table" (change)="updateRegister(i)">
          </td>

          <td class="field-table" *ngIf="item.default;else mostrar">USD $ {{registerDefault?.logisticExpenses }}</td>
          <ng-template #mostrar>
            <td class="editable-field" >
              <input type="number" [(ngModel)]="item.logisticExpenses" class="input-edit-table" (change)="updateRegister(i)">
            </td>
          </ng-template>
          <td class="field-table">{{item.logisticExpensesUnit | number: '1.2-2' }}</td>
          <td class="editable-field">
              <input type="number" [(ngModel)]="item.taxes" class="input-edit-table" (change)="updateRegister(i)">
          </td>
          <td class="editable-field">
            <input type="number" [(ngModel)]="item.others" class="input-edit-table" (change)="updateRegister(i)">
          </td>
          <td class="field-table">{{item.finalCost | number: '1.2-2'}}</td>
          <td class="field-table">{{item.finalPrice | number: '1.2-2'}}</td>
        </tr>
        </tbody>
        <button class="btn-add" (click)="addRegisterToArray()" *ngIf="buttonsBol === false">+ Add</button>
      </table>

    </div>
  </div>
  <div class="suggested-retail-price row mt-5">
    <div class="container__table-final-srp col-6">
      <table class="table-final-srp">
        <caption>Table final SRP</caption>
        <thead>
          <tr>
            <th scope="col" style="width: 145px;"></th>
              <th class="header-table-two" scope="col">Base</th>
            <th class="header-table-two" scope="col">Simulated</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="field-table">Price/ kg</td>
            <td class="field-table">USD {{ priceKgFixed | number: '1.2-2'}}</td>
            <td class="editable-field">
              <input type="number" class="input-edit-table" [ngModel]="project?.costoKg / project?.precioDolar | number: '1.2-2'" (change)="changeMarginEbitda($event)">
            </td>
          </tr>
          <tr>
            <td class="field-table">Gross Margin</td>
            <td class="field-table">{{marginAndEbitda.marginFixed | number: '1.0-0'}}%</td>
            <td class="field-table">{{costPl.operationsFinal[0]?.porcentajeMargenBruto | number: '1.0-0'}}%</td>
          </tr>
          <tr>
            <td class="field-table">EBITDA</td>
            <td class="field-table">{{marginAndEbitda.ebitdaFixed | number: '1.0-0'}}%</td>
            <td class="field-table">{{costPl.operationsFinal[0]?.porcentajeEbitda | number: '1.0-0'}}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-6 srp-container">
      <div class="row">
        <div class="col-6 d-flex justify-content-center">
          <div class="srp-content">
            SRP
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <div class="srp-content">
            USD $ {{finalPrice | number: '1.2-2'}}
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <p class="description-srp text-center">
          Suggested retail price
        </p>
      </div>
    </div>
    <div>
      <button class="btn-add w-25" (click)="postSrp()" *ngIf="buttonsBol === false">Save</button>
    </div>
  </div>
</div>
