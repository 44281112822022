import { Component, OnInit } from '@angular/core';

import { RegisterInfoService } from 'src/app/core/services/register-info/register-info.service';

import * as _ from 'lodash';

import * as XLSX from 'xlsx';

import { NgxSpinnerService } from 'ngx-spinner';

import { InclusionsFlavorsService } from '../../core/services/inclusions-flavors/inclusions-flavors.service';
import { PackagingService } from '../../core/services/packaging/packaging.service';

@Component({
  selector: 'app-consult-price',
  templateUrl: './consult-price.component.html',
  styleUrls: ['./consult-price.component.css'],
})
export class ConsultPriceComponent implements OnInit {
  /** The type of items the user will search. */
  searchType = 'ingredient';

  /** The SAP Code of the item the user will search. */
  sapCode = '';

  /** The name or description of the item the user will search. */
  description = '';

  /**Scales filter "Vigente" and filter date of validation */
  scalesFilter: any;

  public settings: any = {};

  /**Information filtered by typesOfRegister */
  registersInfo: any;

  /**Type register selected**/
  typeRegister: any;

  /**Types of registers */
  typesOfRegister: any = [
    '',
    'Bolsas',
    'Etiquetas',
    'Azucar',
    'Colorantes',
    'Derivados Lacteos',
    'Display y Plegadizas',
    'Tarros y Tapas',
    'Insumos para empaque',
    'Ingredientes Natural',
    'Lecitinas',
    'Inclusiones_agregado',
  ];

  columnsIngredient:any = [
    { field: 'material', header: 'Code' },
    { field: 'txt_material', header: 'Product Name' },
];

  columnsPackaging:any = [
    { field: 'codigoSap', header: 'Code' },
    { field: 'descripcion', header: 'Product Name' },
];

  openAccordion = false
  openAccordion2 = false
  openAccordionPrice = true
  viewMore = false

  /** Default values of the item displayed. */
  itemFound: any = {
    category: '',
    subcategory: '',
    technichalSpecs: '',
    leadtime: '',
    mechanical: '',
    purchaseMin: '',
    provider: '',
    minPrice: '',
    lastUpdate: '',
    status: '',
    incoTerm: '',
    packageType: '',
    material: '',
    sustainability: '',
    area: '',
    structureGrammage: '',
    ctdmin: '',
    units: '',
    currency: '',
    factor: '',
    fullPrice: '',
  };

  /** This object stores the filters of ingredient search. */
  ingredientFilters: any = {
    categories: [],
    // subcategories: [],
    providers: [],
  };

  /** This object stores the filters of packaging search. */
  packagingFilters: any = {
    types: [],
    classifications: [],
    materials: [],
  };

  /** This object stores the selected ingredient filters. */
  ingredientFilterSelections: any = {
    selectedCategory: '',
    // selectedSubcategory: '',
    selectedProvider: '',
  };

  /** This object stores the selected packaging filters. */
  packagingFilterSelections: any = {
    selectedType: '',
    selectedClassification: '',
    selectedMaterial: '',
  };

  /** This array contains the items that match the filters selected or written by the user. */
  searchResults: any[] = [];

  // Generate a copy of the search results to be able to filter them without affecting the original array.
  resultsForFilter: any[] = [];

  inputStatusDefault: boolean;

  /**This variable contain all registers info**/
  allRegisters: any;

  constructor(
    private registerInfoService: RegisterInfoService,
    private inclusionFlavorService: InclusionsFlavorsService,
    private packagingService: PackagingService,
    private spinner: NgxSpinnerService
  ) {
    this.inputStatusDefault = true;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loadIngredientFilters();
    this.configSmartTable();
    this.loadDefaultPrices();
    this.getAllRegisters();
  }

  filterByCategory(event: any) {
    console.log(event, "event")
    console.log(`Filtrando por: ${event}`);
  }

  /**
   * This function handles the changes between type of search and loads the default values to clean the result.
   * @param type The type of item the user will search.
   */
  handleSearchType(type: string) {
    this.loadDefaultValues();
    this.searchResults = [];
    this.sapCode = '';
    this.description = '';
    if (type == 'ingredient') {
      this.searchType = 'ingredient';
    } else if (type == 'packaging') {
      this.searchType = 'packaging';
      this.loadPackagingFilters();
    } else if (type == 'priceChanges') {
      this.searchType = 'priceChanges';
    } else if (type == 'transport') {
      this.searchType = 'transport';
    } else if (type == 'priceTrends') {
      this.searchType = 'priceTrends';
    } else if (type === 'rawMaterialOne') {
      this.searchType = 'rawMaterialOne';
    }
  }

  /**
   * This function loads the selected ingredient values.
   * @param index The selected ingredient index.
   */
  loadIngredientInfo(index: number) {
    this.itemFound.category = this.searchResults[index].categoria || 'NA';
    this.itemFound.subcategory = this.searchResults[index].subcategoria || 'NA';
    this.itemFound.technichalSpecs = this.searchResults[index].ftdaruma || 'NA';
  }

  /**
   * This function loads the selected package values.
   * @param index The selected package index.
   */
  loadPackageInfo(index: number) {
    this.itemFound.packageType = this.searchResults[index].tipoEmpaque || 'NA';
    this.itemFound.material = this.searchResults[index].material || 'NA';
    this.itemFound.technichalSpecs = this.searchResults[index].fichaTecnica || 'NA';
    this.itemFound.mechanical = this.searchResults[index].planoMecanico || 'NA';
    this.itemFound.category = this.searchResults[index].clasificacion || 'NA';
    this.itemFound.sustainability = this.searchResults[index].sostenibilidad || 'NA';
    this.itemFound.area = this.searchResults[index].area;
    this.itemFound.structureGrammage = this.searchResults[index].gramajeEstructura;
    this.itemFound.ctdmin =
      (this.searchResults[index].gramajeEstructura * this.searchResults[index].area) / 1000 || 'NA';
    this.itemFound.porcentaje = 0.9;
  }

  /**
   * This function loads the register info values of either an ingredient or a package.
   * @param sapCode The selected item index.
   */
  async loadRegisterInfo(sapCode: string) {
    let register;
    if (this.searchType == 'ingredient') {
      register = await this.registerInfoService.getByMaterial(sapCode);
    } else {
      register = await this.registerInfoService.getAllByMaterialPackaging(sapCode);
    }
    if (register) {
      this.itemFound.provider = register[0]?.nombre_proveedor || 'NA';
      this.itemFound.lastUpdate = register[0]?.valido_de || 'NA';
      this.itemFound.status = register[0]?.status_reg_info || 'Vencido';
      this.itemFound.leadtime = register[0]?.dias_entrega_reg_info || 'NA';
      this.itemFound.purchaseMin = register[0]?.cantidad_min_reg_info || 'NA';
      this.itemFound.minPrice = '' + parseFloat(register[0]?.precio_neto) / parseFloat(register[0]?.por) || 'NA';
      this.itemFound.incoTerm = register[0]?.inco1 + ' ' + register[0]?.inco2 || 'NA';
      this.itemFound.units = register[0]?.ump;
      this.itemFound.currency = register[0]?.moneda;
      this.itemFound.factor = register[0]?.factor;
      this.itemFound.category = register[0]?.categoria || 'NA';
      this.itemFound.subcategory = register[0]?.subcategoria || 'NA';
      this.itemFound.technichalSpecs = register[0]?.ftdaruma || 'NA';
      this.itemFound.toolbox = register[0]?.toolbox || false;
      this.itemFound.fullPrice =
        (parseFloat(register[0]?.precio_neto) / parseFloat(register[0]?.por)) * (1 + (register[0]?.factor || 0));
      this.loadScales(sapCode, register[0]?.reg_info);
    }
  }

  /**
   * This function loads the scales of the registers info of an item by SAP Code.
   * @param sapCode The SAP Code of the item for which registers info will be displayed.
   * @param reg_info The reg_info is id to filter packaging by reg_info.
   */
  async loadScales(sapCode: string, reg_info: string) {
    let scales: any[] = [];
    let register;
    if (this.searchType == 'ingredient') {
      register = await this.registerInfoService.getByMaterial(sapCode);
    } else {
      register = await this.registerInfoService.getByRegInfo(reg_info);
    }
    let por;
    for (const element of register) {
      por = Number(element.por);
      scales.push({
        quantity: parseFloat(element.cantidad_escala) || element.cantidad_min_reg_info,
        price: (element.importe_escala || element.precio_neto) / por,
        units: element.ump,
        currency: element.moneda,
        status: element.status_reg_info,
        valido_de: element.valido_de,
      });
    }
    let recent: any = this.registerInfoService.getRecent(scales);
    scales = _.orderBy(scales, ['quantity'], ['asc']);
    this.scalesFilter = scales.filter((x: any) => x.valido_de == recent);
  }

  /**
   * This function handles the mutual exclusion of the search bars.
   * @param origin The type of filter the search will use.
   */
  handleSearchBars(origin: string) {
    if (origin == 'description') {
      this.sapCode = '';
    } else {
      this.description = '';
    }
  }

  /**
   * This function loads the selected item values depending on the search type.
   * @param index The index of the selected item.
   */
  loadResult(index: number) {
    this.loadDefaultValues();
    this.setSelectedAsFalse();
    this.searchResults[index].selected = true;
    if (this.searchType == 'ingredient') {
      this.loadRegisterInfo(this.searchResults[index].material);
      this.loadIngredientInfo(index);
    } else {
      this.loadRegisterInfo(this.searchResults[index].codigoSap);
      this.loadPackageInfo(index);
    }
  }

  /**
   * This function sets the default values of the result values.
   */
  loadDefaultValues() {
    this.itemFound = {
      category: '',
      subcategory: '',
      technichalSpecs: '',
      leadtime: '',
      mechanical: '',
      purchaseMin: '',
      provider: '',
      minPrice: '',
      lastUpdate: '',
      status: '',
      incoTerm: '',
      packageType: '',
      material: '',
      sustainability: '',
      area: '',
      structureGrammage: '',
      ctdmin: '',
      units: '',
      currency: '',
      fullPrice: '',
      toolbox: false,
    };
    this.scalesFilter = []
  }

  /**
   * This function loads all the values of the ingredient filters.
   */
  async loadIngredientFilters() {
    this.ingredientFilters.categories = await this.inclusionFlavorService.getIngredientFilters('categorias');
    this.ingredientFilters.providers = await this.inclusionFlavorService.getIngredientFilters('proveedores');
  }

  /**
   * This function loads all the subcategories for ingredients.
   */
  async loadSubcategories() {
    this.ingredientFilters.subcategories = await this.inclusionFlavorService.getSubcategories(
      this.ingredientFilterSelections.selectedCategory
    );
  }

  /**
   * This function loads all the values of the packaging filters.
   */
  async loadPackagingFilters() {
    this.packagingFilters.types = await this.packagingService.getPackagingFilters('tipos');
    this.packagingFilters.classifications = await this.packagingService.getPackagingFilters('clasificaciones');
    this.packagingFilters.materials = await this.packagingService.getPackagingFilters('materiales');
  }

  /**
   * This functions filters the search depending on the search type
   * @param filter The type of search where the filters were selected..
   */
  filterSearch() {
    if (this.searchType == 'ingredient') {
      this.filterIngredient();
    } else if (this.searchType == 'packaging') {
      this.filterPackaging();
    }
    this.inputStatusDefault = false;
  }

  /**Filter of all registers, category, subcategory and provider**/
  // TODO - Call rest service using the txt_gr_articulo in the backend
  filterAllRegisters() {

    if (this.ingredientFilterSelections.selectedCategory==null && this.ingredientFilterSelections.selectedProvider==null) {
      return this.filterIngredient();
    }
    this.searchResults = this.allRegisters.filter(
      (x: any) =>
        x.categoria === this.ingredientFilterSelections.selectedCategory ||
        // x.subcategoria === this.ingredientFilterSelections.selectedSubcategory ||
        x.proveedor === this.ingredientFilterSelections.selectedProvider
    );
  }

  /**Load all registers and hide of spinner loading**/
  getAllRegisters() {
    this.inclusionFlavorService.filter({}).then((res) => {
      this.allRegisters = res as any[];
      this.spinner.hide();
    });
  }

  /**
   * This function builds the query to filter an ingredient and calls the inclusions and flavors service.
   */
  filterIngredient() {
    const ingredientQuery: any = {};
    if (this.description) {
      ingredientQuery.txt_material = this.description.toUpperCase();
    }
    if (this.sapCode) {
      ingredientQuery.codigoSap = this.sapCode;
    }
    if (!Object.keys(ingredientQuery).length) {
      this.resultsForFilter = []
      this.searchResults = []
      return
    }
    this.inclusionFlavorService.filter(ingredientQuery).then((res) => {
      this.searchResults = res as any[];
      this.resultsForFilter = res as any[];
      console.log(this.resultsForFilter);
    });
  }

  /**
   * This function builds the query to filter a package and calls the packaging.
   */
  filterPackaging() {
    const packagingQuery: any = {};
    if (this.packagingFilterSelections.selectedType) {
      packagingQuery.tipoEmpaque = this.packagingFilterSelections.selectedType;
    }
    if (this.packagingFilterSelections.selectedClassification) {
      packagingQuery.clasificacion = this.packagingFilterSelections.selectedClassification;
    }
    if (this.packagingFilterSelections.selectedMaterial) {
      packagingQuery.material = this.packagingFilterSelections.selectedMaterial;
    }
    if (this.description) {
      packagingQuery.descripcion = this.description.toUpperCase();
    }
    if (this.sapCode) {
      packagingQuery.codigoSap = this.sapCode;
    }
    if (!Object.keys(packagingQuery).length) {
      this.searchResults=[]
      return
    }
    this.packagingService.filterConsult(packagingQuery).then((res) => {
      this.searchResults = res as any[];
    });
  }

  /**
   * This functions removes all the filters selected.
   * @param category The category which filters will be removed.
   */
  clearFilters(category: string) {
    if (category == 'ingredient') {
      this.ingredientFilterSelections.selectedCategory = null;
      this.ingredientFilterSelections.selectedSubcategory = null
      this.ingredientFilterSelections.selectedProvider = null;
    } else {
      this.packagingFilterSelections.selectedType = null;
      this.packagingFilterSelections.selectedClassification = null;
      this.packagingFilterSelections.selectedMaterial = null;
    }
    this.filterSearch();
  }

  /**
   * This function sets all the items as not selected.
   */
  setSelectedAsFalse() {
    for (let i = 0; i < this.searchResults.length; i++) {
      this.searchResults[i].selected = false;
    }
  }

  private configSmartTable(): void {
    this.settings = {
      actions: false,
      pager: {
        display: true,
        perPage: 5,
      },
      hideSubHeader: false,
      columns: {
        tipoMaterial: {
          title: 'Material type',
          editable: false,
          filter: true,
        },
        codigoSap: {
          title: 'SAP code',
          editable: false,
          filter: true,
        },
        nombre: {
          title: 'Material Name',
          editable: false,
          filter: true,
        },
        nombreProvedor: {
          title: 'Supplier',
          editable: false,
          filter: true,
        },
        precioAnterior: {
          title: 'Former price',
          editable: false,
          filter: true,
        },
        precioNuevo: {
          title: 'New price',
          editable: false,
          filter: true,
        },
        moneda: {
          title: 'Currency',
          editable: false,
          filter: true,
        },
        ump: {
          title: 'UMP',
          editable: false,
          filter: true,
        },
        cambioPorcentual: {
          title: 'Variation',
          editable: false,
          filter: true,
        },
        fechaFinCotizacion: {
          title: 'Last update',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Status',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**Function returns records according to the selected option  */
  loadChangePrices() {
    this.registerInfoService.getChangePrices(this.typeRegister).then((res) => {
      this.registersInfo = res;
    });
  }

  loadDefaultPrices() {
    this.registerInfoService.getDefaultChagePrices().then((res) => {
      this.registersInfo = res;
    });
  }

  exportExcel() {
    const worksheetRegisterInfo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.registersInfo);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheetRegisterInfo, 'Change prices');
    XLSX.writeFile(workbook, 'Change prices.xlsx');
  }

  openCloseAccordion(){
    this.openAccordion = !this.openAccordion
    if (!this.openAccordion) {
      this.viewMore = false;
    }
  }
  openCloseAccordion2(){
    this.openAccordion2 = !this.openAccordion2
  }
  openCloseAccordionPrice(){
    this.openAccordionPrice = !this.openAccordionPrice
  }

  viewMoreInfo(){
    this.viewMore = !this.viewMore
  }
}
