<div class="row container-transport">
  <div class=" col-12 col-lg-6 mobile-column-transport">
    <div class="card-transport p-3">
      <div class="row">
        <div class="col-12">
          <p class="text-center transport-title">Consult transport</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="title-first">Container</p>
        </div>
        <div class="col-12 col-lg-6">
          <label>Type of container</label>
          <div class="mt-1">
            <select [(ngModel)]="typeContainer" (change)="selectedOption()">
              <option *ngFor="let item of containers" value="{{item}}">{{item}}</option>
            </select>
          </div>
        </div>
        <div class="col-12  col-lg-6">
          <label>Number of containers</label>
          <div class="mt-1">
            <input type="number" min=0 placeholder="Number of containers" [(ngModel)]="numberContainers" (change)="selectedOption()">
            <p *ngIf="numberContainers <= 0" class="text-danger">Please enter a valid container number</p>
          </div>
        </div>
        <div class="col-12 mt-4">
          <p class="title-first">Destination</p>
        </div>
        <div class="col-12  col-lg-6">
          <label>Incoterm</label>
          <div class="mt-1">
            <select [(ngModel)]="incoterm" (change)="selectedOption()">
              <option value="FOB">FOB</option>
              <option value="CIF">CIF</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <label>Source port</label>
          <div class="mt-1">
            <select [(ngModel)]="sourcePortSelect" (change)="selectedOption()">
              <option *ngFor="let item of sourcePort" value="{{item}}">
                {{item}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-4" *ngIf="incoterm == 'CIF'">
          <label>Destination country</label>
          <div class="mt-1">
            <select [(ngModel)]="countrieSelected" (change)="selectedOption()">
              <option *ngFor="let item of countriesFilter" value="{{item}}">
                {{item}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-4" *ngIf="incoterm == 'CIF'">
          <label>Destination port</label>
          <div class="mt-1">
            <select [(ngModel)]="portDestinationSelected" [value]="portDestinationSelected" (change)="selectedOption()">
              <option>{{portDestination}}</option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-4 text-center">
          <button class="text-center btn-consult" [disabled]="numberContainers <= 0 || (incoterm === 'CIF' && portDestination === 'N/A')" (click)="consult()">Search</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 d-flex align-items-center" >
    <div class="card-transport p-3">
      <table *ngIf="showResults" class="table-information table-bordered">
        <caption style="display: none">Shipping expenses table</caption>
        <tr>
          <th class="td-yellow" scope="custom">Customs Expenses (Est) (COP)</th>
          <th scope="custom">${{pricesUsd[0]?.valorUsd * dollar | number: '1.0-0'}}</th>
        </tr>
        <tr>
          <td class="td-yellow" scope="freight">National Freight (COP)</td>
          <td scope="freight">${{transportData[0]?.valorCop || 0 | number: '1.0-0'}}</td>
        </tr>
        <tr>
          <td class="td-yellow" scope="freight">International Freight (USD)</td>
          <td scope="freight">${{freightUsd | number: '1.0-0'}}</td>
        </tr>
        <tr>
          <td class="td-yellow" scope="freight">International Freight (COP)</td>
          <td scope="freight">${{freightUsd * dollar  | number: '1.0-0'}}</td>
        </tr>
        <tr>
          <td class="td-yellow" scope="total">Total Operation (COP)</td>
          <td scope="total">${{totalOperation || 0 | number: '1.0-0'}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
