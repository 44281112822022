<body id="main_container">
  <div class="content">
    <p class="text">
      What's your mission today?
    </p>
    <div class="container__cards">
      <app-menu-card
        *ngFor="let data of cards_data"
        [title]="data.title"
        [detail]="data.detail"
        [imgPath]="data.imgPath"
        [redirectTo]="data.redirectTo"
      ></app-menu-card>
    </div>
  </div>
</body>