import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class PackageDesignService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the design objects that match the values of a specific object.
   * @param queryObject The object whose values will be filtered.
   * @returns A promise of all the designs that match the object's value.
   */
  filter(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`diseno/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
