import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../core/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  wrongPassword = false;
  userNotFound = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.redirectLogedUser();
  }

  /**
   * This function is used to know if a field from projectForm is invalid.
   * @param field The field you need to validate.
   * @returns The state of the field, i.e., valid or invalid.
   */
  fieldIsInvalid(field: string) {
    return this.loginForm.get(field)?.touched && this.loginForm.get(field)?.invalid;
  }

  /**
   * This function calls the auth service to authenticate the user trying to log in.
   * @param event A login attempt event.
   */
  public async login(event: Event) {
    event.preventDefault();
    this.wrongPassword = false;
    this.userNotFound = false;

    if (this.loginForm.valid) {
      try {
        let auth: any = await this.authService.authenticateUser({
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
        });
        if (auth.success) {
          this.authService.storeUserData(auth.token, auth.user);
          if (auth.user.tipo === 'FERIA') {
          // if (auth.user.tipo === 'Feria') {
            this.router.navigate(['/home-feria']);
          } else {
            // this.router.navigate(['/home']);
            window.location.reload();
          }
        } else {
          if (auth.msg == 'Usuario no encontrado') {
            this.userNotFound = true;
          } else if (auth.msg == 'Contraseña incorrecta') {
            this.wrongPassword = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  /**
   * In case the user is already logged, this function redirects to home.
   */
  redirectLogedUser() {
    const token = this.authService.getToken();
    if (token) {
      const typeUser = this.authService.getTypeUser();
      if (typeUser === 'Feria') {
        this.router.navigateByUrl('home-feria');
      } else {
        this.router.navigateByUrl('home');
      }
    }
  }
}
