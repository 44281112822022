import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { reject } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SalesforceServicesService {
  constructor(private mainService: MainService, private http: HttpClient) {}

  token: any;

  authSalesForce(body: any) {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };
    return this.http.post(environment.urlSalesForceAuth, body.toString(), options);
  }

  getTokenSalesForce() {
    return new Promise<any[]>((resolve, reject) => {
      let body = new URLSearchParams();
      body.set('grant_type', 'password');
      body.set('client_id', environment.clientIdSalesForce);
      body.set('client_secret', environment.clientSecretSalesForce);
      body.set('username', environment.usernameSalesForce);
      body.set('password', environment.SalesForcePwd);
      this.authSalesForce(body).subscribe(
        (response: any) => {
          this.token = response;
          resolve(response);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  async getClientsSalesForce() {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.get(`${environment.urlSalesForceServices}/Account`, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Post opportunity to salesforce with the data of the brief
   * @param body is object of the brief
   */
  async postClientSalesForce(body: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.post(`${environment.urlSalesForceServices}/Opportunity`, body, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Update to opportunity to salesforce with the data of the brief
   * @param id is id of the opportunity
   * @param body is object of the brief
   */
  async patchOpportunitySalesForce(id: any, body: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.patch(`${environment.urlSalesForceServices}/Opportunity/${id}`, body, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  async patchLeadSalesForce(id: any, body: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.patch(`${environment.urlSalesForceServices}/Lead/${id}`, body, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  async createContact(body: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.post(`${environment.urlSalesForceServices}/Contact`, body, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Get a list of records account that match the specified SOQL query
   */
  async getAllAccountsQuery() {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.get(`${environment.utlQuerySalesforce}SELECT+id%2C+name+from+Account`, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Get a list of records opportunity that match the specified SOQL query,
   * where is id of the account selected
   * @param id is id of the account selected
   */
  async getAllOpportunitiesQuery(id: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http
        .get(
          `${environment.utlQuerySalesforce}select+name%2C+id+from+opportunity+where+accountId+%3D+%27${id}%27`,
          options
        )
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  async getAllLeadsQuery() {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http
        .get(
          `${environment.utlQuerySalesforce}Select+Company%2C+id+from+lead+where+status+%21%3D+%27converted%27`,
          options
        )
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  async createLeads(body: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.post(`${environment.urlSalesForceServices}/Lead`, body, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  async createAccount(body: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http.post(`${environment.urlSalesForceServices}/Account`, body, options).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Get a list of leads records that match the specified SOQL query,
   * @param name
   */
  async getLeadsByName(name: any) {
    await this.getTokenSalesForce();
    let options = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.access_token}`),
    };
    return new Promise<any[]>((resolve, reject) => {
      return this.http
        .get(`${environment.utlQuerySalesforce}select+name%2C+id+from+lead+where+name+%3D+%27${name}%27`, options)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }
}
