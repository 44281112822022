<div class="main-container">
  <div style="height: auto">
    <div class="" *ngIf="filter === true">
      <div class="filters row">
        <div class="filter-container col-xl-2 col-6 col-lg-6 ">
          <p class="filter-label">Category</p>
          <select name="category" id="category" (change)="filterIngredient()" [(ngModel)]="filterSelections.selectedCategory">
            <option value=""></option>
            <option [value]="item" *ngFor="let item of filters.categories">{{item}}</option>
          </select>
        </div>
        <div class="filter-container col-xl-2 col-6 col-lg-6">
          <p class="filter-label">Subcategory</p>
          <select name="subcategory" id="subcategory" (change)="filterIngredient()" [(ngModel)]="filterSelections.selectedSubcategory">
            <option value=""></option>
            <option [value]="item" *ngFor="let item of filters.subcategories">{{item}}</option>
          </select>
        </div>
        <div class="filter-container col-xl-2 col-12 col-lg-12">
          <p class="filter-label">Format</p>
          <select name="subcategory" id="format" (change)="filterIngredient()" [(ngModel)]="filterSelections.selectedFormat">
            <option value=""></option>
            <option [value]="item" *ngFor="let item of filters.format">{{item}}</option>
          </select>
        </div>
        <div class="col-xl-2 col-lg-12 col-12 btn-clear">
          <button class="clear-btn" (click)="clearFilters()">Clear filters</button>
        </div>
      </div>
    </div>
  </div>
      <div class="products-container">
          <div class="rtl-products-containers">
            <div class="rtl-product" *ngFor="let item of rtlProducts; let i = index" (click)="handleSelection(i)">
              <div class="rtl-image-container" [class.selected]="item.selected">
                <img src="https://luker-recursos.s3.amazonaws.com/create/rtl/{{rtlProducts[i].idRtl}}.png" alt="" class="rtl-image">
              </div>
              <p class="rtl-name">{{item.nombre}}</p>
            </div>
          </div>
        </div>
    </div>
