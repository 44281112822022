import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { SendEmailModalComponent } from '../send-email-modal/send-email-modal.component';
import { MailService } from '../../../core/services/mail/mail.service';

@Component({
  selector: 'app-our-projects',
  templateUrl: './our-projects.component.html',
  styleUrls: ['./our-projects.component.css'],
})
export class OurProjectsComponent implements OnInit {
  /** The projects of go beyond section. */
  projects: any = {
    education: [
      { name: 'Vehicles for Learning', selected: false },
      { name: 'New School', selected: false },
      { name: 'The Power of Education', selected: false },
    ],
    entrepreneurship: [
      { name: 'Empowering Dreams', selected: false },
      { name: 'Dream Journey', selected: false },
      { name: 'Cuentas sin cuento', selected: false },
      { name: 'Young Necocli', selected: false },
      { name: 'Water for Caribia', selected: false },
    ],
    producers: [
      { name: 'The Cacao Effect', selected: false },
      { name: 'Granja Luker', selected: false },
    ],
    art: [
      { name: 'Painting Caribia', selected: false },
      { name: 'Cocoa Festival', selected: false },
    ],
    forests: [
      { name: 'Casanare', selected: false },
      { name: 'Necoclí', selected: false },
    ],
    social: [
      { name: 'Social Innovation Center', selected: false },
      { name: 'Volunteering', selected: false },
    ],
    projectIncome: [
      { name: 'The cacao effect', selected: false },
      { name: 'Masters of cacao', selected: false },
    ],
    projectSocial: [
      { name: 'The Power of education', selected: false },
      { name: 'Empowering Dreams', selected: false },
    ],
    projectMaintain: [
      { name: 'Forest and water', selected: false },
      { name: 'Sustainable future', selected: false },
    ],
  };

  /** The projects selected by the user. */
  selectedProjects: any = {};

  constructor(private sms: SimpleModalService, private mailService: MailService) {}

  ngOnInit(): void {}

  /**
   * This function handles the click events of the projects.
   * @param category The category of the project selected.
   * @param pos The index of the selected project.
   */
  handleClickSelection(category: string, pos: number) {
    console.log(category, pos);
    this.projects[category][pos].selected = !this.projects[category][pos].selected;
  }

  /**
   * This function saves the selected projects in the local storage.
   */
  async saveSelectedProjects() {
    let email = await this.openSendEmailModal();
    if (email.name || email.correo) {
      let keys = Object.keys(this.projects);
      keys.forEach((key) => {
        this.selectedProjects[key] = this.projects[key].filter((project: any) => project.selected);
      });
      this.mailService.sendSusteinabilityEmail(this.selectedProjects, email);
      localStorage.setItem('beyond', JSON.stringify(this.selectedProjects));
    }
  }

  /**
   * This function returns the email to send the selected projects information.
   * @returns The email adress to send the information.
   */
  async openSendEmailModal() {
    let email = await this.sms
      .addModal(SendEmailModalComponent, {}, { autoFocus: true, closeOnClickOutside: false })
      .toPromise();
    return email;
  }
}
