import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';

import { AseoResponse, AseoType } from './aseo.type';

@Injectable({
  providedIn: 'root',
})
export class AseoService {
  constructor(private mainService: MainService) {}

  /**
   * Get aseos price for a project
   * @param tipoProyecto
   */
  getAseoByTypeAndProjectWeight(tipoProyecto: string) {
    return new Promise<AseoResponse>((resolve, reject) => {
      this.mainService.get(`aseos/${tipoProyecto}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
