<div class="modal-content modal-container">
    <div class="">
      <h4>Real Life Size</h4>
    </div>
    <div class="img-container">
        <img src="{{shapeSizeImage}}" alt="">
    </div>
    <div class="btn-container">
      <button type="button" class="submit-btn" (click)="confirm()">
        Close
      </button>
    </div>
  </div>
  