import { Component, OnInit } from '@angular/core';
import { RTLProductService } from '../../../core/services/rtl-product/rtlproduct.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categories-filter',
  templateUrl: './categories-filter.component.html',
  styleUrls: ['./categories-filter.component.css'],
})
export class CategoriesFilterComponent implements OnInit {
  /**ready to launch category item list **/
  categories = [
    {
      name: 'Snacking',
      selected: false,
    },
    {
      name: 'Chocolate confectionary',
      selected: false,
    },
    {
      name: 'Bakery',
      selected: false,
    },
    {
      name: 'Drinking Chocolate',
      selected: false,
    },
  ];

  /**Name of category selected **/
  nameCategorySelected: any;

  constructor(public rtlProductService: RTLProductService, private router: Router) {}

  ngOnInit(): void {}

  /**Function that selects a category and validates that the previously selected option
   @category object of array selected in the html
   */
  selectCategory(category: any) {
    let indexSelected = this.categories.findIndex((item: any) => item.selected === true);
    if (indexSelected !== -1) {
      this.categories[indexSelected].selected = false;
    }
    category.selected = !category.selected;
    this.nameCategorySelected = category.name;
  }

  /**
   * Function that saves the category flow and the name of the selected category in a service
   */
  goToReadyToLaunch() {
    this.rtlProductService.categoriesFilter = true;
    this.rtlProductService.categorySelected = this.nameCategorySelected;
    this.router.navigate(['/ready-to-launch/steps/1']);
  }
}
