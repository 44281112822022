<div class="main-container">
    <div class="form-container">
        <div class="form-card">
            <div class="brand-logo-container">
                <img src="./assets/login/luker-creator.png" class="brand-logo" alt="Logo">
            </div>
            <div class="login-text">
                <p class="login-title">Welcome!</p>
                <p class="login-info">Log in and start making some chocolate!</p>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="login($event)">
                <div class="form-group">
                    <label for="email">Email Adress</label>
                    <input type="email" pInputText formControlName="email" [ngClass]="{'ng-invalid ng-dirty':fieldIsInvalid('email')}" />
                    <div *ngIf="loginForm.get('email')!.hasError('required') && loginForm.get('email')!.touched" class="invalid-warning">
                       Obligatory field
                    </div>
                    <div *ngIf="loginForm.get('email')!.hasError('email')" class="invalid-warning">
                      Enter a valid email address
                    </div>
                    <div *ngIf="userNotFound" class="invalid-warning">
                        User not found
                    </div>
                </div>
                <div class="form-group">
                    <label for="password" >Password</label>
                    <p-password
                      [feedback]="false"
                      [toggleMask]="true"
                      styleClass='p-password p-component p-inputwrapper p-input-icon-right'
                      formControlName="password"
                      [ngClass]="{'ng-invalid ng-dirty':fieldIsInvalid('password')}">
                    </p-password>
<!--                    <input id="password" type="password" formControlName="password" class="form-control-lg" [ngClass]="{'red-border':fieldIsInvalid('password')}"/>-->
                    <div *ngIf="fieldIsInvalid('password')" class="invalid-warning">
                      Obligatory field
                    </div>
                    <div *ngIf="wrongPassword" class="invalid-warning">
                        Incorrect password
                    </div>
                </div>
                <div class="flex-row">
                    <div class="remember-user">
                        <!-- <input type="checkbox" name="remember-me" id="remember-me">Remember me -->
                    </div>
                    <div class="forgot-password">
                        <a routerLink="/forgot-password">Forgot password?</a>
                    </div>
                </div>
                <div>
                    <button class="button" type="submit">Log in</button>
                </div>
            </form>
        </div>
    </div>
</div>
