<div class="modal-content modal-container">
    <div class="">
      <h4>Inclusion Details</h4>
    </div>
    <div class="modal-description">
      <p>Select additional details for your inclusion</p>
    </div>
    <div class="details-container">
        <div class="option-container">
            <p class="option-name">Application</p>
            <select name="application" *ngIf="validationDragees === false" [(ngModel)]="application" (change)="validate('application')" [class.red-border]="emptyApplication">
                <option value="occluded">Occluded: In the mass</option>
                <option value="superficial">Superficial: On top</option>
            </select>
          <div *ngIf="validationDragees === true" class="static-dragees">
            Occluded: In the mass
          </div>
            <p class="warning" *ngIf="emptyApplication">Choose an application first</p>
        </div>
        <p class="option-name">Percentage</p>
      <div *ngIf="validationDragees === false">
        <input type="number" name="percentage" [max]="maxPercentage" [(ngModel)]="percentage" min="0" (change)="validate('percentage')" [class.red-border]="emptyPercentage" [disabled]="maxPercentage == 0">
        <p class="warning" *ngIf="emptyPercentage">Write a percentage first</p>
        <p class="warning" *ngIf="percentage > 15">Max percentage is of 15</p>
      </div>

        <div *ngIf="validationDragees === true" class="static-dragees">
          {{percentage | number: '1.2-2'}}
        </div>
      </div>
    <div class="btn-container">
      <button type="button" class="cancel-btn" (click)="close()">
        Cancel
      </button>
      <button type="button" class="submit-btn" [disabled]="percentage > 15 && !grageaValidation" (click)="confirm()">
        Submit
      </button>
    </div>
</div>
