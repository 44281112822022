import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-flavor-modal',
  templateUrl: './flavor-modal.component.html',
  styleUrls: ['./flavor-modal.component.css'],
})
export class FlavorModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  /** This object stores the flavor selected by the user. */
  @Input() data: any;

  /** The concentration of the flavor. */
  concentration = 0;

  /** Trigger to validate the concentration select has a value. */
  emptySelection = false;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  /**
   * This function saves the flavor details and sets the trigger value.
   */
  confirm() {
    let flavor = this.data;
    flavor.pickedConcentration = this.concentration;
    localStorage.setItem('flavor', JSON.stringify(flavor));
    if (this.concentration) {
      this.result = flavor;
      this.close();
    } else {
      this.emptySelection = true;
      this.result = false;
    }
  }

  /**
   * This function validates if the concentration has value.
   */
  validate() {
    this.emptySelection = false;
  }
}
