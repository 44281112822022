import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CostService {
  rawMaterialsTypeOne: any = {};

  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of the material type one costs.
   * @param dollarPrice The current price of the dollar.
   * @param cacaoPrice The current price of cacao.
   * @param addCacaoPrice A bonus to add to the cacao price.
   * @returns A promise of all the material type one costs.
   */
  getRawMaterialTypeOne(dollarPrice: number, cacaoPrice: number, addCacaoPrice?: number) {
    return new Promise<any[]>((resolve, reject) => {
      let queryParams = new HttpParams();
      queryParams = queryParams.appendAll({
        dollarPrice: dollarPrice,
        cacaoPrice: cacaoPrice,
        addCacaoPrice: addCacaoPrice || 0,
      });
      this.mainService.getWithParams(`costos/materia_prima`, queryParams).subscribe(
        (response: any) => {
          this.rawMaterialsTypeOne = response as any;
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
