import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterTypeService {
  constructor(private mainService: MainService) {}

  /**This function returns a promise o the response after trying to post register type object
   * @param registerType The register type object needed to post.
   * @returns A promise with the response of the register type post attempt.
   */
  postRegisterType(registerType: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('registros_por_tipo', registerType).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          console.error(`Error al crear el tipo de registro ${error}`);
        }
      );
    });
  }
}
