import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { FarmModalComponent } from './farm-modal/farm-modal.component';

@Component({
  selector: 'app-our-farms',
  templateUrl: './our-farms.component.html',
  styleUrls: ['./our-farms.component.css'],
})
export class OurFarmsComponent implements OnInit {
  constructor(private sms: SimpleModalService) {}

  ngOnInit(): void {}

  /**
   * This function opens a modal containing the description of a selected farm.
   * @param name The name of the selected farm.
   */
  showFarmDescription(name: string) {
    let modal = this.sms.addModal(FarmModalComponent, { data: name }, { closeOnClickOutside: true });
  }
}
