<div class="main-container">
    <div class="page-info">
        <span class="title">Our Dreamers</span>
    </div>

    <div class="content">
        <div class="navigation-control">
            <img src="./../../../../assets/sustainability/dreamers/previous-button.png" alt="" class="control" (click)="currentImage = 1" [class.hide]="currentImage == 1">
        </div>
    
        <div class="img-container-dreamer">
            <img src="./../../../../assets/sustainability/dreamers/stories/{{brand}}-1.jpg" alt="" [class.brand-story]="currentImage == 1" [class.hide]="currentImage != 1">
            <img src="./../../../../assets/sustainability/dreamers/stories/{{brand}}-2.jpg" alt="" [class.brand-story]="currentImage == 2" [class.hide]="currentImage != 2">
        </div>
    
        <div class="navigation-control" >
            <img src="./../../../../assets/sustainability/dreamers/next-button.png" alt="" class="control" (click)="currentImage = 2" [class.hide]="currentImage == 2">
        </div>
    </div>

    <div class="content-mobile">
        <div class="img-container-mobile">
            <img src="./../../../../assets/sustainability/dreamers/stories/{{brand}}-1.jpg" alt="brand-img">
            <img src="./../../../../assets/sustainability/dreamers/stories/{{brand}}-2.jpg" alt="brand-img">
        </div>
    </div>
</div>
