<div class="modal-content modal-container">
  <div class="">
    <h4>{{title}}</h4>
  </div>
  <div class="modal-description">
    <p>{{text}}</p>
  </div>
  <div class="btn-container">
    <button type="button" class="cancel-btn" (click)="close()">
      Cancel
    </button>
    <button type="button" class="submit-btn" (click)="confirm()" >
      Confirm
    </button>
  </div>
</div>
