import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ChocolateService {
  /** This array stores the chocolate objects to use less backend calls.*/
  chocolateInfo: any[] = [];

  /** This array contains all the recipe options.*/
  recipesOptions: string[] = [];

  /** This array contains all the chocolate type options, without filtering by recipe.*/
  chocolateTypeOptions: string[] = [];

  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the chocolate objects obtained in the main service.
   * @returns A promise of all the chocolate objects.
   */
  getChocolateInfo() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('chocolate').subscribe(
        (response: any) => {
          this.chocolateInfo = response as any[];
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the portfolio and chocolate types of the chocolate objects.
   * @returns A promise of the portfolio and chocolate types.
   */
  getChocolateOptions() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('chocolate').subscribe(
        (response: any) => {
          this.recipesOptions = _.uniq((response as any[]).map((c) => c.portafolio));
          this.chocolateTypeOptions = _.uniq((response as any[]).map((c) => c.tipo));
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the chocolates that match the values of a specific object.
   * @param queryObject The object whose values will be filtered.
   * @returns A promise of all the chocolates that match the object's value.
   */
  filter(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`chocolate/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
