import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InclusionsFlavorsService } from '../../../core/services/inclusions-flavors/inclusions-flavors.service';
import { ChocolateService } from '../../../core/services/chocolate/chocolate.service';

@Component({
  selector: 'app-trend-info',
  templateUrl: './trend-info.component.html',
  styleUrls: ['./trend-info.component.css'],
})
export class TrendInfoComponent implements OnInit {
  /** The combination of the country and the trend key. */
  key: string = '';

  /** The key of the current trend. */
  trendKey: string = '';

  /** This map contains the name of the trends by key. */
  trendsMap: Map<string, string> = new Map<string, string>();

  /** The title of the current page. */
  title: string = '';

  /** This array stores the chocolates and inclusions of a trend. */
  trendIngredients: any[] = [];

  /** This array stores the paths of the trends benchmarks images. */
  benchmarks: string[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private inclusionsFlavorsService: InclusionsFlavorsService,
    private chocolateService: ChocolateService
  ) {}

  async ngOnInit() {
    this.getTrendPath();
    await this.loadIngredients();
    this.loadBenchmarks();
  }

  /**
   * This function gets the trend key and title by URL.
   */
  getTrendPath() {
    this.key = this.activatedRoute.snapshot.paramMap.get('key') || '';
    let keys = this.key.split('_');
    if (keys) {
      this.trendKey = keys[1];
    }
    this.loadTrendsMap();
    this.title = this.trendsMap.get(this.trendKey) || '';
  }

  /**
   * This function loads the trends map.
   */
  loadTrendsMap() {
    this.trendsMap.set('healthy', 'Healthy Living');
    this.trendsMap.set('ethical', 'Ethical Living');
    this.trendsMap.set('experience', 'Experience More');
  }

  /**
   * This function loads all the benchmarks images of a country and trend.
   */
  loadBenchmarks() {
    for (let i = 0; i < 4; i++) {
      this.benchmarks[i] = `https://luker-recursos.s3.amazonaws.com/create/trends/benchmarks/${this.key.toUpperCase()}${
        i + 1
      }.png`;
    }
  }

  /**
   * This function loads joins the arrays of inclusions and chocolates.
   */
  async loadIngredients() {
    let chocolate = await this.loadChocolates();
    let inclusions = await this.loadInclusions();
    this.trendIngredients = [...chocolate, ...inclusions];
  }

  /**
   * This function loads the chocolates which trend matches the selected trend.
   * @returns An array of the chocolates that match the trend value.
   */
  async loadChocolates() {
    await this.chocolateService.getChocolateInfo();
    let chocolates: any[] = this.chocolateService.chocolateInfo;
    chocolates = chocolates.filter((c: any) => c.trends?.split(',').includes(this.trendKey));
    chocolates.forEach((c) => {
      c.img = `./../../../../assets/basics/${c.tipo}.PNG`;
    });
    return chocolates;
  }

  /**
   * This function loads the inclusions which trend matches the selected trend.
   * @returns An array of the inclusions that match the trend value.
   */
  async loadInclusions() {
    let folder = '';
    let inclusions: any[] = await this.inclusionsFlavorsService.getAll();
    inclusions = inclusions.filter(
      (i: any) => i.trends?.split(',').includes(this.trendKey) && i.codigoSap != 'EN PROCESO'
    );
    inclusions.forEach((i) => {
      if (i.categoria == 'INCLUSIONS') {
        folder = 'inclusions';
      } else if (i.categoria == 'FLAVORS') {
        folder = 'flavors';
      } else if (i.categoria == 'MILK') {
        folder = 'milk';
      } else if (i.categoria == 'SUGAR') {
        folder = 'sweeteners';
      }
      i.img = `./../../../../assets/create/inclusions-and-flavors/${folder}/${i.codigoSap}.jpg`;
    });
    return inclusions;
  }
}
