import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.css'],
})
export class SustainabilityComponent implements OnInit {
  /** Trigger to validate if dreamers card was clicked. */
  isDreamersExpanded = false;

  constructor() {}

  ngOnInit(): void {
    this.isDreamersExpanded = false;
    let self = this;
    document.getElementById('short-cards-container')?.addEventListener('mouseleave', function () {
      self.isDreamersExpanded = false;
    });
  }

  /**
   * This function sets the trigger to true.
   */
  onDreamersClick() {
    this.isDreamersExpanded = true;
  }
}
