import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/services/auth/auth.service';
import { WebSocketService } from '../core/services/web-socket/web-socket.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private webSocketService: WebSocketService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (
        route.queryParamMap.get('role') == 'viewer' ||
        (this.webSocketService.getSessionId && this.webSocketService.getRole == 'viewer')
      ) {
        if (!this.authService.authToken) {
          const auth: any = await this.authService.authenticateUser({
            email: 'guest@lukerchocolate.com',
            password: 'LukerCre@torGuest2021',
          });
          this.authService.storeUserData(auth.token, auth.user);
          resolve(true);
        } else {
          resolve(true);
        }
      } else {
        const token = this.authService.getToken();
        let user = this.authService.getUser();
        if (user.tipo) {
          let response = route.data.roles.some((role: any) => role === user.tipo);
          if (!response) {
            // if (user.tipo === 'Feria') {
            if (user.tipo === 'FERIA') {
              this.router.navigateByUrl('home-feria');
            } else {
              this.router.navigateByUrl('home');
            }
          } else {
            resolve(true);
          }
        } else {
          this.router.navigateByUrl('login');
        }
        if (token) {
          resolve(true);
        } else {
          this.router.navigateByUrl('login');
          reject(false);
        }
      }
    });
  }
}
