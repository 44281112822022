import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { MaterialListService } from '../material-list/material-list.service';

@Injectable({
  providedIn: 'root',
})
export class PrivateLabelPortfolioService {
  constructor(private mainService: MainService, private materialService: MaterialListService) {}

  categoriesFilter = false;
  categorySelected: any;

  /**
   * This function saves a project.
   * @param project The object whose values will be the project values.
   * @returns A promise of the request result.
   */
  async saveProject(project: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.post(`private_label_project`, project).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function deletes a project.
   * @param projectId The _id of the project
   * @returns A promise of the request result.
   */
  async deleteProject(projectId: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.delete(`private_label_project/${projectId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function updates a project.
   * @param project The object whose values will be the project values.
   * @returns A promise of the request result.
   */
  async updateProject(projectId: string, project: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.put(`private_label_project/${projectId}`, project).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of all the private Label portfolio projects currently available.
   * @returns A promise of all the private Label portfolio projects.
   */
  getAllProjects() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('private_label_project').subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getProjectById(projectId: string) {
    return new Promise<any>((resolve, reject) => {
      this.mainService.get(`private_label_project/${projectId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getPlantaTriunfoValuesByRtlId(rtlId: string) {
    return new Promise<any>((resolve, reject) => {
      this.mainService.get(`planta_triunfo/rtl/${rtlId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
