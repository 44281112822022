import { Component, OnInit, Input } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-farm-modal',
  templateUrl: './farm-modal.component.html',
  styleUrls: ['./farm-modal.component.css'],
})
export class FarmModalComponent extends SimpleModalComponent<any, boolean> implements OnInit {
  /** This object stores the farm selected by the user. */
  @Input() data: any;

  /** Path of the farm description image. */
  farmDescription: string = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadFarmDescription();
  }

  /**
   * This function closes the modal.
   */
  confirm() {
    this.close();
  }

  /**
   * This function sets the path of the farm description image.
   */
  loadFarmDescription() {
    this.farmDescription = `./../../../../../assets/sustainability/farms/description/our-farms-${this.data}.png`;
  }
}
