import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ShapeAndSizeService {
  /** This array stores the shape objects to use less backend calls. */
  shapeAndSizesInfo: any[] = [];

  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of all the shape objects obtained in the main service.
   * @returns A promise of all the shape objects.
   */
  getShapesAndSizes() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get('forma').subscribe(
        (response: any) => {
          this.shapeAndSizesInfo = response as any[];
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getImages() {}

  /**
   * This function returns a promise of all the shape objects that match the values of a specific object.
   * @param queryObject The object whose values will be filtered.
   * @returns A promise of all the shapes that match the object's value.
   */
  filter(queryObject: any) {
    const queryParams = new HttpParams({ fromObject: queryObject });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`forma/filtrar`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
