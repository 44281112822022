import { Component, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../core/services/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  faAngleLeft = faAngleLeft;
  forgotForm: FormGroup;
  successMessage: any;
  success = true;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router) {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.redirectLogedUser()
  }

  fieldsInvalid(field: string) {
    return this.forgotForm.get(field)?.touched && this.forgotForm.get(field)?.invalid;
  }

  submitForm() {
    if (this.forgotForm.valid) {
      this.authService.forgotPassword(this.forgotForm.value).then((res: any) => {
        this.success = true;
        this.successMessage = [
          {
            severity: 'success',
            detail: `We will verify if ${this.forgotForm.value.email} is registered in Lukercreator and we will send you a temporary password.`,
          },
        ];
        setTimeout(() => {
          this.success = false;
        }, 5000);
      });
    }
  }

  redirectLogedUser() {
    const token = this.authService.getToken();
    if (token) {
      const typeUser = this.authService.getTypeUser();
      if (typeUser === 'Feria') {
        this.router.navigateByUrl('home-feria');
      } else {
        this.router.navigateByUrl('home');
      }
    }
  }
}
