<div class="main-container">
    <aside>
        <app-assistant [assistantTips]="assistantTips"></app-assistant>
    </aside>

    <div class="options-container row">
      <p class="bespoke-title-mobile">My Bespoke #1</p>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xl-4" style="padding:0px !important">
          <div class="row reverse">
            <div class="col-md-12 col-lg-12 col-xl-12" style="padding: 0px !important;">
              <p class="bespoke-title">My Bespoke #1</p>
              <div class="step-info">
                <p class="step-title">Ingredients</p>
                <p class="step-description">The recipe’s original ingredients are highlighted, but you can adjust them!</p>
              </div>
            </div>
            <div class="container__above col-md-12 col-lg-12 col-xl-12">
              <div class="d-flex container__chocolate">
                <div class="img-container">
                  <img src="{{pickedShape.img}}" class="chocolate-img" alt="bespoke-img">
                </div>
                <div class="container__dropdowns">
                  <div class="select-col">
                    <p class="ingredient-type">Chocolate Type</p>
                    <select name="chocolate-type" id="" class="type-select" (change)="loadPercentagesByType()" [(ngModel)]="pickedType">
                      <option value=""></option>
                      <option value="{{item}}" *ngFor="let item of chocolateTypes">{{item}}</option>
                    </select>
                  </div>
                  <div class="select-col mt-4" *ngIf="pickedType != ''">
                    <p class="ingredient-type">Cacao</p>
                    <select name="cacao-percentage" id="" class="percentage-select" [(ngModel)]="pickedPercentage">
                      <option value="{{item}}" *ngFor="let item of bespokePercentages">{{item * 100 | number : '1.0' }} %</option>
                    </select>
                  </div>
<!--                  <div class="select-col mt-4" *ngIf="pickedType != ''">-->
<!--                    <p class="ingredient-type">Eritritol Cristal</p>-->
<!--                    <select name="cacao-percentage" id="" class="percentage-select" [(ngModel)]="pickedPercentage">-->
<!--                      <option value="{{item}}" *ngFor="let item of bespokePercentages">{{item * 100 | number : '1.0' }} %</option>-->
<!--                    </select>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="selects-row selects-row-desktop">
            <div class="select-col">
              <p class="ingredient-type">Chocolate Type</p>
              <select name="chocolate-type" id="" class="type-select" (change)="loadPercentagesByType()" [(ngModel)]="pickedType">
                <option value=""></option>
                <option value="{{item}}" *ngFor="let item of chocolateTypes">{{item}}</option>
              </select>
            </div>
            <div class="select-col" *ngIf="pickedType != ''">
              <p class="ingredient-type">Cacao</p>
              <select name="cacao-percentage" id="" class="percentage-select" [(ngModel)]="pickedPercentage">
                <option value="{{item}}" *ngFor="let item of bespokePercentages">{{item * 100 | number : '1.0' }} %</option>
              </select>
            </div>
          </div> -->
        </div>
        <div class="bespoke-selections col-lg-12 col-md-12 col-sm-12 col-xl-8">
            <div class="ingredient-rows">
                <div class="ingredient-row" *ngIf="isMilkType">
                    <p class="ingredient-type">Milk</p>
                    <div class="ingredient-list">
                        <div class="ingredient-details" *ngFor="let item of filteredMilk; let i = index">
                            <div class="ingredient-img-container" (click)="handleClickSelection('milk', i)" [class.selected]="item.selected">
                              <img src="{{item.img}}" alt="ingredient-img" class="ingredient-img">
                            </div>
                            <p class="ingredient-name">{{item.nombre | titlecase}}</p>
                        </div>
                    </div>
                </div>
                <div class="ingredient-row">
                    <p class="ingredient-type">Sweeteners</p>
                    <div class="ingredient-list">
                        <div class="ingredient-details" *ngFor="let item of filteredSweeteners; let i = index">
                            <div class="ingredient-img-container" (click)="handleClickSelection('sugar', i)" [class.selected]="item.selected">
                              <img src="{{item.img}}" alt="ingredient-img" class="ingredient-img">
                            </div>
                            <p class="ingredient-name">{{item.nombre | titlecase}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="select-erithritol" *ngIf="erithritolSelected && pickedType">
                <p class="ingredient-type">Erithritol Combination</p>
                <select name="erithritol-combination" class="type-select" [(ngModel)]="pickedErithritolCombination">
                    <option value=""></option>
                    <option value="{{item}}" *ngFor="let item of erithritolCombinations">{{item}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="footer-container">
        <button (click)="changeToNextStep()" class="footer-button" [disabled]="!enableNextStep">Next Step ></button>
    </div>
</div>
