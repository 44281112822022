import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutritional-table',
  templateUrl: './nutritional-table.component.html',
  styleUrls: ['../product-summary.component.css'],
})
export class NutritionalTableComponent implements OnInit {
  @Input() totalNutritionalCost: any;

  constructor() {}

  ngOnInit(): void {}
}
