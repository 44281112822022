<div class="modal-dialog">
  <div class="modal-content modal-container">
      <div class="title">
        <h4>Your Project</h4>
      </div>
      <div class="modal-description">
        <p>Name your project *</p>
      </div>
      <div class="details-container">
          <input type="text" name="name" [(ngModel)]="name" [class.red-border]="emptyName">
          <p class="warning" *ngIf="emptyName">Write a name first</p>
        </div>
        <div class="details-container mt-3">
          <p class="modal-description">Select type of project *</p>
          <select [(ngModel)]="typeProject" (change)="typeOfProject()">
            <option value="experimental">Experimental</option>
            <option value="seguimiento">Client</option>
          </select>
        </div>
      <div class="btn-container">
        <button type="button" class="submit-btn" (click)="confirm()">
          Submit
        </button>
      </div>
  </div>
</div>
