<div class="main-container">
  <div class="step-info row">
    <p class="step-title">{{ stepName }}</p>
  </div>
  <div class="impact-info">

    <ng-container *ngIf="!goBeyondClicked">
      <div class="go-beyond-container">
        <p class="title">Go Beyond</p>
        <div class="logo-container">
          <img src="./../../../assets/impact/chocolate-dream.png" alt="chocolate-dream" class="chocolate-dream-logo" (click)="handleClick()">
        </div>
      </div>
      <div class="impact-container">
        <p class="title">Our Impact</p>
        <div class="impact-img-container">
          <img src="./../../../assets/impact/impact-summary.jpg" alt="impact-image" class="impact-img">
        </div>
      </div>
    </ng-container>
    
    <ng-container *ngIf="goBeyondClicked">
      <app-our-projects></app-our-projects>
    </ng-container>
  </div>
</div>