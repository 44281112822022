<div class="main-container">
  <div class="title">
    <p>Our projects</p>
  </div>
  <div class="projects-container container">
    <div class="project-row row">
      <div class="col">
        <div class="project-card">
          <div class="contain-project">
            <div class="project-img text-center ">
              <img
                src="./../../../../assets/go-beyond/entrepreneurship.jpg"
                alt="entrepreneurship"
              />
            </div>
            <div class="description-project">
              <h4>Enhance Farmers income</h4>
              <ul class="list-interest">
                <li class="interesting"
                    *ngFor="let item of projects.projectIncome; let i = index"
                    (click)="handleClickSelection('projectIncome', i)"
                    [class.selected]="item.selected"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="project-card">
          <div class="contain-project">
            <div class="project-img text-center">
              <img
                src="./../../../../assets/go-beyond/education.jpg"
                alt="education"
              />
            </div>
            <div class="description-project">
              <h4>Strengthen the social wellbeing</h4>
              <ul class="list-interest">
                <li class="interesting"
                    *ngFor="let item of projects.projectSocial; let i = index"
                    (click)="handleClickSelection('projectSocial', i)"
                    [class.selected]="item.selected"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="project-card">
          <div class="contain-project">
            <div class="project-img">
              <div class="text-center">
                <img
                  src="./../../../../assets/go-beyond/cocoa-forest.jpg"
                  alt="cocoa forest"
                />
              </div>
            </div>
            <div class="description-project">
              <h4>Maintain environmental balance</h4>
              <ul class="list-interest">
                <li class="interesting"
                    *ngFor="let item of projects.projectMaintain; let i = index"
                    (click)="handleClickSelection('projectMaintain', i)"
                    [class.selected]="item.selected"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
  <button class="send-email-btn mt-4" (click)="saveSelectedProjects()">
    Send me an email
  </button>
</div>
