import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-inclusion-modal',
  templateUrl: './inclusion-modal.component.html',
  styleUrls: ['./inclusion-modal.component.css'],
})
export class InclusionModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  /** This attribute stores the type selected inclusion. */
  @Input() data: any;

  /** This object stores the selected inclusion. */
  @Input() pickedInclusion: any;

  /** This object is used to load the user selections. */
  inclusion: any;

  /** The max percentage the inclusions can have. */
  maxPercentage = 15;

  /** The application selected by the user. */
  application = '';

  /** The percentage written by the user. */
  percentage = 0;

  /** Trigger to validate the application select has a value. */
  emptyApplication = false;

  /** Trigger to validate the percentage input has a value. */
  emptyPercentage = false;

  bespokeMaterials: any;

  validationPercentage = true;

  validationDragees = false;

  /** Validation of product is gragea **/
  grageaValidation = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadUserSelections();
    this.detectarValor();
  }

  /**
   * This function saves the inclusion details and sets the triggers values.
   */
  confirm() {
    const inclusion = JSON.parse(localStorage.getItem('inclusion') || '{}');
    inclusion[this.data] = {
      inclusion: this.pickedInclusion,
      application: this.application,
      percentage: this.percentage,
    };
    localStorage.setItem('inclusion', JSON.stringify(inclusion));
    if (this.percentage && this.application) {
      this.result = inclusion;
      this.close();
    } else {
      if (!this.application) {
        this.emptyApplication = true;
      }
      if (!this.percentage) {
        this.emptyPercentage = true;
      }
      this.result = false;
    }
  }

  /**
   * This function loads the user previous selections.
   */
  loadUserSelections() {
    this.inclusion = JSON.parse(localStorage.getItem('inclusion') || '{}');

    if (this.data == 'base') {
      this.maxPercentage = 15 - (this.inclusion.extra?.percentage || 0);
    } else {
      this.maxPercentage = 15 - this.inclusion.base.percentage;
    }
    this.application = this.inclusion[this.data]?.application || '';
    this.percentage = this.inclusion[this.data]?.percentage || 0;
    this.bespokeMaterials = JSON.parse(localStorage.getItem('bespoke') || '{}');
    if (this.bespokeMaterials.type.includes('GRAGEA')) {
      this.grageaValidation = true;
      this.identifyDrageesInclusion();
    }
  }

  detectarValor() {
    if (!this.validationDragees) {
      if (this.inclusion.base.inclusion.nombre === this.pickedInclusion.nombre) {
        this.percentage = this.inclusion.base.percentage;
      } else {
        this.percentage = 0;
      }
    }
  }

  identifyDrageesInclusion() {
    let percentageFactorInclusion = this.pickedInclusion.factorBespoke;
    let shapeForm = JSON.parse(localStorage.getItem('shape') || '{}');
    if (this.bespokeMaterials.type.includes('GRAGEA') || shapeForm.texto === 'Dragees') {
      const sumFactorKg = this.bespokeMaterials.materials.reduce((acomulator: any, object: any) => {
        return acomulator + object.factorKg * percentageFactorInclusion;
      }, 0);
      this.percentage = percentageFactorInclusion * 100;
      this.validationPercentage = false;
      this.validationDragees = true;
      this.application = 'occluded';
    }
  }

  /**
   * This function validates if the application or percentage have values.
   * @param field The field which values are needed to validate.
   */
  validate(field: string) {
    if (field == 'application') {
      this.emptyApplication = false;
    } else if (field == 'percentage') {
      this.emptyPercentage = false;
    }
  }
}
