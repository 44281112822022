import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import * as moment from 'moment';

import { take } from 'rxjs/operators';

import * as _ from 'lodash';

import { AuthService } from 'src/app/core/services/auth/auth.service';

import ls from 'localstorage-slim';

import jsPDF from 'jspdf';
import { MoneyApisService } from './../../core/services/money-apis/money-apis.service';
import { WebSocketService } from '../../core/services/web-socket/web-socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RTLProductService } from 'src/app/core/services/rtl-product/rtlproduct.service';

@Component({
  selector: 'app-project-brief',
  templateUrl: './project-brief.component.html',
  styleUrls: ['./project-brief.component.css'],
})
/**
 * TODO - Allow continue in form if the Kg is lower 1500 and change alert color from danger to warning.
 */
export class ProjectBriefComponent implements OnInit {
  projectForm: FormGroup;

  // Alert for weight input if the value is lower than 1500.
  showWeightAlert = false;

  /** The information of the active user. */
  activeUser: any;

  /** The name of the active user set in the 'Prepared By' field of the project brief. */
  preparedByUser = '';

  /** The client live session URL. */
  sessionURL = '';

  dollarPrices: number[] = [];
  euroPrice: number = 0;

  cacaoPrices: any[] = [];
  
  rtlProducts: any[] = [];

  countries: any;

  salesForceClient = false;
  salesForceClientSelected: any;

  params:any = {}

  addedproductsToPage=0
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private webSocketService: WebSocketService,
    private activatedRoute: ActivatedRoute,
    private moneyService: MoneyApisService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private rtlProductService: RTLProductService,

  ) {
    this.projectForm = this.formBuilder.group({
      date: ['', Validators.required],
      preparedBy: [''],
      client: ['', Validators.required],
      country: ['', Validators.required],
      weight: ['', [this.validateWeight.bind(this), Validators.pattern('^(0|[1-9][0-9]*)$')]],
      additionalCacaoPrice: ['', [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]],
      cacaoPrice: ['', [Validators.required]],
      dollarPrice: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.params = this.route.snapshot.queryParams;
    if(this.params.origin == 'private_label'){
      this.getRTLProducts()
    }
    this.getCountries();
    this.addExtraControls();
    this.removePreviousProject();
    this.webSocketService.setAsActive(false);
    this.loadActiveUser();
    this.setDefaultValues();
    this.sortCountries();
    this.setLiveSessionData();
    this.getDollarToCop();
    this.getEuroToCop();
    this.getCocoaPrices();
    // Weight input validation subscription.
    const weightControl = this.projectForm.get('weight');
    if (weightControl) {
      weightControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
        weightControl.updateValueAndValidity();
      });
    }
  }

  /**
   * This functions gets all the RTL products.
   */
  async getRTLProducts() {
    this.rtlProducts = await this.rtlProductService.getAllRTLProducts();
  }

  /**
   * This function validates the weight input.
   * @param control The weight form control.
   * @returns Null if the weight is valid, otherwise returns an object with the error.
   */
  validateWeight(control: FormControl) {
    const weightValue = parseInt(control.value, 10);
    const minimumWeight = 1500;
    if (weightValue < minimumWeight) this.showWeightAlert = true;
    else this.showWeightAlert = false;
    return null;
  }

  getCountries() {
    this.countries = this.moneyService.returnCountries();
  }

  goToSavedQuotes(){
    this.router.navigateByUrl("private-label-projects");
  }

  /**
   * This function watches the changes for the country, cacao price and dollar price form controls.
   */
  addExtraControls() {
    this.validateOtherCountry();
    this.validateOtherCacaoPrice();
    this.validateOtherDollarPrice();
  }

  /**
   * This function watches the changes of the country form control and creates another form control, in case an input for country is needed.
   */
  validateOtherCountry() {
    this.projectForm.controls['country'].valueChanges.subscribe((value) => {
      if (value === 'others') {
        this.projectForm.addControl('otherCountry', new FormControl('', [Validators.required]));
      } else {
        this.projectForm.removeControl('otherCountry');
      }
    });
  }

  /**
   * This function watches the changes of the cacao price form control and creates another form control, in case an input for cacao price is needed.
   */
  validateOtherCacaoPrice() {
    this.projectForm.controls['cacaoPrice'].valueChanges.subscribe((value) => {
      if (value === 'others') {
        this.projectForm.addControl(
          'otherCacaoPrice',
          new FormControl('', [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')])
        );
      } else {
        this.projectForm.removeControl('otherCacaoPrice');
      }
    });
  }

  /**
   * This function watches the changes of the dollar price form control and creates another form control, in case an input for dollar price is needed.
   */
  validateOtherDollarPrice() {
    this.projectForm.controls['dollarPrice'].valueChanges.subscribe((value) => {
      if (value === 'others') {
        this.projectForm.addControl(
          'otherDollarPrice',
          new FormControl('', [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')])
        );
      } else {
        this.projectForm.removeControl('otherDollarPrice');
      }
    });
  }

  /**
   * This functions sorts the countries alphabetically.
   */
  sortCountries() {
    this.countries = _.sortBy(this.countries);
  }

  /**
   * This function creates the project in the database with de data submitted in the projectForm.
   */
  onSubmit(): void {
    if (this.projectForm.valid) {
      this.createProject();
      this.redirectToStepper();
    } else {
      this.projectForm.markAllAsTouched();
    }
  }

  /**
   * This function is used to know if a field from projectForm is invalid.
   * @param field The field you need to validate.
   * @returns The state of the field, i.e., valid or invalid.
   */
  fieldIsInvalid(field: string) {
    return this.projectForm.get(field)?.touched && this.projectForm.get(field)?.invalid;
  }

  /**
   * This function sets the name of the active user as the value of the preparedBy form control, the date as today and a minimum value to weight and additionalCacaoPrice.
   */
  setDefaultValues() {
    this.preparedByUser = `${this.activeUser.nombre} ${this.activeUser.apellido}`;
    this.projectForm.patchValue({
      preparedBy: this.preparedByUser,
      weight: 1500,
      date: moment().format('YYYY-MM-DD'),
      additionalCacaoPrice: 0,
    });
  }

  /**
   * This function removes from local storage the user selections of previous projects.
   */
  removePreviousProject() {
    let items = [
      'brief',
      'selectedOptions',
      'chocolate',
      'bespoke',
      'shape',
      'packaging',
      'comments',
      'beyond',
      'parameters',
      'inclusion',
      'flavor',
      'type',
      'design',
      'artist',
      'objectTransporting',
      'finalPriceUsd',
      'priceUsdTransport',
      'packagings',
      'productFinished',
      'nameProductFinished',
      'pricesModifed',
    ];
    for (const item of items) {
      ls.remove(item);
    }
  }

  /**
   * This function calls the project service to post a new project in the backend.
   */
  createProject() {
    let clientName;
    let idSalesForce;
    let opportunityId;
    if (this.salesForceClientSelected) {
      clientName = this.salesForceClientSelected.nameClient;
      idSalesForce = this.salesForceClientSelected.client;
      opportunityId = this.salesForceClientSelected.opportunity;
    } else {
      clientName = this.projectForm.get('client')?.value;
      idSalesForce = '';
      opportunityId = '';
    }
    let project: any = {
      nombre: `Proyecto de ${this.activeUser.nombre}`,
      usuario: this.activeUser.id,
      fecha: this.projectForm.get('date')?.value,
      cliente: clientName,
      pais:
        this.projectForm.get('country')?.value === 'others'
          ? this.projectForm.get('otherCountry')?.value
          : this.projectForm.get('country')?.value,
      peso: this.projectForm.get('weight')?.value,
      primaCacao: this.projectForm.get('additionalCacaoPrice')?.value,
      precioCacao:
        this.projectForm.get('cacaoPrice')?.value === 'others'
          ? this.projectForm.get('otherCacaoPrice')?.value
          : this.projectForm.get('cacaoPrice')?.value,
      precioDolar:
        this.projectForm.get('dollarPrice')?.value === 'others'
          ? this.projectForm.get('otherDollarPrice')?.value
          : this.projectForm.get('dollarPrice')?.value,
      idSalesForce: idSalesForce,
      opportunityId: opportunityId || '',
      createdAt: Date.now(),
    };
    if (this.webSocketService.active) {
      if (this.webSocketService.getRole == 'admin') {
        this.sendBriefToClient(project);
      }
    }
    ls.set('brief', JSON.stringify(project), { encrypt: true });
    this.saveCostParameters(project);
  }

  /**
   * This function sends the project brief to the client in the current live session.
   * @param project The project brief that will be sent to the client.
   */
  sendBriefToClient(project: any) {
    this.webSocketService.emitToClient({ type: 'brief', project });
  }

  /**
   * This function loads the current active user.
   */
  loadActiveUser() {
    this.activeUser = this.authService.getUser();
  }

  /**
   * This function saves the project brief parameters needed to calculate the cost.
   */
  saveCostParameters(project: any) {
    let costParameters: any = {
      cacaoPrice: project['precioCacao'],
      dollarPrice: project['precioDolar'],
      euroPrice: this.euroPrice,
      weight: project['peso'],
      cacaoBonus: project['primaCacao'],
    };
    ls.set('parameters', JSON.stringify(costParameters), { encrypt: true });
  }

  /**
   * This function redirects the user depending on which menu card he clicked from.
   */
  redirectToStepper() {
    let route = this.getRedirectTo();
    if (this.webSocketService.active) {
      this.webSocketService.emitToClient({ type: 'redirect', url: route });
    }
    this.router.navigateByUrl(route);
  }

  /**
   * This function returns the route the user will get redirected to as a string, depending on which menu card he clicked from.
   * @returns The route the user will get redirected to.
   */
  getRedirectTo() {
    let redirectTo = '';
    const clickOrigin = this.loadUserClickOrigin();
    let redirect = clickOrigin?.split(' ');
    if (redirect?.includes('Ingredient')) {
      redirectTo = 'create-ingredient/basics';
    } else if (redirect?.includes('Product')) {
      redirectTo = 'create-product/basics';
    } else if (redirect?.includes('Launch')) {
      this.removePreviousProductsPrivateLabelPortfolio();
      redirectTo = 'ready-to-launch/steps/1';
    } else if (redirect?.includes('Portfolio')) {
      this.removePreviousProductPortfolio();
      redirectTo = 'portfolio-products/steps/1';
    } else if (redirect?.includes('saved-private-label-quotes')) {
      redirectTo = 'private-label-projects';
    }
    return redirectTo;
  }

  removePreviousProductPortfolio(){
    localStorage.setItem('materialOnePrices', JSON.stringify({}))
    localStorage.setItem('productsList', JSON.stringify([]))
  }
  removePreviousProductsPrivateLabelPortfolio(){
    localStorage.setItem('materialOnePrices', JSON.stringify({}))
    localStorage.setItem('rtlProductsList', JSON.stringify([]))
    ls.set('current_project', JSON.stringify({}), { encrypt: true });
  }

  /**
   * This function returns the name of the menu card the user clicked from.
   * @returns The name of the menu card the user clicked from.
   */
  loadUserClickOrigin() {
    const origin = localStorage.getItem('origin');
    return origin;
  }

  /**
   * This function generates the live client session URL.
   */
  async generateLiveSession() {
    let role = 'viewer';
    let currentSession: any = await this.webSocketService.getLiveSessionCode();
    this.sessionURL = `${this.webSocketService.baseURL}/project-brief?role=${role}&session_id=${currentSession.sessionCode}`;
    this.setLiveSessionData(currentSession.sessionCode + '');
  }

  /**
   * This function loads all the data of the live session and listens to the socket for navigation.
   * @param assignedSessionId
   */
  setLiveSessionData(assignedSessionId?: string) {
    const role: any = this.activatedRoute.snapshot.queryParamMap.get('role') || 'admin';
    let sessionId: any = '';
    if (assignedSessionId) {
      sessionId = assignedSessionId;
    } else {
      sessionId = this.activatedRoute.snapshot.queryParamMap.get('session_id') || '';
    }
    if (sessionId) {
      this.webSocketService.setAsActive(true);
    }
    if (this.webSocketService.active) {
      this.webSocketService.setSessionData(role, sessionId);
      if (sessionId && role == 'viewer') {
        this.webSocketService
          .listen()
          .pipe(take(2))
          .subscribe((data: any) => {
            if (data.object.type == 'redirect') {
              let route = data.object.url;
              this.router.navigateByUrl(`${route}`);
            } else if (data.object.type == 'brief') {
              ls.set('brief', JSON.stringify(data.object.project), { encrypt: true });
              this.saveCostParameters(data.object.project);
            }
          });
      }
    }
  }

  /**
   * This function copies the live session URL to the clipboard.
   * @param input The HTML input id.
   */
  copyURLToClipboard(input: any) {
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
  }

  getDollarToCop() {
    this.moneyService.getTrm().subscribe((res: any) => {
      if (res) {
        this.dollarPrices.push(res.value);
      }
    });
  }
  getEuroToCop() {
    this.moneyService.getEuro().subscribe((res: any) => {
      if (res) {
        this.euroPrice = res.value || res.eur.cop
      }
    });
  }

  getCocoaPrices() {
    this.moneyService.getCocoaPrices().subscribe((res: any) => {
      if (res) {
        let prices = [];
        for (const property in res) {
          let price = res[property];
          prices.push({
            value: price['Último'].replace('s', ''),
            label: price['Último'].replace('s', '') + ' ' + price['Mes'],
          });
        }
        this.cacaoPrices = prices;
      }
    });
  }

async transFormImageToBase64(url: any) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url + '?not-from-cache-please';
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ctx.drawImage(img, 0, 0);
      resolve(canvas);
    };
    img.onerror = (error) => {
      reject(error);
    };
    
  });
}

 async addProductToPdf(image:any, name:string, code:string, doc:any, product:any, productsPerRow:number, productWidth:number, productHeight:number, productGapX:number, productGapY:number){

  if (this.addedproductsToPage>12) {
    doc.addPage();
    const backgroundPages:any = await this.transFormImageToBase64(
      'https://luker-recursos.s3.amazonaws.com/create/newsfeed/fondoluker.png'
    );
    doc.addImage(backgroundPages, 0, 0, 210, 300, '', 'MEDIUM');
    this.addedproductsToPage=0

  }
  const currentRow = this.addedproductsToPage==0?1:Math.ceil(this.addedproductsToPage/productsPerRow)
  const currentColumn = this.addedproductsToPage==0?1:(this.addedproductsToPage>productsPerRow?this.addedproductsToPage-((currentRow-1)*productsPerRow):this.addedproductsToPage)
  const currentRowYGap = (currentRow-1)*productGapY + 46 + (currentRow-1)*(17) + (currentRow-1)*(productHeight) 
  doc.addImage(image, (currentColumn*productGapX)+((currentColumn-1)*productWidth), currentRowYGap, productWidth, productHeight);
  doc.text(code || "", (currentColumn*productGapX + (productWidth*((currentColumn-1)+(1/2)))), (currentRowYGap + productHeight + 5), { align: 'center', maxWidth: productWidth, });
  doc.text(name || "", (currentColumn*productGapX + (productWidth*((currentColumn-1)+(1/2)))), (currentRowYGap + productHeight + 10), { align: 'center', maxWidth: productWidth, });
  this.addedproductsToPage++;
  if (this.addedproductsToPage==1) {
    this.addedproductsToPage++;
  }
 }

  async generatePdf(){
    const doc = new jsPDF({ compress: true });
    doc.setFontSize(8);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#333');
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const backgroundPages:any = await this.transFormImageToBase64(
      'https://luker-recursos.s3.amazonaws.com/create/newsfeed/fondoluker.png'
    );
    doc.addImage(backgroundPages, 0, 0, 210, 300, '', 'MEDIUM');
    this.addedproductsToPage = 0
    for (let product of this.rtlProducts) {
      const imageUrl = `https://luker-recursos.s3.amazonaws.com/create/rtl/${product.idRtl}.png`
      const image:any = await this.transFormImageToBase64(
        imageUrl
      )
      const productsPerRow = 4
      const productWidth = pageWidth/6
      const productHeight = productWidth*1.5
      const productGapX = (pageWidth - (productsPerRow*productWidth))/5
      const productGapY = 7
      await this.addProductToPdf(image, product.nombre, product.idRtl, doc, product, productsPerRow, productWidth, productHeight, productGapX, productGapY)
    }
    doc.save(`Catalog RTL products.pdf`);
  }

  async downloadCatalog(){
    this.spinner.show();
    await this.generatePdf()
    this.spinner.hide();
  }
}

