import { Component, OnInit } from '@angular/core';
import { ProjectComexService } from '../../../core/services/project-comex/project-comex.service';
import { MoneyApisService } from '../../../core/services/money-apis/money-apis.service';

@Component({
  selector: 'app-transport-consult',
  templateUrl: './transport-consult.component.html',
  styleUrls: ['./transport-consult.component.css'],
})
export class TransportConsultComponent implements OnInit {
  containers: string[] = ['20" Dry', '40" Dry', 'Carga suelta', '40" Reefer', '40" Dry HC', '20" Reefer'];
  sourcePort: string[] = ['Cartagena', 'Buenaventura', 'Santa Marta'];
  incoterm: any;
  typeContainer: any;
  numberContainers: any;
  sourcePortSelect: any;
  countriesFilter: any;
  countrieSelected: any;
  registers: any;
  filterPortDestination: any;
  portDestinationSelected: any;
  pricesUsd: any;
  priceCopTransport: any;
  priceUsdTransport: any;
  dollar: any;
  transportData: any;
  totalOperation: any;
  showResults = false;
  freightUsd: any;
  portDestination: any;

  constructor(private projectComexService: ProjectComexService, private moneyService: MoneyApisService) {}

  ngOnInit(): void {
    this.getPortsRegister();
    this.getDollar();
    this.showResults = false;
  }

  selectedOption() {
    this.filterPorts();
  }

  getPortsRegister() {
    let countriesRegisters: any[] = [];
    this.projectComexService.getRegisterTransporting().then((response: any) => {
      this.registers = response;
      countriesRegisters = response.map((item: any) => item.paisDestino);
      this.countriesFilter = countriesRegisters.filter((item: any, index: number) => {
        return countriesRegisters.indexOf(item) === index;
      });
    });
  }

  filterPorts() {
    this.filterPortDestination = this.registers.filter((item: any) => {
      return (
        item.paisDestino == this.countrieSelected &&
        item.tipoContenedor == this.typeContainer &&
        Math.max(item.fechaFin)
      );
    });
    if (this.filterPortDestination.length > 0) {
      this.portDestination = this.filterPortDestination[0].puertoDestino;
    } else {
      this.portDestination = 'N/A';
    }
  }

  getDollar() {
    this.moneyService.getTrm().subscribe((response: any) => {
      if (response) {
        this.dollar = response.value;
      }
    });
  }

  async consult() {
    this.showResults = true;
    await this.getTransportCustoms();
    await this.getTransportData();
    await this.getTotalOperation();
    if (this.incoterm === 'CIF') {
      this.freightUsd = this.filterPortDestination[0]?.fleteUsd;
    } else {
      this.freightUsd = 0;
    }
  }

  async getTransportCustoms() {
    await this.projectComexService.getCustomsTransporting().then((response: any) => {
      this.pricesUsd = response.filter(
        (item: any) => item.puertoOrigen === this.sourcePortSelect && item.tipoContenedor === this.typeContainer
      );
      this.priceCopTransport = this.pricesUsd[0]?.valorUsd * this.dollar;
    });
  }

  async getTransportData() {
    await this.projectComexService.getTransportData().then((response: any) => {
      this.transportData = response.filter(
        (item: any) => item.puertoDestino == this.sourcePortSelect && item.tipoContenedor == this.typeContainer
      );
      this.priceUsdTransport = this.transportData[0]?.valorCop;
    });
  }

  async getTotalOperation() {
    let freightUsd;
    if (this.incoterm === 'CIF') {
      freightUsd = this.filterPortDestination[0]?.fleteUsd * this.dollar;
    } else {
      freightUsd = 0;
    }
    this.totalOperation = (this.priceCopTransport + this.priceUsdTransport + freightUsd) * this.numberContainers;
  }
}
