import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RegisterInfoService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of an array of registers info, filtering by the SAP Code of an item.
   * @param material The SAP Code of the item.
   * @returns A promise of the registers info filtered by SAP Code.
   */
  getByMaterial(material: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`registros_info/material/${material}`).subscribe(
        async (response: any) => {
          let registers: any[] = response;
          let registerId: any;
          // eslint-disable-next-line prefer-spread
          let mostRecentValidSince = this.getRecent(registers);
          registers = registers.filter((x: any) => x.valido_de == mostRecentValidSince);
          if (registers.length > 0) {
            registerId = registers[0].reg_info;
          }

          registers = registers.filter((x: any) => x.reg_info == registerId);
          resolve(registers);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getByRegInfo(regInfo: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`/registros_info/material_reg/${regInfo}`).subscribe(
        async (response: any) => {
          let scales: any[] = response;
          // let recentScale = this.getRecent(scales);
          // // scales = scales.filter((x: any) => x.valido_de == recentScale);
          resolve(scales);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getAllByMaterial(material: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`registros_info/material/${material}`).subscribe(
        async (response: any) => {
          let recentRegister = this.getRecent(response);
          let registers: any[] = response.filter((x: any) => x.valido_de == recentRegister);
          resolve(registers);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getAllByMaterialPackaging(material: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`registros_info_empaque/material/${material}`).subscribe(
        async (response: any) => {
          let registers: any[] = response;
          resolve(registers);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**This function return of register most recent for date
   * @param scales data to filter
   * @return most recent data
   */
  getRecent(scales: any) {
    // eslint-disable-next-line prefer-spread
    let mostRecentValidSince = Math.max.apply(
      Math,
      scales?.map(function (emp: any) {
        return emp.valido_de;
      })
    );
    return mostRecentValidSince;
  }

  /**
   * This function returns a promise of an array of registers info of inclusions and flavors.
   * @param scale The quantity (kg) of chocolate needed.
   * @param pickedInclusions The list of inclusions and flavors the user picked.
   * @returns A promise of the registers info of the inclusions and flavors.
   */
  getInclusionsCost(scale: number, pickedInclusions?: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('scale', scale);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.postWithParams(`registros_info/inclusiones`, pickedInclusions, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getPackagingCost(packaging: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.post('registros_info/empaques', packaging).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getChangePrices(tipoMaterial: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.appendAll({ tipoMaterial: tipoMaterial });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`cambio_precios_tipo`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getDefaultChagePrices() {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.get(`cambio_precios_material`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getProductsByName(name: any) {
    let queryParams = new HttpParams({ fromObject: name });
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`cambio_precios_nombre`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getProductsByCodeSap(codeSap: any, rangeDates: any) {
    let body = {
      rangeDates: rangeDates,
      codeSap: codeSap,
    };
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.post(`cambio_precios_datos`, body).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
