import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class NutritionTableService {
  constructor(private mainService: MainService) {}

  getDataNutritionTable(rawMaterials: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('datos_tabla_nutricional', rawMaterials).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
