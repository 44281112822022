<div class="modal-content modal-container">
  <div class="">
    <h4>Our Farms</h4>
  </div>
  <div class="img-container-modal">
    <img [src]="farmDescription" alt="img-modal" />
  </div>
  <div class="btn-container">
    <button type="button" class="submit-btn" (click)="confirm()">Close</button>
  </div>
</div>
