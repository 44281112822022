<div class="modal-dialog">
  <div class="modal-content modal-container">
     <h4>Change data</h4>
    <div>
      <label>Dollar</label>
      <input [(ngModel)]="data.precioDolar" type="number"  min="0" oninput="this.value = Math.abs(this.value)">
    </div>
    <div>
      <label>Cacao</label>
      <input [(ngModel)]="data.precioCacao" type="number"  min="0" oninput="this.value = Math.abs(this.value)">
    </div>
    <div>
      <label>Quantity</label>
      <input [(ngModel)]="data.peso" type="number"  min="0" oninput="this.value = Math.abs(this.value)">
    </div>
    <div>
      <label>Additional bonus</label>
      <input [(ngModel)]="data.primaCacao" type="number"  min="0" oninput="this.value = Math.abs(this.value)">
    </div>
    <div>
      <button class="submit-btn" (click)="submitBtn()" [disabled]="data.precioDolar == '' || data.peso == '' || data.precioCacao == ''">Submit</button>
    </div>
    <div>
      <button type="button" class="cancel-btn" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>
