import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class SrpService {
  constructor(private mainService: MainService) {}

  /**
   * This post srp simulator.
   * @param srp the object of srp save in mongo.
   * @returns A promise of the srp.
   */
  postSrp(srp: any) {
    return new Promise((resolve, reject) => {
      this.mainService.post('srp', srp).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  /**
   * This function returns a promise of srp by id project.
   * @param projectId The object id of the project.
   * @returns A promise of the srp project.
   */
  getSrpByIdProject(projectId: any) {
    return new Promise((resolve, reject) => {
      this.mainService.get(`srp-project/${projectId}`).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  updateSrpById(id: any, srp: any) {
    return new Promise((resolve, reject) => {
      this.mainService.put(`srp/${id}`, srp).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }
}
