import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css'],
})
export class TipsComponent implements OnInit {
  /** This array stores all the filtered tips of the current step. */
  @Input() tips: any[] = [];

  constructor() {}

  ngOnInit(): void {}
}
