import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-modal-edit-prices',
  templateUrl: './modal-edit-prices.component.html',
  styleUrls: ['../change-data-modal/change-data-modal.component.css'],
})
export class ModalEditPricesComponent extends SimpleModalComponent<any, any> implements OnInit {
  data: any;
  rawMaterialOne: any;
  rawMaterialTwo: any;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  confirm() {
    this.result = {
      rawMaterialOne: this.rawMaterialOne,
      rawMaterialTwo: this.rawMaterialTwo,
      editPrices: true,
    };
    this.close();
  }

  closeModal() {
    this.result = {
      editPrices: false,
    };
    this.close();
  }
}
