import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

@Injectable({
  providedIn: 'root',
})
export class MarginService {
  constructor(private mainService: MainService) {}

  /**
   * This function returns a promise of a margin object that matches the scale of the product.
   * @param scale The quantity in kilograms of the product.
   * @returns A promise of a margin object that matches the scale of the product.
   */
  getMarginByScale(scale: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('scale', scale);
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.getWithParams(`margen/escala`, queryParams).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
