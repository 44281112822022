import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.css'],
})
export class ModalConfirmationComponent extends SimpleModalComponent<any, any> implements OnInit {
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {}

  goToHome() {
    this.close();
    this.router.navigate(['/']);
  }
}
