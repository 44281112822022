<div class="main-container">
  <p class="step-title" *ngIf="currentRoute === '/my-projects?filter=seguimiento'">Client Projects</p>
  <p class="step-title" *ngIf="currentRoute === '/my-projects?filter=experimental'">Experimental Projects</p>
  <!--
  <div class="filters">
    <div class="row">
      <div class="filter-container col-12 col-md-12">
        <p class="filter-name">Filter by user</p>
        <select name="user-filter" id="user-filter" (change)="loadProjectsFromUser($event)" [(ngModel)]="selectedUser">
          <option value=""></option>
          <option [value]="user._id" *ngFor="let user of allUsers">{{user.nombre}} {{user.apellido}}</option>
        </select>
      </div>
    </div>
  </div>
  -->
  <div class="projects-container">
    <div *ngIf="userProjectsFilter.length < 1">
      <p class="no-projects">No projects found</p>
    </div>
    <div
      class="project-row"
      *ngFor="
        let item of userProjectsFilter | paginate: { itemsPerPage: 5, currentPage: page, totalItems: queryLength };
        let i = index
      ">
      <span class="project-name">
        {{ item.nombre }} - Client: {{ item.cliente }} - {{ item.createdAt | date: 'short' }}
      </span>
      <div class="d-flex align-items-center">
        <a [routerLink]="['/simulator-pvp/', item.id]" style="margin-right: 20px" class="view-btn">SRP Simulator</a>
        <div *ngIf="item.tipoProyecto === 'seguimiento'">
          <div class="tooltip-container" *ngIf="item.green" [hidden]="item.estadoPrecio == true">
            <fa-icon [icon]="faCircleInfo" class="fa-solid fa-circle-info icon-green tooltip-trigger"></fa-icon>
            <div class="tooltip-one">
              Tu proyecto tiene 30 dias de creado, no tiene cambios en los precios y ningun registro info esta en estado
              vencido
            </div>
          </div>
          <div class="tooltip-container" *ngIf="item.red" [hidden]="item.estadoPrecio == true">
            <fa-icon [icon]="faCircleInfo" class="fa-solid fa-circle-info icon-red tooltip-trigger"></fa-icon>
            <div class="tooltip-one">Tu proyecto esta vencido por tiempo</div>
          </div>
          <div class="tooltip-container" *ngIf="item.yellow" [hidden]="item.estadoPrecio == true">
            <fa-icon [icon]="faCircleInfo" class="fa-solid fa-circle-info icon-yellow tooltip-trigger"></fa-icon>
            <div class="tooltip-one">Tu proyecto esta proximo a vencer</div>
          </div>
          <div class="tooltip-container" *ngIf="item.estadoPrecio">
            <fa-icon [icon]="faCircleInfo" class="fa-solid fa-circle-info icon-red tooltip-trigger"></fa-icon>
            <div class="tooltip-one">
              Hubo cambios en tus precios de tu proyecto, el tiempo sobrepaso 90 dias o un registro info esta en estado
              vencido
            </div>
          </div>
        </div>
        <button class="view-btn" (click)="viewProjectBrief(i)">View Project Brief</button>
      </div>
    </div>
  </div>
  <div class="row steps-project" *ngIf="queryLength > 0">
    <div class="col-lg-12">
      <pagination-controls
        [previousLabel]="'Previuos page'"
        [nextLabel]="'Next page'"
        [responsive]="true"
        (pageChange)="page = $event; getUserProjects(page)"></pagination-controls>
    </div>
  </div>
</div>
