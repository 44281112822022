<div class="modal-dialog">
  <div class="modal-content modal-container">
    <div>
      <h4 class="title">Select two projects to compare</h4>
    </div>
    <div>
      <label class="label-input" [ngClass]="{'is-invalid': errorA}">Project A*</label>
      <div class="projects-mobile">
        <p-autoComplete [(ngModel)]="projectA"
                        (onSelect)="selectProjectA()"
                        [suggestions]="filteredProjectsA"
                        (completeMethod)="filterProject($event)"
                        field="nombre"
                        [dropdown]="true"
                        [readonly]="true"
                        [style]="{'width': '100%'}"
        >
          <ng-template let-projectsA pTemplate="item">
            <div class="country-item">
              <div>{{projectsA.nombre}} - Client: {{ projectsA.cliente }} - {{ projectsA.createdAt | date: 'short' }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
        <p *ngIf="errorA" class="text-danger">Select a correct option</p>
      </div>
      <div class="projects-desktop">
        <p-autoComplete [(ngModel)]="projectA"
                        (onSelect)="selectProjectA()"
                        [suggestions]="filteredProjectsA"
                        (completeMethod)="filterProject($event)"
                        field="nombre"
                        [dropdown]="true"
                        [style]="{'width': '100%'}"
        >
          <ng-template let-projectsA pTemplate="item">
            <div class="country-item">
              <div>{{projectsA.nombre}} - Client: {{ projectsA.cliente }} - {{ projectsA.createdAt | date: 'short' }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
        <p *ngIf="errorA" class="text-danger">Select a correct option</p>
      </div>
    </div>
    <div class="mt-4">
      <label class="label-input" [ngClass]="{'is-invalid': errorB}">Project B*</label>
      <div class="projects-mobile">
        <p-autoComplete [(ngModel)]="projectB"
                        [suggestions]="filteredProjectsB"
                        (completeMethod)="filterProjectB($event)"
                        field="nombre"
                        [dropdown]="true"
                        [style]="{'width': '100%'}"
                        [readonly]="true"
        >
          <ng-template let-projectsB pTemplate="item">
            <div class="country-item">
              <div>{{projectsB.nombre}}  - Client: {{ projectsB.cliente }} - {{ projectsB.createdAt | date: 'short' }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
        <p *ngIf="errorB" class="text-danger">Select a correct option</p>
      </div>
      <div class="projects-desktop">
        <p-autoComplete [(ngModel)]="projectB"
                        [suggestions]="filteredProjectsB"
                        (completeMethod)="filterProjectB($event)"
                        field="nombre"
                        [dropdown]="true"
                        [style]="{'width': '100%'}"
        >
          <ng-template let-projectsB pTemplate="item">
            <div class="country-item">
              <div>{{projectsB.nombre}}  - Client: {{ projectsB.cliente }} - {{ projectsB.createdAt | date: 'short' }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
        <p *ngIf="errorB" class="text-danger">Select a correct option</p>
      </div>
    </div>
    <div class="text-center mt-3">
      <button class="btn-compare" (click)="confirm()">Compare</button>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="cancel-modal" (click)="close()">Cancel</button>
    </div>
  </div>
</div>
