import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-our-dreamers',
  templateUrl: './our-dreamers.component.html',
  styleUrls: ['./our-dreamers.component.css'],
})
export class OurDreamersComponent implements OnInit {
  /** This attribute stores the brand from the URL. */
  brand = '';

  /** This attribute stores the current brand story image. */
  currentImage = 1;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getBrandFromURL();
  }

  /**
   * This function gets the brand from url to show the brand stories.
   */
  getBrandFromURL() {
    this.brand = this.activatedRoute.snapshot.paramMap.get('brand') || '';
  }
}
