<ng-container *ngIf="headerType == 'normal'">
  <nav class="navbar" [hidden]="hide">

    <div class="back-button-container" (click)="navigateToPreviousLocation()" *ngIf="sessionRole != 'viewer' && (currentFlow=='home-options' || currentFlow=='project-brief' || currentFlow=='launch' || currentFlow=='quote' || currentFlow=='saved-private-label-quotes')">
      <button class="back-arrow"></button>
      <span class="back-button">Go back</span>
    </div>
    <a class="navbar-brand" href="home" *ngIf="sessionRole != 'viewer' && currentFlow != 'home-options' && currentFlow!='project-brief' && currentFlow != 'launch' && currentFlow != 'quote' && currentFlow != 'saved-private-label-quotes'">
      <!-- <a class="navbar-brand" href="home" *ngIf="sessionRole != 'viewer' && currentFlow!='home-options'"> -->
      <img
        src="./../../../../assets/header/luker-logo.png"
        alt=""
        width="100"
        class="logo"
      />
    </a>
    <div class="action d-flex">
      <div class="notifications-menu" [ngClass]="{'active-notifications':activeNotifications}">
        <div class="row mobile-notifications" *ngFor="let item of notifications">
          <div class="img-notification col-4 text-center">
            <img src="{{ item.imagen }}" alt="img-notification">
          </div>
          <div class="col-md-8 col-12 mobile-contain-description">
            <div class="d-md-flex justify-content-md-between mobile-description">
              <div>{{item.nombre}}</div>
              <div>{{item.tipoNotificacion}}</div>
            </div>
            <div class="mobile-description">{{item.descripcion}}</div>
          </div>
          <span class="border-bottom"></span>
        </div>
      </div>
      <div class="profile" (click)="toggleDropdown()">
        <div class="img-container">
          <img src="./../../../../assets/header/default-pic.png" alt="" class="user-pic">
        </div>
        <span>{{user.nombre}}</span>
      </div>
      <div class="dropdown-menu" [ngClass]="{'active-menu':activeDropdown}">
        <div class="user-info">
          <span class="user-name">{{user.nombre + ' ' + user.apellido}}</span>
          <span class="user-email">{{user.email}}</span>
        </div>
        <ul class="dropdown-list">
          <li [routerLink]="['/my-projects']" [queryParams]="{filter: 'seguimiento'}"><img src="./../../../../assets/header/folder.png" alt="folder">My projects</li>
          <li class="d-flex" (click)="openModalUpdatePassword()">
              <fa-icon class="icon-edit" [icon]="faPen"></fa-icon>
              Change password
          </li>
          <li (click)="logout()"><img src="./../../../../assets/header/logout.png" alt="" (click)="logout()">Logout</li>
        </ul>
      </div>
    </div>
  </nav>
</ng-container>


  <ng-container #basics *ngIf="headerType == 'ingredient-basics' || headerType == 'product-basics' || headerType == 'rtl-basics' || headerType === 'simulator-pvp'">
    <nav class="navbar-normal basics" [hidden]="hide">
      <div class="back-button-container" (click)="navigateToPreviousLocation()" *ngIf="sessionRole != 'viewer'">
        <button class="back-arrow"></button>
        <span class="back-button">Go back</span>
      </div>

      <div class="home-btn-container" *ngIf="sessionRole != 'viewer'">
        <a class="navbar-brand home-nav" [routerLink]="'./../../../home'">
          <img src="./../../../../assets/header/home-icon.svg" alt="" class="home-logo"/>
        </a>
        <span class="home-label" [routerLink]="'./../../../home'">Home</span>
      </div>
    </nav>
  </ng-container>

  <ng-container #steps *ngIf="headerType == 'steps'">
    <nav class="navbar-normal" [hidden]="hide">
      <div class="back-button-container" (click)="navigateToPreviousLocation()" *ngIf="sessionRole != 'viewer'">
        <button class="back-arrow"></button>
        <span class="back-button">Go back</span>
      </div>
      <div class="right-menu">
        <div class="steps-container">
          <ul class="navbar-nav" *ngIf="currentFlow == 'ingredient'">
            <li class="nav-item" [ngClass]="currentStep == 1? 'active':'inactive'">
              <span class="nav-link" href="#">1. Shape & Size</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 2 || currentStep == 3? 'active':'inactive'">
              <span class="nav-link" href="#">2. Chocolate</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 4? 'active':'inactive'">
              <span class="nav-link" href="#">3. Packaging</span>
            </li>
            <!--
            <li class="nav-item" [ngClass]="currentStep == 5? 'active':'inactive'">
              <span class="nav-link" href="#">4. Impact</span>
            </li>
            -->
            <li class="nav-item" [ngClass]="currentStep == 5? 'active':'inactive'">
              <span class="nav-link" href="#">4. Transport</span>
            </li>
          </ul>

          <ul class="navbar-nav desktop-nav" *ngIf="currentFlow == 'product'">
            <li class="nav-item" [ngClass]="currentStep == 1? 'active':'inactive'">
              <span class="nav-link" href="#">1. Shape & Size</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 2 || currentStep == 3? 'active':'inactive'" >
              <span class="nav-link" href="#">2. Chocolate</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 4? 'active':'inactive'">
              <span class="nav-link" href="#">3. Flavors</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 5? 'active':'inactive'">
              <span class="nav-link" href="#">4. Packaging</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 6? 'active':'inactive'">
              <span class="nav-link" href="#">5. Design</span>
            </li>
            <!--
            <li class="nav-item" [hidden]="activeTypeUser" [ngClass]="currentStep == 7? 'active':'inactive'">
              <span class="nav-link" href="#">6. Impact</span>
            </li>
            -->
            <li class="nav-item" [hidden]="activeTypeUser" [ngClass]="currentStep == 7 ? 'active':'inactive'">
              <span class="nav-link" href="#">6. Transport</span>
            </li>
            <li class="nav-item" *ngIf="activeTypeUser" [ngClass]="currentStep == 7? 'active':'inactive'">
              <span class="nav-link" href="#">6. information</span>
            </li>
            <!--
            <li class="nav-item" [hidden]="activeTypeUser" [ngClass]="currentStep == 9? 'active':'inactive'">
              <span class="nav-link" href="#">8. Salesforce</span>
            </li>
            -->
          </ul>

          <ul class="navbar-nav desktop-nav" *ngIf="currentFlow == 'consult-price'">
            <li class="nav-item" [ngClass]="currentStep == 1? 'active':'inactive'">
              <span class="nav-link header-link" routerLink="/consult-price">Cost Consulting</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 2? 'active':'inactive'">
              <span class="nav-link header-link" [routerLink]="['/my-projects']" [queryParams]="{filter: 'seguimiento'}">Client Projects</span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 3? 'active':'inactive'">
              <span class="nav-link header-link" [routerLink]="['/my-projects']" [queryParams]="{filter: 'experimental'}">Experimental projects</span>
            </li>
            <li class="nav-item"
                *ngIf="user.tipo === 'ADMIN' || user.tipo === 'SUPERADMIN' || user.tipo === 'RYD'"
                [ngClass]="currentStep == 4? 'active':'inactive'">
              <span class="nav-link header-link" routerLink="/rd-projects">R&D Prototype</span>
            </li>
            <!-- <li class="nav-item"
                *ngIf="user.tipo === 'Administrador' || user.tipo === 'Ryd'"
                [ngClass]="currentStep == 4? 'active':'inactive'">
              <span class="nav-link header-link" routerLink="/rd-projects">R&D Prototype</span>
            </li> -->
            <li class="nav-item" [ngClass]="currentStep == 5? 'active':'inactive'">
              <span class="nav-link header-link" (click)="openCompareProducts()">Compare products</span>
            </li>
          </ul>
          
          <ul class="navbar-nav new-navbar-nav desktop-nav" *ngIf="currentFlow == 'quote'" #stepsContainer>
            <li class="nav-item" [ngClass]="currentStep == 1? 'active':currentStep > 1? 'finished':'inactive'">
              <span class="nav-link" href="#">
                <svg *ngIf="currentStep > 1" class="finished-icon"  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_5188_9356)">
                  <path d="M10 0C4.47719 0 0 4.47719 0 10C0 15.5231 4.47719 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47719 15.5231 0 10 0ZM10 18.7697C5.17531 18.7697 1.25 14.8247 1.25 9.99996C1.25 5.17527 5.17531 1.24996 10 1.24996C14.8247 1.24996 18.75 5.17529 18.75 9.99996C18.75 14.8246 14.8247 18.7697 10 18.7697ZM13.9909 6.34094L8.12373 12.245L5.48154 9.60281C5.23748 9.35875 4.84186 9.35875 4.59748 9.60281C4.35342 9.84688 4.35342 10.2425 4.59748 10.4866L7.69092 13.5803C7.93498 13.8241 8.33061 13.8241 8.57498 13.5803C8.60311 13.5522 8.62719 13.5215 8.64906 13.4897L14.8753 7.22498C15.1191 6.98092 15.1191 6.58529 14.8753 6.34094C14.6309 6.09688 14.2353 6.09688 13.9909 6.34094Z" fill="#468E1B"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_5188_9356">
                  <rect width="25" height="25" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                <div *ngIf="currentStep <= 1"class="step-number">1</div>
                Product finished
              </span>
            </li>
            <!--
            <li class="nav-item" [ngClass]="currentStep == 2? 'active':'inactive'">
              <span class="nav-link" href="#">2. Impact</span>
            </li>
            -->
            <li class="nav-item" [ngClass]="currentStep == 2? 'active':'inactive'">
              <span class="nav-link" href="#">
                <div *ngIf="currentStep <= 2"class="step-number">2</div>
                Download quote
              </span>
            </li>
            <!-- <li class="nav-item" [ngClass]="currentStep == 2? 'active':'inactive'">
              <span class="nav-link" href="#">2. Transport</span>
            </li> -->
          </ul>
          <ul class="navbar-nav new-navbar-nav desktop-nav" *ngIf="currentFlow == 'launch'" #stepsContainer>
            <li class="nav-item" [ngClass]="currentStep == 1? 'active':currentStep > 1? 'finished':'inactive'">
              <span class="nav-link" href="#">
                <svg *ngIf="currentStep > 1" class="finished-icon"  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_5188_9356)">
                  <path d="M10 0C4.47719 0 0 4.47719 0 10C0 15.5231 4.47719 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47719 15.5231 0 10 0ZM10 18.7697C5.17531 18.7697 1.25 14.8247 1.25 9.99996C1.25 5.17527 5.17531 1.24996 10 1.24996C14.8247 1.24996 18.75 5.17529 18.75 9.99996C18.75 14.8246 14.8247 18.7697 10 18.7697ZM13.9909 6.34094L8.12373 12.245L5.48154 9.60281C5.23748 9.35875 4.84186 9.35875 4.59748 9.60281C4.35342 9.84688 4.35342 10.2425 4.59748 10.4866L7.69092 13.5803C7.93498 13.8241 8.33061 13.8241 8.57498 13.5803C8.60311 13.5522 8.62719 13.5215 8.64906 13.4897L14.8753 7.22498C15.1191 6.98092 15.1191 6.58529 14.8753 6.34094C14.6309 6.09688 14.2353 6.09688 13.9909 6.34094Z" fill="#468E1B"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_5188_9356">
                  <rect width="25" height="25" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                <div *ngIf="currentStep <= 1"class="step-number">1</div>
                Private Label
              </span>
            </li>
            <li class="nav-item" [ngClass]="currentStep == 2? 'active':'inactive'">
              <span class="nav-link" href="#">
                <div *ngIf="currentStep <= 2"class="step-number">2</div>
                Download Quote
              </span>
            </li>
          </ul>
        </div>

        <div class="home-btn-container">
          <a class="navbar-brand home-nav" [routerLink]="'./../../../home'" *ngIf="sessionRole != 'viewer'">
            <img src="./../../../../assets/header/home-icon.svg" alt="" class="home-logo"/>
          </a>
          <span class="home-label" [routerLink]="'./../../../home'">Home</span>
        </div>
      </div>
    </nav>
  </ng-container>

<div *ngIf="currentFlow == 'consult-price'" >
  <ul class="mobile-nav">
    <li class="nav-item" [ngClass]="currentStep == 1? 'active':'inactive'">
      <span class="nav-link header-link" routerLink="/consult-price">Cost Consulting</span>
    </li>
    <li class="nav-item" [ngClass]="currentStep == 2? 'active':'inactive'">
      <span class="nav-link header-link" [routerLink]="['/my-projects']" [queryParams]="{filter: 'seguimiento'}">Client Projects</span>
    </li>
    <li class="nav-item" [ngClass]="currentStep == 3? 'active':'inactive'">
      <span class="nav-link header-link" [routerLink]="['/my-projects']" [queryParams]="{filter: 'experimental'}">Experimental projects</span>
    </li>
    <!-- <li class="nav-item" [ngClass]="currentStep == 4? 'active':'inactive'" *ngIf="user.tipo === 'Administrador' || user.tipo === 'Ryd'"> -->
    <li class="nav-item" [ngClass]="currentStep == 4? 'active':'inactive'" *ngIf="user.tipo === 'ADMIN' || user.tipo === 'RYD' || user.tipo === 'SUPERADMIN'">
      <span class="nav-link header-link" routerLink="/rd-projects">R&D Prototype</span>
    </li>
    <li class="nav-item" [ngClass]="currentStep == 5? 'active':'inactive'">
      <span class="nav-link header-link" (click)="openCompareProducts()">Compare products</span>
    </li>
  </ul>
</div>

<div *ngIf="currentFlow == 'product'" [hidden]="headerType == 'ingredient-basics' || headerType == 'product-basics' || headerType == 'rtl-basics' || headerType == 'normal'">
  <ul class="mobile-nav-product">
    <li class="nav-item" [ngClass]="currentStep == 1? 'active':'inactive'">
      <span class="nav-link" href="#">1. Shape & Size</span>
    </li>
    <li class="nav-item" [ngClass]="currentStep == 2 || currentStep == 3? 'active':'inactive'" >
      <span class="nav-link" href="#">2. Chocolate</span>
    </li>
    <li class="nav-item" [ngClass]="currentStep == 4? 'active':'inactive'">
      <span class="nav-link" href="#">3. Flavors</span>
    </li>
    <li class="nav-item" [ngClass]="currentStep == 5? 'active':'inactive'">
      <span class="nav-link" href="#">4. Packaging</span>
    </li>
    <li class="nav-item" [ngClass]="currentStep == 6? 'active':'inactive'">
      <span class="nav-link" href="#">5. Design</span>
    </li>
    <!--
    <li class="nav-item" [hidden]="activeTypeUser" [ngClass]="currentStep == 7? 'active':'inactive'">
      <span class="nav-link" href="#">6. Impact</span>
    </li>
    -->
    <li class="nav-item" [hidden]="activeTypeUser" [ngClass]="currentStep == 7? 'active':'inactive'">
      <span class="nav-link" href="#">6. Transport</span>
    </li>
    <li class="nav-item" *ngIf="activeTypeUser" [ngClass]="currentStep == 7? 'active':'inactive'">
      <span class="nav-link" href="#">6. information</span>
    </li>
  </ul>
</div>

<ng-container *ngIf="currentFlow == 'project-brief' || currentFlow == 'home' || currentFlow === 'home-feria'" >

</ng-container>

  <ng-container *ngIf="headerType == 'create-steps'">
    <a class="navbar-brand" href="home">
      <img
        src="./../../../../assets/header/luker-logo.png"
        alt=""
        width="100"
      />
    </a>
  </ng-container>
