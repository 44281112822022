<div class="main-container">
    <div class="page-info">
        <span class="title">The cocoa context in Colombia</span>
    </div>

    <div class="content">
        <div class="img-container">
            <img src="./../../../../assets/sustainability/context/context.png" alt="">
        </div>
    </div>
</div>
