<div class="main-container">
    <div class="assistant-name">
        <p class="name">José the Assistant</p>
    </div>
    <div class="tips-container">
        <div class="tip-card" *ngFor="let item of assistantTips">
            <p class="marketing tip-title" *ngIf="item.tipo == 'Mercadeo'">Did you know?</p>
            <p class="research-development tip-title" *ngIf="item.tipo == 'R&D'">Remember!</p>
            <p class="tip">{{item.comentario}}.</p>
        </div>
    </div>
</div>
