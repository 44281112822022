<div class="main-container">
  <p class="step-title">R&D Prototype</p>
    <div class="row" style="justify-content: center; width: 100%;">
      <div class="filter-container col-12">
        <p class="filter-name">Filter by user</p>
        <select name="user-filter" id="user-filter" (change)="getProjectsById($event)" [(ngModel)]="selectedUser">
          <option value=""></option>
          <option [value]="user._id" *ngFor="let user of allUsers">{{user.nombre}} {{user.apellido}}</option>
        </select>
      </div>
      <div class="filter-container col-12">
        <div class="search-bar">
          <p class="filter-name">Filter by project name</p>
          <input type="text" name="desc" id="desc" class="project-name-filter" [(ngModel)]="projectNameFilter" (keyup)="onProjectNameFilterChange($event)">
      </div>
      </div>
    </div>
    <div class="projects-container">
      <div *ngIf="userProjectsRd.length < 1">
        <p class="no-projects">No projects found</p>
      </div>
      <div class="project-row" *ngFor="let item of userProjectsRd  | paginate: { itemsPerPage: 5, currentPage: page, totalItems: queryLength }">
        <div>
          <span class="title-project">{{item.nombreProyecto + ' ' + item.version}}</span>
          <div class="title-project">{{item.fechaRealizacion | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div>
          <a class="title-edit" (click)="deleteProject(item._id)">Delete prototype</a>
          <a class="title-edit" [routerLink]="['/rd-consulting', item._id]">Edit Prototype</a>
        </div>
      </div>
    </div>
    <div class="container__btn-create">
      <button class="btn-create" (click)="redirectToNewRd()">Create New</button>
    </div>
    <div class="row steps-rd" *ngIf="queryLength > 0">
      <div class="col-lg-12">
        <pagination-controls
          [previousLabel]="'Previuos page'"
          [nextLabel]="'Next page'"
          [responsive]="true"
          (pageChange)="page = $event; getProjectsById(page)"
          ></pagination-controls>
      </div>
</div>
