<div class="main-container">
  <p class="title-salesforce">Salesforce Setup</p>
  <div class="row">
    <div class="col-12">
      <div class="container-type">
        <label>Select type</label>
        <select [(ngModel)]="typeConsult">
          <option value="lead">Lead</option>
          <option value="opportunity">Opportunity</option>
        </select>
      </div>
    </div>
    <ng-container *ngIf="typeConsult == 'opportunity'">
        <div class="col-12 mt-3">
          <div class="container-type">
            <label>Select client</label>
            <select [(ngModel)]="clientSelected" (change)="getOpportunity()">
              <option *ngFor="let item of salesForceClients" value="{{ item.Id }}">
                {{ item.Name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="container-type">
            <label>Select opportunity</label>
            <select (change)="validateFormLead()" [(ngModel)]="opportunitySelected" [disabled]="newOpportunity">
              <option *ngFor="let item of listOpportunity" value="{{ item.Id }}">{{ item.Name }}</option>
            </select>
          </div>
        </div>
      <div class="col-12">
        <div class="d-flex justify-content-center mt-3 px-4">
          <p-checkbox value="opportunity" (click)="setNewOpportunity()"></p-checkbox>
          <label class="ms-2">New opportunity</label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="typeConsult == 'lead'">
      <div class="container-type">
        <div style="width: 50%; margin-top: 10px">
          <p-autoComplete
            [disabled]="newLead"
            [dropdown]="true"
            field="Company"
            (completeMethod)="filteredItem($event)"
            [(ngModel)]="listLeads"
            [suggestions]="filteredLeads"
            [style]="{ width: '100%', display: 'flex', 'justify-content': 'center' }">
            <ng-template let-item pTemplate="item">
              <div>
                {{ item.Company }}
              </div>
            </ng-template>
          </p-autoComplete>
          <div class="d-flex align-items-center mt-2">
            <p-checkbox value="salesforce" (click)="setFormLead()"></p-checkbox>
            <label class="ms-2">Create lead</label>
          </div>
        </div>
        <div class="container-type w-100 mt-2">
          <label>Select client</label>
          <select [(ngModel)]="clientSelected" [disabled]="newClient">
            <option *ngFor="let item of salesForceClients" value="{{ item.Id }}">
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="d-flex mt-2 w-50">
          <p-checkbox value="salesforce" (click)="setFormAccount()"></p-checkbox>
          <label class="ms-2">Create client</label>
        </div>
        <form class="row w-50 mt-3" *ngIf="newClient" [formGroup]="formAccount" (change)="validateFormLead()">
          <h4>Create client</h4>
          <div class="col-6 input-client">
            <label>Name Client</label>
            <input type="text" formControlName="Name"/>
          </div>
          <div class="col-6 input-client">
            <label>Phone number</label>
            <input type="text" formControlName="Phone"/>
          </div>
        </form>
        <form [formGroup]="formLead" (change)="validateFormLead()" class="row w-50 mt-3" *ngIf="newLead">
          <h4>Create Lead</h4>
          <div class="col-6 input-client">
            <label>First name</label>
            <input type="text" formControlName="FirstName"/>
          </div>
          <div class="col-6 input-client">
            <label>Last Name</label>
            <input type="text" formControlName="LastName"/>
          </div>
          <div class="col-6 mt-1 input-client">
            <label>Company</label>
            <input type="text" formControlName="Company"/>
          </div>
        </form>
        <form (change)="validateFormLead()" [formGroup]="formContact" class="mt-3 row w-50">
          <h4>Create contact</h4>
          <div class="col-6 input-client">
            <label>First name</label>
            <input type="text" formControlName="FirstName">
          </div>
          <div class="col-6 input-client">
            <label>Last name</label>
            <input type="text" formControlName="LastName">
          </div>
          <div class="col-6 mt-1 input-client">
            <label>Email</label>
            <input type="text" formControlName="Email">
          </div>
        </form>
      </div>
    </ng-container>
  </div>
</div>
