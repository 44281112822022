import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RTLProductService } from '../../core/services/rtl-product/rtlproduct.service';
import { AuthService } from '../../core/services/auth/auth.service';

@Component({
  selector: 'app-home-feria',
  templateUrl: './home-feria.component.html',
  styleUrls: ['./home-feria.component.css'],
})
export class HomeFeriaComponent implements OnInit {
  constructor(private router: Router, public rtlProductService: RTLProductService, private authService: AuthService) {}

  ngOnInit(): void {}

  /**
   * Function that redirects to creation flows
   * @param categorySelected Category selected by the user
   * **/
  btnClick(categorySelected: any) {
    this.deleteLocalStorage();
    switch (categorySelected) {
      case 'rtl':
        this.router.navigate(['/ready-to-launch/steps/1']);
        localStorage.setItem('origin', 'Ready to Launch Portfolio');
        this.disableCategoriesFilter();
        break;
      case 'product':
        this.router.navigate(['/create-product/basics']);
        localStorage.setItem('origin', 'Create a Product From Scratch');
        this.disableCategoriesFilter();
        break;
      case 'categories':
        this.router.navigate(['/categories-filter']);
        break;
    }
  }

  /**
   * Function that disables the categories filter flow
   *  */
  disableCategoriesFilter() {
    this.rtlProductService.categoriesFilter = false;
    this.rtlProductService.categorySelected = '';
  }

  /**
   * Remove localstorage when choosing an option
   */
  deleteLocalStorage() {
    let itemsLocalStorage = [
      'parameters',
      'selectedOptions',
      'chocolate',
      'rtl',
      'packaging',
      'inclusion',
      'type',
      'brief',
      'shape',
      'sugar',
      'licitine',
      'flavor',
      'ingredients',
      'design',
      'milk',
      'type',
    ];
    for (let item of itemsLocalStorage) {
      localStorage.removeItem(item);
    }
  }

  logout() {
    this.authService.endActiveSession();
    this.router.navigateByUrl('login');
  }
}
