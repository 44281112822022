<div class="main-container">
  <div class="row">
    <div class="col-12 d-flex justify-content-center" *ngFor="let item of categories">
      <div class="card-categories" [class.selected-category]="item.selected === true" (click)="selectCategory(item)">
        <div>
          <h3>{{item.name}}</h3>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button class="btn-next" (click)="goToReadyToLaunch()">Go to Ready to launch</button>
    </div>
  </div>
</div>
