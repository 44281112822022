<div class="d-flex pt-2">
  <div class="columns-items" *ngFor="let item of claims">
    <div class="d-flex justify-content-center">
      <div class="img-claim">
        <img src="./assets/create/rtl/{{item}}.png" alt="claims of product ready to launch" />
      </div>
    </div>
    <div class="mt-2">
      <p class="title-items">{{ item }}</p>
    </div>
  </div>
</div>
