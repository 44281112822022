<div class="main-container">
    <div class="step-info">
        <p class="step-title">{{ stepName }}</p>
      <div class="buttons-container" style="margin-left: 20px;">
        <button class="request-btn" (click)="openRequestModal()">
          + Request Other Shape
        </button>
      </div>
      <div class="buttons-container" *ngIf="currentShape.formaId">
        <button class="size-btn" (click)="openRealLifeSizeModal(currentShape)">
          See Real Life Size
        </button>
      </div>
    </div>
    <div class="shape-size-options">
      <div class="shape-info">
        <div class="img-container">
          <img [src]="currentShape.img" alt="" class="selected-shape">
        </div>
        <div class="shape-size-details">
          <ng-container>
            <div class="shape-type-info" *ngIf="currentShape.img == ''">
              <p class="shape-type">Shapes</p>
            </div>
            <div class="shape-type-info" *ngIf="currentShape.img != ''">
              <p class="shape-type">{{currentShape.texto}}</p>
              <p class="shape-description">{{currentShape.descripcion}}</p>
            </div>
          </ng-container>
          <div class="size-info">
            <div class="size-row">
              <p>Length</p>
              <p>{{currentShape.largo || '0'}}</p>
            </div>
            <div class="size-row">
              <p>Width</p>
              <p>{{currentShape.ancho || '0'}}</p>
            </div>
            <div class="size-row">
              <p>Height</p>
              <p>{{currentShape.alto || '0'}}</p>
            </div>
          </div>
        </div>
      </div>
      <carousel [cellWidth]="100" [cellsToShow]="cellsToShow" [height]="150"  class="shapes-carousel">
        <ng-container *ngFor="let item of shapeAndSizeOptions; let i = index" class="scroll">
          <div class="carousel-cell" (click)="handleClickSelection(i)">
            <img [src]="item.img" alt="" class="shape-preview" [class.selected]="item.selected">
            <span class="weight-label">{{item.gramaje + ' ' + (item.unidad || '')}}</span>
          </div>
        </ng-container>
      </carousel>

      <div class="images-responsive">
        <div *ngFor="let item of shapeAndSizeOptions; let i = index">
          <div class=" text-center" (click)="handleClickSelection(i)">
            <div class="container-img">
              <img [src]="item.img" alt="img" class="shape-preview" [class.selected]="item.selected">
            </div>
            <span class="weight-label">{{item.gramaje + ' ' + (item.unidad || '')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
