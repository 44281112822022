<div class="main_container" (click)="cardClicked()">
  <div class="logo">
    <img src="{{imgPath}}" alt="logo">
  </div>
  <div class="information">
    <p class="title">
      {{title}}
    </p>
    <p class="details">
      {{detail}}
    </p>
  </div>
</div>