import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterTypeService } from 'src/app/core/services/register-type/register-type.service';


@Component({
  selector: 'app-menu-option',
  templateUrl: './menu-option.component.html',
  styleUrls: ['./menu-option.component.css'],
})
export class MenuOptionComponent implements OnInit {
  /** Input for the title of the card. */
  @Input() title = '';

  /** Input for the description of the card. */
  @Input() detail = '';

  /** Input for the image or logo of the card. */
  @Input() imgPathWhite = '';
  @Input() imgPathBrown = '';

  /** Input to indicate which path should the router redirect to. */
  @Input() redirectTo = '';

  /** Input to indicate which is the origin of the click. */
  @Input() origin = '';
  @Input() params = {};
  
  constructor(private router: Router, private registerTypeService: RegisterTypeService) {}


  ngOnInit(): void {
  }

  /**
   * This function handles the click events, redirecting the user and saving the click origin.
   */
  cardClicked() {
    this.router.navigate([this.redirectTo], { queryParams: this.params});
    this.saveUserClickOrigin();
  }

  /**
   * This function saves the name of the menu card where the user clicked from.
   */
  saveUserClickOrigin() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (this.origin === 'Consult') {
      this.registerTypeService.postRegisterType({ tipo: this.origin, usuario: user.id }).then();
    }
    localStorage.setItem('origin', this.origin);
  }
}
